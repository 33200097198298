import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import _orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { useEffect, useContext } from 'react';
import { Waypoint } from 'react-waypoint';
import {
  Button,
  Container,
  Grid,
  Icon,
  Label,
  Popup,
  Segment,
} from 'semantic-ui-react';

import { getUserPermsSync } from '@/rbac';
import arePermsGoodForRoute from '@routes/arePermsGoodForRoute';
import routeMetadata from '@routes/routeMetadata';
import { FeatureFlagContext } from '@shared/components/FeatureFlag';
import Link from '@shared/components/Link';
import serviceMetadata from '@components/System/Health/serviceMetadata.json';

// eslint-disable-next-line
import s from './Subnav.scss';

// A weakly-referenced map object for holding items associated with stateful
// image data
const cache = new WeakMap();

/**
 * Simple wrapper utility that uses a test to determine if an element should be
 * wrapped in an outer-element or not—this avoids all the conditional element
 * assignments that might otherwise be required in order to do this.
 * @param test {Boolean}
 * @param outer {JSX.Element} An outer element that is used if the test is true
 * @param children {JSX.Element} An inner element to be (optionally) wrapped
 * @returns {JSX.Element} A wrapped or unwrapped element
 */
const OptionalWrapper = ({ test, outer, children }) =>
  test ? outer(children) : children;

/**
 * Simple fetch wrapper to test that this is an admin account and that the data
 * is not already being fetched before retrieving the app service health info.
 * @param props {Object} The component props object provided by the HOC
 */
const fetchServiceList = props => {
  const { auth, app, fetchServicesHealth } = props;
  const { account } = auth;
  const { servicesHealth } = app;
  if (account.isAdmin && !servicesHealth.isFetchingServicesHealth) {
    fetchServicesHealth();
  }
};

/**
 * This operation calculates a status summary of service health. The result of
 * this operation is weakly mapped so that if the underlying system data changes
 * the cache will be reset.
 * @param props {Object} The component props object provided by the HOC
 */
const processSystemData = props => {
  const { app } = props;
  const { servicesHealth } = app;
  const { services } = servicesHealth;
  const { serviceStates, feeds } = services;
  let res = cache.get(services);

  if (serviceStates && feeds && res === undefined) {
    const reqServices = serviceMetadata.filter(servItem => servItem.required);

    const reqStates = serviceStates.reduce((obj, curr) => {
      const {
        service_name: serviceName,
        status,
        service_detail: serviceDetail,
      } = curr;
      const isUp = status && serviceDetail.up;
      const isReqService = reqServices.find(
        servItem => servItem.name === serviceName,
      );
      if (!isReqService || obj[serviceName]) {
        return obj;
      }
      return { ...obj, [serviceName]: isUp };
    }, {});

    const upReqs = reqServices.filter(servItem => reqStates[servItem.name]);
    const reqServicesReady = upReqs.length === reqServices.length;
    const hasFeeds = feeds && feeds.filter(feed => feed.enabled).length > 0;

    const totalGroups = feeds
      .filter(feed => feed.enabled)
      .reduce((arr, curr) => [...arr, ...curr.groups], [])
      .filter(feedGroup => feedGroup.enabled);

    const everyGroupHasRecords = totalGroups.every(
      group => group.record_count && group.record_count > 0,
    );

    res = {
      reqServicesReady,
      hasFeeds,
      everyGroupHasRecords,
    };

    cache.set(services, res);
  }

  return res || false;
};

/**
 * This operation generates the suffix label that provides the health status on
 * the **System** button.
 * @param props {Object} The component props object provided by the HOC
 * @returns {JSX.Element|null}
 */
const systemDataSuffix = props => {
  const systemData = processSystemData(props);
  let suffixEl = null;

  if (systemData) {
    const { hasFeeds, reqServicesReady, everyGroupHasRecords } = systemData;
    let systemIcon = 'times circle';
    let systemColor = 'red';

    if (hasFeeds && reqServicesReady) {
      systemIcon = 'check circle';
      systemColor = everyGroupHasRecords ? 'green' : 'orange';
    }

    suffixEl = (
      <span className={classNames(s.navBtnSuffix)}>
        <Icon
          name={systemIcon}
          color={systemColor}
          style={{ opacity: 1, lineHeight: '1rem', margin: 0 }}
        />
      </span>
    );
  }

  return suffixEl;
};

/**
 * This operation generates the popup that displays the last visited URL within
 * the Image Selection / Analysis component (if available).
 * @param props {Object} The component props object provided by the HOC
 * @param trigger
 * @returns {JSX.Element|null}
 */
const imageDataPopup = (props, trigger) => {
  const { image, auth } = props;
  const { account } = auth;

  const { routeInfo = {} } = image.selection;
  const routeInfoArr = [
    routeInfo.registry,
    routeInfo.repository,
    routeInfo.tag,
    routeInfo.id ? `${routeInfo.id.substring(0, 13)}...` : undefined,
  ];

  // Obtain the account context in order to qualify any `history` changes
  // required, either within this view or by any subcomponents within this
  // view
  const context = account.context?.accountname
    ? `/${encodeURIComponent(account.context?.accountname)}`
    : '';

  const pathStr = routeInfoArr.reduce((prev, curr) => {
    if (curr && !prev) {
      prev = curr;
    } else if (curr) {
      prev = `${prev}/${curr}`;
    }
    return prev;
  }, false);

  return pathStr ? (
    <Popup
      key={routeInfo.path}
      position="bottom center"
      hoverable
      flowing
      className="animate__animated animate__fadeIn"
      offset={[0, 10]}
      content={
        <a href={`${context}${routeInfo.path}`}>
          <Icon name="tag" color="teal" />
          <span>
            Return to&nbsp;
            <strong>{pathStr}</strong>
          </span>
        </a>
      }
      trigger={trigger}
    />
  ) : null;
};

/**
 * This operation generates the popup that displays the last visited URL within
 * the Applications component (if available).
 * @param props {Object} The component props object provided by the HOC
 * @param trigger
 * @returns {JSX.Element|null}
 */
const appDataPopup = (props, trigger) => {
  const { applications, auth } = props;
  const { selected = {} } = applications.viewProps;
  const { account } = auth;

  const pathStr = selected.application
    ? `${selected.applicationName}@${selected.versionName}`
    : false;

  // Obtain the account context in order to qualify any `history` changes
  // required, either within this view or by any subcomponents within this
  // view
  const context = account.context?.accountname
    ? `/${encodeURIComponent(account.context?.accountname)}`
    : '';

  return pathStr ? (
    <Popup
      key={selected.application}
      position="bottom center"
      hoverable
      flowing
      className="animate__animated animate__fadeIn"
      offset={[0, 10]}
      content={
        <a
          href={`${context}/applications/${selected.application}/${selected.version}`}
        >
          <Icon name="tag" color="teal" />
          <span>
            Return to&nbsp;
            <strong>{pathStr}</strong>
          </span>
        </a>
      }
      trigger={trigger}
    />
  ) : null;
};

/**
 * This operation generates the popup that provides the list of all available
 * policies within the account (if this data is available).
 * @param props {Object} The component props object provided by the HOC
 * @param trigger
 * @returns {null}
 */
const policyDataPopup = (props, trigger) => {
  let pContent = null;

  // Grab the policy data list (if present and populated)
  let policyData = props.policy?.manager?.data;
  policyData =
    policyData instanceof Array && policyData.length
      ? _orderBy(policyData, ['name'], ['asc'])
      : false;

  if (policyData) {
    // Always keep the active policy at the top of the dropdown
    pContent = policyData.sort((a, b) => (a.active ? -1 : b.active ? 1 : 0));

    pContent = pContent.map(pItem => (
      <div key={pItem.policy_id}>
        <Link
          className={s.btnPopupPolicyItmLink}
          to={`/policy/${pItem.policy_id}`}
          style={
            props.params && props.params.policyId === pItem.policy_id
              ? {
                  color: 'rgba(0,0,0,.87)',
                  cursor: 'default',
                  fontWeight: 700,
                }
              : null
          }
        >
          <div>{pItem.name}</div>
          {pItem.active ? (
            <Label
              style={{
                marginLeft: '0.5rem',
                minWidth: 'max-content',
              }}
              color="green"
              size="mini"
            >
              Active
            </Label>
          ) : null}
        </Link>
      </div>
    ));

    pContent = (
      <Popup
        key="policyPopup"
        position="bottom center"
        className="animate__animated animate__fadeIn"
        hoverable
        flowing
        content={pContent}
        trigger={trigger}
        offset={[0, 10]}
      />
    );
  }

  return pContent;
};

/**
 * Simple check to see if the report service is available and active.
 * @param props {Object} The component props object provided by the HOC
 * @returns {boolean}
 */
const isReportServiceGood = props => {
  const { isReportsEnabledAndActive } = props.app.healthCheck;
  return (
    isReportsEnabledAndActive?.enabled && isReportsEnabledAndActive?.active
  );
};

/**
 * Simple check to see if the current pathname differs from the route path
 * @param path {string} The configured route path such as '/applications'
 * @returns {boolean}
 */
const isPathDifferent = path =>
  process.env.BROWSER && window.location.pathname !== path;

/**
 * Main composition factory for each button within the main sub-navigation area.
 * @param props {Object} The component props object provided by the HOC
 * @param metadata Navigation bar metadata provided by the route configuration
 * @returns {JSX.Element}
 */
const navBtnBuilder = (props, metadata, featureFlags) => {
  const { auth } = props;
  const { account } = auth;

  return routeMetadata.reduce(
    (prev, curr) => {
      const { navbar, path, featureFlag } = curr;

      const featureAvailable =
        !featureFlag || featureFlags.includes(featureFlag);
      if (navbar && navbar.show !== false && featureAvailable) {
        const { permissions, enable } = navbar;
        let enabled =
          enable ||
          arePermsGoodForRoute({
            permissions: getUserPermsSync({
              account,
              actions: permissions,
            }),
            route: curr,
          });

        if (metadata[navbar.name]?.enabled) {
          enabled = enabled && metadata[navbar.name]?.enabled;
        }

        const shouldLink =
          metadata[navbar.name]?.shouldLink?.(curr.path) ?? true;

        let el = (
          <span className={s.navbarBtnWrapper} key={navbar.name}>
            <OptionalWrapper
              test={enabled && shouldLink}
              outer={children => <Link to={path}>{children}</Link>}
            >
              <Button
                basic
                disabled={!enabled}
                size="small"
                active={props.active === navbar.title}
                icon
                as="span"
                className={classNames('labeled', s.navbarBtn)}
              >
                <Icon
                  color={props.active === navbar.title ? 'grey' : 'teal'}
                  className={classNames(
                    s[navbar.icon] || navbar.icon,
                    props.active === navbar.title ? 'active' : '',
                    s.navbarBtnIcon,
                    s[`${navbar.name}Icon`],
                  )}
                />
                <span className={s.navbarBtnContent}>
                  <span>{navbar.title}</span>
                  {props.active !== navbar.title &&
                  metadata[navbar.name]?.suffix
                    ? metadata[navbar.name].suffix(props)
                    : null}
                </span>
              </Button>
            </OptionalWrapper>
          </span>
        );

        // Wrap the element in a popup if one is specified
        if (metadata[navbar.name]?.popup && props.active !== navbar.title) {
          el = metadata[navbar.name]?.popup(props, el) || el;
        }

        prev[navbar.position || 'left'].push(el);
      }

      return prev;
    },
    {
      left: [],
      right: [],
    },
  );
};

// --- MAIN --------------------------------------------------------------------

/**
 * @param props {Object} The component props object provided by the HOC
 * @returns {JSX.Element|null}
 */
const Subnav = props => {
  const { enrichInventoryView } = props;

  useEffect(() => {
    fetchServiceList(props);
  }, [props.auth.isAuthenticated]);

  const featureFlags = useContext(FeatureFlagContext);

  const buttonListObj = navBtnBuilder(
    props,
    {
      artifacts: { popup: imageDataPopup },
      applications: { popup: appDataPopup },
      policy: { popup: policyDataPopup },
      system: { suffix: systemDataSuffix },
      reports: { enabled: isReportServiceGood },
      inventory: {
        enabled: enrichInventoryView ? isReportServiceGood : undefined,
        shouldLink: isPathDifferent,
      },
    },
    featureFlags,
  );

  return props.auth.isAuthenticated ? (
    <Waypoint
      topOffset="16px"
      onEnter={() => {
        props.setTopnavViewProps('topnav', {
          navbtns: 'up',
        });
      }}
      onLeave={() => {
        const topnavEl = document.getElementById('navBtnGrp');
        if (topnavEl && topnavEl.classList.contains('hidden')) {
          topnavEl.classList.remove('hidden');
        }
        props.setTopnavViewProps('topnav', {
          navbtns: 'down',
        });
      }}
    >
      <div className={s.root}>
        <Container className={s.container}>
          <Segment.Group className={s.noTopBorderRadius}>
            <Segment className={s.noTopBorderRadius}>
              <Grid>
                <Grid.Row column={2}>
                  <Grid.Column width={14}>{buttonListObj.left}</Grid.Column>
                  <Grid.Column textAlign="right" width={2}>
                    {buttonListObj.right}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Segment.Group>
        </Container>
      </div>
    </Waypoint>
  ) : null;
};

export const propTypes = {
  app: PropTypes.shape({}).isRequired,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }).isRequired,
  setTopnavViewProps: PropTypes.func.isRequired,
  enrichInventoryView: PropTypes.bool.isRequired,
};

Subnav.propTypes = propTypes;

export default withStyles(s)(Subnav);
