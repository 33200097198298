import type { InferProps } from 'prop-types';
import type { FunctionComponent } from 'react';
import { connect } from 'react-redux';

import { fetchServicesHealth } from '@/redux/actions/action-services';
import { updateAccountContext } from '@/redux/actions/action-system';
import { setTopnavViewProps } from '@/redux/actions/action-topnav';
import type { RootState } from '@redux/store';

import Subnav, { type propTypes } from './Subnav';

export default connect(
  (state: RootState) => ({
    app: state.app,
    auth: state.auth,
    events: state.events,
    policy: state.policy,
    image: state.image,
    applications: state.applications,
    system: state.system,
  }),
  {
    updateAccountContext,
    setTopnavViewProps,
    fetchServicesHealth,
  },
)(Subnav as FunctionComponent<InferProps<typeof propTypes>>);
