import type { Reducer, AnyAction } from 'redux';

import type {
  AnchoreImageWithAncestors,
  ArtifactCompliance,
  GetArtifactComplianceQueryParams,
  PolicyRecord,
  Source,
} from '@models';
import {
  SET_POLICY_BUNDLE_FETCH_PENDING,
  SET_POLICY_BUNDLE_FETCH_SUCCESS,
  SET_POLICY_BUNDLE_FETCH_ERROR,
  SET_POLICY_BUNDLE_UPDATE_PENDING,
  SET_POLICY_BUNDLE_UPDATE_SUCCESS,
  SET_POLICY_BUNDLE_UPDATE_ERROR,
  SET_POLICY_EDITOR_VIEW_PROPERTIES,
  SET_POLICY_EDITOR_ACTIVE_TAB,
  SET_POLICY_EDITOR_EDIT_NAME,
  SET_POLICY_EDITOR_EDIT_NAME_ERROR,
  SET_POLICY_EDITOR_EDIT_DESCRIPTION,
  SET_POLICY_EDITOR_EDIT_DESCRIPTION_ERROR,
  SET_POLICY_ITEM_REMOVE_DIALOG,
  SET_POLICY_ITEM_REMOVE_ERROR,
  SET_POLICY_ITEM_COPY_DIALOG,
  SET_POLICY_ITEM_COPY_ERROR,
  SET_POLICY_ITEM_NEW_DIALOG,
  SET_POLICY_ITEM_NEW_ERROR,
  SET_POLICY_ITEM_EDIT_DIALOG,
  SET_POLICY_SPEC_FETCH_PENDING,
  SET_POLICY_SPEC_FETCH_SUCCESS,
  SET_POLICY_SPEC_FETCH_ERROR,
  SET_POLICY_ITEM_EDIT_NAME,
  SET_POLICY_ITEM_EDIT_NAME_ERROR,
  SET_POLICY_ITEM_EDIT_DESCRIPTION,
  SET_POLICY_ITEM_EDIT_DESCRIPTION_ERROR,
  SET_POLICY_ITEM_EDIT_RULE_DESCRIPTION,
  SET_POLICY_ITEM_EDIT_RULE_DESCRIPTION_ERROR,
  SET_POLICY_ITEM_EDIT_SAVE_RULES_ERROR,
  SET_ALLOWLIST_ITEM_REMOVE_DIALOG,
  SET_ALLOWLIST_ITEM_REMOVE_ERROR,
  SET_ALLOWLIST_ITEM_COPY_DIALOG,
  SET_ALLOWLIST_ITEM_COPY_ERROR,
  SET_ALLOWLIST_ITEM_NEW_DIALOG,
  SET_ALLOWLIST_ITEM_NEW_ERROR,
  SET_ALLOWLIST_UPLOAD_DIALOG,
  SET_ALLOWLIST_UPLOAD_DIALOG_JSON_FILE,
  SET_ALLOWLIST_UPLOAD_DIALOG_SCHEMA_ERROR,
  SET_ALLOWLIST_ITEM_EDIT_DIALOG,
  SET_ALLOWLIST_ITEM_EDIT_NAME,
  SET_ALLOWLIST_ITEM_EDIT_NAME_ERROR,
  SET_ALLOWLIST_ITEM_EDIT_DESCRIPTION,
  SET_ALLOWLIST_ITEM_EDIT_DESCRIPTION_ERROR,
  SET_ALLOWLIST_ITEM_EDIT_RULE_DESCRIPTION,
  SET_ALLOWLIST_ITEM_EDIT_RULE_DESCRIPTION_ERROR,
  SET_ALLOWLIST_ITEM_EDIT_SAVE_ITEMS_ERROR,
  SET_MAPPING_ITEM_REMOVE_DIALOG,
  SET_MAPPING_EDITOR_SHOWING,
  SET_MAPPING_EDITOR_SUCCESS,
  SET_MAPPING_EDITOR_ERROR,
  SET_TRUSTED_ITEM_ACTIVE_TAB,
  SET_TRUSTED_ITEM_NEW_DIALOG,
  SET_TRUSTED_ITEM_NEW_ERROR,
  SET_TRUSTED_ITEM_REMOVE_DIALOG,
  SET_TRUSTED_ITEM_REMOVE_ERROR,
  SET_ARTIFACT_FETCH_PENDING,
  SET_ARTIFACT_FETCH_SUCCESS,
  SET_ARTIFACT_FETCH_ERROR,
  SET_ARTIFACT_COMPLIANCE_PENDING,
  SET_ARTIFACT_COMPLIANCE_SUCCESS,
  SET_ARTIFACT_COMPLIANCE_ERROR,
  SET_TESTBUNDLE_QUERY_STRING,
  SET_TESTBUNDLE_RESET_IMAGE,
  SET_TESTBUNDLE_SHOWING_RESULTS,
} from '@redux/actionTypes';

const policyEditorPopups = {
  isEditNamePopupOpen: false,
  isEditDescPopupOpen: false,
  isPolicyItemEditNamePopupOpen: false,
  isPolicyItemEditDescPopupOpen: false,
  isAllowlistItemEditNamePopupOpen: false,
  isAllowlistItemEditDescPopupOpen: false,
  isEditMappingNamePopupOpen: false,
  isEditMappingRegPopupOpen: false,
  isEditMappingPolicyPopupOpen: false,
  isEditMappingAllowlistPopupOpen: false,
};

export interface PolicyEditorState {
  data: PolicyRecord | null;
  errorMsg: any;
  isRemovePolicyItemModalOpen: string | false;
  isCopyPolicyItemModalOpen: string | false;
  copyPolicyItemError: boolean;
  removePolicyItemError: boolean;
  isNewPolicyItemModalOpen: boolean;
  newPolicyArtifactType?: string;
  isUpdating: boolean;
  newPolicyItemError: boolean;
  viewProps: any;
  isEditNamePopupOpen: boolean;
  editNameError: boolean;
  isFetching: boolean;
  fetchError: boolean;
  activeTabIndex: number;

  // Policy Editor - Policy Item Rules Editor Props
  isEditPolicyItemModalOpen: boolean;
  isEditPolicyItemModalScoped: boolean;
  editPolicyItemError: boolean;
  savePolicyItemRulesError: boolean;
  isPolicyItemEditNamePopupOpen: boolean;
  editPolicyItemNameError: boolean;
  isPolicyItemEditDescPopupOpen: boolean;
  editPolicyItemDescError: boolean;
  isPolicyItemEditRuleDescPopupOpen: boolean;
  editPolicyItemRuleDescError: boolean;

  // Policy Editor - Allowlist Item Default Props
  isUploadAllowlistModalOpen: boolean;
  uploadError: boolean;
  JSONFileData: boolean;
  JSONFileDataString: boolean;
  isRemoveAllowlistItemModalOpen: boolean | string;
  removeAllowlistItemError: boolean;
  isCopyAllowlistItemModalOpen: boolean | string;
  copyAllowlistItemError: boolean;
  isNewAllowlistItemModalOpen: boolean;
  newAllowlistItemError: boolean;

  // Policy Editor - Allowlist Item Items Editor Props
  isEditAllowlistItemModalOpen: boolean;
  editAllowlistItemError: boolean;
  isAllowlistItemEditNamePopupOpen: boolean;
  editAllowlistItemNameError: boolean;
  isAllowlistItemEditDescPopupOpen: boolean;
  editAllowlistItemDescError: boolean;
  isAllowlistItemEditRuleDescPopupOpen: boolean;
  editAllowlistItemRuleDescError: boolean;
  saveAllowlistItemRulesError: boolean;

  // Policy Editor - Mapping Item Default Props
  isRemoveMappingItemModalOpen: boolean;
  removeMappingItemError: boolean;
  isMappingEditorOpen: boolean;
  mappingEditorError: boolean;

  // Allowed / Denied Images - Policy Spec Props
  trustedItemActiveTabIndex: number;
  isNewTrustedItemModalOpen: boolean;
  isNewTrustedItemTrusted: boolean;
  newTrustedItemError: boolean;
  isRemoveTrustedItemModalOpen: boolean | string;
  isRemoveTrustedItemTrusted: boolean;
  removeTrustedItemError: boolean;

  // Policy Editor - Policy Spec Props
  isFetchingSpec: boolean;
  fetchSpecError: boolean;
  spec: any[];

  // Policy Editor - Test Policy
  testBundleQuery: string;
  lastCheckParams: GetArtifactComplianceQueryParams | false;
  showingTestResults: boolean;

  // Policy Editor - Fetch Artifact Data
  isFetchingArtifact: boolean;
  fetchArtifactError: boolean;
  artifact: AnchoreImageWithAncestors | Source | boolean;

  // Policy Editor - Fetch Artifact Compliance
  isFetchingArtifactCompliance: boolean;
  fetchArtifactComplianceError: boolean;
  check: boolean | ArtifactCompliance;
}

export const defaultState: PolicyEditorState = {
  // Policy Editor Default and General Props
  isEditNamePopupOpen: false,
  editNameError: false,
  isFetching: false,
  fetchError: false,
  isUpdating: false,
  errorMsg: false,
  data: null,
  viewProps: {
    bundles: {
      filterStr: '',
      page: 0,
      pageSize: 10,
    },
    policy: {
      filterStr: '',
      page: 0,
      pageSize: 10,
    },
    policyEdit: {
      filterStr: '',
      page: 0,
      pageSize: 10,
    },
    allowlist: {
      filterStr: '',
      page: 0,
      pageSize: 10,
    },
    allowlistEdit: {
      filterStr: '',
      page: 0,
      pageSize: 10,
    },
    mapping: {
      image: {
        filterStr: '',
        page: 0,
        pageSize: 10,
      },
      source: {
        filterStr: '',
        page: 0,
        pageSize: 10,
      },
    },
    trusted: {
      allowed: {
        filterStr: '',
        page: 0,
        pageSize: 10,
      },
      denied: {
        filterStr: '',
        page: 0,
        pageSize: 10,
      },
    },
    test: {
      filterStr: '',
      page: 0,
      pageSize: 10,
    },
  },

  // Policy Editor - Policy Item Default Props
  activeTabIndex: 0,
  isRemovePolicyItemModalOpen: false,
  removePolicyItemError: false,
  isCopyPolicyItemModalOpen: false,
  copyPolicyItemError: false,
  isNewPolicyItemModalOpen: false,
  newPolicyItemError: false,

  // Policy Editor - Policy Item Rules Editor Props
  isEditPolicyItemModalOpen: false,
  isEditPolicyItemModalScoped: false,
  editPolicyItemError: false,
  savePolicyItemRulesError: false,
  isPolicyItemEditNamePopupOpen: false,
  editPolicyItemNameError: false,
  isPolicyItemEditDescPopupOpen: false,
  editPolicyItemDescError: false,
  isPolicyItemEditRuleDescPopupOpen: false,
  editPolicyItemRuleDescError: false,

  // Policy Editor - Allowlist Item Default Props
  isUploadAllowlistModalOpen: false,
  uploadError: false,
  JSONFileData: false,
  JSONFileDataString: false,
  isRemoveAllowlistItemModalOpen: false,
  removeAllowlistItemError: false,
  isCopyAllowlistItemModalOpen: false,
  copyAllowlistItemError: false,
  isNewAllowlistItemModalOpen: false,
  newAllowlistItemError: false,

  // Policy Editor - Allowlist Item Items Editor Props
  isEditAllowlistItemModalOpen: false,
  editAllowlistItemError: false,
  isAllowlistItemEditNamePopupOpen: false,
  editAllowlistItemNameError: false,
  isAllowlistItemEditDescPopupOpen: false,
  editAllowlistItemDescError: false,
  isAllowlistItemEditRuleDescPopupOpen: false,
  editAllowlistItemRuleDescError: false,
  saveAllowlistItemRulesError: false,

  // Policy Editor - Mapping Item Default Props
  isRemoveMappingItemModalOpen: false,
  removeMappingItemError: false,
  isMappingEditorOpen: false,
  mappingEditorError: false,

  // Allowed / Denied Images - Policy Spec Props
  trustedItemActiveTabIndex: 0,
  isNewTrustedItemModalOpen: false,
  isNewTrustedItemTrusted: false,
  newTrustedItemError: false,
  isRemoveTrustedItemModalOpen: false,
  isRemoveTrustedItemTrusted: false,
  removeTrustedItemError: false,

  // Policy Editor - Policy Spec Props
  isFetchingSpec: false,
  fetchSpecError: false,
  spec: [],

  // Policy Editor - Test Policy
  testBundleQuery: '',
  lastCheckParams: false,
  showingTestResults: false,

  // Policy Editor - Fetch Artifact Data
  isFetchingArtifact: false,
  fetchArtifactError: false,
  artifact: false,

  // Policy Editor - Fetch Artifact Compliance
  isFetchingArtifactCompliance: false,
  fetchArtifactComplianceError: false,
  check: false,
};

const reducer: Reducer<PolicyEditorState> = (
  state = defaultState,
  action: AnyAction,
) => {
  switch (action.type) {
    /**
     * *************************************************************************
     * POLICY EDITOR - POLICY SPEC DATA REDUCERS
     */
    case SET_POLICY_SPEC_FETCH_PENDING:
      return {
        ...state,
        isFetchingSpec: true,
      };
    case SET_POLICY_SPEC_FETCH_SUCCESS: {
      const obj = {
        ...state,
        isFetchingSpec: false,
        fetchSpecError: false,
        spec: action.resp.data,
      };
      // Don't wipe errorMsg if errExists
      if (!action.errExists) obj.errorMsg = false;
      return obj;
    }
    case SET_POLICY_SPEC_FETCH_ERROR:
      return {
        ...state,
        isFetchingSpec: false,
        fetchSpecError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * POLICY EDITOR - POLICY DATA REDUCERS
     */
    case SET_POLICY_BUNDLE_UPDATE_PENDING:
      return {
        ...state,
        isUpdating: true,
      };
    case SET_POLICY_BUNDLE_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        updateError: false,
        errorMsg: false,
        data: action.resp.data,
      };
    case SET_POLICY_BUNDLE_UPDATE_ERROR:
      return {
        ...state,
        isUpdating: false,
        updateError: true,
        errorMsg: action.errorMsg,
      };

    case SET_POLICY_BUNDLE_FETCH_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case SET_POLICY_BUNDLE_FETCH_SUCCESS: {
      const obj = {
        ...state,
        isFetching: false,
        fetchError: false,
        data: action.resp.data,
        check: false,
        artifact: false,
        testBundleQuery: '',
      };
      // Don't wipe errorMsg if errExists
      if (!action.errExists) obj.errorMsg = false;
      return obj;
    }
    case SET_POLICY_BUNDLE_FETCH_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchError: true,
        errorMsg: action.errorMsg,
      };

    case SET_POLICY_EDITOR_ACTIVE_TAB:
      return {
        ...state,
        activeTabIndex: action.idx,
        editNameError: false,
        errorMsg: false,
      };

    case SET_POLICY_EDITOR_VIEW_PROPERTIES:
      return {
        ...state,
        viewProps: action.viewProps,
      };

    case SET_POLICY_EDITOR_EDIT_NAME:
      return {
        ...state,
        ...policyEditorPopups,
        isEditNamePopupOpen: action.open,
        editNameError: false,
        errorMsg: false,
      };
    case SET_POLICY_EDITOR_EDIT_NAME_ERROR:
      return {
        ...state,
        editNameError: true,
        errorMsg: action.errorMsg,
      };

    case SET_POLICY_EDITOR_EDIT_DESCRIPTION:
      return {
        ...state,
        ...policyEditorPopups,
        isEditDescPopupOpen: action.open,
        editDescError: false,
        errorMsg: false,
      };
    case SET_POLICY_EDITOR_EDIT_DESCRIPTION_ERROR:
      return {
        ...state,
        editDescError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * POLICY EDITOR, TOP-LEVEL - POLICY ITEM DATA REDUCERS
     */
    case SET_POLICY_ITEM_REMOVE_DIALOG:
      return {
        ...state,
        ...policyEditorPopups,
        isRemovePolicyItemModalOpen: action.open,
        removePolicyItemError: false,
        errorMsg: false,
      };
    case SET_POLICY_ITEM_REMOVE_ERROR:
      return {
        ...state,
        removePolicyItemError: true,
        errorMsg: action.errorMsg,
      };

    case SET_POLICY_ITEM_COPY_DIALOG:
      return {
        ...state,
        ...policyEditorPopups,
        isCopyPolicyItemModalOpen: action.open,
        copyPolicyItemError: false,
        errorMsg: false,
      };
    case SET_POLICY_ITEM_COPY_ERROR:
      return {
        ...state,
        copyPolicyItemError: true,
        errorMsg: action.errorMsg,
      };

    case SET_POLICY_ITEM_NEW_DIALOG:
      return {
        ...state,
        ...policyEditorPopups,
        isNewPolicyItemModalOpen: action.open,
        newPolicyArtifactType: action.artifactType,
        newPolicyItemError: false,
        errorMsg: false,
      };
    case SET_POLICY_ITEM_NEW_ERROR:
      return {
        ...state,
        newPolicyItemError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * POLICY EDITOR, RULES EDITOR - POLICY ITEM DATA REDUCERS
     */
    case SET_POLICY_ITEM_EDIT_DIALOG:
      return {
        ...state,
        ...policyEditorPopups,
        isEditPolicyItemModalOpen: action.open,
        isEditPolicyItemModalScoped: action.scope,
        editPolicyItemError: false,
        errorMsg: false,
      };
    case SET_POLICY_ITEM_EDIT_NAME:
      return {
        ...state,
        ...policyEditorPopups,
        isPolicyItemEditNamePopupOpen: action.open,
        editPolicyItemNameError: false,
        errorMsg: false,
      };
    case SET_POLICY_ITEM_EDIT_NAME_ERROR:
      return {
        ...state,
        editPolicyItemNameError: true,
        errorMsg: action.errorMsg,
      };

    case SET_POLICY_ITEM_EDIT_DESCRIPTION:
      return {
        ...state,
        ...policyEditorPopups,
        isPolicyItemEditDescPopupOpen: action.open,
        editPolicyItemDescError: false,
        errorMsg: false,
      };
    case SET_POLICY_ITEM_EDIT_DESCRIPTION_ERROR:
      return {
        ...state,
        editPolicyItemDescError: true,
        errorMsg: action.errorMsg,
      };

    case SET_POLICY_ITEM_EDIT_RULE_DESCRIPTION:
      return {
        ...state,
        ...policyEditorPopups,
        isPolicyItemEditRuleDescPopupOpen: action.open,
        editPolicyItemRuleDescError: false,
        errorMsg: false,
      };
    case SET_POLICY_ITEM_EDIT_RULE_DESCRIPTION_ERROR:
      return {
        ...state,
        editPolicyItemRuleDescError: true,
        errorMsg: action.errorMsg,
      };

    case SET_POLICY_ITEM_EDIT_SAVE_RULES_ERROR:
      return {
        ...state,
        savePolicyItemRulesError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * POLICY EDITOR, TOP-LEVEL - ALLOWLIST UPLOAD ITEM DATA REDUCERS
     */
    case SET_ALLOWLIST_UPLOAD_DIALOG:
      return {
        ...state,
        ...policyEditorPopups,
        isUploadAllowlistModalOpen: action.open,
        uploadError: false,
        errorMsg: false,
        JSONFileData: false,
        JSONFileDataString: false,
      };
    case SET_ALLOWLIST_UPLOAD_DIALOG_JSON_FILE: {
      return {
        ...state,
        ...{
          isJSONDragOver: action.over,
          schemaError: false,
          JSONFileDataObj: action.data || false,
          errorMsg: action.error || false,
        },
      };
    }
    case SET_ALLOWLIST_UPLOAD_DIALOG_SCHEMA_ERROR:
      return {
        ...state,
        schemaError: action.errorArr,
        errorMsg: false,
      };

    /**
     * *************************************************************************
     * POLICY EDITOR, TOP-LEVEL - ALLOWLIST ITEM DATA REDUCERS
     */
    case SET_ALLOWLIST_ITEM_REMOVE_DIALOG:
      return {
        ...state,
        ...policyEditorPopups,
        isRemoveAllowlistItemModalOpen: action.open,
        removeAllowlistItemError: false,
        errorMsg: false,
      };
    case SET_ALLOWLIST_ITEM_REMOVE_ERROR:
      return {
        ...state,
        removeAllowlistItemError: true,
        errorMsg: action.errorMsg,
      };

    case SET_ALLOWLIST_ITEM_COPY_DIALOG:
      return {
        ...state,
        ...policyEditorPopups,
        isCopyAllowlistItemModalOpen: action.open,
        copyAllowlistItemError: false,
        errorMsg: false,
      };
    case SET_ALLOWLIST_ITEM_COPY_ERROR:
      return {
        ...state,
        copyAllowlistItemError: true,
        errorMsg: action.errorMsg,
      };

    case SET_ALLOWLIST_ITEM_NEW_DIALOG:
      return {
        ...state,
        ...policyEditorPopups,
        isNewAllowlistItemModalOpen: action.open,
        newAllowlistItemError: false,
        errorMsg: false,
      };
    case SET_ALLOWLIST_ITEM_NEW_ERROR:
      return {
        ...state,
        newAllowlistItemError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * POLICY EDITOR, ITEMS EDITOR - ALLOWLIST ITEM DATA REDUCERS
     */
    case SET_ALLOWLIST_ITEM_EDIT_DIALOG:
      return {
        ...state,
        ...policyEditorPopups,
        isEditAllowlistItemModalOpen: action.open,
        editAllowlistItemError: false,
        errorMsg: false,
      };

    case SET_ALLOWLIST_ITEM_EDIT_NAME:
      return {
        ...state,
        ...policyEditorPopups,
        isAllowlistItemEditNamePopupOpen: action.open,
        editAllowlistItemNameError: false,
        errorMsg: false,
      };
    case SET_ALLOWLIST_ITEM_EDIT_NAME_ERROR:
      return {
        ...state,
        editAllowlistItemNameError: true,
        errorMsg: action.errorMsg,
      };

    case SET_ALLOWLIST_ITEM_EDIT_DESCRIPTION:
      return {
        ...state,
        ...policyEditorPopups,
        isAllowlistItemEditDescPopupOpen: action.open,
        editAllowlistItemDescError: false,
        errorMsg: false,
      };
    case SET_ALLOWLIST_ITEM_EDIT_DESCRIPTION_ERROR:
      return {
        ...state,
        editAllowlistItemDescError: true,
        errorMsg: action.errorMsg,
      };

    case SET_ALLOWLIST_ITEM_EDIT_RULE_DESCRIPTION:
      return {
        ...state,
        ...policyEditorPopups,
        isAllowlistItemEditRuleDescPopupOpen: action.open,
        editAllowlistItemRuleDescError: false,
        errorMsg: false,
      };
    case SET_ALLOWLIST_ITEM_EDIT_RULE_DESCRIPTION_ERROR:
      return {
        ...state,
        editAllowlistItemRuleDescError: true,
        errorMsg: action.errorMsg,
      };

    case SET_ALLOWLIST_ITEM_EDIT_SAVE_ITEMS_ERROR:
      return {
        ...state,
        saveAllowlistItemRulesError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * POLICY EDITOR, TOP-LEVEL - MAPPING ITEM DATA REDUCERS
     */
    case SET_MAPPING_ITEM_REMOVE_DIALOG:
      return {
        ...state,
        ...policyEditorPopups,
        isRemoveMappingItemModalOpen: action.open,
        removeMappingItemError: false,
        errorMsg: false,
      };

    case SET_MAPPING_EDITOR_SHOWING:
      return {
        ...state,
        openMappingEditorId: action.showingMappingId,
        mappingEditorError: false,
        errorMsg: false,
      };

    case SET_MAPPING_EDITOR_SUCCESS:
      return {
        ...state,
        openMappingEditorId: false,
        mappingEditorError: false,
        errorMsg: false,
      };
    case SET_MAPPING_EDITOR_ERROR:
      return {
        ...state,
        mappingEditorError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * POLICY EDITOR, TOP-LEVEL - ALLOWED / DENIED IMAGES
     */
    case SET_TRUSTED_ITEM_ACTIVE_TAB:
      return {
        ...state,
        trustedItemActiveTabIndex: action.idx,
      };

    case SET_TRUSTED_ITEM_NEW_DIALOG:
      return {
        ...state,
        ...policyEditorPopups,
        isNewTrustedItemModalOpen: action.open,
        isNewTrustedItemTrusted: action.isTrusted,
        newTrustedItemError: false,
        errorMsg: false,
      };
    case SET_TRUSTED_ITEM_NEW_ERROR:
      return {
        ...state,
        newTrustedItemError: true,
        errorMsg: action.errorMsg,
      };

    case SET_TRUSTED_ITEM_REMOVE_DIALOG:
      return {
        ...state,
        ...policyEditorPopups,
        isRemoveTrustedItemModalOpen: action.open,
        isRemoveTrustedItemTrusted: action.isTrusted,
        removeTrustedItemError: false,
        errorMsg: false,
      };
    case SET_TRUSTED_ITEM_REMOVE_ERROR:
      return {
        ...state,
        removeTrustedItemError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * POLICY EDITOR, TOP-LEVEL - TEST POLICY QUERY STRING
     */
    case SET_TESTBUNDLE_QUERY_STRING:
      return {
        ...state,
        testBundleQuery: action.query || '',
        artifact: false,
        check: false,
        fetchArtifactError: false,
        lastCheckParams: false,
      };

    case SET_TESTBUNDLE_RESET_IMAGE:
      return {
        ...state,
        artifact: false,
        check: false,
        fetchArtifactError: false,
        testBundleQuery: '',
        lastCheckParams: false,
      };

    case SET_TESTBUNDLE_SHOWING_RESULTS:
      return {
        ...state,
        showingTestResults: action.value,
      };
    /**
     * *************************************************************************
     * POLICY EDITOR, TOP-LEVEL - FETCH ARTIFACT DATA REDUCERS
     */
    case SET_ARTIFACT_FETCH_PENDING:
      return {
        ...state,
        isFetchingArtifact: true,
        check: false,
      };
    case SET_ARTIFACT_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingArtifact: false,
        fetchArtifactError: false,
        errorMsg: false,
        artifact: action.resp.data,
        check: false,
      };
    case SET_ARTIFACT_FETCH_ERROR:
      return {
        ...state,
        isFetchingArtifact: false,
        fetchArtifactError: true,
        errorMsg: action.errorMsg,
        artifact: false,
      };

    /**
     * *************************************************************************
     * POLICY EDITOR, TOP-LEVEL - CHECK ARTIFACT DATA REDUCERS
     */
    case SET_ARTIFACT_COMPLIANCE_PENDING:
      return {
        ...state,
        isFetchingArtifactCompliance: true,
        lastCheckParams: action.params,
      };
    case SET_ARTIFACT_COMPLIANCE_SUCCESS:
      return {
        ...state,
        isFetchingArtifactCompliance: false,
        fetchArtifactComplianceError: false,
        errorMsg: false,
        check: action.resp.data,
      };
    case SET_ARTIFACT_COMPLIANCE_ERROR:
      return {
        ...state,
        isFetchingArtifactCompliance: false,
        fetchArtifactComplianceError: true,
        errorMsg: action.errorMsg,
        lastCheckParams: false,
        check: false,
      };

    default:
      return state;
  }
};

export default reducer;
