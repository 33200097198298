import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Icon, Message } from 'semantic-ui-react';

import s from './NoData.scss';

const NoData = props => {
  const { filterStr, container, items, func, conf, buttonEl } = props;
  const customControl = useMemo(() => func(conf), [func, conf]);
  return (
    <div className={s.root}>
      {filterStr && filterStr.length && items && items.length ? (
        <Message>
          <Icon fitted name="exclamation circle" />
          &nbsp;No results returned for this filter.
        </Message>
      ) : (
        <Message>
          <Icon fitted name="exclamation circle" />
          &nbsp;Hmm, it looks like this {container} doesn&#39;t have any&nbsp;
          {items}
          &nbsp;yet&hellip;&nbsp;
          {buttonEl || customControl}
        </Message>
      )}
    </div>
  );
};

export const propTypes = {
  filterStr: PropTypes.string.isRequired,
  container: PropTypes.string,
  items: PropTypes.string.isRequired,
  func: PropTypes.func,
  conf: PropTypes.shape({}),
  buttonEl: PropTypes.element,
};

NoData.propTypes = propTypes;

NoData.defaultProps = {
  container: 'item',
  conf: {},
  func: () => null,
  buttonEl: undefined,
};

export default withStyles(s)(NoData);
