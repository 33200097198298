// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LD7Ft{background-color:"#fff";border-radius:".28571429rem"}.LD7Ft .bMx1U{text-align:"left"}.LD7Ft div.IxbBW{align-items:center;display:flex;flex-direction:row;line-height:16px;line-height:1rem;margin-left:-.75rem}.LD7Ft div.IxbBW strong{margin-left:.75rem;margin-right:.25rem}.LD7Ft div.IxbBW span{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell": `LD7Ft`,
	"editorButton": `bMx1U`,
	"cellValue": `IxbBW`
};
module.exports = ___CSS_LOADER_EXPORT___;
