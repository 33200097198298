import {
  SET_WHATS_NEW_DIALOG,
  SET_WHATS_NEW_FEATURE_IDX,
  SET_WHATS_NEW_PREV_IDX,
  SET_WHATS_NEW_SHOW_CTRL,
} from '../actionTypes';

export const setWhatsNewFeatureIndex = (idx = -1) => ({
  type: SET_WHATS_NEW_FEATURE_IDX,
  idx,
});

export const setWhatsNewPrevIndex = (idx = -1) => ({
  type: SET_WHATS_NEW_PREV_IDX,
  idx,
});

export const setWhatsNewShowCtrl = (show = false) => ({
  type: SET_WHATS_NEW_SHOW_CTRL,
  show,
});

export const setWhatsNewDialog = (open = false) => ({
  type: SET_WHATS_NEW_DIALOG,
  open,
});
