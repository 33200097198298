import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';

import {
  SET_EVENTS_PENDING,
  SET_EVENTS_SUCCESS,
  SET_EVENTS_ERROR,
  SET_EVENTS_VIEW_PROPERTIES,
  SET_EVENTS_ACTIVE_TAB,
  SET_NOTIFICATIONS_ACTIVE_TAB,
  SET_EVENTS_REMOVE_DIALOG,
  SET_EVENT_REMOVE_DIALOG,
  SET_EVENTS_REMOVE_PENDING,
  SET_EVENTS_REMOVE_SUCCESS,
  SET_EVENTS_REMOVE_ERROR,
  CLEAR_EVENTS_REMOVE_ERRORS,
  CLEAR_EVENTS_PENDING,
  CLEAR_EVENTS_SUCCESS,
  CLEAR_EVENTS_ERROR,
  SET_EVENT_REMOVE_PENDING,
  SET_EVENT_REMOVE_SUCCESS,
  SET_EVENT_REMOVE_ERROR,
  SET_NEW_NOTIFICATION_CONFIG_TEST_PENDING,
  SET_NEW_NOTIFICATION_CONFIG_TEST_SUCCESS,
  SET_NEW_NOTIFICATION_CONFIG_TEST_ERROR,
  SET_NOTIFICATIONS_SPEC_FETCH_PENDING,
  SET_NOTIFICATIONS_SPEC_FETCH_SUCCESS,
  SET_NOTIFICATIONS_SPEC_FETCH_ERROR,
  SET_NOTIFICATION_ADD_DIALOG,
  SET_NOTIFICATION_UPDATE_DIALOG,
  SET_NOTIFICATION_REMOVE_DIALOG,
  SET_NOTIFICATION_CONFIGS_FETCH_PENDING,
  SET_NOTIFICATION_CONFIGS_FETCH_SUCCESS,
  SET_NOTIFICATION_CONFIGS_FETCH_ERROR,
  SET_NOTIFICATION_CONFIG_TEST_PENDING,
  SET_NOTIFICATION_CONFIG_TEST_SUCCESS,
  SET_NOTIFICATION_CONFIG_TEST_ERROR,
  SET_NOTIFICATION_CONFIG_ADD_PENDING,
  SET_NOTIFICATION_CONFIG_ADD_SUCCESS,
  SET_NOTIFICATION_CONFIG_ADD_ERROR,
  SET_NOTIFICATION_CONFIG_UPDATE_PENDING,
  SET_NOTIFICATION_CONFIG_UPDATE_SUCCESS,
  SET_NOTIFICATION_CONFIG_UPDATE_ERROR,
  SET_NOTIFICATION_CONFIG_REMOVE_PENDING,
  SET_NOTIFICATION_CONFIG_REMOVE_SUCCESS,
  SET_NOTIFICATION_CONFIG_REMOVE_ERROR,
  SET_EVENTS_SELECTED,
  SET_EVENT_TYPES_FETCH_PENDING,
  SET_EVENT_TYPES_FETCH_SUCCESS,
  SET_EVENT_TYPES_FETCH_ERROR,
  SET_EVENTS_SEARCH_PENDING,
  SET_EVENTS_SEARCH_SUCCESS,
  SET_EVENTS_SEARCH_ERROR,
  CLEAR_EVENTS_SEARCH,
} from '../actionTypes';

export default (
  state = {
    activeTabIndex: 0,
    activeNotificationsTabIndex: 0,

    isFetchingEvents: false,
    fetchEventsError: false,
    data: false,

    isRemoveEventsModalOpen: false,
    isRemoveEventModalOpen: false,
    isRemoving: false,
    removeError: false,
    isRemovingEvents: false,
    removeEventsError: false,
    removeEventsErrors: false,

    isFetchingEventTypes: false,
    fetchEventTypesError: false,
    eventTypes: false,

    isSearchingEvents: false,
    searchEventsError: false,
    filteredEvents: false,

    isAddNotificationModalOpen: false,
    isUpdateNotificationModalOpen: false,
    isRemoveNotificationModalOpen: false,

    isFetchingNotificationConfigs: false,
    fetchNotificationConfigsError: false,
    notificationConfigs: false,

    isTestingNewNotificationConfig: false,
    testNewNotificationConfigError: false,
    newNotificationTest: false,

    isTestingNotificationConfig: false,
    testNotificationConfigError: false,
    notificationTest: false,

    isAddingNotificationConfig: false,
    addNotificationConfigError: false,

    isUpdatingNotificationConfig: false,
    updateNotificationConfigError: false,

    isRemovingNotificationConfig: false,
    removeNotificationConfigError: false,

    // Notifications Swagger Spec Props
    isFetchingNotificationsSpec: false,
    fetchNotificationsSpecError: false,
    notificationsSpec: false,

    errorMsg: false,

    before: false,
    since: false,
    selected: {},
    viewProps: {
      events: {
        filterStr: '',
        page: 0,
        pageSize: 10,
        filterLevel: {
          info: true,
          error: true,
        },
        selectOption: 'custom',
      },
      notifications: {
        filterStr: '',
        page: 0,
        pageSize: 10,
      },
    },
  },
  action = {},
) => {
  switch (action.type) {
    /**
     * *************************************************************************
     * NOTIFICATIONS SPEC DATA REDUCERS
     */
    case SET_NOTIFICATIONS_SPEC_FETCH_PENDING:
      return {
        ...state,
        isFetchingNotificationsSpec: true,
      };
    case SET_NOTIFICATIONS_SPEC_FETCH_SUCCESS: {
      return {
        ...state,
        isFetchingNotificationsSpec: false,
        fetchNotificationsSpecError: false,
        notificationsSpec: action.resp.data,
      };
    }
    case SET_NOTIFICATIONS_SPEC_FETCH_ERROR:
      return {
        ...state,
        isFetchingNotificationsSpec: false,
        fetchNotificationsSpecError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * NOTIFICATIONS VIEW REDUCERS
     */
    case SET_NOTIFICATION_ADD_DIALOG:
      return {
        ...state,
        isAddNotificationModalOpen: action.open,
        addNotificationConfigError: false,
        errorMsg: false,
      };

    case SET_NOTIFICATION_UPDATE_DIALOG:
      return {
        ...state,
        isUpdateNotificationModalOpen: action.id,
        updateNotificationConfigError: false,
        errorMsg: false,
      };

    case SET_NOTIFICATION_REMOVE_DIALOG:
      return {
        ...state,
        isRemoveNotificationModalOpen: action.id,
        removeNotificationConfigError: false,
        errorMsg: false,
      };

    /**
     * *************************************************************************
     * NOTIFICATIONS DATA REDUCERS
     */
    case SET_NOTIFICATION_CONFIGS_FETCH_PENDING:
      return {
        ...state,
        isFetchingNotificationConfigs: true,
      };
    case SET_NOTIFICATION_CONFIGS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingNotificationConfigs: false,
        fetchNotificationConfigsError: false,
        errorMsg: false,
        notificationConfigs: action.resp.data,
      };
    case SET_NOTIFICATION_CONFIGS_FETCH_ERROR:
      return {
        ...state,
        isFetchingNotificationConfigs: false,
        fetchNotificationConfigsError: true,
        errorMsg: action.errorMsg,
        notificationConfigs: false,
      };

    case SET_NOTIFICATION_CONFIG_ADD_PENDING:
      return {
        ...state,
        isAddingNotificationConfig: action.id,
      };
    case SET_NOTIFICATION_CONFIG_ADD_SUCCESS:
      return {
        ...state,
        isAddingNotificationConfig: false,
        addNotificationConfigError: false,
        isAddNotificationModalOpen: false,
        errorMsg: false,
      };
    case SET_NOTIFICATION_CONFIG_ADD_ERROR:
      return {
        ...state,
        isAddingNotificationConfig: false,
        addNotificationConfigError: true,
        errorMsg: action.errorMsg,
      };

    case SET_NOTIFICATION_CONFIG_UPDATE_PENDING:
      return {
        ...state,
        isUpdatingNotificationConfig: action.id,
      };
    case SET_NOTIFICATION_CONFIG_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdatingNotificationConfig: false,
        updateNotificationConfigError: false,
        isUpdateNotificationModalOpen: false,
        errorMsg: false,
      };
    case SET_NOTIFICATION_CONFIG_UPDATE_ERROR:
      return {
        ...state,
        isUpdatingNotificationConfig: false,
        updateNotificationConfigError: true,
        errorMsg: action.errorMsg,
      };

    case SET_NOTIFICATION_CONFIG_REMOVE_PENDING:
      return {
        ...state,
        isRemovingNotificationConfig: action.id,
      };
    case SET_NOTIFICATION_CONFIG_REMOVE_SUCCESS:
      return {
        ...state,
        isRemovingNotificationConfig: false,
        removeNotificationConfigError: false,
        isRemoveNotificationsModalOpen: false,
        errorMsg: false,
      };
    case SET_NOTIFICATION_CONFIG_REMOVE_ERROR:
      return {
        ...state,
        isRemovingNotificationConfig: false,
        removeNotificationConfigError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * NEW NOTIFICATION TEST REDUCERS
     */
    case SET_NEW_NOTIFICATION_CONFIG_TEST_PENDING:
      return {
        ...state,
        isTestingNewNotificationConfig: action.id,
        newNotificationTest: false,
      };
    case SET_NEW_NOTIFICATION_CONFIG_TEST_SUCCESS:
      return {
        ...state,
        isTestingNewNotificationConfig: false,
        testNewNotificationConfigError: false,
        errorMsg: false,
        newNotificationTest: action.resp.data,
      };
    case SET_NEW_NOTIFICATION_CONFIG_TEST_ERROR:
      return {
        ...state,
        isTestingNewNotificationConfig: false,
        testNewNotificationConfigError: true,
        errorMsg: action.errorMsg,
        newNotificationTest: false,
      };

    /**
     * *************************************************************************
     * NOTIFICATIONS TEST REDUCERS
     */
    case SET_NOTIFICATION_CONFIG_TEST_PENDING:
      return {
        ...state,
        isTestingNotificationConfig: action.id,
        notificationTest: false,
      };
    case SET_NOTIFICATION_CONFIG_TEST_SUCCESS:
      return {
        ...state,
        isTestingNotificationConfig: false,
        testNotificationConfigError: false,
        errorMsg: false,
        notificationTest: action.resp.data,
      };
    case SET_NOTIFICATION_CONFIG_TEST_ERROR:
      return {
        ...state,
        isTestingNotificationConfig: false,
        testNotificationConfigError: true,
        errorMsg: action.errorMsg,
        notificationTest: false,
      };

    /**
     * *************************************************************************
     * EVENT VIEW REDUCERS
     */
    case SET_EVENTS_ACTIVE_TAB:
      return {
        ...state,
        activeTabIndex: action.idx,
      };

    case SET_NOTIFICATIONS_ACTIVE_TAB:
      return {
        ...state,
        activeNotificationsTabIndex: action.idx,
      };

    case SET_EVENTS_VIEW_PROPERTIES:
      return {
        ...state,
        viewProps: action.viewProps,
      };

    case SET_EVENTS_REMOVE_DIALOG:
      return {
        ...state,
        isRemoveEventsModalOpen: action.open,
        errorMsg: false,
      };

    case SET_EVENT_REMOVE_DIALOG:
      return {
        ...state,
        isRemoveEventModalOpen: action.open,
        removeError: false,
        errorMsg: false,
      };

    case SET_EVENTS_SELECTED:
      return {
        ...state,
        selected: action.selected || {},
      };

    /**
     * *************************************************************************
     * EVENT DATA REDUCERS
     */
    case SET_EVENTS_PENDING:
      return {
        ...state,
        isFetchingEvents: !action.silent,
      };
    case SET_EVENTS_SUCCESS: {
      let stateCopy = { ...state };
      if (
        stateCopy.data &&
        stateCopy.data.results instanceof Array &&
        action.resp &&
        action.resp.data &&
        action.resp.data.page > 1 &&
        action.resp.data.results instanceof Array
      ) {
        stateCopy.data.results = stateCopy.data.results.concat(
          action.resp.data.results,
        );

        // Remove any duplicate events that may have crept into the
        // dataset by being pushed across page boundaries
        stateCopy.data.results = _uniq(stateCopy.data.results, 'event_id');
      } else {
        stateCopy = Object.assign(stateCopy, action.resp);
      }

      stateCopy.data.next_page = action.resp.data.next_page;
      stateCopy.data.page = action.resp.data.page;
      stateCopy.before = action.before;
      stateCopy.since = action.since;
      stateCopy.isFetchingEvents = false;
      stateCopy.fetchEventsError = false;
      stateCopy.errorMsg = false;
      return stateCopy;
    }
    case SET_EVENTS_ERROR:
      return {
        ...state,
        isFetchingEvents: false,
        fetchEventsError: true,
        errorMsg: action.errorMsg,
        data: false,
      };

    case SET_EVENTS_REMOVE_PENDING:
      return {
        ...state,
        isRemovingEvents: true,
      };
    case SET_EVENTS_REMOVE_SUCCESS: {
      const stateCopy = { ...state };
      const { results, errors } = action.resp.data;
      const { data: newData, selected: newSelected = {} } = stateCopy;
      const checkForRemoval = (arr, currEvent) => {
        const { event_id: id } = currEvent;
        const isSelected = !!newSelected[id];
        let keepEvent = true;
        // Don't keep if event was deleted / not found
        if (
          results.find(result => result === id) ||
          errors.find(err => err.code === 'NotFound' && err.id === id)
        ) {
          keepEvent = false;
        }
        // Remove selection of event if we're not keeping it
        if (!keepEvent && isSelected) delete newSelected[id];
        if (keepEvent) arr.push(currEvent);
        return arr;
      };
      // Update state for most recent data
      newData.results = newData.results.reduce(checkForRemoval, []);
      stateCopy.data = newData;
      // Ensure events are removed from filtered data (if available)
      if (stateCopy.filteredEvents) {
        const { filteredEvents: newFiltered } = stateCopy;
        // Update state for filtered data
        newFiltered.results = newFiltered.results.reduce(checkForRemoval, []);
        stateCopy.filteredEvents = newFiltered;
      }
      const newErrors = errors.reduce((errs, currErr) => {
        const { id, code } = currErr;
        const newErrs = { ...errs };
        if (code !== 'NotFound') newErrs[id] = currErr;
        return newErrs;
      }, {});
      const hasErrors = !_isEmpty(newErrors);
      return {
        ...stateCopy,
        isRemovingEvents: false,
        removeEventsError: hasErrors,
        removeEventsErrors: hasErrors ? newErrors : false,
        errorMsg: false,
        isRemoveEventsModalOpen: hasErrors,
        selected: newSelected,
      };
    }
    case SET_EVENTS_REMOVE_ERROR:
      return {
        ...state,
        isRemovingEvents: false,
        removeEventsError: true,
        errorMsg: action.errorMsg,
      };
    case CLEAR_EVENTS_REMOVE_ERRORS:
      return {
        ...state,
        removeEventsError: false,
        removeEventsErrors: false,
        isRemoveEventsModalOpen: false,
      };

    case CLEAR_EVENTS_PENDING:
      return {
        ...state,
        isRemovingEvents: true,
      };
    case CLEAR_EVENTS_SUCCESS: {
      return {
        ...state,
        isRemovingEvents: false,
        removeEventsError: false,
        errorMsg: false,
        selected: {},
        isRemoveEventsModalOpen: false,
      };
    }
    case CLEAR_EVENTS_ERROR:
      return {
        ...state,
        isRemovingEvents: false,
        removeEventsError: true,
        errorMsg: action.errorMsg,
      };

    case SET_EVENT_REMOVE_PENDING:
      return {
        ...state,
        isRemoving: true,
      };
    case SET_EVENT_REMOVE_SUCCESS: {
      const obj = {
        ...state,
        isRemoveEventModalOpen: false,
        isRemoving: false,
        removeError: false,
        errorMsg: false,
      };
      const id = action.resp.data;
      // Remove event from recent data
      obj.data.results = obj.data.results.filter(item => item.event_id !== id);
      // If filter data is available, remove event from that list as well
      if (obj.filteredEvents) {
        obj.filteredEvents.results = obj.filteredEvents.results.filter(
          item => item.event_id !== id,
        );
      }
      if (obj.selected && obj.selected[id]) {
        delete obj.selected[id];
      }
      return obj;
    }
    case SET_EVENT_REMOVE_ERROR:
      return {
        ...state,
        isRemoving: false,
        removeError: true,
        errorMsg: action.errorMsg,
      };

    case SET_EVENT_TYPES_FETCH_PENDING:
      return {
        ...state,
        isFetchingEventTypes: true,
        eventTypes: false,
      };
    case SET_EVENT_TYPES_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingEventTypes: false,
        fetchEventTypesError: false,
        errorMsg: false,
        eventTypes: action.resp.data,
      };
    case SET_EVENT_TYPES_FETCH_ERROR:
      return {
        ...state,
        isFetchingEventTypes: false,
        fetchEventTypesError: true,
        errorMsg: action.errorMsg,
        eventTypes: false,
      };

    case SET_EVENTS_SEARCH_PENDING:
      return {
        ...state,
        isSearchingEvents: true,
      };
    case SET_EVENTS_SEARCH_SUCCESS: {
      const stateCopy = { ...state };
      if (
        stateCopy.filteredEvents &&
        stateCopy.filteredEvents.results instanceof Array &&
        action.resp &&
        action.resp.data &&
        action.resp.data.page > 1 &&
        action.resp.data.results instanceof Array
      ) {
        stateCopy.filteredEvents.results =
          stateCopy.filteredEvents.results.concat(action.resp.data.results);

        // Remove any duplicate events that may have crept into the
        // dataset by being pushed across page boundaries
        stateCopy.filteredEvents.results = _uniq(
          stateCopy.filteredEvents.results,
          'event_id',
        );
      } else {
        stateCopy.filteredEvents = action.resp.data;
      }

      stateCopy.filteredEvents.next_page = action.resp.data.next_page;
      stateCopy.filteredEvents.page = action.resp.data.page;
      stateCopy.isSearchingEvents = false;
      stateCopy.searchEventsError = false;
      stateCopy.errorMsg = false;
      return stateCopy;
    }
    case SET_EVENTS_SEARCH_ERROR:
      return {
        ...state,
        isSearchingEvents: false,
        searchEventsError: true,
        errorMsg: action.errorMsg,
        filteredEvents: false,
      };

    case CLEAR_EVENTS_SEARCH:
      return {
        ...state,
        isSearchingEvents: false,
        searchEventsError: false,
        errorMsg: false,
        filteredEvents: false,
      };

    default:
      return state;
  }
};
