import type { InferProps } from 'prop-types';
import type { ComponentClass } from 'react';
import { connect } from 'react-redux';

import type { RootState } from '@redux/store';

import Link, { type propTypes } from './Link';

export default connect(
  (state: RootState) => ({ auth: state.auth }),
  {},
)(Link as ComponentClass<InferProps<typeof propTypes>>);
