import n from 'animate.css';
import ApolloClient from 'apollo-boost';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useState, useEffect, cloneElement } from 'react';
import { ApolloProvider } from 'react-apollo';
import a from 'react-datepicker/dist/react-datepicker.css';
import ReactDOMServer from 'react-dom/server';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import t from 'react-toastify/dist/ReactToastify.css';
import { Container, Grid, Segment } from 'semantic-ui-react';

import o from '@/../public/font-mfizz/font-mfizz.css';
import e from '@/../public/pace/pace-theme-minimal.css';
import i from '@/../public/pdf/roboto.css';
import r from '@/../public/prism/prism.css';
import h from '@/../public/react-table/react-table.css';
import c from '@/../public/semantic/semantic.min.css';
import { appStateReset, setLogoutSuccess } from '@redux/actions/action-auth';
import {
  startAppConnectionCheck,
  startHealthCheck,
} from '@redux/actions/action-health';
import buildStore from '@redux/store';
import darkModeProps from '@shared/components/DarkModeToggle/darkModeProps.json';
import Err from '@shared/components/Err';
import AppTour from '@components/AppTour';
import Footer from '@components/Footer';
import HealthErr from '@components/HealthErr';
import Subnav from '@components/Subnav';
import Topnav from '@components/Topnav';

import ModalDimmer from './ModalDimmer';
import serviceFetchCycler from './serviceFetchCycler';
import socketListenerInit from './socketListenerInit';
import s from './Layout.scss'; // eslint-disable-line postcss-modules/no-unused-class

const USER_TIMEZONE = moment.tz.guess();
const BG_CYCLE_ELAPSE = 300000; // Time (in ms) of each service fetch cycle
const isBrowser = process.env.BROWSER;

// What to display if JS is disabled in the browser
const NoScript = el => {
  const staticMarkup = ReactDOMServer.renderToStaticMarkup(el.children);
  return <noscript dangerouslySetInnerHTML={{ __html: staticMarkup }} />; // eslint-disable-line react/no-danger
};

const store = buildStore(__DEV__);
// If this is the browser context that includes Cypress, make the store
// accessible
if (process.env.BROWSER && window.Cypress && store) {
  window.store = store;
}

/**
 * Pre-render function that only runs once (prior to first render) and then uses
 * a state hook to lock itself.
 * @param initOps {function} Callback function that carries the initialization payload
 *
 */
const initialize = (initOps = () => {}) => {
  const [isInitialized, setIsInitialized] = useState(false);
  if (!isInitialized) {
    initOps();
    setIsInitialized(true);
  }
};

// Main view component
const Layout = props => {
  const {
    apiUrl,
    children,
    customLinks,
    dbVersion,
    engineVersion,
    isWebSocketOnly,
    routeMask,
    uiVersion,
    isEngineHealthy,
    enrichInventoryView,
  } = props;

  let { account, isAuthenticated, permissions, whatsNew } = props;

  // Pointer to the main view element payload
  let mainEl;

  // Create a state object for the license that can easily be updated
  const [isLicenseGood, setIsLicenseGood] = useState(props.isLicenseGood);

  // Pointer to the last time the service fetch cycle ran
  const [serviceFetchCyclerLastRun, setServiceFetchCyclerLastRun] = useState(
    () => moment().tz(USER_TIMEZONE).toISOString(),
  );

  const [isDarkReaderAvailable, setIsDarkReaderAvailable] = useState(false);

  // Create a new instance of the Apollo GraphQL client and add to component
  const [apolloClient] = useState(
    () =>
      new ApolloClient({
        uri: `${apiUrl}/reports/graphql`,
      }),
  );

  // Pre-render view initialization operations
  initialize(() => {
    if (isAuthenticated && isLicenseGood?.isBeforeExpiration && account) {
      const currState = store.getState();
      const { app } = currState;

      ({ whatsNew } = app);
      whatsNew.isWhatsNewCtrlShown = uiVersion !== account.uiVersionPrev;

      store.dispatch(
        appStateReset({
          app: { ...app },
          ...(account.baseState || {}),
          auth: {
            account,
            dbVersion,
            engineVersion,
            errorMsg: false,
            isAuthenticated: true,
            isFetching: false,
            loginError: false,
            logoutError: false,
            permissions,
            prevAuthState: false,
            uiVersion,
          },
        }),
      );
    } else {
      store.dispatch(setLogoutSuccess());
    }
  });

  // Add and remove operations based on authentication state
  useEffect(() => {
    if (isBrowser) {
      // Instantiate the websocket client binding if it's not already present
      if (!window.sock && window.io && isLicenseGood?.isBeforeExpiration) {
        // Initialize all the various socket message listeners
        window.sock = socketListenerInit({
          props,
          isWebSocketOnly,
          setIsLicenseGood,
          startAppConnectionCheck,
          startHealthCheck,
          store,
        });
      }

      if (isAuthenticated && isLicenseGood?.isBeforeExpiration) {
        // This condition adds the user- and account-specific socket channels
        // after authentication, or restores them after a page refresh within an
        // authenticated session
        if (account?.sessionID) {
          // Room scoped to the session ID (private to this user)
          window.sock.emit('addToRoom', account.sessionID);
          // Room scoped to the account name (private to this account)
          window.sock.emit('addToRoom', `account-${account.accountname}`);
        }

        // Initialize Prism if it's not already loaded
        if (!window.Prism) {
          window.Prism = require('@/../public/prism/prism'); // eslint-disable-line global-require
        }

        // Configure the Pace progress bar and then initialize if it's not
        // already present
        if (!window.Pace) {
          window.paceOptions = {
            elements: false,
            restartOnRequestAfter: false,
          };
          require('@/../public/pace/pace.min'); // eslint-disable-line global-require
        }

        // Kick off the background service fetch cycle if it's not already
        // running. Store the cycler ID in the global object—this allows the
        // cycle to be cleared after a component hot-update in development mode.
        if (!window.App.serviceFetchCyclerId) {
          window.App.serviceFetchCyclerId = serviceFetchCycler({
            cycle: BG_CYCLE_ELAPSE,
            setServiceFetchCyclerLastRun,
            store,
          });
        }
      } else {
        // Clear any existing IDs for the image fetch cycler (this should only
        // be required in the development context after a hot-update)
        if (window.App.serviceFetchCyclerId) {
          clearInterval(window.App.serviceFetchCyclerId);
          window.App.serviceFetchCyclerId = false;
        }

        if (window.Pace) {
          window.Pace.stop();
        }
      }
    }
  }, [
    isAuthenticated || store.getState().auth.isAuthenticated,
    isLicenseGood?.isBeforeExpiration,
  ]);

  useEffect(() => {
    if (isBrowser && !window.App.darkReader) {
      let darkModeState = localStorage.getItem('darkmode');

      if (!darkModeState) {
        darkModeState =
          window.matchMedia &&
          window.matchMedia('(prefers-color-scheme: dark)').matches
            ? 'true'
            : 'false';
      }

      window.App.darkReader = require('darkreader'); // eslint-disable-line global-require
      setIsDarkReaderAvailable(true);
      const { darkReader } = window.App;
      if (darkModeState === 'true') {
        darkReader.enable(darkModeProps);
      } else if (darkModeState === 'false') {
        darkReader.disable();
      }
    }
  }, []);

  // Remove the socket listeners if the component unmounts
  useEffect(
    () => () => {
      if (isBrowser && window.sock?.removeAllListeners) {
        window.sock.removeAllListeners('connect');
        window.sock.removeAllListeners('connect_error');
      }
    },
    [],
  );

  // Provide an additional class to the layout wrapper if dark mode is enabled
  const darkClass = isDarkReaderAvailable
    ? window.App.darkReader.isEnabled()
      ? 'dark'
      : ''
    : '';

  if (isLicenseGood) {
    if (isLicenseGood.isBeforeExpiration) {
      const currState = store.getState();
      ({ account } = currState.auth);

      // Updated props are only passed to the Layout component when it mounts
      // or when its internal state object is updated. This means that if you
      // authenticate against any route other than the base path ('/') the
      // Layout props remain unchanged and the background stays the same
      // color.
      //
      // Using the `currState.auth.isAuthenticated` value as a fallback means
      // that the next time the app state changes after login (most likely
      // when the healthcheck cycle ticks) the current accurate authentication
      // state is read and the background gets set correctly.
      //
      // So why does a base path login update the Layout props? It's because
      // in this case, a successful login results in an *immediate* redirect to
      // a different route and this in turn causes the Layout component to get
      // updated.
      isAuthenticated = isAuthenticated || currState.auth.isAuthenticated;

      permissions =
        isAuthenticated && permissions
          ? permissions
          : currState.auth.permissions;

      let defaultBg = s.default;

      if (isAuthenticated && account) {
        defaultBg = isAuthenticated && account.isAdmin ? s.admin : s.standard;

        defaultBg =
          account.context?.accountname !== account.accountname
            ? s.switched
            : defaultBg;
      }

      const minutesUntilNextCycle = moment(serviceFetchCyclerLastRun).add(
        BG_CYCLE_ELAPSE / 1000 / 60,
        'm',
      );

      const bgCycleNextRun = {
        cycle: BG_CYCLE_ELAPSE,
        label: minutesUntilNextCycle.fromNow(),
        value: minutesUntilNextCycle,
      };

      mainEl = (
        <div id="layout" className={classNames(s.root, darkClass)}>
          <div id="bgColor" className={classNames(s.bg, defaultBg)} />
          <div id="bgImage" className={s.doodle} />
          <div className={s.contentWrapper}>
            <NoScript>
              <div className={s.noscript}>
                <Container>
                  <Grid centered columns={1}>
                    <Grid.Column
                      mobile={15}
                      tablet={12}
                      computer={10}
                      largeScreen={8}
                      widescreen={8}
                    >
                      <Segment.Group className="animate__animated animate__fadeIn">
                        <Segment
                          className="animate__animated animate__fadeIn"
                          style={{ padding: '2rem' }}
                        >
                          <h1>We&#39;re sorry&hellip;</h1>
                          <p>
                            &hellip;but JavaScript is required to use the
                            Anchore Enterprise UI Client application. If you
                            think scripts&nbsp;
                            <em>should</em> be enabled on your browser,&nbsp;
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href="https://www.enable-javascript.com"
                            >
                              this site
                            </a>
                            &nbsp;may help.
                          </p>
                          <p>
                            If you&#39;re still having problems, contact us
                            at&nbsp;
                            <a href="mailto:support@anchore.com">
                              support@anchore.com
                            </a>
                            &nbsp;and we&#39;ll do our best to assist.
                          </p>
                          <blockquote>
                            <p>
                              <em>
                                I have not failed. I have just found 10,000 ways
                                that won&#39;t work.
                              </em>
                            </p>
                            <sub>—Thomas A. Edison</sub>
                          </blockquote>
                          <div className={s.logoSmall} />
                        </Segment>
                        <span className={s.nuke} />
                      </Segment.Group>
                    </Grid.Column>
                  </Grid>
                </Container>
              </div>
            </NoScript>
            <Topnav
              active={children.props.title}
              bgCycleElapse={BG_CYCLE_ELAPSE}
              customLinks={customLinks}
              isLicenseGood={isLicenseGood}
              permissions={permissions}
              uiVersion={uiVersion}
              whatsNew={whatsNew}
            />
            <Subnav
              active={children.props.title}
              params={children.props.params}
              enrichInventoryView={enrichInventoryView}
            />
            {cloneElement(children, {
              ...(children.props || {}),
              apolloClient,
              bgCycleNextRun,
              isAuthenticated,
              isEngineHealthy,
              isLicenseGood,
              permissions,
            })}
            <HealthErr />
            <Footer />
            <ToastContainer />
            <AppTour routeMask={routeMask} />
          </div>
        </div>
      );
    } else {
      mainEl = (
        <div id="layout" className={classNames(s.root, darkClass)}>
          <div className={classNames(s.bg, s.error)} />
          <div className={s.doodle} />
          <div
            style={{
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Container>
              <Err
                header={
                  <span>
                    Your Anchore Enterprise
                    {isLicenseGood.type === 'trial' ? ' trial ' : ' '}
                    license has expired&hellip;
                  </span>
                }
                image="robot_flame.svg"
                imageSize="medium"
                headerColor="red"
                base
                message={
                  <>
                    <p>
                      For instructions on how to renew your license please refer
                      to the&nbsp;
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://docs.anchore.com"
                      >
                        documentation
                      </a>
                      .
                    </p>
                    <p>
                      You can also&nbsp;
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://anchore.com/contact"
                      >
                        request full or trial license
                      </a>
                      &nbsp;or contact&nbsp;
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="mailto:support@anchore.com"
                      >
                        Anchore Support
                      </a>
                      .
                    </p>
                  </>
                }
              />
            </Container>
          </div>
          <ToastContainer />
        </div>
      );
    }
  } else {
    mainEl = (
      <div id="layout" className={classNames(s.root, s.bg, darkClass)}>
        <div className={classNames(s.bg, s.error)} />
        <div className={s.doodle} />
        <div
          style={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Container>
            <Err
              header="No valid license detected&hellip;"
              image="robot_notfound.svg"
              imageSize="small"
              headerColor="orange"
              base
              message={
                <>
                  <p>
                    Anchore Enterprise Client requires a valid license to
                    operate.
                  </p>
                  <br />
                  <p>
                    For instructions on how to activate your license please
                    refer to the&nbsp;
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://docs.anchore.com"
                    >
                      documentation
                    </a>
                    .
                  </p>
                  <p>
                    You can also&nbsp;
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://anchore.com/contact"
                    >
                      request a full or trial license
                    </a>
                    &nbsp;or contact&nbsp;
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="mailto:support@anchore.com"
                    >
                      Anchore Support
                    </a>
                    .
                  </p>
                </>
              }
            />
          </Container>
        </div>
        <ToastContainer />
      </div>
    );
  }

  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>{mainEl}</ApolloProvider>
      <ModalDimmer />
      <div id="portalRoot" />
    </Provider>
  );
};

export const propTypes = {
  account: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})])
    .isRequired,
  apiUrl: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.node,
  customLinks: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  dbVersion: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  engineVersion: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isAuthenticated: PropTypes.bool.isRequired,
  isEngineHealthy: PropTypes.bool.isRequired,
  isLicenseGood: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})])
    .isRequired,
  isWebSocketOnly: PropTypes.bool.isRequired,
  permissions: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  routeMask: PropTypes.string,
  uiVersion: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  whatsNew: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})])
    .isRequired,
  enrichInventoryView: PropTypes.bool.isRequired,
};

Layout.propTypes = propTypes;

Layout.defaultProps = {
  apiUrl: undefined,
  children: null,
  customLinks: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  dbVersion: false,
  engineVersion: false,
  permissions: false,
  routeMask: '/',
  uiVersion: false,
};

export default withStyles(i, t, s, a, n, c, h, o, r, e)(Layout);
