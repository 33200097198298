import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import {
  Dimmer,
  Header,
  Icon,
  Input,
  Label,
  Progress,
} from 'semantic-ui-react';
import semver from 'semver';

import metadata from '@/../package.json';

import s from './HealthErr.scss';

// ID for a hidden "Tab Vortex" input element: a field that ignores keyboard
// events and therefore cannot be tabbed out of...
const HIDDEN_INPUT_ID = 'tabVortexInputEl';

class HealthErr extends PureComponent {
  /**
   * [componentDidMount description]
   * @return {[type]} [description]
   */
  componentDidMount() {
    this.mounted = true;

    if (process.env.BROWSER && !this.vortexFn) {
      // Generic event handler that assigns input focus to the "Tab Vortex" if
      // the user attempts to use the Tab key when the shield
      this.vortexFn = event => {
        const { isAppConnectionHealthy, isEngineHealthy, isEngineVersionGood } =
          this.props.app.healthCheck;

        // Flag to indicate if the Anchore Enterprise Services API version is not supported
        // by the current Enterprise Client version
        const isVersionBad = !isEngineVersionGood || !isEngineVersionGood.valid;

        // Get the ID of the element that currently has input focus
        const activeElementId = document.activeElement
          ? document.activeElement.id
          : false;

        // If the shield criteria are true and the current element with focus is
        // not the tab vortex input element, reset focus
        if (
          (!isAppConnectionHealthy || !isEngineHealthy || isVersionBad) &&
          activeElementId !== HIDDEN_INPUT_ID
        ) {
          // Prevent the default behavior of the event (i.e., disable Enter key)
          event.preventDefault();
          const tabVortexEl = document.getElementById(HIDDEN_INPUT_ID);
          setTimeout(() => {
            if (tabVortexEl) tabVortexEl.focus();
          }, 0);
        }
      };

      // We want to intercept key events (specifically Enter and Tab) raised
      // *inside* the viewport, and then redirect focus to the "Tab Vortex".
      window.addEventListener('keydown', this.vortexFn);

      // We also want to stop users from accessing controls by tabbing *into*
      // the viewport from an external control (e.g., the browser address bar).
      window.addEventListener('focus', this.vortexFn);
    }
    return this;
  }

  /**
   * [componentDidUpdate description]
   * @return {[type]} [description]
   */
  componentDidUpdate() {
    const { isAppConnectionHealthy, isEngineHealthy, isEngineVersionGood } =
      this.props.app.healthCheck;

    // Flag to indicate if the application service is down or unreachable
    const isAppServiceDown = !isAppConnectionHealthy;

    // Flag to indicate if Anchore Enterprise is down
    const isEngineDown = !isEngineHealthy;

    // Flag to indicate if the Anchore Enterprise Services API version is not supported by
    // the current Enterprise Client version
    const isVersionBad = !isEngineVersionGood || !isEngineVersionGood.valid;

    // Get the ID of the element that currently has input focus
    const activeElementId = document.activeElement
      ? document.activeElement.id
      : false;

    // Set input focus into the tab vortex if the engine health shield is active
    if (isAppServiceDown || isEngineDown || isVersionBad) {
      document.body.classList.add('noscroll');

      if (activeElementId !== HIDDEN_INPUT_ID) {
        // Get the tab vortex input field
        const tabVortexEl = document.getElementById(HIDDEN_INPUT_ID);
        setTimeout(() => {
          if (tabVortexEl) tabVortexEl.focus();
        }, 0);
      }
    } else {
      document.body.classList.remove('noscroll');
    }
    return this;
  }

  /**
   * [componentWillUnmount description]
   * @return {[type]} [description]
   */
  componentWillUnmount() {
    // Remove the focus and keydown listeners from the window (if present)
    if (this.vortexFn) {
      window.removeEventListener('focus', this.vortexFn);
      window.removeEventListener('keydown', this.vortexFn);
    }
    return this;
  }

  render() {
    const {
      isAppConnectionHealthy,
      isEngineHealthy,
      isEngineVersionGood,
      isAppDBHealthy,
    } = this.props.app.healthCheck;

    // Flag to indicate if the application service is down or unreachable
    const isAppServiceDown = !isAppConnectionHealthy;

    // Flag to indicate if the Anchore Enterprise Services service is down
    const isEngineDown = !isEngineHealthy;

    // Flag to indicate if the Application Database service is down
    const isAppDBDown = !isAppDBHealthy;

    // Flag to indicate if the Anchore Enterprise Services API version is not supported by
    // the current Enterprise Client version
    const isVersionKnown =
      isEngineVersionGood && typeof isEngineVersionGood.valid === 'boolean';
    const isVersionBad = isVersionKnown && !isEngineVersionGood.valid;

    // Get the semver details for the UI and engine if possible
    const uiVersion = isEngineVersionGood ? (
      <Label color="teal" className={s.versionLabel}>
        <span>Anchore Enterprise Client</span>
        <Label.Detail className={s.versionLabelDetail}>
          {isEngineVersionGood.uiVersion}
        </Label.Detail>
      </Label>
    ) : (
      ' '
    );
    const engineVersion = isEngineVersionGood ? (
      <Label color="teal" className={s.versionLabel}>
        <span>Anchore Enterprise Services</span>
        <Label.Detail className={s.versionLabelDetail}>
          {isEngineVersionGood.engineVersion}
        </Label.Detail>
      </Label>
    ) : (
      ' '
    );

    // Default header and subhead messages
    let headerMsg = 'Anchore Enterprise Services Connection Error';
    let subheaderMsg = 'Please wait—we are currently trying to reconnect';

    // Assign the header and subheader dimmer messages based upon the current
    // health condition
    if (isAppServiceDown) {
      headerMsg = 'Web Service Connection Error';
    } else if (isAppDBDown) {
      headerMsg = 'AppDB Service Connection Error';
    } else if (!isEngineDown && isVersionKnown && isVersionBad) {
      headerMsg = 'Service Version Error';

      const anchoreInternalDeployment = process.env.BROWSER
        ? window.App.anchoreInternalDeployment
        : false;
      const versionRequirements = anchoreInternalDeployment
        ? `^${semver.coerce(metadata.apiDependencies.anchore)}`
        : metadata.apiDependencies.anchore;
      const serviceRange = semver
        .validRange(versionRequirements)
        .replace('||', ' || ');

      subheaderMsg = (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Unfortunately
            {uiVersion}
            is incompatible with the
            {engineVersion}
            service
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '1rem',
              justifyContent: 'center',
            }}
          >
            The service application version must be inside this compatibility
            range:
            <Label className={s.rangeLabel} color="pink">
              {serviceRange}
            </Label>
          </div>
        </>
      );
    }

    return this.mounted &&
      (isAppServiceDown || isEngineDown || isVersionBad) ? (
      <Dimmer page active style={{ zIndex: 10000 }}>
        <Input
          id={HIDDEN_INPUT_ID}
          style={{
            position: 'absolute',
            opacity: 0,
            right: '100%',
          }}
          onKeyDown={event => {
            if (isAppServiceDown || isEngineDown || isVersionBad) {
              event.preventDefault();
            }
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 0,
          }}
        >
          <Icon.Group size="huge">
            <Icon name="warning sign" />
            <Icon
              corner
              name={isAppServiceDown || isEngineDown ? 'plug' : 'fork'}
              style={{
                color: 'rgba(219, 40, 40, 1)',
              }}
            />
          </Icon.Group>
        </div>
        <Header as="h2" inverted>
          {headerMsg}
          <Header.Subheader style={{ marginTop: '0.5rem' }}>
            <div
              style={{
                marginTop: '1rem',
              }}
            >
              {subheaderMsg}
            </div>
            {isAppServiceDown || isEngineDown ? (
              <Progress
                value="1"
                total="1"
                active
                size="small"
                color="red"
                inverted
                style={{ marginTop: '1.125rem' }}
              />
            ) : null}
          </Header.Subheader>
        </Header>
      </Dimmer>
    ) : null;
  }
}

export const propTypes = {
  app: PropTypes.shape({
    healthCheck: PropTypes.shape({
      isAppConnectionHealthy: PropTypes.bool,
      isEngineHealthy: PropTypes.bool,
      isAppDBHealthy: PropTypes.bool,
      isEngineVersionGood: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
          engineVersion: PropTypes.string,
          uiVersion: PropTypes.string,
          valid: PropTypes.bool,
        }),
      ]),
    }),
  }).isRequired,
};

HealthErr.propTypes = propTypes;

export default withStyles(s)(HealthErr);
