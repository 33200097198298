import type { Reducer, AnyAction } from 'redux';

import { SET_TOPNAV_VIEW_PROPERTIES } from '../actionTypes';

export interface TopNavState {
  viewProps: {
    topnav: {
      navbtns: false | 'up' | 'down';
      accountbtn: boolean;
      contextitm: boolean;
      linksitm: boolean;
      registryitm: boolean;
    };
  };
}

export const defaultState: TopNavState = {
  viewProps: {
    topnav: {
      navbtns: false,
      accountbtn: false,
      contextitm: false,
      linksitm: false,
      registryitm: false,
    },
  },
};
const reducer: Reducer<TopNavState> = (
  state = defaultState,
  action: AnyAction,
) => {
  switch (action.type) {
    case SET_TOPNAV_VIEW_PROPERTIES:
      return {
        ...state,
        viewProps: action.viewProps,
      };

    default:
      return state;
  }
};

export default reducer;
