import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Dropdown,
  Input,
  Pagination,
  Popup,
  Button,
  Icon,
} from 'semantic-ui-react';

// eslint-disable-next-line postcss-modules/no-unused-class
import s from './TablePagination.scss';

const TablePagination = props => {
  const {
    page: propsPage,
    pages,
    pageSize,
    className,
    items,
    pageProps,
    sizeOptions,
    noSizeOptions,
    filterStr,
    onFilteredChange,
    filterClass,
    getFilterContent,
    noFocus,
    onPageSizeChange,
    onPageChange,
    style,
    rawData,
    noFilter,
    state,
  } = props;

  const [filterOpen, setFilterOpen] = useState(false);
  const filterRef = useRef();

  const getSafePage = pageNum => {
    let newPage = pageNum;
    const pagesCount = pages;
    if (Number.isNaN(newPage)) {
      newPage = propsPage;
    }
    if (newPage > pagesCount - 1 || newPage < 0) {
      newPage = 0;
    }
    return newPage;
  };

  const page = useMemo(() => getSafePage(propsPage), [getSafePage, propsPage]);

  useEffect(() => {
    if (page !== propsPage) {
      onPageChange(page);
    }
  }, [page, propsPage, onPageChange]);

  const handlePageSizeChange = (e, { value }) => {
    if (pageSize !== value) {
      onPageSizeChange(value);
    }
  };

  const handlePaginationChange = (e, { activePage }) => {
    const newPage = getSafePage(activePage - 1);
    if (propsPage !== newPage) {
      onPageChange(newPage);
    }
  };

  const handleFilterChange = (e, { value }) => {
    if (onFilteredChange && filterStr !== value) {
      onFilteredChange('filter', value);
    }
  };

  const handleFilterFocus = e => {
    // Reset value to move cursor to end of input
    const tempVal = e.target.value;
    e.target.value = '';
    e.target.value = tempVal;
  };

  const handleFilterOpen = () => {
    setFilterOpen(true);
  };

  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  const pageSizeOptions = sizeOptions || [
    { key: 1, text: '5', value: 5 },
    { key: 2, text: '10', value: 10 },
    { key: 3, text: '25', value: 25 },
    { key: 4, text: '50', value: 50 },
    { key: 5, text: '100', value: 100 },
  ];

  return (
    <div className={classNames(className, s.root)} style={style}>
      <div className={classNames(s.pageSize, noSizeOptions ? s.hide : '')}>
        Display&nbsp;
        <Dropdown
          placeholder={String(pageSize)}
          options={pageSizeOptions}
          value={pageSize}
          onChange={handlePageSizeChange}
          selection
          fluid
          style={{ width: 'unset', minWidth: '5rem' }}
        />
        &nbsp;
        <div style={{ width: '100%' }}>{items}</div>
      </div>
      <div
        className={classNames(s.filter, noFilter ? s.hide : '', filterClass)}
      >
        Filter:&nbsp;
        <Input
          value={filterStr}
          onChange={handleFilterChange}
          onFocus={handleFilterFocus}
          autoFocus={!noFocus}
          className={getFilterContent ? s.noRightBorder : ''}
          action
          icon
        >
          <input />
          {filterStr ? (
            <Icon
              name="times"
              link
              style={{
                fontSize: '0.8rem',
                ...(getFilterContent ? { right: '2.75rem' } : {}),
              }}
              onClick={() => handleFilterChange(null, { value: '' })} // Resets filter
            />
          ) : null}
          {getFilterContent ? (
            <Popup
              // Semantic doesn't forward the ref to the underlying element
              context={filterRef?.current?.ref?.current}
              // Necessary because scrolling in modals causes weird behavior
              hideOnScroll
              // Fully-controlled because sometimes hiding on scroll would not correctly bind the popup
              open={filterOpen}
              onOpen={handleFilterOpen}
              onClose={handleFilterClose}
              position="right center"
              // Ensures that on change of the sortedData, popup will recalculate position (necessary add for modals)
              popperDependencies={[rawData]}
              // Update boundary to be the viewport which allows popups to overflow within a modal
              popperModifiers={[
                {
                  name: 'preventOverflow',
                  options: {
                    boundary: 'viewport',
                  },
                },
              ]}
              on="click"
              trigger={
                <Button
                  ref={filterRef}
                  basic
                  icon="filter"
                  style={{ marginLeft: '-1px' }} // Ensures button is properly attached
                />
              }
              content={getFilterContent(state)}
            />
          ) : null}
        </Input>
      </div>
      <div className={s.pagination}>
        <Pagination
          activePage={page + 1}
          onPageChange={handlePaginationChange}
          totalPages={pages}
          firstItem={null}
          lastItem={null}
          pointing
          secondary
          {...pageProps}
        />
      </div>
    </div>
  );
};

export const propTypes = {
  state: PropTypes.shape({}),
  filterStr: PropTypes.string,
  noFilter: PropTypes.bool,
  onFilteredChange: PropTypes.func,
  filterClass: PropTypes.string,
  getFilterContent: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  noFocus: PropTypes.bool,
  sortedData: PropTypes.arrayOf(PropTypes.shape({})),
  page: PropTypes.number,
  pageSize: PropTypes.number,
  pages: PropTypes.number.isRequired,
  className: PropTypes.string,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
  items: PropTypes.string,
  pageProps: PropTypes.shape({}),
  sizeOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  noSizeOptions: PropTypes.bool,
  rawData: PropTypes.arrayOf(PropTypes.shape({})),
};

TablePagination.propTypes = propTypes;

TablePagination.defaultProps = {
  state: {},
  filterStr: '',
  noFilter: false,
  onFilteredChange: undefined,
  filterClass: '',
  getFilterContent: false,
  noFocus: false,
  sortedData: [],
  className: '',
  style: {},
  items: 'Items',
  page: 0,
  pageSize: 10,
  pageProps: {},
  sizeOptions: false,
  noSizeOptions: false,
  rawData: [],
};

export default withStyles(s)(TablePagination);
