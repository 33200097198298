import type { InferProps } from 'prop-types';
import type { ComponentClass } from 'react';
import { connect } from 'react-redux';

import type { RootState } from '@redux/store';

import HealthErr, { type propTypes } from './HealthErr';

export default connect(
  (state: RootState) => ({ app: state.app }),
  {},
)(HealthErr as ComponentClass<InferProps<typeof propTypes>>);
