/* eslint-disable camelcase */
// To extend ServiceRequest, you can find our custom declaration in global.d.ts
export type { Request as ServiceRequest } from 'express';

export interface ServiceResponse {
  status: 'success' | 'error';
}

export interface EntityServiceResponse<T> extends ServiceResponse {
  body: {
    data: T;
  };
}

export type {
  Application,
  ApplicationVersion,
  FeedMetadata,
  ServiceList,
} from '@/api/api';

export interface ServiceError {
  statusCode?: number;
  message?: string;
  type?: string;
}

export type PaginationParams = {
  limit?: number;
  filter?: string;
  page?: number;
  sort?: Record<string, boolean>;
};

export type DataUpdateType = 'create' | 'update' | 'delete';
export interface DataUpdateEvent {
  entity: string;
  entityId: string;
  updateType: DataUpdateType;
  originatingSocketId: string;
}

/**
 * Data entity fetched from the backend
 */
export type DataEntity<T, K extends string = string, D = Record<K, T>> = {
  isLoading: boolean;
  isUpdating: boolean;
  data: D | null;
  error?: ServiceError;
  stale?: Record<K, DataUpdateType>;
};

export const nullDataEntity: Readonly<DataEntity<any, string, null>> =
  Object.freeze({
    isLoading: false,
    isUpdating: false,
    data: null,
  });

export type EntityStatus =
  | 'initial'
  | 'loading'
  | 'updating'
  | 'ready'
  | 'error';

export interface StatusEntity<T> {
  status: EntityStatus;
  data: T | null;
  error?: ServiceError;
  stale?: boolean;
}

export const nullStatusEntity: Readonly<StatusEntity<any>> = Object.freeze({
  status: 'initial',
  data: null,
});

export interface EntityActionParams {
  showToastOnSuccess?: boolean;
  showToastOnFailure?: boolean;
}

export interface TableViewProps {
  page: number;
  pageSize: number;
  filterStr: string;
  sort?: Record<string, boolean>;
}

export const initialTableViewProps: Readonly<TableViewProps> = Object.freeze({
  page: 0,
  pageSize: 10,
  filterStr: '',
});

export type Field = (
  | {
      typeName?: 'String';
      data?: string;
    }
  | {
      typeName: 'Enum';
      data?: string;
      enumValues: string[];
    }
  | {
      typeName: 'EnumList';
      data?: string[];
      enumValues: string[];
    }
  | {
      typeName: 'Boolean';
      data?: boolean;
    }
  | {
      typeName: 'DateTime';
      data?: string;
    }
  | {
      typeName: 'TimePeriod';
      data?: string;
    }
  | {
      typeName: 'Int';
      data?: number;
    }
) & {
  key: string;
  name?: string;
  example?: string;
  category?: string;
  description?: string;
  required?: boolean;
};

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;
