import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import _startCase from 'lodash/startCase';
import {
  type ModalProps as SemanticModalProps,
  Button,
  Header,
  Icon,
} from 'semantic-ui-react';

import type { ModalPropTypes, ModalType, ServiceError } from '@models';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { hideModal, selectActiveModalProps } from '@redux/modals.slice';
import CancelButton from '@shared/components/CancelButton';
import ErrMessage from '@shared/components/ErrMessage';
import Modal from '@shared/components/Modal';
import raiseToast from '@shared/components/Toast';

import s from './DeleteModal.scss';

/**
 * In general, delete modals only need to accept an 'id' and 'name' prop to
 * display the correct text. Excess keys are allowed to be passed in as various
 * actions may need additional info but they won't be used further in the modal.
 */
export type DeleteProps<T extends ModalType> = {
  id: string;
  name?: string;
} & ModalPropTypes[T];

/**
 * For modal content, there are a few options available. The 'item' prop can be
 * optionally provided to tweak the default text.
 *
 * If you need more control over the text, you can pass in 'textHeader' and
 * 'text' strings.
 *
 * For complete control, you can pass in a 'content' prop which will be rendered
 * instead.
 */
export interface DeleteModalProps<T extends ModalType>
  extends SemanticModalProps {
  id: T;
  className?: string;
  item?: string;
  onClick: (
    props: DeleteProps<T>,
  ) =>
    | boolean
    | Promise<
        | { type: string; meta?: { requestStatus: 'fulfilled' | 'rejected' } }
        | boolean
      >;
  isDeleting: boolean;
  error: ServiceError | undefined;
  title?: string | ((props: DeleteProps<T>) => string);
  textHeader?: string | ((props: DeleteProps<T>) => string);
  text?: string | ((props: DeleteProps<T>) => string);
  content?: JSX.Element | ((props: DeleteProps<T>) => JSX.Element);
  actionName?: string;
}

/**
 * A reusable modal for deletion of an item.
 * @param props.id Required — provide a unique id for the modal
 * @param props.item Simple drop-in replacement for 'item' in text such as "Are you sure you want to delete this item?"
 * @param props.onClick Function to call on click of "Delete" button
 * @param props.isDeleting Boolean to indicate if the delete action is in progress
 * @param props.error If error is present, display it inline
 * @example
 * // Intended usage
 * <DeleteModal
 *   id="deleteTemplate"
 *   item="template"
 *   onClick={({ id }) => dispatch(deleteTemplate(id))}
 *   isDeleting={isUpdating}
 *   error={error}
 * />
 *
 * @param props.content Custom content to display in the modal. Overrides default 'textHeader' and 'text' props.
 * @example
 * // If you need to provide custom content, you can pass in a JSX.Element or a
 * // function if you need to key off of the passed 'id' and 'name' props
 * <DeleteModal
 *   id="deleteTemplate"
 *   content={
 *     <>
 *       <Header inverted as="h3">
 *         Are you sure you want to delete this template?
 *       </Header>
 *       <p>Once you delete this template, you won&#39;t be able to recover it.</p>
 *     </>
 *   }
 *   onClick={({ id }) => dispatch(deleteTemplate(id))}
 *   isDeleting={isUpdating}
 *   error={error}
 * />
 *
 * @param props.title Custom title string to show in top portion of modal
 * @param props.textHeader Custom textHeader string to show in Header
 * @param props.text Custom text string to show in 'p' tag
 * @example
 * // For customizing the default text, you can use the 'title', 'textHeader',
 * // and 'text' props. They can be either a string or a function if you need to
 * // key off of the passed 'id' and 'name' props.
 * <DeleteModal
 *   id="deleteTemplate"
 *   title={({ name }) => `Delete Template "${name}""`}
 *   textHeader="Are you sure you want to delete this template?"
 *   text="Once you delete this template, you won't be able to recover it."
 *   onClick={({ id }) => dispatch(deleteTemplate(id))}
 *   isDeleting={isUpdating}
 *   error={error}
 * />
 */
const DeleteModal = <T extends ModalType>({
  id,
  item = 'item',
  onClick,
  isDeleting,
  error,
  className = undefined,
  title = undefined,
  textHeader = undefined,
  text = undefined,
  content = undefined,
  actionName = 'delete',
  ...props
}: DeleteModalProps<T>) => {
  const dispatch = useAppDispatch();
  const activeModalProps = useAppSelector(selectActiveModalProps(id));

  let view: JSX.Element | null = null;

  if (activeModalProps) {
    // By default, activeModalProps will key off of every modal type but we cast
    // it here to only expect 'id' and (optional) 'name' values
    const passedProps = activeModalProps as DeleteProps<T>;
    const titleText = typeof title === 'function' ? title(passedProps) : title;
    const textHeaderEl =
      typeof textHeader === 'function' ? textHeader(passedProps) : textHeader;
    const textEl = typeof text === 'function' ? text(passedProps) : text;
    const contentEl =
      typeof content === 'function' ? content(passedProps) : content;

    const { name } = passedProps;

    view = (
      <Modal
        id={id}
        size="tiny"
        className={classNames(s.root, className)}
        {...props}
      >
        <Header className={s.title}>
          {titleText || `${_startCase(actionName)} ${_startCase(item)}`}
        </Header>
        <Modal.Content scrolling className={s.content}>
          {!!error && <ErrMessage deleteErr error={error} />}
          {contentEl || (
            <>
              <Header className={s.textHeader}>
                {textHeaderEl ||
                  `Are you sure you want to ${actionName} ${
                    name ? `the ${item} "${name}"` : `this ${item}`
                  }?`}
              </Header>
              <p className={s.text}>
                {textEl ||
                  `Once you ${actionName} it, this ${item} can't be recovered.`}
              </p>
            </>
          )}
        </Modal.Content>
        <Modal.Actions className={s.actions}>
          <CancelButton
            dark
            autoFocus
            disabled={isDeleting}
            onClick={() => dispatch(hideModal(id))}
          />
          <Button
            color="red"
            disabled={isDeleting}
            loading={isDeleting}
            onClick={async () => {
              const actionResponse = await onClick(passedProps);
              const isSuccessful =
                typeof actionResponse === 'boolean'
                  ? actionResponse
                  : actionResponse?.meta?.requestStatus === 'fulfilled';

              if (isSuccessful) {
                dispatch(hideModal(id));
                raiseToast({
                  toastId: `${actionName}/${item}/${id}`,
                  message: `The ${item}${
                    name ? ` "${name}"` : ''
                  } has been successfully ${actionName}d.`,
                  level: 'success',
                  icon: 'trash',
                  autoClose: 8000,
                  dismissAll: true,
                });
              }
            }}
          >
            <Icon name="trash" /> {_startCase(actionName)}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  return view;
};

export default withStyles(s)(DeleteModal);
