import type { InferProps } from 'prop-types';
import type { FunctionComponent } from 'react';
import { connect } from 'react-redux';

import { setTourProps } from '@redux/actions/action-tour';
import type { RootState } from '@redux/store';

import AppTour, { type propTypes } from './AppTour';

export default connect(
  (state: RootState) => ({ app: state.app, auth: state.auth }),
  { setTourProps },
)(AppTour as FunctionComponent<InferProps<typeof propTypes>>);
