import _difference from 'lodash/difference';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Joyride from 'react-joyride';

import tourConfigFactory from './tourConfig';

const options = {
  options: {
    zIndex: 1001,
    textColor: 'rgba(0,0,0,.87)',
    fontFamily: 'Lato',
    primaryColor: '#2185d0',
    beaconSize: '2rem',
  },
  buttonNext: {
    backgroundColor: '#2185d0',
    fontFamily: 'Lato',
    borderRadius: '.28571429rem',
    fontSize: '1rem',
    fontWeight: 700,
    outline: 'none',
    padding: '0.75rem 1.5rem',
  },
  buttonBack: {
    fontFamily: 'Lato',
    color: '#4183c4',
    fontSize: '1.125rem',
    fontWeight: 700,
    outline: 'none',
  },
  beacon: {
    top: '0.345rem',
    outline: 'none',
  },
};

/**
 *
 * @param str
 * @param ctx
 * @param sep
 * @returns {*}
 */
const getObj = (str, ctx, sep) =>
  str
    .split(sep || '.')
    .filter(num => num.length)
    .reduce(
      (prev, curr, idx, list) => (prev ? prev[list[idx]] : undefined),
      ctx || this,
    );

// Main view component
const AppTour = props => {
  const { app, setTourProps } = props;
  let { routeMask } = props;

  const tourConfig = tourConfigFactory(props);

  // Get the app tour properties object
  const { tourProps } = app.tour;

  // Current path string
  const [path, setPath] = useState(routeMask || false);

  // Configuration object for the current route
  const [config, setConfig] = useState(false);

  // Identity of the currently active step definition list
  const [stepId, setStepId] = useState(false);

  // Step definition list
  const [steps, setSteps] = useState(false);

  // Decides if the tour should be active or paused (it's necessary to do this
  // before a state transition to get the component to reinitialize
  const [run, setRun] = useState(false);

  // Get the tour data (if any) the corresponds to the current path from the
  // tour props object, or show the global tour if the prop for that path (`/*`)
  // is not disabled
  const tourPropsPath =
    app.tour.tourProps?.['/*']?.isDisabled === false
      ? tourProps['/*']
      : tourProps?.[path] || false;

  // Similarly, set the route mask to the global tour if the prop for that path
  // if it's not disabled
  routeMask =
    app.tour.tourProps?.['/*']?.isDisabled === false ? '/*' : routeMask;

  // If the route has changed then reset the path hook and other base conditions
  useEffect(() => {
    if (routeMask !== path) {
      setPath(routeMask || false);
      setConfig(false);
      setStepId(false);
      setSteps(false);
      setRun(true);
    }
  }, [routeMask]);

  // Find the correct `steps` list that corresponds to the current step ID and
  // then enable the `run` attribute on the tour component. Null the current
  // steps object if no match is found.
  useEffect(() => {
    setTimeout(() => {
      if (config) {
        const stepObj = config.find(item => item.id === stepId);
        if (stepObj) {
          let arr = stepObj.steps;

          // Filter out any introductory steps if introductions are disabled for
          // this tour
          if (tourPropsPath && !tourPropsPath.showIntro) {
            arr = arr.filter(item => !item.intro);
          }

          setSteps(arr);
          setRun(true);
        } else {
          setSteps(false);
        }
      }
    }, 0);
  }, [tourPropsPath && !tourPropsPath.isDisabled]);

  // Initialize the app tour properties object if it hasn't been initialized
  // already
  useEffect(() => {
    if (tourConfig && !tourProps) {
      setTourProps(
        Object.keys(tourConfig).reduce((prev, curr) => {
          const p = prev;
          p[curr] = {
            showIntro: false,
            isDisabled: true,
          };
          return p;
        }, {}),
      );
    } else if (
      tourConfig &&
      tourProps &&
      _difference(Object.keys(tourProps), Object.keys(tourConfig)).length
    ) {
      // For all tour props are stored in the current user record, ensure that
      // any items that *aren't* present in the tour configuration get removed
      setTourProps(
        Object.keys(tourConfig).reduce((prev, curr) => {
          const p = prev;
          if (tourProps[curr]) {
            p[curr] = tourProps[curr];
          }
          return p;
        }, {}),
      );
    }
  }, [tourProps]);

  // The following condition safely bootstraps the `config` and `path`
  // properties when the page is initialized (the `config` object should be
  // `false` when this happens).
  if (!config) {
    const baseConfig =
      typeof routeMask === 'string' &&
      typeof tourConfig === 'object' &&
      tourConfig[routeMask] instanceof Array &&
      tourConfig[routeMask].length
        ? tourConfig[routeMask]
        : false;

    // If the `baseConfig` was retrieved for this path, then proceed
    if (baseConfig) {
      setPath(routeMask);
      setConfig(baseConfig);
    }
  }

  // When a path and a config is available, the steps associated with the
  // current route can be determined
  if (path && config && tourProps) {
    const stepObj = config.reduce((prev, curr) => {
      let p = prev;
      const c = curr;

      if (
        typeof c.id === 'string' &&
        c.steps instanceof Array &&
        c.steps.length
      ) {
        if (!p && c.state instanceof Array && c.state.length) {
          const isEveryKeyMatched = c.state.every(item => {
            let stateVal = getObj(item.key, props);
            if (typeof stateVal === 'object') {
              stateVal = Boolean(stateVal);
            }
            return stateVal === item.value;
          });
          if (isEveryKeyMatched) {
            p = { id: c.id, steps: c.steps };
          }
        } else if (
          !p &&
          (!(c.state instanceof Array) ||
            (c.state instanceof Array && !c.state.length))
        ) {
          p = { id: c.id, steps: c.steps };
        }
      }

      return p;
    }, false);

    // If the currently stored set of steps no longer correlates with the
    // calculated step object, pause the tour component and switch the step ID
    // so that it matches the new set
    if (stepId !== stepObj.id) {
      setRun(false);
      setStepId(stepObj.id);
    }
  }

  // If the current runtime is the browser and steps have been found that
  // correspond to the current state, render the component
  return process.env.BROWSER &&
    steps &&
    stepId &&
    tourProps &&
    !tourPropsPath.isDisabled ? (
    <Joyride
      key={stepId}
      run={run}
      continuous
      styles={options}
      steps={steps}
      locale={{ last: 'Finish' }}
      showSkipButton
      callback={data => {
        if (
          tourPropsPath &&
          (data.action === 'reset' || data.action === 'close')
        ) {
          tourPropsPath.isDisabled = true;
          tourPropsPath.showIntro = false;
          setTourProps({ ...tourProps, ...tourPropsPath });
        }
      }}
    />
  ) : null;
};

export const propTypes = {
  routeMask: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  app: PropTypes.shape({
    tour: PropTypes.shape({
      isTourActive: PropTypes.bool,
      tourProps: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
    }).isRequired,
  }).isRequired,
  auth: PropTypes.shape({
    account: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  }).isRequired,
  setTourProps: PropTypes.func.isRequired,
};

AppTour.propTypes = propTypes;

export default AppTour;
