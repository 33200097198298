import type { InferProps } from 'prop-types';
import type { ComponentClass } from 'react';
import { connect } from 'react-redux';

import {
  addNewRegistry,
  clearRegistryAddError,
  clearRegistryRemoveError,
  clearRegistryUpdateError,
  fetchRegistries,
  removeRegistry,
  setRegistryAddDialog,
  setRegistryRemoveDialog,
  setRegistryUpdateDialog,
  setSystemViewProps,
  updateRegistry,
} from '@redux/actions/action-system';
import { setTopnavViewProps } from '@redux/actions/action-topnav';
import type { RootState } from '@redux/store';

import RegistryList, { type propTypes } from './RegistryList';

export default connect(
  (state: RootState) => ({ system: state.system, app: state.app }),
  {
    addNewRegistry,
    clearRegistryAddError,
    clearRegistryRemoveError,
    clearRegistryUpdateError,
    fetchRegistries,
    removeRegistry,
    setRegistryAddDialog,
    setRegistryRemoveDialog,
    setRegistryUpdateDialog,
    setSystemViewProps,
    setTopnavViewProps,
    updateRegistry,
  },
)(RegistryList as ComponentClass<InferProps<typeof propTypes>>);
