import request from 'superagent';

import {
  // Service Health Operations
  SET_SERVICES_HEALTH_FETCH_PENDING,
  SET_SERVICES_HEALTH_FETCH_SUCCESS,
  SET_SERVICES_HEALTH_FETCH_ERROR,
} from '../actionTypes';

// *** FETCH SERVICES HEALTH ************************************************* //
export const setServicesHealthFetchPending = () => ({
  type: SET_SERVICES_HEALTH_FETCH_PENDING,
});

export const setServicesHealthFetchSuccess = resp => ({
  type: SET_SERVICES_HEALTH_FETCH_SUCCESS,
  resp,
});

export const setServicesHealthFetchError = errorMsg => ({
  type: SET_SERVICES_HEALTH_FETCH_ERROR,
  errorMsg,
});

export const fetchServicesHealth = () => (dispatch, getState) => {
  const currState = getState();
  const isHealthy =
    currState.app &&
    currState.app.healthCheck &&
    currState.app.healthCheck.isEngineHealthy;

  let resp = false;

  if (isHealthy) {
    dispatch(setServicesHealthFetchPending());

    resp = request
      .get(`/service/health/services`)
      .then(fetchServicesHealthResp =>
        dispatch(setServicesHealthFetchSuccess(fetchServicesHealthResp.body)),
      )
      .catch(err => {
        let errResp;
        if (err.response && err.response.body && err.response.body.data) {
          errResp = dispatch(
            setServicesHealthFetchError(err.response.body.data),
          );
        } else {
          errResp = dispatch(setServicesHealthFetchError());
        }
        return errResp;
      });
  }

  return resp || dispatch(setServicesHealthFetchError());
};
