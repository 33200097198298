import type { ArtifactType } from '@models';

const artifactTypes: ArtifactType[] = [
  {
    id: 'source',
    typeName: 'Repository',
    title: 'Source Repositories',
    titleSingular: 'Source Repository',
    icon: 'icon-git',
  },
  {
    id: 'image',
    typeName: 'Image',
    title: 'Container Images',
    titleSingular: 'Container Image',
    icon: 'tags',
  },
];

export default artifactTypes;
