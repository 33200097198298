import {
  SET_DASHBOARD_VIEW_PROPERTIES,
  SET_WIDGETS_FETCH_PENDING,
  SET_WIDGETS_FETCH_SUCCESS,
  SET_WIDGETS_FETCH_ERROR,
  SET_WIDGET_ADD_DIALOG,
  SET_WIDGET_ADD_PENDING,
  SET_WIDGET_ADD_SUCCESS,
  SET_WIDGET_ADD_ERROR,
  SET_WIDGET_UPDATE_DIALOG,
  SET_WIDGET_UPDATE_PENDING,
  SET_WIDGET_UPDATE_SUCCESS,
  SET_WIDGET_UPDATE_ERROR,
  SET_WIDGET_REMOVE_DIALOG,
  SET_WIDGET_REMOVE_PENDING,
  SET_WIDGET_REMOVE_SUCCESS,
  SET_WIDGET_REMOVE_ERROR,
  SET_WIDGETS_UPDATE_PENDING,
  SET_WIDGETS_UPDATE_SUCCESS,
  SET_WIDGETS_UPDATE_ERROR,
  SET_METRICS_FETCH_PENDING,
  SET_METRICS_FETCH_SUCCESS,
  SET_METRICS_FETCH_ERROR,
  SET_METRICS_UPDATE_PENDING,
  SET_METRICS_UPDATE_SUCCESS,
  SET_METRICS_UPDATE_ERROR,
  SET_COMPLIANCE_ALERTS_FETCH_PENDING,
  SET_COMPLIANCE_ALERTS_FETCH_SUCCESS,
  SET_COMPLIANCE_ALERTS_FETCH_ERROR,
  SET_COMPLIANCE_ALERT_UPDATE_PENDING,
  SET_COMPLIANCE_ALERT_UPDATE_SUCCESS,
  SET_COMPLIANCE_ALERT_UPDATE_ERROR,
  SET_COMPLIANCE_ALERT_DISMISS_MODAL,
} from '../actionTypes';

export default (
  state = {
    // General Props
    errorMsg: false,
    viewProps: {
      mainTable: {
        page: 0,
        pageSize: 10,
      },
      subTable: {
        page: 0,
        pageSize: 10,
      },
      alerts: {
        height: 10,
        mode: 'expanded',
      },
    },

    // Widget Data Structures
    isFetchingWidgets: false,
    fetchWidgetsError: false,
    widgets: false,

    isAddWidgetModalOpen: false,
    isAddingWidget: false,
    addWidgetError: false,

    isUpdateWidgetModalOpen: false,
    isUpdatingWidget: false,
    updateWidgetError: false,

    isRemoveWidgetModalOpen: false,
    isRemovingWidget: false,
    removeWidgetError: false,

    isUpdatingWidgets: false,
    updateWidgetsError: false,

    // Metrics Data Structures
    isFetchingMetrics: false,
    fetchMetricsError: false,
    metrics: false,

    // Alerts Data Structures
    isFetchingComplianceAlerts: false,
    fetchComplianceAlertsError: false,
    complianceAlerts: false,

    isDismissComplianceAlertModalOpen: false,
    isUpdatingComplianceAlert: false,
    updateComplianceAlertError: false,
  },
  action = {},
) => {
  switch (action.type) {
    /**
     * *************************************************************************
     * DASHBOARD VIEW REDUCERS
     */
    case SET_DASHBOARD_VIEW_PROPERTIES:
      return {
        ...state,
        viewProps: action.viewProps,
      };

    case SET_WIDGET_ADD_DIALOG:
      return {
        ...state,
        isAddWidgetModalOpen: action.open,
        addWidgetError: false,
        errorMsg: false,
      };

    case SET_WIDGET_UPDATE_DIALOG:
      return {
        ...state,
        isUpdateWidgetModalOpen: action.open,
        updateWidgetError: false,
        errorMsg: false,
      };

    case SET_WIDGET_REMOVE_DIALOG:
      return {
        ...state,
        isRemoveWidgetModalOpen: action.open,
        removeWidgetError: false,
        errorMsg: false,
      };

    case SET_COMPLIANCE_ALERT_DISMISS_MODAL:
      return {
        ...state,
        isDismissComplianceAlertModalOpen: action.open,
        updateComplianceAlertError: false,
        errorMsg: false,
      };

    /**
     * *************************************************************************
     * WIDGETS REDUCERS
     */
    case SET_WIDGETS_FETCH_PENDING:
      return {
        ...state,
        isFetchingWidgets: true,
      };
    case SET_WIDGETS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingWidgets: false,
        fetchWidgetsError: false,
        errorMsg: false,
        widgets: action.resp.data,
      };
    case SET_WIDGETS_FETCH_ERROR:
      return {
        ...state,
        isFetchingWidgets: false,
        fetchWidgetsError: true,
        errorMsg: action.errorMsg,
        widgets: false,
      };

    case SET_WIDGET_ADD_PENDING:
      return {
        ...state,
        isAddingWidget: true,
      };
    case SET_WIDGET_ADD_SUCCESS: {
      const obj = {
        ...state,
        isAddWidgetModalOpen: false,
        isAddingWidget: false,
        addWidgetError: false,
        errorMsg: false,
      };
      obj.widgets = obj.widgets
        ? obj.widgets.concat(action.resp.data)
        : action.resp.data;
      return obj;
    }
    case SET_WIDGET_ADD_ERROR:
      return {
        ...state,
        isAddingWidget: false,
        addWidgetError: true,
        errorMsg: action.errorMsg,
      };

    case SET_WIDGET_UPDATE_PENDING:
      return {
        ...state,
        isUpdatingWidget: true,
      };
    case SET_WIDGET_UPDATE_SUCCESS: {
      const obj = {
        ...state,
        isUpdateWidgetModalOpen: false,
        isUpdatingWidget: false,
        updateWidgetError: false,
        errorMsg: false,
      };
      const updatedWidget = action.resp.data[0];
      obj.widgets = obj.widgets.map(item => {
        // Replace existing widget with updated data
        if (item.i === updatedWidget.i) {
          return { ...item, ...updatedWidget };
        }
        return item;
      });
      return obj;
    }
    case SET_WIDGET_UPDATE_ERROR:
      return {
        ...state,
        isUpdatingWidget: false,
        updateWidgetError: true,
        errorMsg: action.errorMsg,
      };

    case SET_WIDGET_REMOVE_PENDING:
      return {
        ...state,
        isRemovingWidget: true,
      };
    case SET_WIDGET_REMOVE_SUCCESS: {
      const obj = {
        ...state,
        isRemoveWidgetModalOpen: false,
        isRemovingWidget: false,
        removeWidgetError: false,
        errorMsg: false,
      };
      obj.widgets = obj.widgets.filter(item => item.i !== action.resp.data);
      return obj;
    }
    case SET_WIDGET_REMOVE_ERROR:
      return {
        ...state,
        isRemovingWidget: false,
        removeWidgetError: true,
        errorMsg: action.errorMsg,
      };

    case SET_WIDGETS_UPDATE_PENDING:
      return {
        ...state,
        isUpdatingWidgets: true,
      };
    case SET_WIDGETS_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdatingWidgets: false,
        updateWidgetsError: false,
        errorMsg: false,
        widgets: action.resp.data[0],
      };
    case SET_WIDGETS_UPDATE_ERROR:
      return {
        ...state,
        isUpdatingWidgets: false,
        updateWidgetsError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * METRICS REDUCERS
     */
    case SET_METRICS_FETCH_PENDING:
      return {
        ...state,
        isFetchingMetrics: true,
      };
    case SET_METRICS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingMetrics: false,
        fetchMetricsError: false,
        errorMsg: false,
        metrics: action.resp.data,
      };
    case SET_METRICS_FETCH_ERROR:
      return {
        ...state,
        isFetchingMetrics: false,
        fetchMetricsError: true,
        errorMsg: action.errorMsg,
        metrics: false,
      };

    case SET_METRICS_UPDATE_PENDING:
      return {
        ...state,
        isUpdatingMetrics: true,
      };
    case SET_METRICS_UPDATE_SUCCESS: {
      const obj = {
        ...state,
        isUpdatingMetrics: false,
        updateMetricsError: false,
        errorMsg: false,
      };
      const newMetrics = action.resp.data;
      // Go through each metric id and add the new data points to the front
      Object.keys(newMetrics).forEach(metricId => {
        const oldMetricData = obj.metrics[metricId] || [];
        obj.metrics[metricId] = [...newMetrics[metricId], ...oldMetricData];
      });
      return obj;
    }
    case SET_METRICS_UPDATE_ERROR:
      return {
        ...state,
        isUpdatingMetrics: false,
        updateMetricsError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * ALERTS REDUCERS
     */
    case SET_COMPLIANCE_ALERTS_FETCH_PENDING:
      return {
        ...state,
        isFetchingComplianceAlerts: true,
      };
    case SET_COMPLIANCE_ALERTS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingComplianceAlerts: false,
        fetchComplianceAlertsError: false,
        errorMsg: false,
        complianceAlerts: {
          data: action.resp.data,
          timestamp: action.resp.timestamp,
        },
      };
    case SET_COMPLIANCE_ALERTS_FETCH_ERROR:
      return {
        ...state,
        isFetchingComplianceAlerts: false,
        fetchComplianceAlertsError: true,
        errorMsg: action.errorMsg,
        complianceAlerts: false,
      };

    case SET_COMPLIANCE_ALERT_UPDATE_PENDING:
      return {
        ...state,
        isUpdatingComplianceAlert: true,
      };
    case SET_COMPLIANCE_ALERT_UPDATE_SUCCESS:
      return {
        ...state,
        isDismissComplianceAlertModalOpen: false,
        isUpdatingComplianceAlert: false,
        updateComplianceAlertError: false,
        errorMsg: false,
      };
    case SET_COMPLIANCE_ALERT_UPDATE_ERROR:
      return {
        ...state,
        isUpdatingComplianceAlert: false,
        updateComplianceAlertError: true,
        errorMsg: action.errorMsg,
      };

    default:
      return state;
  }
};
