import withStyles from 'isomorphic-style-loader/withStyles';
import {
  Modal as SemanticModal,
  type ModalProps as SemanticModalProps,
  ModalActions as SemanticModalActions,
  ModalContent as SemanticModalContent,
  ModalDescription as SemanticModalDescription,
  ModalDimmer as SemanticModalDimmer,
  ModalHeader as SemanticModalHeader,
} from 'semantic-ui-react';

import type { ModalType } from '@models';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import {
  hideModal,
  selectActiveModal,
  selectIsModalVisible,
} from '@redux/modals.slice';

import s from './Modal.scss';

export interface ModalProps extends SemanticModalProps {
  id: ModalType;
}

const Modal = (props: ModalProps) => {
  const { id, children } = props;
  const dispatch = useAppDispatch();
  const activeModal = useAppSelector(selectActiveModal(id));
  const isVisible = useAppSelector(selectIsModalVisible(id));

  return (
    <SemanticModal
      role="dialog"
      className={!isVisible ? s.hiddenModal : ''}
      style={{ visibility: isVisible ? 'visible' : 'hidden' }}
      open={!!activeModal}
      closeIcon={isVisible}
      dimmer={{ style: { background: 'transparent' } }}
      onClose={() => dispatch(hideModal(id))}
      mountNode={process.env.BROWSER && document?.getElementById('rootDimmer')}
      {...props}
    >
      {children}
    </SemanticModal>
  );
};

interface ModalComponent extends React.FunctionComponent<ModalProps> {
  Actions: typeof SemanticModalActions;
  Content: typeof SemanticModalContent;
  Description: typeof SemanticModalDescription;
  Dimmer: typeof SemanticModalDimmer;
  Header: typeof SemanticModalHeader;
}

Object.assign(Modal, {
  Actions: SemanticModalActions,
  Content: SemanticModalContent,
  Description: SemanticModalDescription,
  Dimmer: SemanticModalDimmer,
  Header: SemanticModalHeader,
});
export default withStyles(s)(Modal as ModalComponent);
