import type { Reducer, AnyAction } from 'redux';

import type { FeedMetadata, ServiceList } from '@models';
import {
  SET_SERVICES_HEALTH_FETCH_PENDING,
  SET_SERVICES_HEALTH_FETCH_SUCCESS,
  SET_SERVICES_HEALTH_FETCH_ERROR,
} from '@redux/actionTypes';

export interface ServicesHealthState {
  isFetchingServicesHealth: boolean;
  fetchServicesHealthError: boolean;
  errorMsg: any;
  services:
    | {
        feeds: FeedMetadata;
        serviceStates: ServiceList;
      }
    | false;
}

export const defaultState: ServicesHealthState = {
  isFetchingServicesHealth: false,
  fetchServicesHealthError: false,
  errorMsg: false,
  services: false,
};

const reducer: Reducer<ServicesHealthState> = (
  state = defaultState,
  action: AnyAction,
) => {
  switch (action.type) {
    case SET_SERVICES_HEALTH_FETCH_PENDING:
      return {
        ...state,
        isFetchingServicesHealth: true,
      };
    case SET_SERVICES_HEALTH_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingServicesHealth: false,
        errorMsg: false,
        fetchServicesHealthError: false,
        services: action.resp.data,
      };
    case SET_SERVICES_HEALTH_FETCH_ERROR:
      return {
        ...state,
        isFetchingServicesHealth: false,
        services: false,
        fetchServicesHealthError: true,
        errorMsg: action.errorMsg,
      };

    default:
      return state;
  }
};

export default reducer;
