import type { CreateEntityActionOptions } from './createEntityAction';

const getEntityActions = <
  Actions,
  Config extends CreateEntityActionOptions<Actions>,
>(
  config: Config,
) =>
  Object.keys(config).reduce(
    (obj, key) => ({ ...obj, [key]: config[key as keyof typeof config].thunk }),
    {} as { [K in keyof Config]: Config[K]['thunk'] },
  );

export default getEntityActions;
