import type { Reducer, AnyAction } from 'redux';

import {
  SET_POLICY_BUNDLES_FETCH_PENDING,
  SET_POLICY_BUNDLES_FETCH_SUCCESS,
  SET_POLICY_BUNDLES_FETCH_ERROR,
  SET_POLICY_BUNDLE_ADD_PENDING,
  SET_POLICY_BUNDLE_ADD_SUCCESS,
  SET_POLICY_BUNDLE_ADD_ERROR,
  SET_POLICY_BUNDLE_ADD_DIALOG,
  SET_POLICY_BUNDLE_REMOVE_PENDING,
  SET_POLICY_BUNDLE_REMOVE_SUCCESS,
  SET_POLICY_BUNDLE_REMOVE_ERROR,
  SET_POLICY_BUNDLE_REMOVE_DIALOG,
  SET_POLICY_BUNDLE_UPLOAD_DIALOG,
  SET_POLICY_BUNDLE_UPLOAD_DIALOG_JSON_FILE,
  SET_POLICY_BUNDLE_UPLOAD_DIALOG_JSON_STRING,
  SET_POLICY_BUNDLE_UPLOAD_DIALOG_SCHEMA_ERROR,
  SET_POLICY_BUNDLE_COPY_DIALOG,
  SET_POLICY_BUNDLE_DOWNLOAD_PENDING,
  SET_POLICY_BUNDLE_DOWNLOAD_SUCCESS,
  SET_POLICY_BUNDLE_DOWNLOAD_ERROR,
  SET_POLICY_BUNDLE_DOWNLOAD_CLEAR,
  SET_POLICY_BUNDLE_UPDATE_PENDING,
  SET_POLICY_BUNDLE_UPDATE_SUCCESS,
  SET_POLICY_BUNDLE_UPDATE_ERROR,
  CLEAR_POLICY_BUNDLE_REMOVE_ERROR,
} from '../actionTypes';

export interface PolicyManagerState {
  isFetching: boolean;
  isAdding: boolean;
  isRemoving: boolean;
  isDownloading: boolean;
  isUpdating: boolean;
  fetchError: boolean;
  addError: boolean;
  removeError: boolean;
  downloadError: boolean;
  updateError: boolean;
  errorMsg: any;
  data: any;
  isNewBundleModalOpen: boolean;
  isRemoveBundleModalOpen: string | false;
  isUploadBundleModalOpen: string | false;
  isCopyBundleModalOpen: string | false;
  isJSONDragOver: boolean;
  JSONFileData: boolean;
  JSONFileDataString: boolean;
  policyBundleDownloadData: any;
  validationError: boolean;
}

export const defaultState: PolicyManagerState = {
  isFetching: false,
  isAdding: false,
  isRemoving: false,
  isDownloading: false,
  isUpdating: false,
  fetchError: false,
  addError: false,
  removeError: false,
  downloadError: false,
  updateError: false,
  errorMsg: false,
  data: false,
  isNewBundleModalOpen: false,
  isRemoveBundleModalOpen: false,
  isUploadBundleModalOpen: false,
  isCopyBundleModalOpen: false,
  isJSONDragOver: false,
  JSONFileData: false,
  JSONFileDataString: false,
  policyBundleDownloadData: false,
  validationError: false,
};

const reducer: Reducer<PolicyManagerState> = (
  state = defaultState,
  action: AnyAction,
) => {
  switch (action.type) {
    /**
     * *************************************************************************
     * POLICY BUNDLE DIALOG REDUCERS
     */
    case SET_POLICY_BUNDLE_ADD_DIALOG:
      return {
        ...state,
        isNewBundleModalOpen: action.open,
        addError: false,
        errorMsg: false,
      };

    case SET_POLICY_BUNDLE_REMOVE_DIALOG:
      return {
        ...state,
        isRemoveBundleModalOpen: action.open,
        removeError: false,
        errorMsg: false,
      };

    case SET_POLICY_BUNDLE_UPLOAD_DIALOG:
      return {
        ...state,
        isUploadBundleModalOpen: action.open,
        uploadError: false,
        errorMsg: false,
        JSONFileData: false,
        JSONFileDataString: false,
      };

    case SET_POLICY_BUNDLE_COPY_DIALOG:
      return {
        ...state,
        isCopyBundleModalOpen: action.open,
        copyError: false,
        errorMsg: false,
      };

    /**
     * *************************************************************************
     * POLICY BUNDLE DATA REDUCERS
     */
    case SET_POLICY_BUNDLE_UPDATE_PENDING:
      return {
        ...state,
        isUpdating: true,
      };
    case SET_POLICY_BUNDLE_UPDATE_SUCCESS: {
      const obj = {
        ...state,
        isUpdating: false,
        updateError: false,
        errorMsg: false,
        data: state.data,
      };

      // Note that policy bundle updates can be executed outside of the Policy
      // Manager, in which case there won't be any items in the `data` prop so
      // first check to see if any items are present before doing the following
      if (obj.data instanceof Array && obj.data.length) {
        obj.data = obj.data.map(policy => {
          // If the updated bundle is active, deactivate the others.
          if (action.resp.data && action.resp.data.active) {
            policy.active = false;
          }

          // Replace existing bundle item with new data
          if (policy.policy_id === action.resp.data.policy_id) {
            policy = action.resp.data;
          }
          return policy;
        });
      }
      return obj;
    }
    case SET_POLICY_BUNDLE_UPDATE_ERROR:
      return {
        ...state,
        isUpdating: false,
        updateError: true,
        errorMsg: action.errorMsg,
      };

    case SET_POLICY_BUNDLES_FETCH_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case SET_POLICY_BUNDLES_FETCH_SUCCESS: {
      const obj = {
        ...state,
        isFetching: false,
        fetchError: false,
        data: action.resp.data,
      };
      // Don't wipe errorMsg if errExists
      if (!action.errExists) obj.errorMsg = false;
      return obj;
    }
    case SET_POLICY_BUNDLES_FETCH_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchError: true,
        errorMsg: action.errorMsg,
      };

    case SET_POLICY_BUNDLE_ADD_PENDING:
      return {
        ...state,
        isAdding: true,
        addError: false,
      };
    case SET_POLICY_BUNDLE_ADD_SUCCESS: {
      const obj = {
        ...state,
        isAdding: false,
        addError: false,
        errorMsg: false,
      };
      obj.data = [...obj.data, action.resp.data];
      return obj;
    }
    case SET_POLICY_BUNDLE_ADD_ERROR:
      return {
        ...state,
        isAdding: false,
        addError: true,
        errorMsg: action.errorMsg,
      };

    case SET_POLICY_BUNDLE_DOWNLOAD_PENDING:
      return {
        ...state,
        isDownloading: true,
        downloadError: false,
        policyBundleDownloadData: false,
      };
    case SET_POLICY_BUNDLE_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        isDownloading: false,
        downloadError: false,
        errorMsg: false,
        policyBundleDownloadData: action.data,
      };
    }
    case SET_POLICY_BUNDLE_DOWNLOAD_ERROR:
      return {
        ...state,
        isDownloading: false,
        downloadError: true,
        errorMsg: action.errorMsg,
        policyBundleDownloadData: false,
      };
    case SET_POLICY_BUNDLE_DOWNLOAD_CLEAR:
      return {
        ...state,
        isDownloading: false,
        downloadError: false,
        errorMsg: false,
        policyBundleDownloadData: false,
      };

    case SET_POLICY_BUNDLE_REMOVE_PENDING:
      return {
        ...state,
        isRemoving: true,
      };
    case SET_POLICY_BUNDLE_REMOVE_SUCCESS: {
      const obj = {
        ...state,
        isRemoving: false,
        removeError: false,
        errorMsg: false,
      };
      obj.data = obj.data.filter(item => item.policy_id !== action.resp.data);
      return obj;
    }
    case SET_POLICY_BUNDLE_REMOVE_ERROR:
      return {
        ...state,
        isRemoving: false,
        removeError: true,
        errorMsg: action.errorMsg,
      };
    case CLEAR_POLICY_BUNDLE_REMOVE_ERROR:
      return {
        ...state,
        removeError: false,
        errorMsg: false,
      };

    case SET_POLICY_BUNDLE_UPLOAD_DIALOG_JSON_FILE: {
      return {
        ...state,
        ...{
          isJSONDragOver: action.over,
          schemaError: false,
          JSONFileDataObj: action.data || false,
          errorMsg: action.error || false,
        },
      };
    }

    case SET_POLICY_BUNDLE_UPLOAD_DIALOG_SCHEMA_ERROR:
      return {
        ...state,
        schemaError: action.errorArr,
        errorMsg: false,
      };

    case SET_POLICY_BUNDLE_UPLOAD_DIALOG_JSON_STRING:
      return {
        ...state,
        JSONFileDataString: action.data,
      };

    default:
      return state;
  }
};

export default reducer;
