import {
  SET_APP_CONNECTION_HEALTH,
  SET_ENGINE_HEALTH,
  SET_VERSION_HEALTH,
} from '../actionTypes';

export const setAppConnectionHealth = health => ({
  type: SET_APP_CONNECTION_HEALTH,
  health,
});

export const setEngineHealth = (health, reports, appdb) => ({
  type: SET_ENGINE_HEALTH,
  health,
  reports,
  appdb,
});

export const setVersionHealth = health => ({
  type: SET_VERSION_HEALTH,
  health,
});

export const startAppConnectionCheck = isAppConnectionHealthy => dispatch => {
  if (process.env.BROWSER && window.App) {
    window.App.isAppConnectionHealthy = isAppConnectionHealthy;
  }
  dispatch(setAppConnectionHealth(isAppConnectionHealthy));
};

export const startVersionCheck =
  isEngineVersionGood => (dispatch, getState) => {
    if (
      getState().app.healthCheck.isEngineVersionGood !== isEngineVersionGood
    ) {
      if (process.env.BROWSER && window.App) {
        window.App.isEngineVersionGood = isEngineVersionGood;
      }
      dispatch(setVersionHealth(isEngineVersionGood));
    }
  };

export const startHealthCheck =
  (isEngineHealthy, isReportsEnabledAndActive, isAppDBHealthy) => dispatch => {
    if (process.env.BROWSER && window.App) {
      window.App.isEngineHealthy = isEngineHealthy;
      window.App.isReportsEnabledAndActive = isReportsEnabledAndActive;
      window.App.isAppDBHealthy = isAppDBHealthy;
    }
    dispatch(
      setEngineHealth(
        isEngineHealthy,
        isReportsEnabledAndActive,
        isAppDBHealthy,
      ),
    );
  };
