import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import merge from 'deepmerge';

import type {
  DeepPartial,
  GetImagesCountParams,
  GetImagesCountResponse,
  GetIncompleteImagesParams,
  GetIncompleteImagesResponse,
  GetImageRegistryParams,
  GetImageRegistryResponse,
  GetImageRepositoryParams,
  GetImageRepositoryResponse,
  GetImageSummaryParams,
  GetImageSummaryResponse,
  GetImageTagParams,
  GetImageTagResponse,
  StatusEntity,
  TableViewProps,
  ImageRepository,
  ImageTag,
  ImageTagDigest,
  ImageSummaryItem,
  ImagesCount,
  IncompleteImage,
  RegistryParams,
  RepositoryParams,
  TagParams,
  TotalRows,
} from '@models';
import { initialTableViewProps, nullStatusEntity } from '@models';

import {
  createEntityAction,
  createStatusEntityReducers,
  getEntityActions,
} from './common';

// --- Data entities -----------------------------------------------------------
const entities = {
  fetchImagesCount: createEntityAction<
    GetImagesCountParams,
    GetImagesCountResponse
  >({
    actionName: 'images/count/get',
    stateKey: 'count',
    servicePath: () => '/service/images/count',
    customRequest: (request, path, params) => request.get(path).query(params),
  }),
  fetchIncompleteImages: createEntityAction<
    GetIncompleteImagesParams,
    GetIncompleteImagesResponse
  >({
    actionName: 'images/incomplete/get',
    stateKey: 'incomplete',
    servicePath: () => '/service/images/incomplete',
    customRequest: (request, path, params) => request.get(path).query(params),
  }),
  fetchImageRegistry: createEntityAction<
    GetImageRegistryParams,
    GetImageRegistryResponse
  >({
    actionName: 'images/registry/get',
    stateKey: 'registry',
    servicePath: ({ registry }) => `/service/images/registry/${registry}`,
    customRequest: (request, path, params) => request.get(path).query(params),
  }),
  fetchImageRepository: createEntityAction<
    GetImageRepositoryParams,
    GetImageRepositoryResponse
  >({
    actionName: 'images/repository/get',
    stateKey: 'repository',
    servicePath: ({ registry, repository }) =>
      `/service/images/registry/${registry}/repository/${encodeURIComponent(repository)}`,
    customRequest: (request, path, params) => request.get(path).query(params),
  }),
  fetchImageSummary: createEntityAction<
    GetImageSummaryParams,
    GetImageSummaryResponse
  >({
    actionName: 'images/summary/get',
    stateKey: 'summary',
    servicePath: () => '/service/images/summary',
    customRequest: (request, path, params) => request.get(path).query(params),
  }),
  fetchImageTag: createEntityAction<GetImageTagParams, GetImageTagResponse>({
    actionName: 'images/tag/get',
    stateKey: 'tag',
    servicePath: ({ registry, repository, tag }) =>
      `/service/images/registry/${registry}/repository/${encodeURIComponent(repository)}/tag/${tag}`,
    customRequest: (request, path, params) => request.get(path).query(params),
  }),
};

export const {
  fetchImagesCount,
  fetchImageRegistry,
  fetchImageRepository,
  fetchImageSummary,
  fetchImageTag,
  fetchIncompleteImages,
} = getEntityActions(entities);

// --- Types -------------------------------------------------------------------
export interface ImagesState {
  count: StatusEntity<ImagesCount> & Partial<RepositoryParams>;
  incomplete: StatusEntity<IncompleteImage[]>;
  summary: StatusEntity<ImageSummaryItem[]> & Partial<TotalRows>;
  registry: StatusEntity<ImageRepository[]> &
    Partial<RegistryParams> &
    Partial<TotalRows>;
  repository: StatusEntity<ImageTag[]> &
    Partial<RepositoryParams> &
    Partial<TotalRows>;
  tag: StatusEntity<ImageTagDigest[]> & Partial<TagParams> & Partial<TotalRows>;
  viewProps: {
    incomplete: TableViewProps & {
      pending: boolean;
      analyzing: boolean;
      failed: boolean;
    };
    summary: TableViewProps;
    registry: TableViewProps;
    repository: TableViewProps & { animateToolbar: boolean };
    tag: TableViewProps;
  };
}

type ImagesViewProps = ImagesState['viewProps'];

// --- Default values ----------------------------------------------------------
export const initialState: ImagesState = {
  count: nullStatusEntity,
  incomplete: nullStatusEntity,
  summary: nullStatusEntity,
  registry: nullStatusEntity,
  repository: nullStatusEntity,
  tag: nullStatusEntity,
  viewProps: {
    incomplete: {
      ...initialTableViewProps,
      pending: true,
      analyzing: true,
      failed: true,
    },
    summary: {
      ...initialTableViewProps,
      sort: { lastUpdated: true },
    },
    registry: {
      ...initialTableViewProps,
      sort: { lastUpdated: true },
    },
    repository: {
      ...initialTableViewProps,
      animateToolbar: false,
      sort: { lastDetectedAt: true },
    },
    tag: {
      ...initialTableViewProps,
      sort: { lastAnalyzed: true },
    },
  },
};

// --- Slice -------------------------------------------------------------------
const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    setImagesViewProps: (
      state,
      action: PayloadAction<DeepPartial<ImagesViewProps>>,
    ) => {
      state.viewProps = merge(state.viewProps, action.payload, {
        customMerge: key => {
          // Replace the sort object instead of merging it
          if (key === 'sort') return (a, b) => b;
          return undefined;
        },
      }) as ImagesViewProps;
    },
  },
  extraReducers: builder => {
    createStatusEntityReducers(entities, builder);
  },
});

export const { setImagesViewProps } = imagesSlice.actions;

export default imagesSlice.reducer;
