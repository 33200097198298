// Reset all Redux state objects to baseline
export const SET_APP_STATE_RESET = 'SET_APP_STATE_RESET';

// Update entire Redux state objects directly
export const SET_APP_STATE_UPDATE = 'SET_APP_STATE_UPDATE';

// App connection health state operations
export const SET_APP_CONNECTION_HEALTH = 'SET_APP_CONNECTION_HEALTH';

// Engine health state operations
export const SET_ENGINE_HEALTH = 'SET_ENGINE_HEALTH';

// Engine version state operations
export const SET_VERSION_HEALTH = 'SET_VERSION_HEALTH';

// App-wide print-dialog status
export const SET_PRINT_DIALOG = 'SET_PRINT_DIALOG';
export const SET_PRINT_VIEW_PENDING = 'SET_PRINT_VIEW_PENDING';

// App-wide tour properties
export const SET_TOUR_PROPS = 'SET_TOUR_PROPS';

// App-wide download properties
export const SET_DOWNLOAD_IN_PROGRESS = 'SET_DOWNLOAD_IN_PROGRESS';

// App-wide "What's New?" properties
export const SET_WHATS_NEW_DIALOG = 'SET_WHATS_NEW_DIALOG';
export const SET_WHATS_NEW_FEATURE_IDX = 'SET_WHATS_NEW_FEATURE_IDX';
export const SET_WHATS_NEW_PREV_IDX = 'SET_WHATS_NEW_PREV_IDX';
export const SET_WHATS_NEW_SHOW_CTRL = 'SET_WHATS_NEW_SHOW_CTRL';

// Top-navigation view, general options
export const SET_TOPNAV_VIEW_PROPERTIES = 'SET_TOPNAV_VIEW_PROPERTIES';

// Authentication state operations
export const SET_LOGIN_PENDING = 'SET_LOGIN_PENDING';
export const SET_LOGIN_SUCCESS = 'SET_LOGIN_SUCCESS';
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
export const SET_LOGOUT_PENDING = 'SET_LOGOUT_PENDING';
export const SET_LOGOUT_SUCCESS = 'SET_LOGOUT_SUCCESS';
export const SET_LOGOUT_ERROR = 'SET_LOGOUT_ERROR';

// Fetch policy list state operations
export const SET_POLICY_BUNDLES_FETCH_PENDING =
  'SET_POLICY_BUNDLES_FETCH_PENDING';
export const SET_POLICY_BUNDLES_FETCH_SUCCESS =
  'SET_POLICY_BUNDLES_FETCH_SUCCESS';
export const SET_POLICY_BUNDLES_FETCH_ERROR = 'SET_POLICY_BUNDLES_FETCH_ERROR';

// Post new policy state operations
export const SET_POLICY_BUNDLE_ADD_PENDING = 'SET_POLICY_BUNDLE_ADD_PENDING';
export const SET_POLICY_BUNDLE_ADD_SUCCESS = 'SET_POLICY_BUNDLE_ADD_SUCCESS';
export const SET_POLICY_BUNDLE_ADD_ERROR = 'SET_POLICY_BUNDLE_ADD_ERROR';

// Download policy state operations
export const SET_POLICY_BUNDLE_DOWNLOAD_PENDING =
  'SET_POLICY_BUNDLE_DOWNLOAD_PENDING';
export const SET_POLICY_BUNDLE_DOWNLOAD_SUCCESS =
  'SET_POLICY_BUNDLE_DOWNLOAD_SUCCESS';
export const SET_POLICY_BUNDLE_DOWNLOAD_ERROR =
  'SET_POLICY_BUNDLE_DOWNLOAD_ERROR';
export const SET_POLICY_BUNDLE_DOWNLOAD_CLEAR =
  'SET_POLICY_BUNDLE_DOWNLOAD_CLEAR';

// Delete policy state operations
export const SET_POLICY_BUNDLE_REMOVE_PENDING =
  'SET_POLICY_BUNDLE_REMOVE_PENDING';
export const SET_POLICY_BUNDLE_REMOVE_SUCCESS =
  'SET_POLICY_BUNDLE_REMOVE_SUCCESS';
export const SET_POLICY_BUNDLE_REMOVE_ERROR = 'SET_POLICY_BUNDLE_REMOVE_ERROR';
export const CLEAR_POLICY_BUNDLE_REMOVE_ERROR =
  'CLEAR_POLICY_BUNDLE_REMOVE_ERROR';

// Update policy state operations
export const SET_POLICY_BUNDLE_UPDATE_PENDING =
  'SET_POLICY_BUNDLE_UPDATE_PENDING';
export const SET_POLICY_BUNDLE_UPDATE_SUCCESS =
  'SET_POLICY_BUNDLE_UPDATE_SUCCESS';
export const SET_POLICY_BUNDLE_UPDATE_ERROR = 'SET_POLICY_BUNDLE_UPDATE_ERROR';

// Add policy dialog state operations
export const SET_POLICY_BUNDLE_ADD_DIALOG = 'SET_POLICY_BUNDLE_ADD_DIALOG';
export const SET_POLICY_BUNDLE_REMOVE_DIALOG =
  'SET_POLICY_BUNDLE_REMOVE_DIALOG';
export const SET_POLICY_BUNDLE_UPLOAD_DIALOG =
  'SET_POLICY_BUNDLE_UPLOAD_DIALOG';
export const SET_POLICY_BUNDLE_COPY_DIALOG = 'SET_POLICY_BUNDLE_COPY_DIALOG';

// Policy upload state operations
export const SET_POLICY_BUNDLE_UPLOAD_DIALOG_JSON_FILE =
  'SET_POLICY_BUNDLE_UPLOAD_DIALOG_JSON_FILE';
export const SET_POLICY_BUNDLE_UPLOAD_DIALOG_JSON_STRING =
  'SET_POLICY_BUNDLE_UPLOAD_DIALOG_JSON_STRING';
export const SET_POLICY_BUNDLE_UPLOAD_DIALOG_JSON_UPDATE =
  'SET_POLICY_BUNDLE_UPLOAD_DIALOG_JSON_UPDATE';
export const SET_POLICY_BUNDLE_UPLOAD_DIALOG_SCHEMA_ERROR =
  'SET_POLICY_BUNDLE_UPLOAD_DIALOG_SCHEMA_ERROR';

// Policy Editor, general editing and view actions
export const SET_POLICY_EDITOR_ACTIVE_TAB = 'SET_POLICY_EDITOR_ACTIVE_TAB';
export const SET_POLICY_EDITOR_EDIT_NAME = 'SET_POLICY_EDITOR_EDIT_NAME';
export const SET_POLICY_EDITOR_EDIT_NAME_ERROR =
  'SET_POLICY_EDITOR_EDIT_NAME_ERROR';
export const SET_POLICY_EDITOR_EDIT_DESCRIPTION =
  'SET_POLICY_EDITOR_EDIT_DESCRIPTION';
export const SET_POLICY_EDITOR_EDIT_DESCRIPTION_ERROR =
  'SET_POLICY_EDITOR_EDIT_DESCRIPTION_ERROR';
export const SET_POLICY_EDITOR_VIEW_PROPERTIES =
  'SET_POLICY_EDITOR_VIEW_PROPERTIES';

// Fetch policies state operations
export const SET_POLICY_BUNDLE_FETCH_PENDING =
  'SET_POLICY_BUNDLE_FETCH_PENDING';
export const SET_POLICY_BUNDLE_FETCH_SUCCESS =
  'SET_POLICY_BUNDLE_FETCH_SUCCESS';
export const SET_POLICY_BUNDLE_FETCH_ERROR = 'SET_POLICY_BUNDLE_FETCH_ERROR';

// Policy item management dialog state operations
export const SET_POLICY_ITEM_REMOVE_DIALOG = 'SET_POLICY_ITEM_REMOVE_DIALOG';
export const SET_POLICY_ITEM_REMOVE_ERROR = 'SET_POLICY_ITEM_REMOVE_ERROR';
export const SET_POLICY_ITEM_COPY_DIALOG = 'SET_POLICY_ITEM_COPY_DIALOG';
export const SET_POLICY_ITEM_COPY_ERROR = 'SET_POLICY_ITEM_COPY_ERROR';
export const SET_POLICY_ITEM_NEW_DIALOG = 'SET_POLICY_ITEM_NEW_DIALOG';
export const SET_POLICY_ITEM_NEW_ERROR = 'SET_POLICY_ITEM_NEW_ERROR';

// Policy item rule editing state operations
export const SET_POLICY_ITEM_EDIT_DIALOG = 'SET_POLICY_ITEM_EDIT_DIALOG';
export const SET_POLICY_ITEM_EDIT_NAME = 'SET_POLICY_ITEM_EDIT_NAME';
export const SET_POLICY_ITEM_EDIT_NAME_ERROR =
  'SET_POLICY_ITEM_EDIT_NAME_ERROR';
export const SET_POLICY_ITEM_EDIT_DESCRIPTION =
  'SET_POLICY_ITEM_EDIT_DESCRIPTION';
export const SET_POLICY_ITEM_EDIT_DESCRIPTION_ERROR =
  'SET_POLICY_ITEM_EDIT_DESCRIPTION_ERROR';
export const SET_POLICY_ITEM_EDIT_SAVE_RULES_ERROR =
  'SET_POLICY_ITEM_EDIT_SAVE_RULES_ERROR';
export const SET_POLICY_ITEM_EDIT_RULE_DESCRIPTION =
  'SET_POLICY_ITEM_EDIT_RULE_DESCRIPTION';
export const SET_POLICY_ITEM_EDIT_RULE_DESCRIPTION_ERROR =
  'SET_POLICY_ITEM_EDIT_RULE_DESCRIPTION_ERROR';

// Fetch policy spec state operations
export const SET_POLICY_SPEC_FETCH_PENDING = 'SET_POLICY_SPEC_FETCH_PENDING';
export const SET_POLICY_SPEC_FETCH_SUCCESS = 'SET_POLICY_SPEC_FETCH_SUCCESS';
export const SET_POLICY_SPEC_FETCH_ERROR = 'SET_POLICY_SPEC_FETCH_ERROR';

// Allowlist Editor, general editing actions
export const SET_ALLOWLIST_ITEM_REMOVE_DIALOG =
  'SET_ALLOWLIST_ITEM_REMOVE_DIALOG';
export const SET_ALLOWLIST_ITEM_REMOVE_ERROR =
  'SET_ALLOWLIST_ITEM_REMOVE_ERROR';
export const SET_ALLOWLIST_ITEM_COPY_DIALOG = 'SET_ALLOWLIST_ITEM_COPY_DIALOG';
export const SET_ALLOWLIST_ITEM_COPY_ERROR = 'SET_ALLOWLIST_ITEM_COPY_ERROR';
export const SET_ALLOWLIST_ITEM_NEW_DIALOG = 'SET_ALLOWLIST_ITEM_NEW_DIALOG';
export const SET_ALLOWLIST_ITEM_NEW_ERROR = 'SET_ALLOWLIST_ITEM_NEW_ERROR';
export const SET_ALLOWLIST_UPLOAD_DIALOG = 'SET_ALLOWLIST_UPLOAD_DIALOG';
export const SET_ALLOWLIST_UPLOAD_DIALOG_JSON_FILE =
  'SET_ALLOWLIST_UPLOAD_DIALOG_JSON_STRING';
export const SET_ALLOWLIST_UPLOAD_DIALOG_SCHEMA_ERROR =
  'SET_ALLOWLIST_UPLOAD_DIALOG_SCHEMA_ERROR';

// Allowlist Editor, item editing actions
export const SET_ALLOWLIST_ITEM_EDIT_DIALOG = 'SET_ALLOWLIST_ITEM_EDIT_DIALOG';
export const SET_ALLOWLIST_ITEM_EDIT_NAME = 'SET_ALLOWLIST_ITEM_EDIT_NAME';
export const SET_ALLOWLIST_ITEM_EDIT_NAME_ERROR =
  'SET_ALLOWLIST_ITEM_EDIT_NAME_ERROR';
export const SET_ALLOWLIST_ITEM_EDIT_DESCRIPTION =
  'SET_ALLOWLIST_ITEM_EDIT_DESCRIPTION';
export const SET_ALLOWLIST_ITEM_EDIT_DESCRIPTION_ERROR =
  'SET_ALLOWLIST_ITEM_EDIT_DESCRIPTION_ERROR';
export const SET_ALLOWLIST_ITEM_EDIT_RULE_DESCRIPTION =
  'SET_ALLOWLIST_ITEM_EDIT_RULE_DESCRIPTION';
export const SET_ALLOWLIST_ITEM_EDIT_RULE_DESCRIPTION_ERROR =
  'SET_ALLOWLIST_ITEM_EDIT_RULE_DESCRIPTION_ERROR';
export const SET_ALLOWLIST_ITEM_EDIT_SAVE_ITEMS_ERROR =
  'SET_ALLOWLIST_ITEM_EDIT_SAVE_ITEMS_ERROR';

// Mapping Editor, general editing actions
export const SET_MAPPING_ITEM_REMOVE_DIALOG = 'SET_MAPPING_ITEM_REMOVE_DIALOG';
export const SET_MAPPING_ITEM_REMOVE_ERROR = 'SET_MAPPING_ITEM_REMOVE_ERROR';

export const SET_MAPPING_EDITOR_SHOWING = 'SET_MAPPING_EDITOR_SHOWING';
export const SET_MAPPING_EDITOR_SUCCESS = 'SET_MAPPING_EDITOR_SUCCESS';
export const SET_MAPPING_EDITOR_ERROR = 'SET_MAPPING_EDITOR_ERROR';

// Allowed / Denied Images Editor, general editing actions
export const SET_TRUSTED_ITEM_ACTIVE_TAB = 'SET_TRUSTED_ITEM_ACTIVE_TAB';
export const SET_TRUSTED_ITEM_NEW_DIALOG = 'SET_TRUSTED_ITEM_NEW_DIALOG';
export const SET_TRUSTED_ITEM_NEW_ERROR = 'SET_TRUSTED_ITEM_NEW_ERROR';
export const SET_TRUSTED_ITEM_REMOVE_DIALOG = 'SET_TRUSTED_ITEM_REMOVE_DIALOG';
export const SET_TRUSTED_ITEM_REMOVE_ERROR = 'SET_TRUSTED_ITEM_REMOVE_ERROR';

// Multiple images data fetch actions
export const SET_IMAGES_FETCH_PENDING = 'SET_IMAGES_FETCH_PENDING';
export const SET_IMAGES_FETCH_SUCCESS = 'SET_IMAGES_FETCH_SUCCESS';
export const SET_IMAGES_FETCH_ERROR = 'SET_IMAGES_FETCH_ERROR';

// Set the dataset that corresponds to the image route
export const SET_IMAGES_ROUTE_DATA = 'SET_IMAGES_ROUTE_DATA';

// Single image data add actions
export const SET_IMAGE_ADD_PENDING = 'SET_IMAGE_ADD_PENDING';
export const SET_IMAGE_ADD_SUCCESS = 'SET_IMAGE_ADD_SUCCESS';
export const SET_IMAGE_ADD_ERROR = 'SET_IMAGE_ADD_ERROR';

// Single image data remove actions
export const SET_IMAGE_REMOVE_PENDING = 'SET_IMAGE_REMOVE_PENDING';
export const SET_IMAGE_REMOVE_SUCCESS = 'SET_IMAGE_REMOVE_SUCCESS';
export const SET_IMAGE_REMOVE_ERROR = 'SET_IMAGE_REMOVE_ERROR';

// Multiple image data remove actions
export const SET_IMAGES_REMOVE_PENDING = 'SET_IMAGES_REMOVE_PENDING';
export const SET_IMAGES_REMOVE_SUCCESS = 'SET_IMAGES_REMOVE_SUCCESS';
export const SET_IMAGES_REMOVE_ERROR = 'SET_IMAGES_REMOVE_ERROR';

// Test policy actions
export const SET_TESTBUNDLE_QUERY_STRING = 'SET_TESTBUNDLE_QUERY_STRING';
export const SET_TESTBUNDLE_RESET_IMAGE = 'SET_TESTBUNDLE_RESET_IMAGE';
export const SET_TESTBUNDLE_SHOWING_RESULTS = 'SET_TESTBUNDLE_SHOWING_RESULTS';

// Image Selection view, general options
export const SET_IMAGE_ADD_DIALOG = 'SET_IMAGE_ADD_DIALOG';
export const SET_REPOSITORY_WATCH_MODAL = 'SET_REPOSITORY_WATCH_MODAL';
export const SET_IMAGE_SELECTION_VIEW_PROPERTIES =
  'SET_IMAGE_SELECTION_VIEW_PROPERTIES';
export const SET_IMAGES_REMOVE_DIALOG = 'SET_IMAGES_REMOVE_DIALOG';

// Image Selection view, tag removal actions
export const SET_TAGS_REMOVE_DIALOG = 'SET_TAGS_REMOVE_DIALOG';
export const SET_TAGS_REMOVE_PENDING = 'SET_TAGS_REMOVE_PENDING';
export const SET_TAGS_REMOVE_SUCCESS = 'SET_TAGS_REMOVE_SUCCESS';
export const SET_TAGS_REMOVE_ERROR = 'SET_TAGS_REMOVE_ERROR';
export const CLEAR_TAGS_REMOVE_ERROR = 'CLEAR_TAGS_REMOVE_ERROR';

// Artifact Compliance view, Allowlist Item Edit actions
export const SET_ALLOWLIST_ITEM_EDIT_MODAL = 'SET_ALLOWLIST_ITEM_EDIT_MODAL';
export const SET_ALLOWLIST_ITEM_EDIT_SAVE_ERROR =
  'SET_ALLOWLIST_ITEM_EDIT_SAVE_ERROR';

// Image Build Summary view, general options
export const SET_IMAGE_BUILD_SUMMARY_ACTIVE_TAB =
  'SET_IMAGE_BUILD_SUMMARY_ACTIVE_TAB';

// Image Build Summary, fetch actions
export const SET_IMAGE_BUILD_SUMMARY_PENDING =
  'SET_IMAGE_BUILD_SUMMARY_PENDING';
export const SET_IMAGE_BUILD_SUMMARY_SUCCESS =
  'SET_IMAGE_BUILD_SUMMARY_SUCCESS';
export const SET_IMAGE_BUILD_SUMMARY_ERROR = 'SET_IMAGE_BUILD_SUMMARY_ERROR';

// Event data, general options
export const SET_EVENTS_ACTIVE_TAB = 'SET_EVENTS_ACTIVE_TAB';
export const SET_NOTIFICATIONS_ACTIVE_TAB = 'SET_NOTIFICATIONS_ACTIVE_TAB';
export const SET_EVENTS_VIEW_PROPERTIES = 'SET_EVENTS_VIEW_PROPERTIES';
export const SET_EVENTS_REMOVE_DIALOG = 'SET_EVENTS_REMOVE_DIALOG';
export const SET_EVENT_REMOVE_DIALOG = 'SET_EVENT_REMOVE_DIALOG';
export const SET_EVENTS_SELECTED = 'SET_EVENTS_SELECTED';

// Event data, fetch actions
export const SET_EVENTS_PENDING = 'SET_EVENTS_PENDING';
export const SET_EVENTS_SUCCESS = 'SET_EVENTS_SUCCESS';
export const SET_EVENTS_ERROR = 'SET_EVENTS_ERROR';
export const SET_EVENT_TYPES_FETCH_PENDING = 'SET_EVENT_TYPES_FETCH_PENDING';
export const SET_EVENT_TYPES_FETCH_SUCCESS = 'SET_EVENT_TYPES_FETCH_SUCCESS';
export const SET_EVENT_TYPES_FETCH_ERROR = 'SET_EVENT_TYPES_FETCH_ERROR';
export const SET_EVENTS_SEARCH_PENDING = 'SET_EVENTS_SEARCH_PENDING';
export const SET_EVENTS_SEARCH_SUCCESS = 'SET_EVENTS_SEARCH_SUCCESS';
export const SET_EVENTS_SEARCH_ERROR = 'SET_EVENTS_SEARCH_ERROR';
export const CLEAR_EVENTS_SEARCH = 'CLEAR_EVENTS_SEARCH';

// Event data, remove actions
export const SET_EVENTS_REMOVE_PENDING = 'SET_EVENTS_REMOVE_PENDING';
export const SET_EVENTS_REMOVE_SUCCESS = 'SET_EVENTS_REMOVE_SUCCESS';
export const SET_EVENTS_REMOVE_ERROR = 'SET_EVENTS_REMOVE_ERROR';
export const CLEAR_EVENTS_REMOVE_ERRORS = 'CLEAR_EVENTS_REMOVE_ERRORS';
export const SET_EVENT_REMOVE_PENDING = 'SET_EVENT_REMOVE_PENDING';
export const SET_EVENT_REMOVE_SUCCESS = 'SET_EVENT_REMOVE_SUCCESS';
export const SET_EVENT_REMOVE_ERROR = 'SET_EVENT_REMOVE_ERROR';
export const CLEAR_EVENTS_PENDING = 'CLEAR_EVENTS_PENDING';
export const CLEAR_EVENTS_SUCCESS = 'CLEAR_EVENTS_SUCCESS';
export const CLEAR_EVENTS_ERROR = 'CLEAR_EVENTS_ERROR';

// Subscription, add actions
export const SET_SUBSCRIPTION_ADD_PENDING = 'SET_SUBSCRIPTION_ADD_PENDING';
export const SET_SUBSCRIPTION_ADD_SUCCESS = 'SET_SUBSCRIPTION_ADD_SUCCESS';
export const SET_SUBSCRIPTION_ADD_ERROR = 'SET_SUBSCRIPTION_ADD_ERROR';

// Subscription, update actions
export const SET_SUBSCRIPTION_UPDATE_PENDING =
  'SET_SUBSCRIPTION_UPDATE_PENDING';
export const SET_SUBSCRIPTION_UPDATE_SUCCESS =
  'SET_SUBSCRIPTION_UPDATE_SUCCESS';
export const SET_SUBSCRIPTION_UPDATE_ERROR = 'SET_SUBSCRIPTION_UPDATE_ERROR';

// Subscriptions, fetch actions
export const SET_SUBSCRIPTIONS_FETCH_PENDING =
  'SET_SUBSCRIPTIONS_FETCH_PENDING';
export const SET_SUBSCRIPTIONS_FETCH_SUCCESS =
  'SET_SUBSCRIPTIONS_FETCH_SUCCESS';
export const SET_SUBSCRIPTIONS_FETCH_ERROR = 'SET_SUBSCRIPTIONS_FETCH_ERROR';

// Repository, add actions
export const SET_REPOSITORY_ADD_PENDING = 'SET_REPOSITORY_ADD_PENDING';
export const SET_REPOSITORY_ADD_SUCCESS = 'SET_REPOSITORY_ADD_SUCCESS';
export const SET_REPOSITORY_ADD_ERROR = 'SET_REPOSITORY_ADD_ERROR';

// Repository, general action
export const SET_REPOSITORY_ADD_DIALOG = 'SET_REPOSITORY_ADD_DIALOG';

// System view, general options
export const SET_SYSTEM_VIEW_PROPERTIES = 'SET_SYSTEM_VIEW_PROPERTIES';
export const SET_SYSTEM_ACTIVE_TAB = 'SET_SYSTEM_ACTIVE_TAB';

// LDAP config data, fetch actions
export const SET_LDAP_CONFIG_FETCH_PENDING = 'SET_LDAP_CONFIG_FETCH_PENDING';
export const SET_LDAP_CONFIG_FETCH_SUCCESS = 'SET_LDAP_CONFIG_FETCH_SUCCESS';
export const SET_LDAP_CONFIG_FETCH_ERROR = 'SET_LDAP_CONFIG_FETCH_ERROR';

// LDAP config data, update actions
export const SET_LDAP_CONFIG_UPDATE_PENDING = 'SET_LDAP_CONFIG_UPDATE_PENDING';
export const SET_LDAP_CONFIG_UPDATE_SUCCESS = 'SET_LDAP_CONFIG_UPDATE_SUCCESS';
export const SET_LDAP_CONFIG_UPDATE_ERROR = 'SET_LDAP_CONFIG_UPDATE_ERROR';

// LDAP config data, test actions
export const SET_LDAP_CONFIG_TEST_PENDING = 'SET_LDAP_CONFIG_TEST_PENDING';
export const SET_LDAP_CONFIG_TEST_SUCCESS = 'SET_LDAP_CONFIG_TEST_SUCCESS';
export const SET_LDAP_CONFIG_TEST_ERROR = 'SET_LDAP_CONFIG_TEST_ERROR';

// Registries data, general options
export const SET_REGISTRY_ADD_DIALOG = 'SET_REGISTRY_ADD_DIALOG';
export const SET_REGISTRY_UPDATE_DIALOG = 'SET_REGISTRY_UPDATE_DIALOG';
export const SET_REGISTRY_REMOVE_DIALOG = 'SET_REGISTRY_REMOVE_DIALOG';

// Registries data, fetch actions
export const SET_REGISTRIES_FETCH_PENDING = 'SET_REGISTRIES_FETCH_PENDING';
export const SET_REGISTRIES_FETCH_SUCCESS = 'SET_REGISTRIES_FETCH_SUCCESS';
export const SET_REGISTRIES_FETCH_ERROR = 'SET_REGISTRIES_FETCH_ERROR';

// Registries data, add actions
export const SET_REGISTRY_ADD_PENDING = 'SET_REGISTRY_ADD_PENDING';
export const SET_REGISTRY_ADD_SUCCESS = 'SET_REGISTRY_ADD_SUCCESS';
export const SET_REGISTRY_ADD_ERROR = 'SET_REGISTRY_ADD_ERROR';
export const CLEAR_REGISTRY_ADD_ERROR = 'CLEAR_REGISTRY_ADD_ERROR';

// Registries data, update actions
export const SET_REGISTRY_UPDATE_PENDING = 'SET_REGISTRY_UPDATE_PENDING';
export const SET_REGISTRY_UPDATE_SUCCESS = 'SET_REGISTRY_UPDATE_SUCCESS';
export const SET_REGISTRY_UPDATE_ERROR = 'SET_REGISTRY_UPDATE_ERROR';
export const CLEAR_REGISTRY_UPDATE_ERROR = 'CLEAR_REGISTRY_UPDATE_ERROR';

// Registries data, remove actions
export const SET_REGISTRY_REMOVE_PENDING = 'SET_REGISTRY_REMOVE_PENDING';
export const SET_REGISTRY_REMOVE_SUCCESS = 'SET_REGISTRY_REMOVE_SUCCESS';
export const SET_REGISTRY_REMOVE_ERROR = 'SET_REGISTRY_REMOVE_ERROR';
export const CLEAR_REGISTRY_REMOVE_ERROR = 'CLEAR_REGISTRY_REMOVE_ERROR';

// Notifications view, general options
export const SET_NOTIFICATION_ADD_DIALOG = 'SET_NOTIFICATION_ADD_DIALOG';
export const SET_NOTIFICATION_UPDATE_DIALOG = 'SET_NOTIFICATION_UPDATE_DIALOG';
export const SET_NOTIFICATION_REMOVE_DIALOG = 'SET_NOTIFICATION_REMOVE_DIALOG';

// Notifications Swagger data, fetch actions
export const SET_NOTIFICATIONS_SPEC_FETCH_PENDING =
  'SET_NOTIFICATIONS_SPEC_FETCH_PENDING';
export const SET_NOTIFICATIONS_SPEC_FETCH_SUCCESS =
  'SET_NOTIFICATIONS_SPEC_FETCH_SUCCESS';
export const SET_NOTIFICATIONS_SPEC_FETCH_ERROR =
  'SET_NOTIFICATIONS_SPEC_FETCH_ERROR';

// Notification configs, fetch actions
export const SET_NOTIFICATION_CONFIGS_FETCH_PENDING =
  'SET_NOTIFICATION_CONFIGS_FETCH_PENDING';
export const SET_NOTIFICATION_CONFIGS_FETCH_SUCCESS =
  'SET_NOTIFICATION_CONFIGS_FETCH_SUCCESS';
export const SET_NOTIFICATION_CONFIGS_FETCH_ERROR =
  'SET_NOTIFICATION_CONFIGS_FETCH_ERROR';

// New notification config, test actions
export const SET_NEW_NOTIFICATION_CONFIG_TEST_PENDING =
  'SET_NEW_NOTIFICATION_CONFIG_TEST_PENDING';
export const SET_NEW_NOTIFICATION_CONFIG_TEST_SUCCESS =
  'SET_NEW_NOTIFICATION_CONFIG_TEST_SUCCESS';
export const SET_NEW_NOTIFICATION_CONFIG_TEST_ERROR =
  'SET_NEW_NOTIFICATION_CONFIG_TEST_ERROR';

// Notification config, test actions
export const SET_NOTIFICATION_CONFIG_TEST_PENDING =
  'SET_NOTIFICATION_CONFIG_TEST_PENDING';
export const SET_NOTIFICATION_CONFIG_TEST_SUCCESS =
  'SET_NOTIFICATION_CONFIG_TEST_SUCCESS';
export const SET_NOTIFICATION_CONFIG_TEST_ERROR =
  'SET_NOTIFICATION_CONFIG_TEST_ERROR';

// Notification config, add actions
export const SET_NOTIFICATION_CONFIG_ADD_PENDING =
  'SET_NOTIFICATION_CONFIG_ADD_PENDING';
export const SET_NOTIFICATION_CONFIG_ADD_SUCCESS =
  'SET_NOTIFICATION_CONFIG_ADD_SUCCESS';
export const SET_NOTIFICATION_CONFIG_ADD_ERROR =
  'SET_NOTIFICATION_CONFIG_ADD_ERROR';

// Notification config, update actions
export const SET_NOTIFICATION_CONFIG_UPDATE_PENDING =
  'SET_NOTIFICATION_CONFIG_UPDATE_PENDING';
export const SET_NOTIFICATION_CONFIG_UPDATE_SUCCESS =
  'SET_NOTIFICATION_CONFIG_UPDATE_SUCCESS';
export const SET_NOTIFICATION_CONFIG_UPDATE_ERROR =
  'SET_NOTIFICATION_CONFIG_UPDATE_ERROR';

// Notification config, remove actions
export const SET_NOTIFICATION_CONFIG_REMOVE_PENDING =
  'SET_NOTIFICATION_CONFIG_REMOVE_PENDING';
export const SET_NOTIFICATION_CONFIG_REMOVE_SUCCESS =
  'SET_NOTIFICATION_CONFIG_REMOVE_SUCCESS';
export const SET_NOTIFICATION_CONFIG_REMOVE_ERROR =
  'SET_NOTIFICATION_CONFIG_REMOVE_ERROR';

// Notification drivers, fetch actions
export const SET_NOTIFICATION_DRIVERS_FETCH_PENDING =
  'SET_NOTIFICATION_DRIVERS_FETCH_PENDING';
export const SET_NOTIFICATION_DRIVERS_FETCH_SUCCESS =
  'SET_NOTIFICATION_DRIVERS_FETCH_SUCCESS';
export const SET_NOTIFICATION_DRIVERS_FETCH_ERROR =
  'SET_NOTIFICATION_DRIVERS_FETCH_ERROR';

// Notification drivers, update actions
export const SET_NOTIFICATION_DRIVERS_UPDATE_PENDING =
  'SET_NOTIFICATION_DRIVERS_UPDATE_PENDING';
export const SET_NOTIFICATION_DRIVERS_UPDATE_SUCCESS =
  'SET_NOTIFICATION_DRIVERS_UPDATE_SUCCESS';
export const SET_NOTIFICATION_DRIVERS_UPDATE_ERROR =
  'SET_NOTIFICATION_DRIVERS_UPDATE_ERROR';

// Swagger data, fetch actions
export const SET_SWAGGER_SPEC_PENDING = 'SET_SWAGGER_SPEC_PENDING';
export const SET_SWAGGER_SPEC_SUCCESS = 'SET_SWAGGER_SPEC_SUCCESS';
export const SET_SWAGGER_SPEC_ERROR = 'SET_SWAGGER_SPEC_ERROR';

// User account configuration, update actions
export const SET_PASSWORD_UPDATE_PENDING = 'SET_PASSWORD_UPDATE_PENDING';
export const SET_PASSWORD_UPDATE_SUCCESS = 'SET_PASSWORD_UPDATE_SUCCESS';
export const SET_PASSWORD_UPDATE_ERROR = 'SET_PASSWORD_UPDATE_ERROR';
export const SET_PASSWORD_UPDATE_RESET = 'SET_PASSWORD_UPDATE_RESET';

// Admin accounts, general options
export const SET_ACCOUNT_ACTIVE_TAB = 'SET_ACCOUNT_ACTIVE_TAB';
export const SET_ACCOUNT_CREATE_DIALOG = 'SET_ACCOUNT_CREATE_DIALOG';
export const SET_ACCOUNT_REMOVE_DIALOG = 'SET_ACCOUNT_REMOVE_DIALOG';
export const SET_ACCOUNT_ADD_USER_DIALOG = 'SET_ACCOUNT_ADD_USER_DIALOG';
export const SET_ACCOUNT_UPDATE_USER_DIALOG = 'SET_ACCOUNT_UPDATE_USER_DIALOG';
export const SET_ACCOUNT_REMOVE_USER_DIALOG = 'SET_ACCOUNT_REMOVE_USER_DIALOG';
export const SET_ACCOUNT_VIEW_ROLE_DIALOG = 'SET_ACCOUNT_VIEW_ROLE_DIALOG';

// Admin accounts, fetch actions
export const SET_ACCOUNTS_FETCH_PENDING = 'SET_ACCOUNTS_FETCH_PENDING';
export const SET_ACCOUNTS_FETCH_SUCCESS = 'SET_ACCOUNTS_FETCH_SUCCESS';
export const SET_ACCOUNTS_FETCH_ERROR = 'SET_ACCOUNTS_FETCH_ERROR';
export const SET_ACCOUNT_FETCH_PENDING = 'SET_ACCOUNT_FETCH_PENDING';
export const SET_ACCOUNT_FETCH_SUCCESS = 'SET_ACCOUNT_FETCH_SUCCESS';
export const SET_ACCOUNT_FETCH_ERROR = 'SET_ACCOUNT_FETCH_ERROR';

// Admin accounts, add actions
export const SET_ACCOUNT_CREATE_PENDING = 'SET_ACCOUNT_CREATE_PENDING';
export const SET_ACCOUNT_CREATE_SUCCESS = 'SET_ACCOUNT_CREATE_SUCCESS';
export const SET_ACCOUNT_CREATE_ERROR = 'SET_ACCOUNT_CREATE_ERROR';

// Admin accounts, update actions
export const SET_ACCOUNT_UPDATE_PENDING = 'SET_ACCOUNT_UPDATE_PENDING';
export const SET_ACCOUNT_UPDATE_SUCCESS = 'SET_ACCOUNT_UPDATE_SUCCESS';
export const SET_ACCOUNT_UPDATE_ERROR = 'SET_ACCOUNT_UPDATE_ERROR';

// Admin accounts, activate/deactivate actions
export const SET_ACCOUNT_ACTIVE_PENDING = 'SET_ACCOUNT_ACTIVE_PENDING';
export const SET_ACCOUNT_ACTIVE_SUCCESS = 'SET_ACCOUNT_ACTIVE_SUCCESS';
export const SET_ACCOUNT_ACTIVE_ERROR = 'SET_ACCOUNT_ACTIVE_ERROR';

// Admin accounts, remove actions
export const SET_ACCOUNT_REMOVE_PENDING = 'SET_ACCOUNT_REMOVE_PENDING';
export const SET_ACCOUNT_REMOVE_SUCCESS = 'SET_ACCOUNT_REMOVE_SUCCESS';
export const SET_ACCOUNT_REMOVE_ERROR = 'SET_ACCOUNT_REMOVE_ERROR';

// Admin accounts, add user
export const SET_ACCOUNT_ADD_USER_PENDING = 'SET_ACCOUNT_ADD_USER_PENDING';
export const SET_ACCOUNT_ADD_USER_SUCCESS = 'SET_ACCOUNT_ADD_USER_SUCCESS';
export const SET_ACCOUNT_ADD_USER_ERROR = 'SET_ACCOUNT_ADD_USER_ERROR';

// Admin accounts, fetch LDAP user mappings
export const SET_ACCOUNT_FETCH_LDAP_USER_MAPPINGS_PENDING =
  'SET_ACCOUNT_FETCH_LDAP_USER_MAPPINGS_PENDING';
export const SET_ACCOUNT_FETCH_LDAP_USER_MAPPINGS_SUCCESS =
  'SET_ACCOUNT_FETCH_LDAP_USER_MAPPINGS_SUCCESS';
export const SET_ACCOUNT_FETCH_LDAP_USER_MAPPINGS_ERROR =
  'SET_ACCOUNT_FETCH_LDAP_USER_MAPPINGS_ERROR';

// Admin accounts, add LDAP user mapping
export const SET_ACCOUNT_ADD_LDAP_USER_MAPPING_PENDING =
  'SET_ACCOUNT_ADD_LDAP_USER_MAPPING_PENDING';
export const SET_ACCOUNT_ADD_LDAP_USER_MAPPING_SUCCESS =
  'SET_ACCOUNT_ADD_LDAP_USER_MAPPING_SUCCESS';
export const SET_ACCOUNT_ADD_LDAP_USER_MAPPING_ERROR =
  'SET_ACCOUNT_ADD_LDAP_USER_MAPPING_ERROR';

// Admin accounts, update LDAP user mapping
export const SET_ACCOUNT_UPDATE_LDAP_USER_MAPPING_PENDING =
  'SET_ACCOUNT_UPDATE_LDAP_USER_MAPPING_PENDING';
export const SET_ACCOUNT_UPDATE_LDAP_USER_MAPPING_SUCCESS =
  'SET_ACCOUNT_UPDATE_LDAP_USER_MAPPING_SUCCESS';
export const SET_ACCOUNT_UPDATE_LDAP_USER_MAPPING_ERROR =
  'SET_ACCOUNT_UPDATE_LDAP_USER_MAPPING_ERROR';

// LDAP config data, remove user mapping
export const SET_ACCOUNT_REMOVE_LDAP_USER_MAPPING_PENDING =
  'SET_ACCOUNT_REMOVE_LDAP_USER_MAPPING_PENDING';
export const SET_ACCOUNT_REMOVE_LDAP_USER_MAPPING_SUCCESS =
  'SET_ACCOUNT_REMOVE_LDAP_USER_MAPPING_SUCCESS';
export const SET_ACCOUNT_REMOVE_LDAP_USER_MAPPING_ERROR =
  'SET_ACCOUNT_REMOVE_LDAP_USER_MAPPING_ERROR';

// LDAP config data, reorder user mappings
export const SET_ACCOUNT_REORDER_LDAP_USER_MAPPINGS_PENDING =
  'SET_ACCOUNT_REORDER_LDAP_USER_MAPPINGS_PENDING';
export const SET_ACCOUNT_REORDER_LDAP_USER_MAPPINGS_SUCCESS =
  'SET_ACCOUNT_REORDER_LDAP_USER_MAPPINGS_SUCCESS';
export const SET_ACCOUNT_REORDER_LDAP_USER_MAPPINGS_ERROR =
  'SET_ACCOUNT_REORDER_LDAP_USER_MAPPINGS_ERROR';

// LDAP config data, test user mappings
export const SET_ACCOUNT_TEST_LDAP_USER_MAPPINGS_PENDING =
  'SET_ACCOUNT_TEST_LDAP_USER_MAPPINGS_PENDING';
export const SET_ACCOUNT_TEST_LDAP_USER_MAPPINGS_SUCCESS =
  'SET_ACCOUNT_TEST_LDAP_USER_MAPPINGS_SUCCESS';
export const SET_ACCOUNT_TEST_LDAP_USER_MAPPINGS_ERROR =
  'SET_ACCOUNT_TEST_LDAP_USER_MAPPINGS_ERROR';

// Admin accounts, remove user
export const SET_ACCOUNT_REMOVE_USER_PENDING =
  'SET_ACCOUNT_REMOVE_USER_PENDING';
export const SET_ACCOUNT_REMOVE_USER_SUCCESS =
  'SET_ACCOUNT_REMOVE_USER_SUCCESS';
export const SET_ACCOUNT_REMOVE_USER_ERROR = 'SET_ACCOUNT_REMOVE_USER_ERROR';

// Admin accounts, update user credentials
export const SET_ACCOUNT_UPDATE_USER_CREDENTIALS_PENDING =
  'SET_ACCOUNT_UPDATE_USER_CREDENTIALS_PENDING';
export const SET_ACCOUNT_UPDATE_USER_CREDENTIALS_SUCCESS =
  'SET_ACCOUNT_UPDATE_USER_CREDENTIALS_SUCCESS';
export const SET_ACCOUNT_UPDATE_USER_CREDENTIALS_ERROR =
  'SET_ACCOUNT_UPDATE_USER_CREDENTIALS_ERROR';

// Admin accounts, switch account context
export const SET_ACCOUNT_UPDATE_CONTEXT_PENDING =
  'SET_ACCOUNT_UPDATE_CONTEXT_PENDING';
export const SET_ACCOUNT_UPDATE_CONTEXT_SUCCESS =
  'SET_ACCOUNT_UPDATE_CONTEXT_SUCCESS';
export const SET_ACCOUNT_UPDATE_CONTEXT_ERROR =
  'SET_ACCOUNT_UPDATE_CONTEXT_ERROR';

// Roles, fetch actions
export const SET_ROLES_FETCH_PENDING = 'SET_ROLES_FETCH_PENDING';
export const SET_ROLES_FETCH_SUCCESS = 'SET_ROLES_FETCH_SUCCESS';
export const SET_ROLES_FETCH_ERROR = 'SET_ROLES_FETCH_ERROR';

// Roles, update members
export const SET_ROLES_UPDATE_MEMBERS_PENDING =
  'SET_ROLES_UPDATE_MEMBERS_PENDING';
export const SET_ROLES_UPDATE_MEMBERS_SUCCESS =
  'SET_ROLES_UPDATE_MEMBERS_SUCCESS';
export const SET_ROLES_UPDATE_MEMBERS_ERROR = 'SET_ROLES_UPDATE_MEMBERS_ERROR';

// Roles, fetch actions per user, grouped by account
export const SET_ACCOUNTS_USER_ROLES_FETCH_PENDING =
  'SET_ACCOUNTS_USER_ROLES_FETCH_PENDING';
export const SET_ACCOUNTS_USER_ROLES_FETCH_SUCCESS =
  'SET_ACCOUNTS_USER_ROLES_FETCH_SUCCESS';
export const SET_ACCOUNTS_USER_ROLES_FETCH_ERROR =
  'SET_ACCOUNTS_USER_ROLES_FETCH_ERROR';

// SAML IDPS, general options
export const SET_ADD_SAML_IDP_DIALOG = 'SET_ADD_SAML_IDP_DIALOG';
export const SET_UPDATE_SAML_IDP_DIALOG = 'SET_UPDATE_SAML_IDP_DIALOG';
export const SET_REMOVE_SAML_IDP_DIALOG = 'SET_REMOVE_SAML_IDP_DIALOG';
export const CLEAR_SAML_IDP_ADD_ERROR = 'CLEAR_SAML_IDP_ADD_ERROR';
export const CLEAR_SAML_IDP_UPDATE_ERROR = 'CLEAR_SAML_IDP_UPDATE_ERROR';
export const CLEAR_SAML_IDP_REMOVE_ERROR = 'CLEAR_SAML_IDP_REMOVE_ERROR';

// SAML IDPS, fetch actions
export const SET_SAML_IDPS_FETCH_PENDING = 'SET_SAML_IDPS_FETCH_PENDING';
export const SET_SAML_IDPS_FETCH_SUCCESS = 'SET_SAML_IDPS_FETCH_SUCCESS';
export const SET_SAML_IDPS_FETCH_ERROR = 'SET_SAML_IDPS_FETCH_ERROR';

// SAML IDP, add actions
export const SET_SAML_IDP_ADD_PENDING = 'SET_SAML_IDP_ADD_PENDING';
export const SET_SAML_IDP_ADD_SUCCESS = 'SET_SAML_IDP_ADD_SUCCESS';
export const SET_SAML_IDP_ADD_ERROR = 'SET_SAML_IDP_ADD_ERROR';

// SAML IDP, update actions
export const SET_SAML_IDP_UPDATE_PENDING = 'SET_SAML_IDP_UPDATE_PENDING';
export const SET_SAML_IDP_UPDATE_SUCCESS = 'SET_SAML_IDP_UPDATE_SUCCESS';
export const SET_SAML_IDP_UPDATE_ERROR = 'SET_SAML_IDP_UPDATE_ERROR';

// SAML IDP, remove actions
export const SET_SAML_IDP_REMOVE_PENDING = 'SET_SAML_IDP_REMOVE_PENDING';
export const SET_SAML_IDP_REMOVE_SUCCESS = 'SET_SAML_IDP_REMOVE_SUCCESS';
export const SET_SAML_IDP_REMOVE_ERROR = 'SET_SAML_IDP_REMOVE_ERROR';

// Dashboard view, general options
export const SET_DASHBOARD_VIEW_PROPERTIES = 'SET_DASHBOARD_VIEW_PROPERTIES';

// Widgets data, general options
export const SET_WIDGET_ADD_DIALOG = 'SET_WIDGET_ADD_DIALOG';
export const SET_WIDGET_UPDATE_DIALOG = 'SET_WIDGET_UPDATE_DIALOG';
export const SET_WIDGET_REMOVE_DIALOG = 'SET_WIDGET_REMOVE_DIALOG';

// Widgets, fetch actions
export const SET_WIDGETS_FETCH_PENDING = 'SET_WIDGETS_FETCH_PENDING';
export const SET_WIDGETS_FETCH_SUCCESS = 'SET_WIDGETS_FETCH_SUCCESS';
export const SET_WIDGETS_FETCH_ERROR = 'SET_WIDGETS_FETCH_ERROR';

// Widgets, add actions
export const SET_WIDGET_ADD_PENDING = 'SET_WIDGET_ADD_PENDING';
export const SET_WIDGET_ADD_SUCCESS = 'SET_WIDGET_ADD_SUCCESS';
export const SET_WIDGET_ADD_ERROR = 'SET_WIDGET_ADD_ERROR';

// Widget, update actions
export const SET_WIDGET_UPDATE_PENDING = 'SET_WIDGET_UPDATE_PENDING';
export const SET_WIDGET_UPDATE_SUCCESS = 'SET_WIDGET_UPDATE_SUCCESS';
export const SET_WIDGET_UPDATE_ERROR = 'SET_WIDGET_UPDATE_ERROR';

// Widget, remove actions
export const SET_WIDGET_REMOVE_PENDING = 'SET_WIDGET_REMOVE_PENDING';
export const SET_WIDGET_REMOVE_SUCCESS = 'SET_WIDGET_REMOVE_SUCCESS';
export const SET_WIDGET_REMOVE_ERROR = 'SET_WIDGET_REMOVE_ERROR';

// Widgets, update actions
export const SET_WIDGETS_UPDATE_PENDING = 'SET_WIDGETS_UPDATE_PENDING';
export const SET_WIDGETS_UPDATE_SUCCESS = 'SET_WIDGETS_UPDATE_SUCCESS';
export const SET_WIDGETS_UPDATE_ERROR = 'SET_WIDGETS_UPDATE_ERROR';

// Metrics, fetch actions
export const SET_METRICS_FETCH_PENDING = 'SET_METRICS_FETCH_PENDING';
export const SET_METRICS_FETCH_SUCCESS = 'SET_METRICS_FETCH_SUCCESS';
export const SET_METRICS_FETCH_ERROR = 'SET_METRICS_FETCH_ERROR';

// Metrics, update actions
export const SET_METRICS_UPDATE_PENDING = 'SET_METRICS_UPDATE_PENDING';
export const SET_METRICS_UPDATE_SUCCESS = 'SET_METRICS_UPDATE_SUCCESS';
export const SET_METRICS_UPDATE_ERROR = 'SET_METRICS_UPDATE_ERROR';

// Graphql Spec, fetch actions
export const SET_GRAPHQL_SPEC_FETCH_PENDING = 'SET_GRAPHQL_SPEC_FETCH_PENDING';
export const SET_GRAPHQL_SPEC_FETCH_SUCCESS = 'SET_GRAPHQL_SPEC_FETCH_SUCCESS';
export const SET_GRAPHQL_SPEC_FETCH_ERROR = 'SET_GRAPHQL_SPEC_FETCH_ERROR';

// Action Workbench view, general actions
export const SET_ACTION_WORKBENCH_SELECTED_ENDPOINT_DROPDOWN =
  'SET_ACTION_WORKBENCH_SELECTED_ENDPOINT_DROPDOWN';
export const SET_ACTION_WORKBENCH_SELECTED_ENDPOINT_IDX =
  'SET_ACTION_WORKBENCH_SELECTED_ENDPOINT_IDX';
export const SET_ACTION_WORKBENCH_SUBMIT_DIALOG =
  'SET_ACTION_WORKBENCH_SUBMIT_DIALOG';

// Action Workbench, data actions
export const SET_ACTION_WORKBENCH_PLAN_ITEMS_FETCH_PENDING =
  'SET_ACTION_WORKBENCH_PLAN_ITEMS_FETCH_PENDING';
export const SET_ACTION_WORKBENCH_PLAN_ITEMS_FETCH_SUCCESS =
  'SET_ACTION_WORKBENCH_PLAN_ITEMS_FETCH_SUCCESS';
export const SET_ACTION_WORKBENCH_PLAN_ITEMS_FETCH_ERROR =
  'SET_ACTION_WORKBENCH_PLAN_ITEMS_FETCH_ERROR';
export const SET_ACTION_WORKBENCH_PLAN_ITEMS_UPDATE_PENDING =
  'SET_UPDATE_ACTION_WORKBENCH_PLAN_ITEMS_PENDING';
export const SET_ACTION_WORKBENCH_PLAN_ITEMS_UPDATE_SUCCESS =
  'SET_ACTION_WORKBENCH_PLAN_ITEMS_UPDATE_SUCCESS';
export const SET_ACTION_WORKBENCH_PLAN_ITEMS_UPDATE_ERROR =
  'SET_ACTION_WORKBENCH_PLAN_ITEMS_UPDATE_ERROR';

// Action Plan, data actions
export const SET_ACTION_PLAN_SUBMISSION_ADD_PENDING =
  'SET_ACTION_PLAN_SUBMISSION_ADD_PENDING';
export const SET_ACTION_PLAN_SUBMISSION_ADD_SUCCESS =
  'SET_ACTION_PLAN_SUBMISSION_ADD_SUCCESS';
export const SET_ACTION_PLAN_SUBMISSION_ADD_ERROR =
  'SET_ACTION_PLAN_SUBMISSION_ADD_ERROR';

// Action Workbench (historic items), data actions
export const SET_ACTION_WORKBENCH_HISTORIC_ITEMS_FETCH_PENDING =
  'SET_ACTION_WORKBENCH_HISTORIC_ITEMS_FETCH_PENDING';
export const SET_ACTION_WORKBENCH_HISTORIC_ITEMS_FETCH_SUCCESS =
  'SET_ACTION_WORKBENCH_HISTORIC_ITEMS_FETCH_SUCCESS';
export const SET_ACTION_WORKBENCH_HISTORIC_ITEMS_FETCH_ERROR =
  'SET_ACTION_WORKBENCH_HISTORIC_ITEMS_FETCH_ERROR';
export const SET_ACTION_WORKBENCH_HISTORIC_ITEMS_RESET =
  'SET_ACTION_WORKBENCH_HISTORIC_ITEMS_RESET';

// Services health, general actions
export const SET_SERVICE_VIEW_MODAL = 'SET_SERVICE_VIEW_MODAL';
export const SET_FEED_VIEW_MODAL = 'SET_FEED_VIEW_MODAL';

// Services health, fetch actions
export const SET_SERVICES_HEALTH_FETCH_PENDING =
  'SET_SERVICES_HEALTH_FETCH_PENDING';
export const SET_SERVICES_HEALTH_FETCH_SUCCESS =
  'SET_SERVICES_HEALTH_FETCH_SUCCESS';
export const SET_SERVICES_HEALTH_FETCH_ERROR =
  'SET_SERVICES_HEALTH_FETCH_ERROR';

// Alerts, general actions
export const SET_COMPLIANCE_ALERT_DISMISS_MODAL =
  'SET_COMPLIANCE_ALERT_DISMISS_MODAL';

// Alerts, fetch actions
export const SET_COMPLIANCE_ALERTS_FETCH_PENDING =
  'SET_COMPLIANCE_ALERTS_FETCH_PENDING';
export const SET_COMPLIANCE_ALERTS_FETCH_SUCCESS =
  'SET_COMPLIANCE_ALERTS_FETCH_SUCCESS';
export const SET_COMPLIANCE_ALERTS_FETCH_ERROR =
  'SET_COMPLIANCE_ALERTS_FETCH_ERROR';
export const SET_COMPLIANCE_ALERT_FETCH_PENDING =
  'SET_COMPLIANCE_ALERT_FETCH_PENDING';
export const SET_COMPLIANCE_ALERT_FETCH_SUCCESS =
  'SET_COMPLIANCE_ALERT_FETCH_SUCCESS';
export const SET_COMPLIANCE_ALERT_FETCH_ERROR =
  'SET_COMPLIANCE_ALERT_FETCH_ERROR';

// Alerts, update actions
export const SET_COMPLIANCE_ALERT_UPDATE_PENDING =
  'SET_COMPLIANCE_ALERT_UPDATE_PENDING';
export const SET_COMPLIANCE_ALERT_UPDATE_SUCCESS =
  'SET_COMPLIANCE_ALERT_UPDATE_SUCCESS';
export const SET_COMPLIANCE_ALERT_UPDATE_ERROR =
  'SET_COMPLIANCE_ALERT_UPDATE_ERROR';

// Artifacts, general actions
export const SET_ARTIFACT_ANALYSIS_ACTIVE_TAB =
  'SET_ARTIFACT_ANALYSIS_ACTIVE_TAB';

export const SET_ARTIFACT_ANALYSIS_SERVICES_RESPONDED =
  'SET_ARTIFACT_ANALYSIS_SERVICES_RESPONDED';

export const SET_ARTIFACT_ANALYSIS_VIEW_PROPERTIES =
  'SET_ARTIFACT_ANALYSIS_VIEW_PROPERTIES';

// Artifacts, fetch actions
export const SET_ARTIFACT_FETCH_PENDING = 'SET_ARTIFACT_FETCH_PENDING';
export const SET_ARTIFACT_FETCH_SUCCESS = 'SET_ARTIFACT_FETCH_SUCCESS';
export const SET_ARTIFACT_FETCH_ERROR = 'SET_ARTIFACT_FETCH_ERROR';

export const SET_ARTIFACT_VULNERABILITIES_PENDING =
  'SET_ARTIFACT_VULNERABILITIES_PENDING';
export const SET_ARTIFACT_VULNERABILITIES_SUCCESS =
  'SET_ARTIFACT_VULNERABILITIES_SUCCESS';
export const SET_ARTIFACT_VULNERABILITIES_ERROR =
  'SET_ARTIFACT_VULNERABILITIES_ERROR';

export const SET_ARTIFACT_CONTENTS_ACTIVE_TAB =
  'SET_ARTIFACT_CONTENTS_ACTIVE_TAB';
export const SET_ARTIFACT_CONTENTS_PENDING = 'SET_ARTIFACT_CONTENTS_PENDING';
export const SET_ARTIFACT_CONTENTS_SUCCESS = 'SET_ARTIFACT_CONTENTS_SUCCESS';
export const SET_ARTIFACT_CONTENTS_ERROR = 'SET_ARTIFACT_CONTENTS_ERROR';

export const SET_ARTIFACT_COMPLIANCE_PENDING =
  'SET_ARTIFACT_COMPLIANCE_PENDING';
export const SET_ARTIFACT_COMPLIANCE_SUCCESS =
  'SET_ARTIFACT_COMPLIANCE_SUCCESS';
export const SET_ARTIFACT_COMPLIANCE_ERROR = 'SET_ARTIFACT_COMPLIANCE_ERROR';

export const SET_ARTIFACT_CHANGELOG_ACTIVE_TAB =
  'SET_ARTIFACT_CHANGELOG_ACTIVE_TAB;';
export const SET_ARTIFACT_CHANGELOG_PENDING = 'SET_ARTIFACT_CHANGELOG_PENDING';
export const SET_ARTIFACT_CHANGELOG_SUCCESS = 'SET_ARTIFACT_CHANGELOG_SUCCESS';
export const SET_ARTIFACT_CHANGELOG_ERROR = 'SET_ARTIFACT_CHANGELOG_ERROR';

// Artifact Analysis Reports, general options
export const SET_ARTIFACT_REPORT_FORMAT_PENDING =
  'SET_ARTIFACT_REPORT_FORMAT_PENDING';
export const SET_ARTIFACT_REPORT_FORMAT_SUCCESS =
  'SET_ARTIFACT_REPORT_FORMAT_SUCCESS';
export const SET_ARTIFACT_REPORT_FORMAT_ERROR =
  'SET_ARTIFACT_REPORT_FORMAT_ERROR';
