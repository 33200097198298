import { Dimmer } from 'semantic-ui-react';

import { useAppSelector } from '@redux/hooks';
import { selectIsAnyModalActive } from '@redux/modals.slice';

import s from './Layout.scss'; // eslint-disable-line postcss-modules/no-unused-class

const ModalDimmer = () => {
  const isAnyModalActive = useAppSelector(selectIsAnyModalActive);

  return (
    <Dimmer active={isAnyModalActive} id="rootDimmer" className={s.dimmer} />
  );
};

export default ModalDimmer;
