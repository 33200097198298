import {
  SET_GRAPHQL_SPEC_FETCH_PENDING,
  SET_GRAPHQL_SPEC_FETCH_SUCCESS,
  SET_GRAPHQL_SPEC_FETCH_ERROR,
} from '../actionTypes';

export const defaultState = {
  isFetchingGraphqlSpec: false,
  fetchGraphqlSpecError: false,
  errorMsg: false,
  spec: false,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    /**
     * *************************************************************************
     * GRAPHQL SPEC DATA REDUCERS
     */
    case SET_GRAPHQL_SPEC_FETCH_PENDING:
      return {
        ...state,
        isFetchingGraphqlSpec: true,
        fetchGraphqlSpecError: false,
        errorMsg: false,
      };
    case SET_GRAPHQL_SPEC_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingGraphqlSpec: false,
        fetchGraphqlSpecError: false,
        errorMsg: false,
        spec: action.resp.data.schema,
      };
    case SET_GRAPHQL_SPEC_FETCH_ERROR:
      return {
        ...state,
        isFetchingGraphqlSpec: false,
        fetchGraphqlSpecError: true,
        errorMsg: action.errorMsg,
        spec: false,
      };

    default:
      return state;
  }
};
