import type { Reducer, AnyAction } from 'redux';

import {
  SET_LOGIN_PENDING,
  SET_LOGIN_SUCCESS,
  SET_LOGIN_ERROR,
  SET_LOGOUT_PENDING,
  SET_LOGOUT_SUCCESS,
  SET_LOGOUT_ERROR,
} from '../actionTypes';

export interface AuthState {
  loginError: boolean;
  logoutError: boolean;
  isAuthenticated: boolean;
  isFetching: boolean;
  errorMsg: any;
  prevAuthState: boolean;
  engineVersion: string | false;
  dbVersion: string | false;
  uiVersion: string | false;
  permissions: Record<string, boolean> | false;
  account: any;
}

export const defaultState: Readonly<AuthState> = Object.freeze({
  loginError: false,
  logoutError: false,
  isAuthenticated: false,
  isFetching: false,
  errorMsg: false,
  prevAuthState: false,
  engineVersion: false,
  dbVersion: false,
  uiVersion: false,
  permissions: false,
  account: false,
});

const reducer: Reducer<AuthState> = (
  state = defaultState,
  action: AnyAction,
) => {
  switch (action.type) {
    case SET_LOGIN_PENDING:
      return {
        ...state,
        isAuthenticated: false,
        isFetching: true,
      };
    case SET_LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isFetching: false,
        loginError: false,
        errorMsg: false,
        dbVersion: action.dbVersion,
        engineVersion: action.engineVersion,
        uiVersion: action.uiVersion,
        prevAuthState: state.isAuthenticated,
        permissions: action.permissions,
        account: action.account,
      };
    case SET_LOGIN_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        isFetching: false,
        loginError: true,
        errorMsg: action.errorMsg,
      };

    case SET_LOGOUT_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case SET_LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        isFetching: false,
        errorMsg: false,
        prevAuthState: state.isAuthenticated,
        engineVersion: false,
        dbVersion: false,
        uiVersion: false,
        permissions: false,
        account: false,
      };
    case SET_LOGOUT_ERROR:
      return {
        ...state,
        isFetching: false,
        logoutError: true,
        errorMsg: action.errorMsg,
      };

    default:
      return state;
  }
};

export default reducer;
