import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import merge from 'deepmerge';

import type {
  ListApiKeysActionParams,
  UserApiKey,
  DataEntity,
  UserGroup,
  GetAccountUserApiKeysResponse,
  UpdateApiKeysActionParams,
  PatchAccountUserApiKeysResponse,
  PostAccountUserApiKeysResponse,
  CreateApiKeysParams,
  DeepPartial,
  TableViewProps,
  DeleteUserGroupParams,
  DeleteUserGroupResponse,
  GetUserGroupsResponse,
  CreateUserGroupParams,
  PostUserGroupResponse,
  UpdateUserGroupParams,
  PutUserGroupResponse,
} from '@models';
import { initialTableViewProps, nullDataEntity } from '@models';

import {
  createEntityAction,
  createEntityReducers,
  getEntityActions,
} from './common';
import { hideModal, showModal } from './modals.slice';

// --- GENERIC ENTITY ACTIONS --------------------------------------------------

const entities = {
  // Get Account User API Keys
  listApiKeys: createEntityAction<
    ListApiKeysActionParams,
    GetAccountUserApiKeysResponse
  >({
    stateKey: 'apiKeys',
    servicePath: params =>
      `/service/accounts/${encodeURIComponent(
        params.account,
      )}/users/${encodeURIComponent(params.user)}/api-keys`,
  }),
  // Get User Groups
  fetchUserGroups: createEntityAction<void, GetUserGroupsResponse>({
    stateKey: 'userGroups',
    servicePath: () => '/service/user-groups',
  }),
};
export const { listApiKeys, fetchUserGroups } = getEntityActions(entities);

// --- API KEYS ----------------------------------------------------------------

export const createApiKey = createEntityAction<
  CreateApiKeysParams,
  PostAccountUserApiKeysResponse
>({
  verb: 'post',
  stateKey: 'apiKeys',
  servicePath: params =>
    `/service/accounts/${encodeURIComponent(
      params.account,
    )}/users/${encodeURIComponent(params.user)}/api-keys`,
  customRequest: (req, path, params) => req.post(path).send(params.body),
}).thunk;

export const updateApiKey = createEntityAction<
  UpdateApiKeysActionParams,
  PatchAccountUserApiKeysResponse
>({
  verb: 'patch',
  stateKey: 'apiKeys',
  servicePath: params =>
    `/service/accounts/${encodeURIComponent(
      params.account,
    )}/users/${encodeURIComponent(params.user)}/api-keys/${params.name}`,
  customRequest: (req, path, params) => req.patch(path).send(params.body),
}).thunk;

// --- USER GROUPS -------------------------------------------------------------

export const createUserGroup = createEntityAction<
  CreateUserGroupParams,
  PostUserGroupResponse
>({
  verb: 'post',
  stateKey: 'userGroups',
  servicePath: () => `/service/user-groups`,
  customRequest: (req, path, params) => req.post(path).send(params),
}).thunk;

export const updateUserGroup = createEntityAction<
  UpdateUserGroupParams,
  PutUserGroupResponse
>({
  verb: 'put',
  stateKey: 'userGroups',
  servicePath: ({ id }) => `/service/user-groups/${id}`,
  customRequest: (req, path, params) => req.put(path).send(params),
}).thunk;

export const deleteUserGroup = createEntityAction<
  DeleteUserGroupParams,
  DeleteUserGroupResponse
>({
  verb: 'delete',
  stateKey: 'userGroups',
  servicePath: ({ id }) => `/service/user-groups/${id}`,
}).thunk;

// --- ACCOUNT SLICE -----------------------------------------------------------

export interface AccountsState {
  // The API keys for the currently selected account and user
  apiKeys: DataEntity<UserApiKey>;
  userGroups: DataEntity<UserGroup>;
  viewProps: {
    apiKeysModal: TableViewProps;
    userGroups: TableViewProps;
  };
}
export const initialState: AccountsState = {
  apiKeys: nullDataEntity,
  userGroups: nullDataEntity,
  viewProps: {
    apiKeysModal: initialTableViewProps,
    userGroups: initialTableViewProps,
  },
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setAccountsViewProps: (
      state,
      action: PayloadAction<DeepPartial<AccountsState['viewProps']>>,
    ) => {
      state.viewProps = merge(
        state.viewProps,
        action.payload,
      ) as AccountsState['viewProps'];
    },
  },
  extraReducers: builder => {
    createEntityReducers(entities, builder);

    builder
      // --- Create New API Key ------------------------------------------------
      .addCase(createApiKey.pending, state => {
        state.apiKeys.isUpdating = true;
      })
      .addCase(createApiKey.fulfilled, (state, action) => {
        const { name } = action.payload.body.data;
        state.apiKeys.isUpdating = false;
        if (!state.apiKeys.data) state.apiKeys.data = {};
        state.apiKeys.data[name] = action.payload.body.data;
        delete state.apiKeys.error;
      })
      .addCase(createApiKey.rejected, (state, action) => {
        state.apiKeys.isUpdating = false;
        state.apiKeys.error = action.payload || action.error;
      })

      // --- Update API Key ----------------------------------------------------
      .addCase(updateApiKey.pending, state => {
        state.apiKeys.isUpdating = true;
      })
      .addCase(updateApiKey.fulfilled, (state, action) => {
        const { name } = action.meta.arg;
        state.apiKeys.isUpdating = false;
        state.apiKeys.data![name] = {
          ...state.apiKeys.data![name],
          ...action.payload.body.data,
        };
        delete state.apiKeys.error;
      })
      .addCase(updateApiKey.rejected, (state, action) => {
        state.apiKeys.isUpdating = false;
        state.apiKeys.error = action.payload || action.error;
      })

      // --- Create New User Group ---------------------------------------------
      .addCase(createUserGroup.pending, state => {
        state.userGroups.isUpdating = true;
      })
      .addCase(createUserGroup.fulfilled, (state, action) => {
        const { group_uuid: id } = action.payload.body.data;
        state.userGroups.isUpdating = false;
        if (!state.userGroups.data) state.userGroups.data = {};
        state.userGroups.data[id] = action.payload.body.data;
        delete state.userGroups.error;
      })
      .addCase(createUserGroup.rejected, (state, action) => {
        state.userGroups.isUpdating = false;
        state.userGroups.error = action.payload || action.error;
      })

      // --- Update User Group -------------------------------------------------
      .addCase(updateUserGroup.pending, state => {
        state.userGroups.isUpdating = true;
      })
      .addCase(updateUserGroup.fulfilled, (state, action) => {
        const { group_uuid: id } = action.payload.body.data;
        state.userGroups.isUpdating = false;
        if (!state.userGroups.data) state.userGroups.data = {};
        state.userGroups.data[id] = action.payload.body.data;
        delete state.userGroups.error;
      })
      .addCase(updateUserGroup.rejected, (state, action) => {
        state.userGroups.isUpdating = false;
        state.userGroups.error = action.payload || action.error;
      })

      // --- Delete User Group -------------------------------------------------
      .addCase(deleteUserGroup.pending, state => {
        state.userGroups.isUpdating = true;
      })
      .addCase(deleteUserGroup.fulfilled, (state, action) => {
        const { id } = action.meta.arg;
        state.userGroups.isUpdating = false;
        delete state.userGroups.data?.[id];
      })
      .addCase(deleteUserGroup.rejected, (state, action) => {
        state.userGroups.isUpdating = false;
        state.userGroups.error = action.payload || action.error;
      })

      // Show/Hide Modals
      .addCase(showModal, (state, action) => {
        // Open of ApiKeysModal, RevokeApiKeyModal or NewApiKeyModal
        if (
          action.payload.type === 'apiKeys' ||
          action.payload.type === 'revokeApiKey' ||
          action.payload.type === 'newApiKey'
        ) {
          delete state.apiKeys.error;
        }
        // Open of EditUserGroupModal or DeleteUserGroupModal
        if (
          action.payload.type === 'editUserGroup' ||
          action.payload.type === 'deleteUserGroup'
        ) {
          delete state.userGroups.error;
        }
      })
      .addCase(hideModal, (state, action) => {
        // Closing of NewApiKeyModal or RevokeApiKeyModal
        if (
          action.payload === 'newApiKey' ||
          action.payload === 'revokeApiKey'
        ) {
          delete state.apiKeys.error;
        }
        // Closing of EditUserGroupModal or DeleteUserGroupModal
        if (
          action.payload === 'editUserGroup' ||
          action.payload === 'deleteUserGroup'
        ) {
          delete state.userGroups.error;
        }
      });
  },
});

export const { setAccountsViewProps } = accountsSlice.actions;

export default accountsSlice.reducer;
