import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import {
  Dropdown,
  Input,
  Pagination,
  Popup,
  Button,
  Icon,
} from 'semantic-ui-react';

// eslint-disable-next-line postcss-modules/no-unused-class
import s from './TablePagination.scss';

class TablePagination extends PureComponent {
  constructor(props) {
    super(props);
    const { filterStr, page } = this.props;
    const safePage = this.getSafePage(page);
    if (safePage !== page) {
      this.props.onPageChange(safePage);
    }
    this.state = { filterStr, filterOpen: false };
    this.filterRef = createRef();
  }

  componentDidUpdate(prevProps) {
    const { page, pages } = this.props;
    const safePage = this.getSafePage(page);
    if (prevProps.pages !== pages && safePage !== page) {
      this.props.onPageChange(safePage);
    }
  }

  handleFilterChange = (e, { value }) => {
    const { filtered = [{ id: 'filter', value: '' }] } = this.props;
    if (filtered[0].value !== value) {
      this.props.onFilteredChange('filter', value);
    }
    this.setState({ filterStr: value });
  };

  handleFilterClose = () => {
    this.setState({ filterOpen: false });
  };

  // eslint-disable-next-line class-methods-use-this
  handleFilterFocus = e => {
    // Reset value to move cursor to end of input
    const tempVal = e.target.value;
    e.target.value = '';
    e.target.value = tempVal;
  };

  handleFilterOpen = () => {
    this.setState({ filterOpen: true });
  };

  handlePageSizeChange = (e, { value }) => {
    if (this.props.pageSize !== value) {
      this.props.onPageSizeChange(value);
    }
  };

  handlePaginationChange = (e, { activePage }) => {
    const newPage = this.getSafePage(activePage - 1);
    if (this.props.page !== newPage) {
      this.props.onPageChange(newPage);
    }
  };

  getSafePage = (page, pages) => {
    let newPage = page;
    const pagesCount = pages || this.props.pages;
    if (Number.isNaN(newPage)) {
      newPage = this.props.page;
    }
    return Math.min(Math.max(newPage, 0), pagesCount - 1);
  };

  render() {
    const {
      pages,
      pageSize,
      className,
      items,
      noFocus,
      pageProps,
      sizeOptions,
      noSizeOptions,
      noFilter,
      filterClass,
      filterContent,
      state,
    } = this.props;

    const page = this.getSafePage(this.props.page);

    const pageSizeOptions = sizeOptions || [
      { key: 1, text: '5', value: 5 },
      { key: 2, text: '10', value: 10 },
      { key: 3, text: '25', value: 25 },
      { key: 4, text: '50', value: 50 },
      { key: 5, text: '100', value: 100 },
    ];

    return (
      <div className={classNames(className, s.root)} style={this.props.style}>
        <div className={classNames(s.pageSize, noSizeOptions ? s.hide : '')}>
          Display&nbsp;
          <Dropdown
            placeholder={String(pageSize)}
            options={pageSizeOptions}
            value={pageSize}
            onChange={this.handlePageSizeChange}
            selection
            fluid
            style={{ width: 'unset', minWidth: '5rem' }}
          />
          &nbsp;
          <div style={{ width: '100%' }}>{items}</div>
        </div>
        <div
          className={classNames(s.filter, noFilter ? s.hide : '', filterClass)}
        >
          Filter:&nbsp;
          <Input
            value={this.state.filterStr}
            onChange={this.handleFilterChange}
            onFocus={this.handleFilterFocus}
            autoFocus={!noFocus}
            className={filterContent ? s.noRightBorder : ''}
            action
            icon
          >
            <input />
            {this.state.filterStr ? (
              <Icon
                name="times"
                link
                style={{
                  fontSize: '0.8rem',
                  ...(filterContent ? { right: '2.75rem' } : {}),
                }}
                onClick={() => this.handleFilterChange(null, { value: '' })} // Resets filter
              />
            ) : null}
            {filterContent ? (
              <Popup
                // Semantic doesn't forward the ref to the underlying element
                context={
                  this.filterRef &&
                  this.filterRef.current &&
                  this.filterRef.current.ref &&
                  this.filterRef.current.ref.current
                }
                // Necessary because scrolling in modals causes weird behavior
                hideOnScroll
                // Fully-controlled because sometimes hiding on scroll would not correctly bind the popup
                open={this.state.filterOpen}
                onOpen={this.handleFilterOpen}
                onClose={this.handleFilterClose}
                position="right center"
                // Ensures that on change of the sortedData, popup will recalculate position (necessary add for modals)
                popperDependencies={[state.sortedData]}
                // Update boundary to be the viewport which allows popups to overflow within a modal
                popperModifiers={[
                  {
                    name: 'preventOverflow',
                    options: {
                      boundary: 'viewport',
                    },
                  },
                ]}
                on="click"
                trigger={
                  <Button
                    ref={this.filterRef}
                    basic
                    icon="filter"
                    style={{ marginLeft: '-1px' }} // Ensures button is properly attached
                  />
                }
                content={filterContent}
              />
            ) : null}
          </Input>
        </div>
        <div className={s.pagination}>
          <Pagination
            activePage={page + 1}
            onPageChange={this.handlePaginationChange}
            totalPages={pages}
            firstItem={null}
            lastItem={null}
            pointing
            secondary
            {...pageProps}
          />
        </div>
      </div>
    );
  }
}

export const propTypes = {
  state: PropTypes.shape({
    sortedData: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  page: PropTypes.number,
  pageSize: PropTypes.number,
  pages: PropTypes.number.isRequired,
  className: PropTypes.string,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  onFilteredChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filtered: PropTypes.arrayOf(PropTypes.object).isRequired,
  style: PropTypes.shape({}),
  items: PropTypes.string,
  filterStr: PropTypes.string,
  noFocus: PropTypes.bool,
  pageProps: PropTypes.shape({}),
  sizeOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  noSizeOptions: PropTypes.bool,
  noFilter: PropTypes.bool,
  filterClass: PropTypes.string,
  filterContent: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
};

TablePagination.propTypes = propTypes;

TablePagination.defaultProps = {
  state: {},
  className: '',
  style: {},
  items: 'Items',
  page: 0,
  pageSize: 10,
  filterStr: '',
  noFocus: false,
  pageProps: {},
  sizeOptions: false,
  noSizeOptions: false,
  noFilter: false,
  filterClass: '',
  filterContent: false,
};

export default withStyles(s)(TablePagination);
