// Update the DB State using socket messages
export default (state, action, item) => {
  const { actionType, parentKey, allowlist } = item;
  if (actionType === action.type) {
    const parent = state[parentKey];
    const newObj = {};
    allowlist.forEach(child => {
      // Account for nested child inputs as well
      const keySegments = child.split('.');
      let parentRef = parent;
      let ref = newObj;
      for (let i = 0; i < keySegments.length; i += 1) {
        const segment = keySegments[i];
        const isLast = i === keySegments.length - 1;
        const valid = Object.prototype.hasOwnProperty.call(parentRef, segment);
        const hasProperty = Object.prototype.hasOwnProperty.call(ref, segment);
        // Only further extend newObj if property actually exists in state
        if (!valid) break;
        if (!hasProperty) ref[segment] = isLast ? parentRef[segment] : {};
        if (!isLast) {
          parentRef = parentRef[segment];
          ref = ref[segment];
        }
      }
    });

    window.sock.emit(`anchore/message`, {
      key: parentKey,
      type: 'state',
      sessionID: window.App.account.sessionID,
      data: newObj,
    });
  }
};
