import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Button, type ButtonProps } from 'semantic-ui-react';

import s from './CancelButton.scss';

export interface CancelButtonProps extends ButtonProps {
  dark?: boolean;
  className?: string;
}

/**
 * A reusable 'Cancel' button to serve as a common modal action to close it. Any
 * Semantic UI Button prop can be passed in. Often, the disabled and onClick props
 * are the only ones needed.
 * @param props.disabled Boolean on whether the button should be disabled
 * @param props.onClick Function to call on click of "Cancel" button
 * @example
 * // Intended usage
 * <CancelButton
 *   disabled={isUpdating}
 *   onClick={() => dispatch(hideModal('editTemplate'))}
 * />
 */
const CancelButton = ({
  dark = false,
  className = undefined,
  ...buttonProps
}: CancelButtonProps) => (
  <Button
    className={classNames(
      dark || window?.App?.darkReader?.isEnabled() ? s.dark : s.light,
      className,
    )}
    {...buttonProps}
  >
    Cancel
  </Button>
);

export default withStyles(s)(CancelButton);
