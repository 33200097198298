import type { PreloadedState } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';

import rootReducer from '@redux/reducers';

const buildStore = (devMode: boolean, preloadedState?: PreloadedState<any>) =>
  // Create the main Redux store, add the reducers and any required middleware
  configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: devMode,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ immutableCheck: false }).concat(
        // eslint-disable-next-line global-require,@typescript-eslint/no-var-requires
        devMode && process.env.BROWSER ? require('redux-logger').logger : [],
      ),
  });

export type RootState = ReturnType<typeof rootReducer>;
export type Store = ReturnType<typeof buildStore>;
export type AppDispatch = Store['dispatch'];

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default buildStore;
