import type { Reducer, AnyAction } from 'redux';

import { SET_TOUR_PROPS } from '../actionTypes';

export interface TourState {
  tourProps:
    | {
        '/*': {
          isDisabled: boolean;
          showIntro: boolean;
        };
      }
    | false;
}

export const defaultState: TourState = {
  tourProps: false,
};

const reducer: Reducer<TourState> = (
  state = defaultState,
  action: AnyAction,
) => {
  switch (action.type) {
    case SET_TOUR_PROPS:
      return {
        ...state,
        tourProps: action.props,
      };

    default:
      return state;
  }
};

export default reducer;
