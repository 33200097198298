import _merge from 'lodash/merge';

import { SET_TOPNAV_VIEW_PROPERTIES } from '../actionTypes';

// *** TOPNAV DATA, VIEW STATES ******************************************** //
// eslint-disable-next-line import/prefer-default-export
export const setTopnavViewProps = (view, props) => (dispatch, getState) => {
  const currState = getState();
  if (currState.app.topnav.viewProps[view]) {
    dispatch({
      type: SET_TOPNAV_VIEW_PROPERTIES,
      viewProps: {
        ...currState.app.topnav.viewProps,
        [view]: _merge(currState.app.topnav.viewProps[view] ?? {}, props),
      },
    });
  }
};
