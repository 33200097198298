import type { Reducer, AnyAction } from 'redux';

import {
  SET_SUBSCRIPTION_ADD_PENDING,
  SET_SUBSCRIPTION_ADD_SUCCESS,
  SET_SUBSCRIPTION_ADD_ERROR,
  SET_SUBSCRIPTION_UPDATE_PENDING,
  SET_SUBSCRIPTION_UPDATE_SUCCESS,
  SET_SUBSCRIPTION_UPDATE_ERROR,
  SET_SUBSCRIPTIONS_FETCH_PENDING,
  SET_SUBSCRIPTIONS_FETCH_SUCCESS,
  SET_SUBSCRIPTIONS_FETCH_ERROR,
} from '../actionTypes';

export interface SubscriptionState {
  isAddingSubscription: boolean;
  addError: boolean;
  errorMsg: any;
  data: any;
  isUpdatingSubscription: boolean;
  updateError: boolean;
  isFetchingSubscriptions: boolean;
  fetchError: boolean;
}

export const defaultState: Readonly<SubscriptionState> = Object.freeze({
  isAddingSubscription: false,
  addError: false,
  isUpdatingSubscription: false,
  updateError: false,
  isFetchingSubscriptions: false,
  fetchError: false,
  errorMsg: false,
  data: false,
});

const reducer: Reducer<SubscriptionState> = (
  state = defaultState,
  action: AnyAction,
) => {
  switch (action.type) {
    /**
     * *************************************************************************
     * SUBSCRIPTION DATA REDUCERS
     */
    case SET_SUBSCRIPTION_ADD_PENDING:
      return {
        ...state,
        isAddingSubscription: true,
        addError: false,
        errorMsg: false,
      };
    case SET_SUBSCRIPTION_ADD_SUCCESS:
      return {
        ...state,
        isAddingSubscription: false,
        addError: false,
        errorMsg: false,
        data: action.resp.data,
      };
    case SET_SUBSCRIPTION_ADD_ERROR:
      return {
        ...state,
        isAddingSubscription: false,
        addError: true,
        errorMsg: action.errorMsg,
        data: false,
      };

    case SET_SUBSCRIPTION_UPDATE_PENDING:
      return {
        ...state,
        isUpdatingSubscription: true,
        updateError: false,
        errorMsg: false,
      };
    case SET_SUBSCRIPTION_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdatingSubscription: false,
        updateError: false,
        errorMsg: false,
        data: action.resp.data,
      };
    case SET_SUBSCRIPTION_UPDATE_ERROR:
      return {
        ...state,
        isUpdatingSubscription: false,
        updateError: true,
        errorMsg: action.errorMsg,
        data: false,
      };

    case SET_SUBSCRIPTIONS_FETCH_PENDING:
      return {
        ...state,
        isFetchingSubscriptions: true,
        fetchError: false,
        errorMsg: false,
      };
    case SET_SUBSCRIPTIONS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingSubscriptions: false,
        fetchError: false,
        errorMsg: false,
        data: action.resp.data[0] || {},
      };
    case SET_SUBSCRIPTIONS_FETCH_ERROR:
      return {
        ...state,
        isFetchingSubscriptions: false,
        fetchError: true,
        errorMsg: action.errorMsg,
        data: false,
      };

    default:
      return state;
  }
};

export default reducer;
