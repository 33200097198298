import {
  SET_ACTION_PLAN_SUBMISSION_ADD_ERROR,
  SET_ACTION_PLAN_SUBMISSION_ADD_PENDING,
  SET_ACTION_PLAN_SUBMISSION_ADD_SUCCESS,
  SET_ACTION_WORKBENCH_HISTORIC_ITEMS_FETCH_ERROR,
  SET_ACTION_WORKBENCH_HISTORIC_ITEMS_FETCH_PENDING,
  SET_ACTION_WORKBENCH_HISTORIC_ITEMS_FETCH_SUCCESS,
  SET_ACTION_WORKBENCH_HISTORIC_ITEMS_RESET,
  SET_ACTION_WORKBENCH_PLAN_ITEMS_FETCH_ERROR,
  SET_ACTION_WORKBENCH_PLAN_ITEMS_FETCH_PENDING,
  SET_ACTION_WORKBENCH_PLAN_ITEMS_FETCH_SUCCESS,
  SET_ACTION_WORKBENCH_PLAN_ITEMS_UPDATE_ERROR,
  SET_ACTION_WORKBENCH_PLAN_ITEMS_UPDATE_PENDING,
  SET_ACTION_WORKBENCH_PLAN_ITEMS_UPDATE_SUCCESS,
  SET_ACTION_WORKBENCH_SELECTED_ENDPOINT_DROPDOWN,
  SET_ACTION_WORKBENCH_SELECTED_ENDPOINT_IDX,
  SET_ACTION_WORKBENCH_SUBMIT_DIALOG,
  SET_ALLOWLIST_ITEM_EDIT_MODAL,
  SET_ALLOWLIST_ITEM_EDIT_SAVE_ERROR,
  SET_ARTIFACT_ANALYSIS_ACTIVE_TAB,
  SET_ARTIFACT_ANALYSIS_SERVICES_RESPONDED,
  SET_ARTIFACT_ANALYSIS_VIEW_PROPERTIES,
  SET_ARTIFACT_CHANGELOG_ACTIVE_TAB,
  SET_ARTIFACT_CHANGELOG_ERROR,
  SET_ARTIFACT_CHANGELOG_PENDING,
  SET_ARTIFACT_CHANGELOG_SUCCESS,
  SET_ARTIFACT_COMPLIANCE_ERROR,
  SET_ARTIFACT_COMPLIANCE_PENDING,
  SET_ARTIFACT_COMPLIANCE_SUCCESS,
  SET_ARTIFACT_CONTENTS_ACTIVE_TAB,
  SET_ARTIFACT_CONTENTS_ERROR,
  SET_ARTIFACT_CONTENTS_PENDING,
  SET_ARTIFACT_CONTENTS_SUCCESS,
  SET_ARTIFACT_FETCH_ERROR,
  SET_ARTIFACT_FETCH_PENDING,
  SET_ARTIFACT_FETCH_SUCCESS,
  SET_ARTIFACT_REPORT_FORMAT_ERROR,
  SET_ARTIFACT_REPORT_FORMAT_PENDING,
  SET_ARTIFACT_REPORT_FORMAT_SUCCESS,
  SET_ARTIFACT_VULNERABILITIES_ERROR,
  SET_ARTIFACT_VULNERABILITIES_PENDING,
  SET_ARTIFACT_VULNERABILITIES_SUCCESS,
  SET_COMPLIANCE_ALERT_FETCH_ERROR,
  SET_COMPLIANCE_ALERT_FETCH_PENDING,
  SET_COMPLIANCE_ALERT_FETCH_SUCCESS,
  SET_IMAGE_ADD_SUCCESS,
  SET_IMAGE_BUILD_SUMMARY_ACTIVE_TAB,
  SET_IMAGE_BUILD_SUMMARY_ERROR,
  SET_IMAGE_BUILD_SUMMARY_PENDING,
  SET_IMAGE_BUILD_SUMMARY_SUCCESS,
} from '../actionTypes';

export const defaultState = {
  // General Props
  errorMsg: false,
  activeTabIndex: 0,
  artifactAnalysisServicesResponded: false,
  viewProps: {
    overview: {
      filterStr: '',
      page: 0,
      pageSize: 10,
      hideSev: {
        Critical: false,
        High: false,
        Medium: false,
        Low: false,
        Negligible: false,
        Unknown: false,
      },
      hideActions: {
        Stop: false,
        Warn: false,
        Go: false,
      },
    },
    compliance: {
      evalTable: {
        filterStr: '',
        page: 0,
        pageSize: 10,
        filterOutWl: true,
        filterActions: {
          stop: true,
          warn: true,
          go: true,
        },
      },
      probTable: {
        filterStr: '',
        page: 0,
        pageSize: 10,
      },
    },
    contents: {
      filterStr: '',
      page: 0,
      pageSize: 10,
    },
    vulnerabilities: {
      filterStr: '',
      page: 0,
      pageSize: 10,
      filterSev: {
        Critical: true,
        High: true,
        Medium: true,
        Low: true,
        Negligible: true,
        Unknown: true,
      },
      cvesNonOS: false,
      cvesWithFixes: false,
      cvesInherited: false,
      cvesVendorOnly: true,
    },
    changelog: {
      filterStr: '',
      page: 0,
      pageSize: 10,
      filterStatus: {
        added: true,
        removed: true,
        changed: true,
      },
    },
    actionWorkbench: {
      filterStr: '',
      page: 0,
      pageSize: 5,
    },
  },

  // Artifact data structures
  isFetchingArtifact: false,
  fetchArtifactError: false,
  data: false,

  // Policy evaluation data structures
  isFetchingArtifactCompliance: false,
  fetchArtifactComplianceError: false,
  check: false,

  isAllowlistItemEditModalOpen: false,
  saveAllowlistItemEditError: false,

  // Artifact vulnerabilities data structures
  isFetchingArtifactVulnerabilities: false,
  fetchArtifactVulnerabilitiesError: false,
  vulnerabilities: false,

  // Artifact changelog data structures
  activeChangelogTabIndex: 0,
  isFetchingArtifactChangelog: false,
  fetchArtifactChangelogError: false,
  changelog: false,

  // Artifact contents data structures
  activeContentsTabIndex: 0,
  artifactContents: {},

  // Image build summary data structures
  activeBuildSummaryTabIndex: 0,
  isFetchingImageBuildSummary: false,
  fetchImageBuildSummaryError: false,
  buildSummary: false,

  // Artifact Report structures
  isFormattingArtifactReport: false,
  formatArtifactReportError: false,

  // Action Workbench, Endpoint List
  isSelectedEndpointDropdownOpen: false,
  endpointIdx: -1,

  // Action Workbench, view structures
  isActionWorkbenchSubmitActionModalOpen: false,

  // Action Workbench, data structures
  isFetchingActionWorkbenchPlanItems: false,
  fetchActionWorkbenchPlanItemsError: false,
  isUpdatingActionWorkbenchPlanItems: false,
  updateActionWorkbenchPlanItemsError: false,
  isFetchingActionWorkbenchHistoricItems: false,
  fetchActionWorkbenchHistoricItemsError: false,
  actionWorkbenchPlanItems: [],
  actionWorkbenchHistoricItems: false,

  // Action Plan, data structures
  isAddingActionPlan: false,
  addActionPlanError: false,

  // Compliance Alert, data structures
  isFetchingComplianceAlert: false,
  fetchComplianceAlertError: false,
  complianceAlert: false,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    /**
     * *************************************************************************
     * IMAGE VIEW STATE REDUCERS
     */

    case SET_ARTIFACT_ANALYSIS_SERVICES_RESPONDED:
      return {
        ...state,
        artifactAnalysisServicesResponded: action.responded,
      };
    case SET_ARTIFACT_ANALYSIS_VIEW_PROPERTIES:
      return {
        ...state,
        viewProps: action.viewProps,
      };
    case SET_ARTIFACT_ANALYSIS_ACTIVE_TAB:
      return {
        ...state,
        activeTabIndex: action.idx,
        errorMsg: false,
      };

    /**
     * *************************************************************************
     * ARTIFACT DATA REDUCERS
     */
    case SET_ARTIFACT_FETCH_PENDING:
      return {
        ...state,
        isFetchingArtifact: true,
      };
    case SET_ARTIFACT_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingArtifact: false,
        fetchArtifactError: false,
        errorMsg: false,
        data: action.resp.data,
      };
    case SET_ARTIFACT_FETCH_ERROR:
      return {
        ...state,
        isFetchingArtifact: false,
        fetchArtifactError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * ARTIFACT COMPLIANCE EVALUATION DATA REDUCERS
     */
    case SET_ARTIFACT_COMPLIANCE_PENDING:
      return {
        ...state,
        isFetchingArtifactCompliance: true,
        check: false,
      };
    case SET_ARTIFACT_COMPLIANCE_SUCCESS:
      return {
        ...state,
        isFetchingArtifactCompliance: false,
        fetchArtifactComplianceError: false,
        errorMsg: false,
        check: action.resp.data,
      };
    case SET_ARTIFACT_COMPLIANCE_ERROR:
      return {
        ...state,
        isFetchingArtifactCompliance: false,
        fetchArtifactComplianceError: true,
        errorMsg: action.errorMsg,
        check: false,
      };

    case SET_ALLOWLIST_ITEM_EDIT_MODAL:
      return {
        ...state,
        isAllowlistItemEditModalOpen: action.open,
        errorMsg: false,
      };

    case SET_ALLOWLIST_ITEM_EDIT_SAVE_ERROR:
      return {
        ...state,
        saveAllowlistItemEditError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * ARTIFACT VULNERABILITIES DATA REDUCERS
     */
    case SET_ARTIFACT_VULNERABILITIES_PENDING:
      return {
        ...state,
        isFetchingArtifactVulnerabilities: true,
        vulnerabilities: false,
      };
    case SET_ARTIFACT_VULNERABILITIES_SUCCESS:
      return {
        ...state,
        isFetchingArtifactVulnerabilities: false,
        fetchArtifactVulnerabilitiesError: false,
        errorMsg: false,
        vulnerabilities: action.resp.data,
      };
    case SET_ARTIFACT_VULNERABILITIES_ERROR:
      return {
        ...state,
        isFetchingArtifactVulnerabilities: false,
        fetchArtifactVulnerabilitiesError: true,
        errorMsg: action.errorMsg,
        vulnerabilities: false,
      };

    /**
     * *************************************************************************
     * ARTIFACT CHANGELOG DATA REDUCERS
     */
    case SET_ARTIFACT_CHANGELOG_ACTIVE_TAB:
      return {
        ...state,
        activeChangelogTabIndex: action.idx,
        errorMsg: false,
      };

    case SET_ARTIFACT_CHANGELOG_PENDING:
      return {
        ...state,
        isFetchingArtifactChangelog: true,
        errorMsg: false,
      };
    case SET_ARTIFACT_CHANGELOG_SUCCESS:
      return {
        ...state,
        isFetchingArtifactChangelog: false,
        fetchArtifactChangelogError: false,
        errorMsg: false,
        changelog: action.resp.data,
      };
    case SET_ARTIFACT_CHANGELOG_ERROR:
      return {
        ...state,
        isFetchingArtifactChangelog: false,
        fetchArtifactChangelogError: true,
        errorMsg: action.errorMsg,
        changelog: false,
      };

    /**
     * *************************************************************************
     * ARTIFACT CONTENTS DATA REDUCERS
     */
    case SET_ARTIFACT_CONTENTS_ACTIVE_TAB:
      return {
        ...state,
        activeContentsTabIndex: action.idx,
        errorMsg: false,
      };

    case SET_ARTIFACT_CONTENTS_PENDING:
      return {
        ...state,
        artifactContents: {
          ...state.artifactContents,
          [action.contentType]: {
            fetching: true,
          },
        },
      };
    case SET_ARTIFACT_CONTENTS_SUCCESS:
      return {
        ...state,
        artifactContents: {
          ...state.artifactContents,
          [action.contentType]: {
            fetching: false,
            errorMsg: false,
            error: false,
            contents: action.resp.data,
          },
        },
      };
    case SET_ARTIFACT_CONTENTS_ERROR:
      return {
        ...state,
        artifactContents: {
          ...state.artifactContents,
          [action.contentType]: {
            fetching: false,
            errorMsg: action.errorMsg || {},
            error: true,
            contents: false,
          },
        },
      };

    /**
     * *************************************************************************
     * ARTIFACT REPORT REDUCERS
     */
    case SET_ARTIFACT_REPORT_FORMAT_PENDING:
      return {
        ...state,
        isFormattingArtifactReport: true,
        formatArtifactReportError: false,
      };
    case SET_ARTIFACT_REPORT_FORMAT_SUCCESS:
      return {
        ...state,
        isFormattingArtifactReport: false,
        formatArtifactReportError: false,
        errorMsg: false,
      };
    case SET_ARTIFACT_REPORT_FORMAT_ERROR:
      return {
        ...state,
        isFormattingArtifactReport: false,
        formatArtifactReportError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * IMAGE BUILD SUMMARY DATA REDUCERS
     */
    case SET_IMAGE_BUILD_SUMMARY_ACTIVE_TAB:
      return {
        ...state,
        activeBuildSummaryTabIndex: action.idx,
        errorMsg: false,
      };

    case SET_IMAGE_BUILD_SUMMARY_PENDING:
      return {
        ...state,
        fetchImageBuildSummaryError: false,
        isFetchingImageBuildSummary: true,
        errorMsg: false,
      };
    case SET_IMAGE_BUILD_SUMMARY_SUCCESS:
      return {
        ...state,
        isFetchingImageBuildSummary: false,
        fetchImageBuildSummaryError: false,
        errorMsg: false,
        buildSummary: action.resp.data,
      };
    case SET_IMAGE_BUILD_SUMMARY_ERROR:
      return {
        ...state,
        isFetchingImageBuildSummary: false,
        fetchImageBuildSummaryError: true,
        errorMsg: action.errorMsg,
        buildSummary: false,
      };

    /**
     * *************************************************************************
     * ACTION WORKBENCH REDUCERS
     */
    case SET_ACTION_WORKBENCH_SELECTED_ENDPOINT_DROPDOWN:
      return {
        ...state,
        isSelectedEndpointDropdownOpen: action.open,
        endpointIdx: -1,
      };

    case SET_ACTION_WORKBENCH_SELECTED_ENDPOINT_IDX:
      return {
        ...state,
        endpointIdx: action.idx,
      };

    case SET_ACTION_WORKBENCH_SUBMIT_DIALOG:
      return {
        ...state,
        isActionWorkbenchSubmitActionModalOpen: action.open,
        errorMsg: false,
      };

    case SET_ACTION_WORKBENCH_PLAN_ITEMS_FETCH_PENDING:
      return {
        ...state,
        isFetchingActionWorkbenchPlanItems: true,
        fetchActionWorkbenchPlanItemsError: false,
        errorMsg: false,
      };
    case SET_ACTION_WORKBENCH_PLAN_ITEMS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingActionWorkbenchPlanItems: false,
        fetchActionWorkbenchPlanItemsError: false,
        actionWorkbenchPlanItems: action.resp.data ?? [],
        errorMsg: false,
      };
    case SET_ACTION_WORKBENCH_PLAN_ITEMS_FETCH_ERROR:
      return {
        ...state,
        isFetchingActionWorkbenchPlanItems: false,
        fetchActionWorkbenchPlanItemsError: true,
        errorMsg: action.errorMsg,
      };

    case SET_ACTION_WORKBENCH_PLAN_ITEMS_UPDATE_PENDING:
      return {
        ...state,
        isUpdatingActionWorkbenchPlanItems: true,
        updateActionWorkbenchPlanItemsError: false,
        errorMsg: false,
      };
    case SET_ACTION_WORKBENCH_PLAN_ITEMS_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdatingActionWorkbenchPlanItems: false,
        updateActionWorkbenchPlanItemsError: false,
        actionWorkbenchPlanItems: action.resp.data ?? [],
        errorMsg: false,
      };
    case SET_ACTION_WORKBENCH_PLAN_ITEMS_UPDATE_ERROR:
      return {
        ...state,
        isUpdatingActionWorkbenchPlanItems: false,
        updateActionWorkbenchPlanItemsError: true,
        errorMsg: action.errorMsg,
      };

    case SET_ACTION_WORKBENCH_HISTORIC_ITEMS_FETCH_PENDING:
      return {
        ...state,
        isFetchingActionWorkbenchHistoricItems: true,
        fetchActionWorkbenchHistoricItemsError: false,
        errorMsg: false,
      };
    case SET_ACTION_WORKBENCH_HISTORIC_ITEMS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingActionWorkbenchHistoricItems: false,
        fetchActionWorkbenchHistoricItemsError: false,
        errorMsg: false,
        actionWorkbenchHistoricItems: action.resp.data ?? [],
      };
    case SET_ACTION_WORKBENCH_HISTORIC_ITEMS_FETCH_ERROR:
      return {
        ...state,
        isFetchingActionWorkbenchHistoricItems: false,
        fetchActionWorkbenchHistoricItemsError: true,
        errorMsg: action.errorMsg,
      };

    case SET_ACTION_WORKBENCH_HISTORIC_ITEMS_RESET:
      return {
        ...state,
        isFetchingActionWorkbenchHistoricItems: false,
        fetchActionWorkbenchHistoricItemsError: false,
        errorMsg: false,
        actionWorkbenchHistoricItems: null,
      };

    /**
     * *************************************************************************
     * ACTION PLAN REDUCERS
     */
    case SET_ACTION_PLAN_SUBMISSION_ADD_PENDING:
      return {
        ...state,
        addActionPlanError: false,
        isAddingActionPlan: true,
        errorMsg: false,
      };
    case SET_ACTION_PLAN_SUBMISSION_ADD_SUCCESS:
      return {
        ...state,
        isAddingActionPlan: false,
        addActionPlanError: false,
        errorMsg: false,
        isActionWorkbenchSubmitActionModalOpen: false,
        actionWorkbenchPlanItems: action.resp.data,
        actionWorkbenchHistoricItems: false,
      };
    case SET_ACTION_PLAN_SUBMISSION_ADD_ERROR:
      return {
        ...state,
        isAddingActionPlan: false,
        addActionPlanError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * COMPLIANCE ALERT REDUCERS
     */
    case SET_COMPLIANCE_ALERT_FETCH_PENDING:
      return {
        ...state,
        fetchComplianceAlertError: false,
        isFetchingComplianceAlert: true,
        errorMsg: false,
      };
    case SET_COMPLIANCE_ALERT_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingComplianceAlert: false,
        fetchComplianceAlertError: false,
        errorMsg: false,
        complianceAlert: action.resp.data,
      };
    case SET_COMPLIANCE_ALERT_FETCH_ERROR:
      return {
        ...state,
        isFetchingComplianceAlert: false,
        fetchComplianceAlertError: true,
        errorMsg: action.errorMsg,
      };

    // After adding annotations, include them onto current artifact
    case SET_IMAGE_ADD_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          annotations: action.resp.data.annotations,
        },
      };

    default:
      return state;
  }
};
