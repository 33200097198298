import type { Reducer, AnyAction } from 'redux';

import {
  SET_WHATS_NEW_DIALOG,
  SET_WHATS_NEW_FEATURE_IDX,
  SET_WHATS_NEW_PREV_IDX,
  SET_WHATS_NEW_SHOW_CTRL,
} from '../actionTypes';

export interface WhatsNewState {
  featureIdx: number;
  prevIdx: number;
  isWhatsNewModalOpen: boolean;
  isWhatsNewCtrlShown: boolean;
}

export const defaultState: WhatsNewState = {
  featureIdx: -1,
  prevIdx: -1,
  isWhatsNewModalOpen: false,
  isWhatsNewCtrlShown: false,
};

const reducer: Reducer<WhatsNewState> = (
  state = defaultState,
  action: AnyAction,
) => {
  switch (action.type) {
    case SET_WHATS_NEW_FEATURE_IDX:
      return {
        ...state,
        featureIdx: action.idx,
      };

    case SET_WHATS_NEW_PREV_IDX:
      return {
        ...state,
        prevIdx: action.idx,
        featureIdx: -1,
      };

    case SET_WHATS_NEW_DIALOG:
      return {
        ...state,
        isWhatsNewModalOpen: action.open,
        featureIdx: -1,
        prevIdx: -1,
      };

    case SET_WHATS_NEW_SHOW_CTRL:
      return {
        ...state,
        isWhatsNewCtrlShown: action.show,
      };

    default:
      return state;
  }
};

export default reducer;
