import type { Reducer, AnyAction } from 'redux';

import { SET_PRINT_DIALOG, SET_PRINT_VIEW_PENDING } from '../actionTypes';

export interface PrintState {
  isPrintDialogOpen: boolean;
  isPrintViewPending: boolean;
}

export const defaultState: PrintState = {
  isPrintDialogOpen: false,
  isPrintViewPending: false,
};

const reducer: Reducer<PrintState> = (
  state = defaultState,
  action: AnyAction,
) => {
  switch (action.type) {
    case SET_PRINT_DIALOG:
      return {
        ...state,
        isPrintDialogOpen: action.open,
      };

    case SET_PRINT_VIEW_PENDING:
      return {
        ...state,
        isPrintViewPending: action.pending,
      };

    default:
      return state;
  }
};

export default reducer;
