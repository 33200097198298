import classNames from 'classnames';
import Clipboard from 'clipboard';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Button, Label, Icon, Popup } from 'semantic-ui-react';

import raiseToast from '@shared/components/Toast';

import s from './CopyButton.scss';

const CopyButton = props => {
  const { content, message, corner, icon, className, style, popupProps } =
    props;
  // Set message that will be raised on success
  let msgEl = message;
  if (typeof message === 'string') {
    // Account if 'message' was unset
    const msgString = message.length
      ? message
      : 'This item has been successfully copied to your clipboard.';
    msgEl = <span>{msgString}</span>;
  }

  // Use clipboard and on unmount, clean it up
  useEffect(() => {
    const clipboard = new Clipboard(`.${s.copy}`);
    return () => clipboard.destroy();
  }, []);

  return icon ? (
    <Popup
      position="top center"
      trigger={
        <Icon
          link
          color="teal"
          name="copy"
          className={classNames(s.copy, s.root, s.icon, className)}
          data-clipboard-text={content}
          style={style}
          onClick={() =>
            raiseToast({
              message: msgEl,
              level: 'info',
              autoClose: 8000,
              icon: 'copy',
              dismissAll: true,
            })
          }
        />
      }
      content="Click to copy to clipboard"
      {...popupProps}
    />
  ) : (
    <Button
      as={Label}
      onClick={() => {
        raiseToast({
          message: msgEl,
          level: 'info',
          autoClose: 8000,
          icon: 'copy',
          dismissAll: true,
        });
      }}
      basic
      className={classNames(
        s.copy,
        s.root,
        corner ? `${s.corner} darkCornerFix` : '',
        className,
      )}
      data-clipboard-text={content}
      style={{ cursor: 'pointer', ...style }}
    >
      <span>
        <Icon color="teal" name="copy" />
        Copy
      </span>
    </Button>
  );
};

CopyButton.defaultProps = {
  message: '',
  corner: false,
  icon: false,
  className: '',
  style: {},
  popupProps: {},
};

export const propTypes = {
  content: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  corner: PropTypes.bool,
  icon: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  popupProps: PropTypes.shape({}),
};

CopyButton.propTypes = propTypes;

export default withStyles(s)(CopyButton);
