import type { InferProps } from 'prop-types';
import type { FunctionComponent } from 'react';
import { connect } from 'react-redux';

import { setTourProps } from '@redux/actions/action-tour';
import {
  setWhatsNewDialog,
  setWhatsNewFeatureIndex,
  setWhatsNewPrevIndex,
  setWhatsNewShowCtrl,
} from '@redux/actions/action-whatsnew';
import type { RootState } from '@redux/store';

import WhatsNew, { type propTypes } from './WhatsNew';

export default connect(
  (state: RootState) => ({ auth: state.auth, app: state.app }),
  {
    setWhatsNewDialog,
    setWhatsNewFeatureIndex,
    setWhatsNewPrevIndex,
    setWhatsNewShowCtrl,
    setTourProps,
  },
)(WhatsNew as FunctionComponent<InferProps<typeof propTypes>>);
