// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./Roboto-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./Roboto-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./Roboto-Italic.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./Roboto-BoldItalic.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face{font-family:Roboto;font-style:normal;font-weight:400;src:local("Roboto-Regular"),url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("ttf")}@font-face{font-family:Roboto;font-style:normal;font-weight:700;src:local("Roboto-Bold"),url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("ttf")}@font-face{font-family:Roboto;font-style:italic;font-weight:400;src:local("Roboto-Italic"),url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("ttf")}@font-face{font-family:Roboto;font-style:italic;font-weight:700;src:local("Roboto-BoldItalic"),url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("ttf")}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
