import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import md from 'markdown-it';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import {
  Accordion,
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Label,
  Menu,
  Popup,
} from 'semantic-ui-react';

import history from '@/history';

import s from './WhatsNew.scss';

// Construct the Markdown renderer
const mdObj = md({ breaks: true });

// Main view component
const WhatsNew = props => {
  const {
    app,
    auth,
    data,
    setWhatsNewDialog,
    setWhatsNewFeatureIndex,
    setWhatsNewPrevIndex,
    setWhatsNewShowCtrl,
    setTourProps,
  } = props;

  const { whatsNew, tour } = app;
  const { isWhatsNewModalOpen, featureIdx, prevIdx, isWhatsNewCtrlShown } =
    whatsNew;

  let el = null;

  if (data && isWhatsNewCtrlShown) {
    const fn = (e, titleProps) => {
      const { index } = titleProps;
      const newIndex = featureIdx === index ? -1 : index;
      setWhatsNewFeatureIndex(newIndex);
    };

    let currData = data;

    if (prevIdx !== -1) {
      currData = data.previous[prevIdx];
    }

    el = (
      <div className={s.whatsNewWrapper}>
        <Container className={classNames(s.whatsNewContainer, s.first)}>
          <Header
            as="h4"
            style={{
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {prevIdx === -1 ? 'Welcome to ' : ''}Anchore Enterprise Client
              Version {currData.version || auth.uiVersion}
              {prevIdx !== -1 ? (
                <Label basic size="tiny" color="blue">
                  Previous Release
                </Label>
              ) : null}
            </span>
            <Button
              size="tiny"
              compact
              color="violet"
              className={s.whatsNewDismissBtn}
              onClick={() => {
                setWhatsNewShowCtrl();
              }}
            >
              <Icon name="thumbs up" />
              Got it!
            </Button>
          </Header>
        </Container>
        <Container className={s.whatsNewContainer}>
          <p>{currData.summary}</p>
        </Container>
        <Accordion className={s.whatsNewAccordion} as={Menu} fluid vertical>
          {currData.updates.map((item, idx) => (
            <Menu.Item
              key={item.feature}
              className={classNames(
                s.whatsNewAccordionItm,
                featureIdx === idx ? s.open : s.closed,
              )}
            >
              <Accordion.Title
                index={idx}
                active={featureIdx === idx}
                onClick={fn}
              >
                <Header as="h4" className={s.whatsNewAccordionItmHeader}>
                  <Icon
                    color="teal"
                    className={s.whatsNewAccordionItmHeaderIcon}
                    name={`chevron ${featureIdx === idx ? 'down' : 'right'}`}
                  />
                  {item.feature}
                </Header>
              </Accordion.Title>
              <Accordion.Content
                className={s.whatsNewAccordionItmContent}
                active={featureIdx === idx}
              >
                {item.updates.map((itm, idx2, list) => (
                  <Fragment key={itm.title}>
                    <Header
                      as="h5"
                      className={s.whatsNewAccordionItmContentHeader}
                    >
                      {itm.title}
                    </Header>
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: mdObj.render(itm.description),
                      }}
                      className={s.whatsNewAccordionItmContent}
                    />
                    {list.length - 1 !== idx2 || item.tour ? (
                      <Divider className={s.divider} fitted />
                    ) : null}
                  </Fragment>
                ))}
                {item.tour && tour.tourProps && tour.tourProps[item.tour] ? (
                  <Button
                    compact
                    basic
                    color="blue"
                    size="tiny"
                    className={s.whatsNewAccordionItmContentTourBtn}
                    onClick={() => {
                      setWhatsNewDialog(false);
                      tour.tourProps[item.tour].isDisabled = false;
                      tour.tourProps[item.tour].showIntro = true;
                      setTourProps(tour.tourProps);

                      if (item.tour !== document.location.pathname) {
                        history.replace(item.tour);
                      }
                    }}
                  >
                    Take the {item.feature} Tour
                  </Button>
                ) : null}
              </Accordion.Content>
            </Menu.Item>
          ))}
        </Accordion>
        {data.previous instanceof Array && data.previous.length ? (
          <Container className={s.whatsNewPrevContainer}>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column width={8}>
                  {data.previous[prevIdx + 1] ? (
                    <Button
                      compact
                      size="mini"
                      onClick={() => {
                        setWhatsNewPrevIndex(prevIdx + 1);
                      }}
                    >
                      <Icon name="angle double left" />
                      Version {data.previous[prevIdx + 1].version}
                    </Button>
                  ) : null}
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                  {data.previous[prevIdx - 1] ? (
                    <Button
                      compact
                      size="mini"
                      onClick={() => {
                        setWhatsNewPrevIndex(prevIdx - 1);
                      }}
                    >
                      Version {data.previous[prevIdx - 1].version}
                      <Icon name="angle double right" />
                    </Button>
                  ) : null}
                  {!prevIdx ? (
                    <Button
                      compact
                      floated="right"
                      size="mini"
                      onClick={() => {
                        setWhatsNewPrevIndex(-1);
                      }}
                    >
                      Version {data.version}
                      <Icon name="angle double right" />
                    </Button>
                  ) : prevIdx === -1 && tour.tourProps?.['/*'] ? (
                    <Button
                      compact
                      basic
                      color="blue"
                      size="tiny"
                      floated="right"
                      onClick={() => {
                        setWhatsNewDialog(false);
                        tour.tourProps['/*'].isDisabled = false;
                        tour.tourProps['/*'].showIntro = true;
                        setTourProps(tour.tourProps);
                      }}
                    >
                      Take the New Feature Tour
                    </Button>
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        ) : null}
      </div>
    );

    el = (
      <Menu.Item className={s.root}>
        <Popup
          className={classNames(
            s.whatsNewPopup,
            'animate__animated animate__fadeIn',
          )}
          basic
          on="click"
          open={isWhatsNewModalOpen}
          onOpen={() => {
            setWhatsNewDialog(true);
          }}
          onClose={() => {
            setWhatsNewDialog(false);
          }}
          content={el}
          position="bottom left"
          trigger={
            <div>
              <Button
                className={s.whatsNewBtn}
                color="green"
                size="mini"
                icon
                labelPosition="right"
              >
                <Icon name={isWhatsNewModalOpen ? 'caret up' : 'caret down'} />
                <span className={s.whatsNewBtnTxt}>
                  What&#39;s New in This Release?
                </span>
              </Button>
              <div className={s.happyDog} />
            </div>
          }
        />
      </Menu.Item>
    );
  }

  return el;
};

export const propTypes = {
  auth: PropTypes.shape({
    account: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
    uiVersion: PropTypes.string,
  }).isRequired,
};

WhatsNew.propTypes = propTypes;

export default withStyles(s)(WhatsNew);
