import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Segment, Grid, Label, Image, Icon, Button } from 'semantic-ui-react';

import Link from '@shared/components/Link';

import s from './Err.scss';

// Provide defaults based on type for consistency ('NotFound', 'InternalServer', etc.)
const getDefaults = type => {
  switch (type) {
    case 'Forbidden': {
      return {
        statusCode: 403,
        header: 'Forbidden',
        headerColor: 'red',
        image: 'spacecat_stop.svg',
        imageSize: 'medium',
        message:
          "We're really sorry but you don't have permission to access that resource.",
      };
    }
    case 'NotFound': {
      return {
        statusCode: 404,
        header: 'Not Found',
        headerColor: 'violet',
        image: 'robot_notfound.svg',
        imageSize: 'small',
        message: "We're really sorry but we could not locate that resource.",
      };
    }
    case 'InternalServer': {
      return {
        statusCode: 500,
        header: 'Service Error',
        headerColor: 'red',
        image: 'robot_dismantled.svg',
        imageSize: 'large',
        message:
          "We're really sorry but we weren't able to display this page because of a data retrieval error.",
      };
    }
    case 'GraphQL': {
      return {
        header: type,
        headerColor: 'teal',
        image: 'robot_nuke.svg',
        imageSize: 'medium',
        message:
          "We're really sorry but we encountered an error with the reporting service. Please try again...",
      };
    }
    default: {
      return {
        header: type,
        headerColor: 'teal',
        image: 'robot_dismantled.svg',
        imageSize: 'large',
        message:
          "We're really sorry but we weren't able to display this page because of a data retrieval error.",
      };
    }
  }
};

// Unify settings - props provided > error object message > defaults
const unifySettings = (defaults, errInfo, props) => {
  // Remove any null values from props
  const propsCopy = Object.entries(props)
    .filter(item => item[1] !== null)
    .reduce((obj, curr) => {
      const [key, value] = curr;
      return Object.assign(obj, { [key]: value });
    }, {});

  return { ...defaults, ...errInfo, ...propsCopy };
};

class Err extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { showDetail: false };
    return this;
  }

  /**
   * [handleKeyDown Can press enter when 'Show Details' is focused to toggle]
   * @return {[type]} [description]
   */
  handleKeyDown = e => {
    const isEnterKey = e.type === 'keydown' && e.keyCode === 13;
    if (isEnterKey) this.toggleDetails();
    return this;
  };

  /**
   * [toggleDetails description]
   * @return {[type]} [description]
   */
  toggleDetails = () => {
    this.setState(prevState => ({
      showDetail: !prevState.showDetail,
    }));
    return this;
  };

  /**
   * [render description]
   * @return {[type]} [description]
   */
  render() {
    const { error, errorType, onRetryButtonClick, isRetrying } = this.props;
    const err = error;
    const type = errorType || err?.type;
    const statusCode = err?.statusCode;
    let errMsg;
    let detail;

    // See if the error message provided is parseable
    try {
      const parsedErr = JSON.parse(err.message);
      detail = parsedErr.detail;
      errMsg = parsedErr.message;
    } catch (parseErr) {
      errMsg = err?.message || false;
    }

    const defaults = getDefaults(type);
    const settings = unifySettings(
      defaults,
      err ? { statusCode, message: errMsg } : {},
      // Prevents no-unused-props giving false positive
      // when passing whole props object to helper function
      {
        className: this.props.className,
        header: this.props.header,
        headerColor: this.props.headerColor,
        image: this.props.image,
        imageSize: this.props.imageSize,
        message: this.props.message,
        route: this.props.route,
        routeIcon: this.props.routeIcon,
        routeMsg: this.props.routeMsg,
        base: this.props.base,
        inline: this.props.inline,
        noBorder: this.props.noBorder,
      },
    );

    const {
      className,
      header,
      headerColor,
      image,
      imageSize,
      message,
      route,
      routeIcon,
      routeMsg,
      base,
      inline,
      noBorder,
      statusCode: code,
    } = settings;

    return (
      <Segment.Group
        className={classNames(
          !base ? s.noBoxShadow : '',
          noBorder ? s.noBorder : '',
        )}
      >
        <Segment
          style={{
            overflow: 'hidden',
          }}
          className={classNames(
            className,
            s.root,
            base ? s.base : s.noBoxShadow,
            inline ? s.inline : '',
          )}
        >
          <Grid
            verticalAlign="middle"
            centered
            columns={1}
            style={{ height: '100%', width: '100%' }}
          >
            <Grid.Row>
              <Grid.Column textAlign="center">
                <div style={{ margin: '0 0 2rem 0' }}>
                  <Label
                    size="huge"
                    color={headerColor}
                    pointing="below"
                    style={{ padding: '2rem', margin: '1rem 0 2rem 0' }}
                  >
                    <em>&lt;Beep Boop&gt;</em>
                    &nbsp;
                    {code && `${code}—`}
                    {header}
                  </Label>
                  <Image
                    size={imageSize || 'large'}
                    centered
                    src={`/img/${image}`}
                  />
                </div>
                <Segment.Group
                  className={s.noBoxShadow}
                  style={{
                    marginBottom: '1rem',
                  }}
                >
                  <Segment
                    size="large"
                    secondary
                    className={classNames(s.noBoxShadow, s.errorMsgSegment)}
                  >
                    {message}
                    {detail && Object.keys(detail).length !== 0 && (
                      <Button
                        floated="right"
                        color="blue"
                        disabled={this.state.showDetail}
                        className={s.detailLink}
                        onClick={this.toggleDetails}
                        onKeyDown={this.handleKeyDown}
                        tabIndex="0"
                      >
                        Show Details
                      </Button>
                    )}
                    {detail && this.state.showDetail && (
                      <Segment>
                        <pre className={s.jsonStrFmt}>
                          {JSON.stringify(detail, null, 2)}
                        </pre>
                      </Segment>
                    )}
                  </Segment>
                  {route && (
                    <Segment className={s.noBoxShadow}>
                      <Link to={route}>
                        {routeIcon && <Icon name={routeIcon} />}
                        {routeMsg}
                      </Link>
                    </Segment>
                  )}
                  {onRetryButtonClick && (
                    <Segment className={s.noBoxShadow}>
                      <Button
                        primary
                        compact
                        size="small"
                        onClick={onRetryButtonClick}
                        loading={isRetrying}
                        disabled={isRetrying}
                      >
                        <Icon name="redo" />
                        Retry
                      </Button>
                    </Segment>
                  )}
                </Segment.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Segment.Group>
    );
  }
}

export const propTypes = {
  className: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]), // Allows use of a string or jsx
  headerColor: PropTypes.string,
  image: PropTypes.string,
  imageSize: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]), // Allows use of a string or jsx
  route: PropTypes.string,
  routeIcon: PropTypes.string,
  routeMsg: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  errorType: PropTypes.string,
  base: PropTypes.bool,
  inline: PropTypes.bool,
  noBorder: PropTypes.bool,
  onRetryButtonClick: PropTypes.function,
  isRetrying: PropTypes.boolean,
};

Err.propTypes = propTypes;

Err.defaultProps = {
  className: '',
  header: null,
  headerColor: null,
  image: null,
  imageSize: null,
  message: null,
  route: null,
  routeIcon: null,
  routeMsg: null,
  error: null,
  errorType: null,
  base: false,
  inline: false,
  noBorder: false,
  onRetryButtonClick: null,
  isRetrying: false,
};

export default withStyles(s)(Err);
