import type { InferProps } from 'prop-types';
import type { ComponentClass } from 'react';
import { connect } from 'react-redux';

import { updatePassword } from '@redux/actions/action-system';
import type { RootState } from '@redux/store';

import ChangePassword, { type propTypes } from './ChangePassword';

export default connect((state: RootState) => ({ system: state.system }), {
  updatePassword,
})(ChangePassword as ComponentClass<InferProps<typeof propTypes>>);
