import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import merge from 'deepmerge';

import type {
  DataEntity,
  ListAlpActionParams,
  GetAlpListResponse,
  ArtifactLifecyclePolicy,
  PutAlpResponse,
  UpdateAlpActionParams,
  CreateAlpActionParams,
  GetAlpResponse,
  PostAlpResponse,
  GetAlpActionParams,
  DeleteAlpActionParams,
  DeleteAlpResponse,
  TableViewProps,
  DeepPartial,
} from '@models';
import { initialTableViewProps, nullDataEntity } from '@models';

import {
  createEntityAction,
  createEntityReducers,
  getEntityActions,
} from './common';
import { showModal } from './modals.slice';

const entities = {
  listArtifactLifecyclePolicies: createEntityAction<
    ListAlpActionParams,
    GetAlpListResponse
  >({
    stateKey: 'artifactLifecyclePolicies',
    servicePath: () => `/service/artifact-lifecycle-policies`,
  }),
};

export const { listArtifactLifecyclePolicies } = getEntityActions(entities);

export const updateArtifactLifecyclePolicy = createEntityAction<
  UpdateAlpActionParams,
  PutAlpResponse
>({
  verb: 'put',
  stateKey: 'artifactLifecyclePolicies',
  servicePath: params => `/service/artifact-lifecycle-policies/${params.uuid}`,
  toastOnFailure: ({ name }, error) => (
    <span>
      <p>
        An error occurred while attempting to update artifact lifecycle policy{' '}
        <strong>{name}</strong>.
      </p>
      {error?.message}
    </span>
  ),
  toastOnSuccess: ({ name }) => (
    <span>
      Artifact lifecycle policy <strong>{name}</strong> was successfully
      updated.
    </span>
  ),
  getToastId: ({ uuid }) => uuid!,
}).thunk;

export const deleteArtifactLifecyclePolicy = createEntityAction<
  DeleteAlpActionParams,
  DeleteAlpResponse
>({
  verb: 'delete',
  stateKey: 'artifactLifecyclePolicies',
  servicePath: params => `/service/artifact-lifecycle-policies/${params.uuid}`,
}).thunk;

export const createArtifactLifecyclePolicy = createEntityAction<
  CreateAlpActionParams,
  PostAlpResponse
>({
  verb: 'post',
  stateKey: 'artifactLifecyclePolicies',
  servicePath: () => `/service/artifact-lifecycle-policies`,
  toastOnFailure: ({ name }, error) => (
    <span>
      <p>
        An error occurred while attempting to create artifact lifecycle policy{' '}
        <strong>{name}</strong>.
      </p>
      {error?.message}
    </span>
  ),
  toastOnSuccess: ({ name }) => (
    <span>
      Artifact lifecycle policy <strong>{name}</strong> was successfully
      created.
    </span>
  ),
}).thunk;

export const getArtifactLifecyclePolicy = createEntityAction<
  GetAlpActionParams,
  GetAlpResponse
>({
  stateKey: 'artifactLifecyclePolicy',
  actionName: 'artifactLifecyclePolicy/get',
  servicePath: params => `/service/artifact-lifecycle-policies/${params.uuid}`,
}).thunk;

export interface AlpState {
  artifactLifecyclePolicies: DataEntity<ArtifactLifecyclePolicy>;
  artifactLifecyclePolicy?: ArtifactLifecyclePolicy;
  viewProps: {
    dataManagementView: TableViewProps;
  };
}

export const initialState: AlpState = {
  artifactLifecyclePolicies: nullDataEntity,
  viewProps: {
    dataManagementView: initialTableViewProps,
  },
};

const alpSlice = createSlice({
  name: 'alp',
  initialState,
  reducers: {
    setAlpViewProps: (
      state,
      action: PayloadAction<DeepPartial<AlpState['viewProps']>>,
    ) => {
      state.viewProps = merge(
        state.viewProps,
        action.payload,
      ) as AlpState['viewProps'];
    },
  },
  extraReducers: builder => {
    createEntityReducers(entities, builder);

    builder
      // Create Artifact Lifecycle Policy
      .addCase(createArtifactLifecyclePolicy.pending, state => {
        state.artifactLifecyclePolicies.isUpdating = true;
      })
      .addCase(createArtifactLifecyclePolicy.fulfilled, (state, action) => {
        const { uuid } = action.payload.body.data;
        state.artifactLifecyclePolicies.isUpdating = false;
        state.artifactLifecyclePolicies.data![uuid!] = action.payload.body.data;
      })
      .addCase(createArtifactLifecyclePolicy.rejected, (state, action) => {
        state.artifactLifecyclePolicies.isUpdating = false;
        state.artifactLifecyclePolicies.error = action.payload || action.error;
      })

      // Update Artifact Lifecycle Policy
      .addCase(updateArtifactLifecyclePolicy.pending, state => {
        state.artifactLifecyclePolicies.isUpdating = true;
      })
      .addCase(updateArtifactLifecyclePolicy.fulfilled, (state, action) => {
        const { uuid } = action.meta.arg;
        state.artifactLifecyclePolicies.isUpdating = false;
        state.artifactLifecyclePolicies.data![uuid!] = action.payload.body.data;
      })
      .addCase(updateArtifactLifecyclePolicy.rejected, (state, action) => {
        state.artifactLifecyclePolicies.isUpdating = false;
        state.artifactLifecyclePolicies.error = action.payload || action.error;
      })

      // Delete Artifact Lifecycle Policy
      .addCase(deleteArtifactLifecyclePolicy.pending, state => {
        state.artifactLifecyclePolicies.isUpdating = true;
      })
      .addCase(deleteArtifactLifecyclePolicy.fulfilled, (state, action) => {
        const { uuid } = action.meta.arg;
        state.artifactLifecyclePolicies.isUpdating = false;
        delete state.artifactLifecyclePolicies.error;
        delete state.artifactLifecyclePolicies.data?.[uuid!];
      })
      .addCase(deleteArtifactLifecyclePolicy.rejected, (state, action) => {
        state.artifactLifecyclePolicies.isUpdating = false;
        state.artifactLifecyclePolicies.error = action.payload || action.error;
      })

      // Retrieve a single Artifact Lifecycle Policy
      .addCase(getArtifactLifecyclePolicy.pending, state => {
        state.artifactLifecyclePolicies.isLoading = true;
      })
      .addCase(getArtifactLifecyclePolicy.fulfilled, (state, action) => {
        state.artifactLifecyclePolicies.isLoading = false;
        state.artifactLifecyclePolicy = action.payload.body.data;
      })
      .addCase(getArtifactLifecyclePolicy.rejected, (state, action) => {
        state.artifactLifecyclePolicies.isLoading = false;
        state.artifactLifecyclePolicies.error = action.payload || action.error;
      })

      .addCase(showModal, (state, action) => {
        // Open of EditAlpModal
        if (action.payload.type === 'editAlp') {
          delete state.artifactLifecyclePolicies.error;
        }
      });
  },
});

export const { setAlpViewProps } = alpSlice.actions;

export default alpSlice.reducer;
