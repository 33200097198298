import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { Pagination } from 'semantic-ui-react';

import s from './TableInfo.scss';

const getSafePage = (page, pages, currPage) => {
  const newPage = Number.isNaN(page) ? currPage : page;
  return Math.min(Math.max(newPage, 0), pages - 1);
};

const TableInfo = ({ state, item, items, tableObj, totalItems, ...props }) => {
  const { page, pages, pageSize, sortedData } = state;
  const total = typeof totalItems === 'number' ? totalItems : sortedData.length;
  const minNum = pageSize * page + 1;
  const maxNum = pageSize * (page + 1) > total ? total : pageSize * (page + 1);

  const handlePaginationChange = (e, { activePage }) => {
    const newPage = getSafePage(activePage - 1, pages, page);
    if (page !== newPage) {
      tableObj.onPageChange(newPage);
    }
  };

  return total > 0 ? (
    <div className={s.root} {...props}>
      <div>
        {total === 1
          ? `Showing 1 ${item}`
          : `Showing ${minNum} to ${maxNum} of ${total} ${items || `${item}s`}`}
      </div>
      <div>
        <Pagination
          className="noprint"
          activePage={page + 1}
          onPageChange={handlePaginationChange}
          totalPages={pages}
          firstItem={null}
          lastItem={null}
          pointing
          secondary
        />
      </div>
    </div>
  ) : null;
};

TableInfo.defaultProps = { item: 'Item', items: '', totalItems: undefined };

export const propTypes = {
  tableObj: PropTypes.shape({
    onPageChange: PropTypes.func,
  }).isRequired,
  state: PropTypes.shape({
    page: PropTypes.number,
    pages: PropTypes.number,
    pageSize: PropTypes.number,
    sortedData: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  totalItems: PropTypes.number,
  item: PropTypes.string,
  items: PropTypes.string,
};

TableInfo.propTypes = propTypes;

export default withStyles(s)(TableInfo);
