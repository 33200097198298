import routeMetadata from '@routes/routeMetadata';

const withoutContext = pathname => {
  // [start, context, ...routePath]
  const [, , ...routePath] = pathname.split('/');

  return `/${routePath.join('/')}`;
};

/**
 * This utility function inspects the current metadata for a path and determines
 * whether or not the user has the requisite permissions to view it
 * @param {Object} config
 * @param {object} config.permissions The permissions list object associated with the user
 * @param {string} config.pathname The root of the path the user is attempting to access (for example, `/images`
 * @returns {boolean}
 */
export default ({ permissions, pathname, route }) => {
  const routeItem =
    route ||
    routeMetadata.find(item => {
      if (!item.path) return false;

      return (
        item.navbar?.permissions instanceof Array &&
        item.navbar?.permissions.length &&
        withoutContext(pathname).startsWith(item.path)
      );
    });
  return routeItem?.navbar?.permissions instanceof Array &&
    routeItem.navbar.permissions.length
    ? routeItem.navbar.permissions.reduce(
        (prev, curr) =>
          prev !== false ? permissions[curr.action] || false : false,
        null,
      ) || false
    : true;
};
