import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import type { ReactNode } from 'react';
import { useRef, useMemo } from 'react';
import type { MessageProps } from 'semantic-ui-react';
import { Divider, Label, Message, Transition } from 'semantic-ui-react';
import { useToggle } from 'usehooks-ts';

import type { ServiceError } from '@models';

import s from './ErrMessage.scss';

interface ParsedMessage {
  message: string;
  detail?: Record<string, unknown>;
}

interface PreparedError {
  type: string;
  statusCode?: number;
  parsedMessage?: ParsedMessage;
  rawMessage?: string;
  canRender: boolean;
}

type MaybeServiceErrorInstance = Error & ServiceError;

export interface ErrMessageProps {
  /**
   * Applies a custom classname to the root Message component
   */
  className?: string;
  /**
   * If provided, will render a 'View Details' button to reveal the contents
   */
  detail?: string | boolean | ReactNode;
  /**
   * Modifies the theme to be compatible with delete modal style
   */
  deleteErr?: boolean;
  /**
   * Expects an error object or a boolean. When truthy it will show a custom
   * message if set.
   */
  error: MaybeServiceErrorInstance | ServiceError | boolean;
  /**
   * A custom message to accompany or override the `error`
   */
  message?: string | boolean | ReactNode;
  /**
   * Props object for the Semantic UI Message component
   */
  messageProps?: Partial<MessageProps>;
  /**
   * Callback function to be called when the user clicks the close icon, if set
   */
  onDismiss?: () => void;
  /**
   * When set alongside a custom `message`, any nested message in the error
   * object will be parsed and rendered into the detail section, unless the
   * `detail` prop is also provided, which overrides the content.
   */
  showDetail?: boolean;
}

/**
 * A flexible error message component to present errors in a consistent manner.
 *
 * Handles fully custom messages, or derived messages from error objects. Also
 * enables a view/hide detail control when content is available.
 *
 * Note that besides `className` and `onDismiss`, all other Message component
 * props should be passed as an object to `messageProps`. `onDismiss` needed to
 * be separated due to some styling conditionality with the 'View Details'
 * button/link.
 *
 * @example
 * <ErrMessage
 *   error={(addUserError && errorMsg) || !!error}
 *   message="Custom message will override an error object"
 *   detail="Custom details will override derived details"
 *   className="animate__animated animate__shake"
 *   messageProps={{ size: 'tiny' }}
 *   onDismiss={() => console.log('class dismissed! 🧑‍🏫')}
 * />
 *
 */
const ErrMessage = ({
  className = 'animate__animated animate__fadeIn',
  error,
  message = false,
  detail = false,
  showDetail = false,
  deleteErr = false,
  messageProps = {},
  onDismiss,
}: ErrMessageProps) => {
  const [isDetailVisible, toggleIsDetailVisible] = useToggle();

  const detailRef = useRef<HTMLDivElement>(null);

  const { type, statusCode, parsedMessage, rawMessage, canRender } =
    useMemo<PreparedError>(() => {
      const preparedError: PreparedError = {
        type: 'Error',
        canRender: false,
      };

      if (!error) return preparedError;

      if (error === true) {
        return {
          ...preparedError,
          canRender: true,
        };
      }

      try {
        preparedError.parsedMessage = JSON.parse(error.message ?? '');
      } catch {
        // Don't need to handle this error, just catch it
      }

      return {
        ...preparedError,
        type: error.type || preparedError.type,
        statusCode: error.statusCode,
        rawMessage: error.message,
        canRender: true,
      };
    }, [error]);

  const detailContent = useMemo(() => {
    if (typeof detail === 'string') {
      return <p>{detail}</p>;
    }

    if (message) {
      // When there is a custom message, only derive detail based on the option
      if (showDetail) {
        if (parsedMessage) {
          return (
            <pre className={s.jsonStrFmt}>
              {JSON.stringify(parsedMessage, null, 2)}
            </pre>
          );
        }

        return rawMessage ? <p>{rawMessage}</p> : null;
      }
    } else if (
      // Only try to derive nested detail if there is no custom message
      !detail &&
      parsedMessage?.detail &&
      Object.keys(parsedMessage.detail).length > 0
    ) {
      return (
        <pre className={s.jsonStrFmt}>
          {JSON.stringify(parsedMessage.detail, null, 2)}
        </pre>
      );
    }

    return detail || null;
  }, [detail, message, showDetail, parsedMessage, rawMessage]);

  const messageContent = useMemo<ReactNode>(() => {
    const content =
      message ||
      parsedMessage?.message ||
      rawMessage ||
      `We're sorry but the operation failed.${
        detailContent ? ' View details for more information.' : ''
      }`;

    return typeof content === 'string' ? (
      <p className={s.message}>{content}</p>
    ) : (
      content
    );
  }, [message, parsedMessage, rawMessage, detailContent]);

  if (!canRender) return null;

  /**
   * Scrolls the detail section into view when it becomes visible. This is
   * assigned to a Transition callback to ensure the animation is complete and
   * the target element is in the DOM.
   */
  const handleDetailShown = () => {
    detailRef.current?.scrollIntoView({
      block: 'nearest',
      behavior: 'smooth',
    });
  };

  const hasDetail = !!detailContent;

  return (
    <Message
      role="alert"
      error={!deleteErr}
      color={deleteErr ? 'red' : undefined}
      className={classNames(
        className,
        deleteErr ? s.deleteMessage : '',
        s.root,
      )}
      onDismiss={onDismiss}
      {...messageProps}
    >
      <Message.Header className={s.header}>
        <Label as="h6" attached="top left" className={s.label}>
          {statusCode && `${statusCode} - `}
          {type || 'Error'}
        </Label>

        {hasDetail && (
          <Label
            as="button"
            attached="top right"
            className={classNames(
              s.detailLink,
              onDismiss ? s.detailMargin : '',
            )}
            onClick={toggleIsDetailVisible}
          >
            {isDetailVisible ? 'Hide' : 'View'} Details
          </Label>
        )}
      </Message.Header>

      {messageContent}

      {hasDetail && (
        <Transition
          visible={isDetailVisible}
          duration={300}
          onShow={handleDetailShown}
        >
          <div ref={detailRef} className={s.detailContainer}>
            <Divider horizontal className={s.detailHeader} inverted={deleteErr}>
              Details
            </Divider>

            {detailContent}
          </div>
        </Transition>
      )}
    </Message>
  );
};

export default withStyles(s)(ErrMessage);
