import _merge from 'lodash/merge';
import request from 'superagent';

import {
  CLEAR_REGISTRY_ADD_ERROR,
  CLEAR_REGISTRY_REMOVE_ERROR,
  CLEAR_REGISTRY_UPDATE_ERROR,
  CLEAR_SAML_IDP_ADD_ERROR,
  CLEAR_SAML_IDP_REMOVE_ERROR,
  CLEAR_SAML_IDP_UPDATE_ERROR,
  SET_ACCOUNT_ACTIVE_ERROR,
  SET_ACCOUNT_ACTIVE_PENDING,
  SET_ACCOUNT_ACTIVE_SUCCESS,
  SET_ACCOUNT_ACTIVE_TAB,
  SET_ACCOUNT_ADD_LDAP_USER_MAPPING_ERROR,
  SET_ACCOUNT_ADD_LDAP_USER_MAPPING_PENDING,
  SET_ACCOUNT_ADD_LDAP_USER_MAPPING_SUCCESS,
  SET_ACCOUNT_ADD_USER_DIALOG,
  SET_ACCOUNT_ADD_USER_ERROR,
  SET_ACCOUNT_ADD_USER_PENDING,
  SET_ACCOUNT_ADD_USER_SUCCESS,
  SET_ACCOUNT_CREATE_DIALOG,
  SET_ACCOUNT_CREATE_ERROR,
  SET_ACCOUNT_CREATE_PENDING,
  SET_ACCOUNT_CREATE_SUCCESS,
  SET_ACCOUNT_UPDATE_ERROR,
  SET_ACCOUNT_UPDATE_PENDING,
  SET_ACCOUNT_UPDATE_SUCCESS,
  SET_ACCOUNT_FETCH_ERROR,
  SET_ACCOUNT_FETCH_LDAP_USER_MAPPINGS_ERROR,
  SET_ACCOUNT_FETCH_LDAP_USER_MAPPINGS_PENDING,
  SET_ACCOUNT_FETCH_LDAP_USER_MAPPINGS_SUCCESS,
  SET_ACCOUNT_FETCH_PENDING,
  SET_ACCOUNT_FETCH_SUCCESS,
  SET_ACCOUNT_REMOVE_DIALOG,
  SET_ACCOUNT_REMOVE_ERROR,
  SET_ACCOUNT_REMOVE_LDAP_USER_MAPPING_ERROR,
  SET_ACCOUNT_REMOVE_LDAP_USER_MAPPING_PENDING,
  SET_ACCOUNT_REMOVE_LDAP_USER_MAPPING_SUCCESS,
  SET_ACCOUNT_REMOVE_PENDING,
  SET_ACCOUNT_REMOVE_SUCCESS,
  SET_ACCOUNT_REMOVE_USER_DIALOG,
  SET_ACCOUNT_REMOVE_USER_ERROR,
  SET_ACCOUNT_REMOVE_USER_PENDING,
  SET_ACCOUNT_REMOVE_USER_SUCCESS,
  SET_ACCOUNT_REORDER_LDAP_USER_MAPPINGS_ERROR,
  SET_ACCOUNT_REORDER_LDAP_USER_MAPPINGS_PENDING,
  SET_ACCOUNT_REORDER_LDAP_USER_MAPPINGS_SUCCESS,
  SET_ACCOUNT_TEST_LDAP_USER_MAPPINGS_ERROR,
  SET_ACCOUNT_TEST_LDAP_USER_MAPPINGS_PENDING,
  SET_ACCOUNT_TEST_LDAP_USER_MAPPINGS_SUCCESS,
  SET_ACCOUNT_UPDATE_CONTEXT_ERROR,
  SET_ACCOUNT_UPDATE_CONTEXT_PENDING,
  SET_ACCOUNT_UPDATE_CONTEXT_SUCCESS,
  SET_ACCOUNT_UPDATE_LDAP_USER_MAPPING_ERROR,
  SET_ACCOUNT_UPDATE_LDAP_USER_MAPPING_PENDING,
  SET_ACCOUNT_UPDATE_LDAP_USER_MAPPING_SUCCESS,
  SET_ACCOUNT_UPDATE_USER_CREDENTIALS_ERROR,
  SET_ACCOUNT_UPDATE_USER_CREDENTIALS_PENDING,
  SET_ACCOUNT_UPDATE_USER_CREDENTIALS_SUCCESS,
  SET_ACCOUNT_UPDATE_USER_DIALOG,
  SET_ACCOUNT_VIEW_ROLE_DIALOG,
  SET_ACCOUNTS_FETCH_ERROR,
  SET_ACCOUNTS_FETCH_PENDING,
  SET_ACCOUNTS_FETCH_SUCCESS,
  SET_ACCOUNTS_USER_ROLES_FETCH_ERROR,
  SET_ACCOUNTS_USER_ROLES_FETCH_PENDING,
  SET_ACCOUNTS_USER_ROLES_FETCH_SUCCESS,
  SET_ADD_SAML_IDP_DIALOG,
  SET_FEED_VIEW_MODAL,
  SET_LDAP_CONFIG_FETCH_ERROR,
  SET_LDAP_CONFIG_FETCH_PENDING,
  SET_LDAP_CONFIG_FETCH_SUCCESS,
  SET_LDAP_CONFIG_TEST_ERROR,
  SET_LDAP_CONFIG_TEST_PENDING,
  SET_LDAP_CONFIG_TEST_SUCCESS,
  SET_LDAP_CONFIG_UPDATE_ERROR,
  SET_LDAP_CONFIG_UPDATE_PENDING,
  SET_LDAP_CONFIG_UPDATE_SUCCESS,
  SET_NOTIFICATION_DRIVERS_FETCH_ERROR,
  SET_NOTIFICATION_DRIVERS_FETCH_PENDING,
  SET_NOTIFICATION_DRIVERS_FETCH_SUCCESS,
  SET_NOTIFICATION_DRIVERS_UPDATE_ERROR,
  SET_NOTIFICATION_DRIVERS_UPDATE_PENDING,
  SET_NOTIFICATION_DRIVERS_UPDATE_SUCCESS,
  SET_PASSWORD_UPDATE_ERROR,
  SET_PASSWORD_UPDATE_PENDING,
  SET_PASSWORD_UPDATE_RESET,
  SET_PASSWORD_UPDATE_SUCCESS,
  SET_REGISTRIES_FETCH_ERROR,
  SET_REGISTRIES_FETCH_PENDING,
  SET_REGISTRIES_FETCH_SUCCESS,
  SET_REGISTRY_ADD_DIALOG,
  SET_REGISTRY_ADD_ERROR,
  SET_REGISTRY_ADD_PENDING,
  SET_REGISTRY_ADD_SUCCESS,
  SET_REGISTRY_REMOVE_DIALOG,
  SET_REGISTRY_REMOVE_ERROR,
  SET_REGISTRY_REMOVE_PENDING,
  SET_REGISTRY_REMOVE_SUCCESS,
  SET_REGISTRY_UPDATE_DIALOG,
  SET_REGISTRY_UPDATE_ERROR,
  SET_REGISTRY_UPDATE_PENDING,
  SET_REGISTRY_UPDATE_SUCCESS,
  SET_REMOVE_SAML_IDP_DIALOG,
  SET_ROLES_FETCH_ERROR,
  SET_ROLES_FETCH_PENDING,
  SET_ROLES_FETCH_SUCCESS,
  SET_ROLES_UPDATE_MEMBERS_ERROR,
  SET_ROLES_UPDATE_MEMBERS_PENDING,
  SET_ROLES_UPDATE_MEMBERS_SUCCESS,
  SET_SAML_IDP_ADD_ERROR,
  SET_SAML_IDP_ADD_PENDING,
  SET_SAML_IDP_ADD_SUCCESS,
  SET_SAML_IDP_REMOVE_ERROR,
  SET_SAML_IDP_REMOVE_PENDING,
  SET_SAML_IDP_REMOVE_SUCCESS,
  SET_SAML_IDP_UPDATE_ERROR,
  SET_SAML_IDP_UPDATE_PENDING,
  SET_SAML_IDP_UPDATE_SUCCESS,
  SET_SAML_IDPS_FETCH_ERROR,
  SET_SAML_IDPS_FETCH_PENDING,
  SET_SAML_IDPS_FETCH_SUCCESS,
  SET_SERVICE_VIEW_MODAL,
  SET_SYSTEM_ACTIVE_TAB,
  SET_SYSTEM_VIEW_PROPERTIES,
  SET_UPDATE_SAML_IDP_DIALOG,
} from '../actionTypes';

import { setNotificationConfigsFetchSuccess } from './action-events';
import { fetchServicesHealth } from './action-services';

// *** SYSTEM VIEW ********************************************************** //
export const setSystemActiveTabIndex = idx => ({
  type: SET_SYSTEM_ACTIVE_TAB,
  idx: typeof idx === 'number' ? idx : 0,
});

export const setSystemViewProps = (view, props) => (dispatch, getState) => {
  const currState = getState();
  if (currState.system.viewProps[view]) {
    dispatch({
      type: SET_SYSTEM_VIEW_PROPERTIES,
      viewProps: {
        ...currState.system.viewProps,
        [view]: _merge(currState.system.viewProps[view] ?? {}, props),
      },
    });
  }
};

// *** HEALTH VIEW ********************************************************** //
export const setServiceViewModal = open => ({
  type: SET_SERVICE_VIEW_MODAL,
  open,
});

export const setFeedViewModal = open => ({
  type: SET_FEED_VIEW_MODAL,
  open,
});

// *** FETCH LDAP CONFIG **************************************************** //
export const setLdapConfigFetchPending = () => ({
  type: SET_LDAP_CONFIG_FETCH_PENDING,
});

export const setLdapConfigFetchSuccess = resp => ({
  type: SET_LDAP_CONFIG_FETCH_SUCCESS,
  resp,
});

export const setLdapConfigFetchError = errorMsg => ({
  type: SET_LDAP_CONFIG_FETCH_ERROR,
  errorMsg,
});

export const fetchLdapConfig = () => (dispatch, getState) => {
  const currState = getState();
  const isHealthy =
    currState.app &&
    currState.app.healthCheck &&
    currState.app.healthCheck.isEngineHealthy;

  let resp = false;

  if (isHealthy) {
    dispatch(setLdapConfigFetchPending());
    resp = request
      .get(`/service/appdb/ldap/config`)
      .then(fetchLdapConfigResp =>
        dispatch(setLdapConfigFetchSuccess(fetchLdapConfigResp.body)),
      )
      .catch(err => {
        let errResp;
        if (err.response && err.response.body && err.response.body.data) {
          errResp = dispatch(setLdapConfigFetchError(err.response.body.data));
        } else {
          errResp = dispatch(setLdapConfigFetchError());
        }
        return errResp;
      });
  }
  return resp || dispatch(setLdapConfigFetchError());
};

// *** UPDATE LDAP CONFIG *************************************************** //
export const setLdapConfigUpdatePending = () => ({
  type: SET_LDAP_CONFIG_UPDATE_PENDING,
});

export const setLdapConfigUpdateSuccess = resp => ({
  type: SET_LDAP_CONFIG_UPDATE_SUCCESS,
  resp,
});

export const setLdapConfigUpdateError = errorMsg => ({
  type: SET_LDAP_CONFIG_UPDATE_ERROR,
  errorMsg,
});

export const updateLdapConfig =
  (props, params = {}) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy =
      currState.app &&
      currState.app.healthCheck &&
      currState.app.healthCheck.isEngineHealthy;

    let resp = false;

    if (isHealthy) {
      dispatch(setLdapConfigUpdatePending());
      resp = request
        .put(`/service/appdb/ldap/config/${props.id}`)
        .query(params)
        .send(props)
        .then(updateLdapConfigResp =>
          dispatch(setLdapConfigUpdateSuccess(updateLdapConfigResp.body)),
        )
        .catch(err => {
          let errResp;
          if (err.response && err.response.body && err.response.body.data) {
            errResp = dispatch(
              setLdapConfigUpdateError(err.response.body.data),
            );
          } else {
            errResp = dispatch(setLdapConfigUpdateError());
          }
          return errResp;
        });
    }
    return resp || dispatch(setLdapConfigUpdateError());
  };

// *** TEST LDAP CONFIG ***************************************************** //
export const setLdapConfigTestPending = () => ({
  type: SET_LDAP_CONFIG_TEST_PENDING,
});

export const setLdapConfigTestSuccess = resp => ({
  type: SET_LDAP_CONFIG_TEST_SUCCESS,
  resp,
});

export const setLdapConfigTestError = errorMsg => ({
  type: SET_LDAP_CONFIG_TEST_ERROR,
  errorMsg,
});

export const testLdapConfig = () => (dispatch, getState) => {
  const currState = getState();
  const isHealthy =
    currState.app &&
    currState.app.healthCheck &&
    currState.app.healthCheck.isEngineHealthy;

  let resp = false;

  if (isHealthy) {
    dispatch(setLdapConfigTestPending());
    resp = request
      .get(`/service/appdb/ldap/config/test`)
      .then(testLdapConfigResp =>
        dispatch(setLdapConfigTestSuccess(testLdapConfigResp.body)),
      )
      .catch(err => {
        let errResp;
        if (err.response && err.response.body && err.response.body.data) {
          errResp = dispatch(setLdapConfigTestError(err.response.body.data));
        } else {
          errResp = dispatch(setLdapConfigTestError());
        }
        return errResp;
      });
  }
  return resp || dispatch(setLdapConfigTestError());
};

// *** REGISTRY DIALOGS ***************************************************** //
export const setRegistryAddDialog = open => ({
  type: SET_REGISTRY_ADD_DIALOG,
  open,
});

export const setRegistryUpdateDialog = open => ({
  type: SET_REGISTRY_UPDATE_DIALOG,
  open,
});

export const setRegistryRemoveDialog = open => ({
  type: SET_REGISTRY_REMOVE_DIALOG,
  open,
});

// *** FETCH NOTIFICATION DRIVERS ******************************************* //
export const setNotificationDriversFetchPending = () => ({
  type: SET_NOTIFICATION_DRIVERS_FETCH_PENDING,
});

export const setNotificationDriversFetchSuccess = resp => ({
  type: SET_NOTIFICATION_DRIVERS_FETCH_SUCCESS,
  resp,
});

export const setNotificationDriversFetchError = errorMsg => ({
  type: SET_NOTIFICATION_DRIVERS_FETCH_ERROR,
  errorMsg,
});

export const fetchNotificationDrivers = () => (dispatch, getState) => {
  const currState = getState();
  const isHealthy = currState?.app?.healthCheck?.isEngineHealthy;

  let resp = false;

  if (isHealthy) {
    dispatch(setNotificationDriversFetchPending());
    resp = request
      .get(`/service/notifications/endpoints`)
      .then(fetchNotificationDriversResp =>
        dispatch(
          setNotificationDriversFetchSuccess(fetchNotificationDriversResp.body),
        ),
      )
      .catch(err => {
        let errResp;
        if (err.response && err.response.body && err.response.body.data) {
          errResp = dispatch(
            setNotificationDriversFetchError(err.response.body.data),
          );
        } else {
          errResp = dispatch(setNotificationDriversFetchError());
        }
        return errResp;
      });
  }
  return resp || dispatch(setNotificationDriversFetchError());
};

// *** UPDATE NOTIFICATION DRIVERS ****************************************** //
export const setNotificationDriversUpdatePending = name => ({
  type: SET_NOTIFICATION_DRIVERS_UPDATE_PENDING,
  name,
});

export const setNotificationDriversUpdateSuccess = resp => ({
  type: SET_NOTIFICATION_DRIVERS_UPDATE_SUCCESS,
  resp,
});

export const setNotificationDriversUpdateError = errorMsg => ({
  type: SET_NOTIFICATION_DRIVERS_UPDATE_ERROR,
  errorMsg,
});

export const updateNotificationDrivers =
  ({ name, driverList }) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy = currState?.app?.healthCheck?.isEngineHealthy;

    let resp = false;

    if (isHealthy) {
      dispatch(setNotificationDriversUpdatePending(name));
      resp = request
        .put(`/service/notifications/endpoints/${name}`)
        .send(driverList)
        .then(updateNotificationDriversResp =>
          // If the enabled / disabled state of an endpoint driver is changed, in
          // addition to fetching the updated endpoint state data we also retrieve
          // the notifications config data—this will ensure that the corresponding
          // subtabs in the Notifications view display the correct state.
          request
            .get(`/service/notifications/configs`)
            .then(fetchNotificationConfigsResp =>
              Promise.all([
                dispatch(
                  setNotificationDriversUpdateSuccess(
                    updateNotificationDriversResp.body,
                  ),
                ),
                dispatch(
                  setNotificationConfigsFetchSuccess(
                    fetchNotificationConfigsResp.body,
                  ),
                ),
              ]),
            ),
        )
        .catch(err => {
          let errResp;
          if (err.response && err.response.body && err.response.body.data) {
            errResp = dispatch(
              setNotificationDriversUpdateError(err.response.body.data),
            );
          } else {
            errResp = dispatch(setNotificationDriversUpdateError());
          }
          return errResp;
        });
    }
    return resp || dispatch(setNotificationDriversUpdateError());
  };

// *** FETCH REGISTRIES ***************************************************** //
export const setRegistriesFetchPending = () => ({
  type: SET_REGISTRIES_FETCH_PENDING,
});

export const setRegistriesFetchSuccess = resp => ({
  type: SET_REGISTRIES_FETCH_SUCCESS,
  resp,
});

export const setRegistriesFetchError = errorMsg => ({
  type: SET_REGISTRIES_FETCH_ERROR,
  errorMsg,
});

export const fetchRegistries = () => (dispatch, getState) => {
  const currState = getState();
  const isHealthy =
    currState.app &&
    currState.app.healthCheck &&
    currState.app.healthCheck.isEngineHealthy;

  let resp = false;

  if (isHealthy) {
    dispatch(setRegistriesFetchPending());
    resp = request
      .get(`/service/registries`)
      .then(fetchRegistriesResp =>
        dispatch(setRegistriesFetchSuccess(fetchRegistriesResp.body)),
      )
      .catch(err => {
        let errResp;
        if (err.response && err.response.body && err.response.body.data) {
          errResp = dispatch(setRegistriesFetchError(err.response.body.data));
        } else {
          errResp = dispatch(setRegistriesFetchError());
        }
        return errResp;
      });
  }
  return resp || dispatch(setRegistriesFetchError());
};

// *** ADD REGISTRIES ******************************************************* //
export const setRegistryAddPending = () => ({
  type: SET_REGISTRY_ADD_PENDING,
});

export const setRegistryAddSuccess = resp => ({
  type: SET_REGISTRY_ADD_SUCCESS,
  resp,
});

export const setRegistryAddError = errorMsg => ({
  type: SET_REGISTRY_ADD_ERROR,
  errorMsg,
});

export const clearRegistryAddError = () => ({
  type: CLEAR_REGISTRY_ADD_ERROR,
});

export const addNewRegistry =
  (props, params = {}) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy =
      currState.app &&
      currState.app.healthCheck &&
      currState.app.healthCheck.isEngineHealthy;

    let resp = false;

    if (isHealthy) {
      dispatch(setRegistryAddPending());
      resp = request
        .post(`/service/registries`)
        .query(params)
        .set('Content-Type', 'application/json')
        .send(props)
        .then(addNewRegistryResp =>
          dispatch(setRegistryAddSuccess(addNewRegistryResp.body)),
        )
        .catch(err => {
          let errResp;
          if (err.response && err.response.body && err.response.body.data) {
            errResp = dispatch(setRegistryAddError(err.response.body.data));
          } else {
            errResp = dispatch(setRegistryAddError());
          }
          return errResp;
        });
    }

    return resp || dispatch(setRegistryAddError());
  };

// *** UPDATE REGISTRIES **************************************************** //
export const setRegistryUpdatePending = () => ({
  type: SET_REGISTRY_UPDATE_PENDING,
});

export const setRegistryUpdateSuccess = resp => ({
  type: SET_REGISTRY_UPDATE_SUCCESS,
  resp,
});

export const setRegistryUpdateError = errorMsg => ({
  type: SET_REGISTRY_UPDATE_ERROR,
  errorMsg,
});

export const clearRegistryUpdateError = () => ({
  type: CLEAR_REGISTRY_UPDATE_ERROR,
});

export const updateRegistry =
  (props, params = {}) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy =
      currState.app &&
      currState.app.healthCheck &&
      currState.app.healthCheck.isEngineHealthy;

    let resp = false;

    if (isHealthy) {
      dispatch(setRegistryUpdatePending());
      resp = request
        .put(`/service/registries/${props.registry}`)
        .query(params)
        .send(props)
        .then(updateRegistryResp =>
          dispatch(setRegistryUpdateSuccess(updateRegistryResp.body)),
        )
        .catch(err => {
          let errResp;
          if (err.response && err.response.body && err.response.body.data) {
            errResp = dispatch(setRegistryUpdateError(err.response.body.data));
          } else {
            errResp = dispatch(setRegistryUpdateError());
          }
          return errResp;
        });
    }
    return resp || dispatch(setRegistryUpdateError());
  };

// *** REMOVE REGISTRIES **************************************************** //
export const setRegistryRemovePending = () => ({
  type: SET_REGISTRY_REMOVE_PENDING,
});

export const setRegistryRemoveSuccess = resp => ({
  type: SET_REGISTRY_REMOVE_SUCCESS,
  resp,
});

export const setRegistryRemoveError = errorMsg => ({
  type: SET_REGISTRY_REMOVE_ERROR,
  errorMsg,
});

export const clearRegistryRemoveError = () => ({
  type: CLEAR_REGISTRY_REMOVE_ERROR,
});

export const removeRegistry = props => (dispatch, getState) => {
  const currState = getState();
  const isHealthy =
    currState.app &&
    currState.app.healthCheck &&
    currState.app.healthCheck.isEngineHealthy;

  let resp = false;

  if (isHealthy) {
    dispatch(setRegistryRemovePending());
    resp = request
      .delete(`/service/registries/${encodeURIComponent(props.registry)}`)
      .then(removeRegistryResp =>
        dispatch(setRegistryRemoveSuccess(removeRegistryResp.body)),
      )
      .catch(err => {
        let errResp;
        if (err.response && err.response.body && err.response.body.data) {
          errResp = dispatch(setRegistryRemoveError(err.response.body.data));
        } else {
          errResp = dispatch(setRegistryRemoveError());
        }
        return errResp;
      });
  }
  return resp || dispatch(setRegistryRemoveError());
};

// *** USER CREDENTIALS ***************************************************** //
export const setPasswordUpdatePending = () => ({
  type: SET_PASSWORD_UPDATE_PENDING,
});

export const setPasswordUpdateSuccess = resp => ({
  type: SET_PASSWORD_UPDATE_SUCCESS,
  resp,
});

export const setPasswordUpdateError = errorMsg => ({
  type: SET_PASSWORD_UPDATE_ERROR,
  errorMsg,
});

export const setPasswordUpdateReset = () => ({
  type: SET_PASSWORD_UPDATE_RESET,
});

export const updatePassword = props => (dispatch, getState) => {
  const currState = getState();
  const isHealthy =
    currState.app &&
    currState.app.healthCheck &&
    currState.app.healthCheck.isEngineHealthy;

  let resp = false;

  if (isHealthy) {
    dispatch(setPasswordUpdatePending());
    resp = request
      .post(`/service/user/credentials`)
      .send(props)
      .then(updatePasswordResp =>
        dispatch(setPasswordUpdateSuccess(updatePasswordResp.body)),
      )
      .catch(err => {
        let errResp;
        if (err.response && err.response.body && err.response.body.data) {
          errResp = dispatch(setPasswordUpdateError(err.response.body.data));
        } else {
          errResp = dispatch(setPasswordUpdateError());
        }
        return errResp;
      });
  }
  return resp || dispatch(setPasswordUpdateError());
};

// *** ACCOUNT VIEW / DIALOGS *********************************************** //
export const setAccountActiveTab = idx => ({
  type: SET_ACCOUNT_ACTIVE_TAB,
  idx: typeof idx === 'number' ? idx : 0,
});

export const setAccountCreateDialog = open => ({
  type: SET_ACCOUNT_CREATE_DIALOG,
  open,
});

export const setAccountRemoveDialog = open => ({
  type: SET_ACCOUNT_REMOVE_DIALOG,
  open,
});

export const setAccountAddUserDialog = open => ({
  type: SET_ACCOUNT_ADD_USER_DIALOG,
  open,
});

export const setAccountUpdateUserDialog = open => ({
  type: SET_ACCOUNT_UPDATE_USER_DIALOG,
  open,
});

export const setAccountRemoveUserDialog = open => ({
  type: SET_ACCOUNT_REMOVE_USER_DIALOG,
  open,
});

export const setAccountViewRoleDialog = open => ({
  type: SET_ACCOUNT_VIEW_ROLE_DIALOG,
  open,
});

// *** FETCH ACCOUNTS ******************************************************* //
export const setAccountsFetchPending = () => ({
  type: SET_ACCOUNTS_FETCH_PENDING,
});

export const setAccountsFetchSuccess = resp => ({
  type: SET_ACCOUNTS_FETCH_SUCCESS,
  resp,
});

export const setAccountsFetchError = errorMsg => ({
  type: SET_ACCOUNTS_FETCH_ERROR,
  errorMsg,
});

export const fetchAccounts = () => (dispatch, getState) => {
  const currState = getState();
  const isHealthy =
    currState.app &&
    currState.app.healthCheck &&
    currState.app.healthCheck.isEngineHealthy;

  let resp = false;

  if (isHealthy) {
    dispatch(setAccountsFetchPending());
    resp = request
      .get(`/service/accounts`)
      .then(fetchAccountsResp =>
        dispatch(setAccountsFetchSuccess(fetchAccountsResp.body)),
      )
      .catch(err => {
        let errResp;
        if (err.response && err.response.body && err.response.body.data) {
          errResp = dispatch(setAccountsFetchError(err.response.body.data));
        } else {
          errResp = dispatch(setAccountsFetchError());
        }
        return errResp;
      });
  }
  return resp || dispatch(setAccountsFetchError());
};

// *** FETCH ACCOUNT ******************************************************** //
export const setAccountFetchPending = () => ({
  type: SET_ACCOUNT_FETCH_PENDING,
});

export const setAccountFetchSuccess = resp => ({
  type: SET_ACCOUNT_FETCH_SUCCESS,
  resp,
});

export const setAccountFetchError = errorMsg => ({
  type: SET_ACCOUNT_FETCH_ERROR,
  errorMsg,
});

export const fetchAccount = name => (dispatch, getState) => {
  const currState = getState();
  const isHealthy =
    currState.app &&
    currState.app.healthCheck &&
    currState.app.healthCheck.isEngineHealthy;

  let resp = false;

  if (isHealthy) {
    dispatch(setAccountFetchPending());
    resp = request
      .get(`/service/account${name ? `s/${name}` : ''}`)
      .then(fetchAccountResp =>
        dispatch(setAccountFetchSuccess(fetchAccountResp.body)),
      )
      .catch(err => {
        let errResp;
        if (err.response && err.response.body && err.response.body.data) {
          errResp = dispatch(setAccountFetchError(err.response.body.data));
        } else {
          errResp = dispatch(setAccountFetchError());
        }
        return errResp;
      });
  }
  return resp || dispatch(setAccountFetchError());
};

// *** CREATE ACCOUNT ******************************************************* //
export const setCreateAccountPending = () => ({
  type: SET_ACCOUNT_CREATE_PENDING,
});

export const setCreateAccountSuccess = resp => ({
  type: SET_ACCOUNT_CREATE_SUCCESS,
  resp,
});

export const setCreateAccountError = errorMsg => ({
  type: SET_ACCOUNT_CREATE_ERROR,
  errorMsg,
});

export const createAccount = props => (dispatch, getState) => {
  const currState = getState();
  const isHealthy =
    currState.app &&
    currState.app.healthCheck &&
    currState.app.healthCheck.isEngineHealthy;

  let resp = false;

  if (isHealthy) {
    dispatch(setCreateAccountPending());
    resp = request
      .post(`/service/accounts`)
      .send(props)
      .then(accountResp =>
        // Rather than try to patch the updated response into the
        // existing nested accounts tree on the client, we'll just
        // silently re-fetch the current accounts structure from the
        // service...
        request
          .get('/service/accounts')
          .query({ nested: true })
          .then(accountsResp =>
            Promise.all([
              dispatch(setCreateAccountSuccess(accountResp.body)),
              dispatch(setAccountsFetchSuccess(accountsResp.body)),
            ]),
          ),
      )
      .catch(err => {
        let errResp;
        if (err.response && err.response.body && err.response.body.data) {
          errResp = dispatch(setCreateAccountError(err.response.body.data));
        } else {
          errResp = dispatch(setCreateAccountError());
        }
        return errResp;
      });
  }
  return resp || dispatch(setCreateAccountError());
};

// *** UPDATE ACCOUNT ******************************************************* //
export const setUpdateAccountPending = () => ({
  type: SET_ACCOUNT_UPDATE_PENDING,
});

export const setUpdateAccountSuccess = resp => ({
  type: SET_ACCOUNT_UPDATE_SUCCESS,
  resp,
});

export const setUpdateAccountError = errorMsg => ({
  type: SET_ACCOUNT_UPDATE_ERROR,
  errorMsg,
});

export const updateAccount = props => (dispatch, getState) => {
  const currState = getState();
  const isHealthy = currState.app?.healthCheck?.isEngineHealthy;

  let resp = false;

  if (isHealthy) {
    dispatch(setUpdateAccountPending());
    resp = request
      .put(`/service/accounts/${props.name}`)
      .send(props)
      .then(accountResp =>
        // Rather than try to patch the updated response into the
        // existing nested accounts tree on the client, we'll just
        // silently re-fetch the current accounts structure from the
        // service...
        request
          .get('/service/accounts')
          .query({ nested: true })
          .then(accountsResp =>
            Promise.all([
              dispatch(setUpdateAccountSuccess(accountResp.body)),
              dispatch(setAccountsFetchSuccess(accountsResp.body)),
            ]),
          ),
      )
      .catch(err => {
        let errResp;
        if (err.response?.body?.data) {
          errResp = dispatch(setUpdateAccountError(err.response.body.data));
        } else {
          errResp = dispatch(setUpdateAccountError());
        }
        return errResp;
      });
  }
  return resp || dispatch(setUpdateAccountError());
};

// *** ACTIVATE / DEACTIVATE ACCOUNT **************************************** //
export const setActiveAccountPending = () => ({
  type: SET_ACCOUNT_ACTIVE_PENDING,
});

export const setActiveAccountSuccess = resp => ({
  type: SET_ACCOUNT_ACTIVE_SUCCESS,
  resp,
});

export const setActiveAccountError = errorMsg => ({
  type: SET_ACCOUNT_ACTIVE_ERROR,
  errorMsg,
});

export const activeAccount =
  (props = {}) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy =
      currState.app &&
      currState.app.healthCheck &&
      currState.app.healthCheck.isEngineHealthy;

    let resp = false;

    if (
      isHealthy &&
      // Bit more checking here than usual in order to ensure the route gets
      // composed correctly
      typeof props === 'object' &&
      typeof props.account === 'string' &&
      typeof props.active === 'boolean'
    ) {
      dispatch(setActiveAccountPending());

      resp = request
        .post(
          `/service/accounts/${encodeURIComponent(props.account)}/${
            props.active ? 'activate' : 'deactivate'
          }`,
        )
        .then(accountResp =>
          // Rather than try to patch the updated response into the
          // existing nested accounts tree on the client, we'll just
          // silently re-fetch the current accounts structure from the
          // service...
          request
            .get('/service/accounts')
            .query({ nested: true })
            .then(accountsResp =>
              Promise.all([
                dispatch(setActiveAccountSuccess(accountResp.body)),
                dispatch(setAccountsFetchSuccess(accountsResp.body)),
              ]),
            ),
        )
        .catch(err => {
          let errResp;
          if (err.response && err.response.body && err.response.body.data) {
            errResp = dispatch(setActiveAccountError(err.response.body.data));
          } else {
            errResp = dispatch(setActiveAccountError());
          }
          return errResp;
        });
    }
    return resp || dispatch(setActiveAccountError());
  };

// *** REMOVE ACCOUNT ******************************************************* //
export const setRemoveAccountPending = () => ({
  type: SET_ACCOUNT_REMOVE_PENDING,
});

export const setRemoveAccountSuccess = resp => ({
  type: SET_ACCOUNT_REMOVE_SUCCESS,
  resp,
});

export const setRemoveAccountError = errorMsg => ({
  type: SET_ACCOUNT_REMOVE_ERROR,
  errorMsg,
});

export const removeAccount =
  (props = {}) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy =
      currState.app &&
      currState.app.healthCheck &&
      currState.app.healthCheck.isEngineHealthy;

    let resp = false;

    if (
      isHealthy &&
      // Bit more checking here than usual in order to ensure the route gets
      // composed correctly
      typeof props === 'object' &&
      typeof props.account === 'string'
    ) {
      dispatch(setRemoveAccountPending());

      resp = request
        .delete(`/service/accounts/${encodeURIComponent(props.account)}`)
        .then(accountResp =>
          // Rather than try to patch the updated response into the
          // existing nested accounts tree on the client, we'll just
          // silently re-fetch the current accounts structure from the
          // service...
          request
            .get('/service/accounts')
            .query({ nested: true })
            .then(accountsResp =>
              Promise.all([
                dispatch(setRemoveAccountSuccess(accountResp.body)),
                dispatch(setAccountsFetchSuccess(accountsResp.body)),
              ]),
            ),
        )
        .catch(err => {
          let errResp;
          if (err.response && err.response.body && err.response.body.data) {
            errResp = dispatch(setRemoveAccountError(err.response.body.data));
          } else {
            errResp = dispatch(setRemoveAccountError());
          }
          return errResp;
        });
    }
    return resp || dispatch(setRemoveAccountError());
  };

// *** UPDATE ACCOUNT CONTEXT *********************************************** //
export const setUpdateAccountContextPending = () => ({
  type: SET_ACCOUNT_UPDATE_CONTEXT_PENDING,
});

export const setUpdateAccountContextSuccess = resp => ({
  type: SET_ACCOUNT_UPDATE_CONTEXT_SUCCESS,
  resp,
});

export const setUpdateAccountContextError = errorMsg => ({
  type: SET_ACCOUNT_UPDATE_CONTEXT_ERROR,
  errorMsg,
});

export const updateAccountContext =
  (props = {}) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy = currState.app?.healthCheck?.isEngineHealthy;
    const isAdmin = currState.auth?.account?.isAdmin;
    let resp = false;

    if (
      isHealthy &&
      // Apply a bit more checking than usual in order to ensure the route gets
      // composed correctly
      typeof props === 'object' &&
      typeof props.name === 'string'
    ) {
      dispatch(setUpdateAccountContextPending());

      resp = request
        .post('/service/accounts/context')
        .send(props)
        .then(accountResp => {
          // If the user is an administrator, after switching context we'll need
          // to re-fetch the service health information; this is because the
          // Redux `appStateReset` operation executed by the `showAccountSwitch`
          // method of `Topnav` clears it from the state store.
          if (isAdmin) {
            dispatch(fetchServicesHealth());
          }
          return dispatch(setUpdateAccountContextSuccess(accountResp.body));
        })
        .catch(err => {
          let errResp;
          if (err?.response?.body?.data) {
            errResp = dispatch(
              setUpdateAccountContextError(err.response.body.data),
            );
          } else {
            errResp = dispatch(setUpdateAccountContextError());
          }
          return errResp;
        });
    }
    return resp || dispatch(setUpdateAccountContextError());
  };

// *** ADD USER ************************************************************* //
export const setAddUserPending = () => ({
  type: SET_ACCOUNT_ADD_USER_PENDING,
});

export const setAddUserSuccess = resp => ({
  type: SET_ACCOUNT_ADD_USER_SUCCESS,
  resp,
});

export const setAddUserError = errorMsg => ({
  type: SET_ACCOUNT_ADD_USER_ERROR,
  errorMsg,
});

export const addUser =
  (props = {}) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy = currState.app?.healthCheck?.isEngineHealthy;
    const isAdmin = currState.auth?.account?.isAdmin;

    let resp = false;

    if (
      isHealthy &&
      // Bit more checking here than usual in order to ensure the route gets
      // composed correctly
      typeof props === 'object' &&
      typeof props.account === 'string' &&
      typeof props.username === 'string' &&
      typeof props.password === 'string'
    ) {
      dispatch(setAddUserPending());

      resp = request
        .post(`/service/accounts/${encodeURIComponent(props.account)}/users`)
        .send({
          username: props.username,
          password: props.password,
          samlIdp: props.samlIdp,
          type: props.type,
        })
        .then(addUserResp => {
          // Rather than try to patch the updated response into the
          // existing nested accounts tree on the client, we'll just
          // silently re-fetch the current accounts structure from the
          // service...
          if (isAdmin) {
            return request
              .get('/service/accounts')
              .query({ nested: true })
              .then(accountsResp =>
                Promise.all([
                  dispatch(setAddUserSuccess(addUserResp.body)),
                  dispatch(setAccountsFetchSuccess(accountsResp.body)),
                ]),
              );
          }
          return request
            .get(
              `/service/accounts/${encodeURIComponent(
                currState.auth.account.context.accountname,
              )}`,
            )
            .then(accountResp =>
              Promise.all([
                dispatch(setAddUserSuccess(addUserResp.body)),
                dispatch(setAccountFetchSuccess(accountResp.body)),
              ]),
            );
        })
        .catch(err => {
          let errResp;
          if (err.response && err.response.body && err.response.body.data) {
            errResp = dispatch(setAddUserError(err.response.body.data));
          } else {
            errResp = dispatch(setAddUserError());
          }
          return errResp;
        });
    }
    return resp || dispatch(setAddUserError());
  };

// *** REMOVE USER ********************************************************** //
export const setRemoveUserPending = () => ({
  type: SET_ACCOUNT_REMOVE_USER_PENDING,
});

export const setRemoveUserSuccess = resp => ({
  type: SET_ACCOUNT_REMOVE_USER_SUCCESS,
  resp,
});

export const setRemoveUserError = errorMsg => ({
  type: SET_ACCOUNT_REMOVE_USER_ERROR,
  errorMsg,
});

export const removeUser =
  (props = {}) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy =
      currState.app &&
      currState.app.healthCheck &&
      currState.app.healthCheck.isEngineHealthy;
    const isAdmin =
      currState.auth &&
      currState.auth.account &&
      currState.auth.account.isAdmin;

    let resp = false;

    if (
      isHealthy &&
      // Bit more checking here than usual in order to ensure the route gets
      // composed correctly
      typeof props === 'object' &&
      typeof props.account === 'string' &&
      typeof props.username === 'string'
    ) {
      dispatch(setRemoveUserPending());

      resp = request
        .delete(
          `/service/accounts/${encodeURIComponent(
            props.account,
          )}/users/${encodeURIComponent(props.username)}`,
        )
        .then(removeUserResp => {
          // Rather than try to patch the updated response into the
          // existing nested accounts tree on the client, we'll just
          // silently re-fetch the current accounts structure from the
          // service...
          if (isAdmin) {
            return request
              .get('/service/accounts')
              .query({ nested: true })
              .then(accountsResp =>
                Promise.all([
                  dispatch(setRemoveUserSuccess(removeUserResp.body)),
                  dispatch(setAccountsFetchSuccess(accountsResp.body)),
                ]),
              );
          }
          return request
            .get(
              `/service/accounts/${encodeURIComponent(
                currState.auth.account.context.accountname,
              )}`,
            )
            .then(accountResp =>
              Promise.all([
                dispatch(setRemoveUserSuccess(removeUserResp.body)),
                dispatch(setAccountFetchSuccess(accountResp.body)),
              ]),
            );
        })
        .catch(err => {
          let errResp;
          if (err.response && err.response.body && err.response.body.data) {
            errResp = dispatch(setRemoveUserError(err.response.body.data));
          } else {
            errResp = dispatch(setRemoveUserError());
          }
          return errResp;
        });
    }
    return resp || dispatch(setRemoveUserError());
  };

// *** FETCH LDAP USER MAPPINGS ********************************************* //
export const setFetchLdapUserMappingsPending = () => ({
  type: SET_ACCOUNT_FETCH_LDAP_USER_MAPPINGS_PENDING,
});

export const setFetchLdapUserMappingsSuccess = resp => ({
  type: SET_ACCOUNT_FETCH_LDAP_USER_MAPPINGS_SUCCESS,
  resp,
});

export const setFetchLdapUserMappingsError = errorMsg => ({
  type: SET_ACCOUNT_FETCH_LDAP_USER_MAPPINGS_ERROR,
  errorMsg,
});

export const fetchLdapUserMappings = () => (dispatch, getState) => {
  const currState = getState();
  const isHealthy =
    currState.app &&
    currState.app.healthCheck &&
    currState.app.healthCheck.isEngineHealthy;
  const isAdmin =
    currState.auth && currState.auth.account && currState.auth.account.isAdmin;

  let resp = false;

  if (isHealthy) {
    dispatch(setFetchLdapUserMappingsPending());

    resp = request
      .get(`/service/appdb/auth/mappings`)
      .then(fetchLdapUserMappingsResp => {
        // Rather than try to patch the updated response into the
        // existing nested accounts tree on the client, we'll just
        // silently re-fetch the current accounts structure from the
        // service...
        if (isAdmin) {
          return request
            .get('/service/accounts')
            .query({ nested: true })
            .then(accountsResp =>
              Promise.all([
                dispatch(
                  setFetchLdapUserMappingsSuccess(
                    fetchLdapUserMappingsResp.body,
                  ),
                ),
                dispatch(setAccountsFetchSuccess(accountsResp.body)),
              ]),
            );
        }
        return request
          .get(
            `/service/accounts/${encodeURIComponent(
              currState.auth.account.context.accountname,
            )}`,
          )
          .then(accountResp =>
            Promise.all([
              dispatch(
                setFetchLdapUserMappingsSuccess(fetchLdapUserMappingsResp.body),
              ),
              dispatch(setAccountFetchSuccess(accountResp.body)),
            ]),
          );
      })
      .catch(err => {
        let errResp;
        if (err.response && err.response.body && err.response.body.data) {
          errResp = dispatch(
            setFetchLdapUserMappingsError(err.response.body.data),
          );
        } else {
          errResp = dispatch(setFetchLdapUserMappingsError());
        }
        return errResp;
      });
  }
  return resp || dispatch(setFetchLdapUserMappingsError());
};

// *** ADD LDAP USER MAPPING ************************************************ //
export const setAddLdapUserMappingPending = () => ({
  type: SET_ACCOUNT_ADD_LDAP_USER_MAPPING_PENDING,
});

export const setAddLdapUserMappingSuccess = resp => ({
  type: SET_ACCOUNT_ADD_LDAP_USER_MAPPING_SUCCESS,
  resp,
});

export const setAddLdapUserMappingError = errorMsg => ({
  type: SET_ACCOUNT_ADD_LDAP_USER_MAPPING_ERROR,
  errorMsg,
});

export const addLdapUserMapping =
  (props = {}) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy =
      currState.app &&
      currState.app.healthCheck &&
      currState.app.healthCheck.isEngineHealthy;
    const isAdmin =
      currState.auth &&
      currState.auth.account &&
      currState.auth.account.isAdmin;

    let resp = false;

    if (
      isHealthy &&
      // Bit more checking here than usual in order to ensure the route gets
      // composed correctly
      typeof props === 'object' &&
      typeof props.account === 'string' &&
      typeof props.username === 'string' &&
      typeof props.filter === 'string'
    ) {
      dispatch(setAddLdapUserMappingPending());

      resp = request
        .post(
          `/service/accounts/${encodeURIComponent(
            props.account,
          )}/users/mappings/ldap`,
        )
        .send({
          username: props.username,
          filter: props.filter,
        })
        .then(addLdapUserMappingResp => {
          // Rather than try to patch the updated response into the
          // existing nested accounts tree on the client, we'll just
          // silently re-fetch the current accounts structure from the
          // service...
          if (isAdmin) {
            return request
              .get('/service/accounts')
              .query({ nested: true })
              .then(accountsResp =>
                Promise.all([
                  dispatch(
                    setAddLdapUserMappingSuccess(addLdapUserMappingResp.body),
                  ),
                  dispatch(setAccountsFetchSuccess(accountsResp.body)),
                ]),
              );
          }
          return request
            .get(
              `/service/accounts/${encodeURIComponent(
                currState.auth.account.context.accountname,
              )}`,
            )
            .then(accountResp =>
              Promise.all([
                dispatch(
                  setAddLdapUserMappingSuccess(addLdapUserMappingResp.body),
                ),
                dispatch(setAccountFetchSuccess(accountResp.body)),
              ]),
            );
        })
        .catch(err => {
          let errResp;
          if (err.response && err.response.body && err.response.body.data) {
            errResp = dispatch(
              setAddLdapUserMappingError(err.response.body.data),
            );
          } else {
            errResp = dispatch(setAddLdapUserMappingError());
          }
          return errResp;
        });
    }
    return resp || dispatch(setAddLdapUserMappingError());
  };

// *** UPDATE LDAP USER MAPPING ********************************************* //
export const setUpdateLdapUserMappingPending = () => ({
  type: SET_ACCOUNT_UPDATE_LDAP_USER_MAPPING_PENDING,
});

export const setUpdateLdapUserMappingSuccess = resp => ({
  type: SET_ACCOUNT_UPDATE_LDAP_USER_MAPPING_SUCCESS,
  resp,
});

export const setUpdateLdapUserMappingError = errorMsg => ({
  type: SET_ACCOUNT_UPDATE_LDAP_USER_MAPPING_ERROR,
  errorMsg,
});

export const updateLdapUserMapping =
  (props = {}) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy =
      currState.app &&
      currState.app.healthCheck &&
      currState.app.healthCheck.isEngineHealthy;
    const isAdmin =
      currState.auth &&
      currState.auth.account &&
      currState.auth.account.isAdmin;

    let resp = false;

    if (
      isHealthy &&
      // Bit more checking here than usual in order to ensure the route gets
      // composed correctly
      typeof props === 'object' &&
      typeof props.account === 'string' &&
      typeof props.username === 'string' &&
      typeof props.filter === 'string'
    ) {
      dispatch(setUpdateLdapUserMappingPending());

      resp = request
        .put(
          `/service/accounts/${encodeURIComponent(
            props.account,
          )}/users/mappings/ldap/${encodeURIComponent(props.username)}`,
        )
        .send({ filter: props.filter })
        .then(updateLdapUserMappingResp => {
          // Rather than try to patch the updated response into the
          // existing nested accounts tree on the client, we'll just
          // silently re-fetch the current accounts structure from the
          // service...
          if (isAdmin) {
            return request
              .get('/service/accounts')
              .query({ nested: true })
              .then(accountsResp =>
                Promise.all([
                  dispatch(
                    setUpdateLdapUserMappingSuccess(
                      updateLdapUserMappingResp.body,
                    ),
                  ),
                  dispatch(setAccountsFetchSuccess(accountsResp.body)),
                ]),
              );
          }
          return request
            .get(
              `/service/accounts/${encodeURIComponent(
                currState.auth.account.context.accountname,
              )}`,
            )
            .then(accountResp =>
              Promise.all([
                dispatch(
                  setUpdateLdapUserMappingSuccess(
                    updateLdapUserMappingResp.body,
                  ),
                ),
                dispatch(setAccountFetchSuccess(accountResp.body)),
              ]),
            );
        })
        .catch(err => {
          let errResp;
          if (err.response && err.response.body && err.response.body.data) {
            errResp = dispatch(
              setUpdateLdapUserMappingError(err.response.body.data),
            );
          } else {
            errResp = dispatch(setUpdateLdapUserMappingError());
          }
          return errResp;
        });
    }
    return resp || dispatch(setUpdateLdapUserMappingError());
  };

// *** REORDER LDAP USER MAPPINGS ******************************************* //
export const setReorderLdapUserMappingsPending = () => ({
  type: SET_ACCOUNT_REORDER_LDAP_USER_MAPPINGS_PENDING,
});

export const setReorderLdapUserMappingsSuccess = resp => ({
  type: SET_ACCOUNT_REORDER_LDAP_USER_MAPPINGS_SUCCESS,
  resp,
});

export const setReorderLdapUserMappingsError = errorMsg => ({
  type: SET_ACCOUNT_REORDER_LDAP_USER_MAPPINGS_ERROR,
  errorMsg,
});

export const reorderLdapUserMappings =
  (props = {}) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy =
      currState.app &&
      currState.app.healthCheck &&
      currState.app.healthCheck.isEngineHealthy;
    const isAdmin =
      currState.auth &&
      currState.auth.account &&
      currState.auth.account.isAdmin;

    let resp = false;

    if (
      isHealthy &&
      typeof props === 'object' &&
      typeof props.oldIndex === 'number' &&
      typeof props.newIndex === 'number'
    ) {
      dispatch(setReorderLdapUserMappingsPending());

      resp = request
        .post(`/service/accounts/mappings/ldap/reorder`)
        .send({ oldIndex: props.oldIndex, newIndex: props.newIndex })
        .then(reorderLdapUserMappingsResp => {
          // Rather than try to patch the updated response into the
          // existing nested accounts tree on the client, we'll just
          // silently re-fetch the current accounts structure from the
          // service...
          if (isAdmin) {
            return request
              .get('/service/accounts')
              .query({ nested: true })
              .then(accountsResp =>
                Promise.all([
                  dispatch(
                    setReorderLdapUserMappingsSuccess(
                      reorderLdapUserMappingsResp.body,
                    ),
                  ),
                  dispatch(setAccountsFetchSuccess(accountsResp.body)),
                ]),
              );
          }
          return request
            .get(
              `/service/accounts/${encodeURIComponent(
                currState.auth.account.context.accountname,
              )}`,
            )
            .then(accountResp =>
              Promise.all([
                dispatch(
                  setUpdateLdapUserMappingSuccess(
                    reorderLdapUserMappingsResp.body,
                  ),
                ),
                dispatch(setAccountFetchSuccess(accountResp.body)),
              ]),
            );
        })
        .catch(err => {
          let errResp;
          if (err.response && err.response.body && err.response.body.data) {
            errResp = dispatch(
              setReorderLdapUserMappingsError(err.response.body.data),
            );
          } else {
            errResp = dispatch(setReorderLdapUserMappingsError());
          }
          return errResp;
        });
    }
    return resp || dispatch(setReorderLdapUserMappingsError());
  };

// *** TEST LDAP CONFIG ***************************************************** //
export const setTestLdapUserMappingsPending = () => ({
  type: SET_ACCOUNT_TEST_LDAP_USER_MAPPINGS_PENDING,
});

export const setTestLdapUserMappingsSuccess = resp => ({
  type: SET_ACCOUNT_TEST_LDAP_USER_MAPPINGS_SUCCESS,
  resp,
});

export const setTestLdapUserMappingsError = errorMsg => ({
  type: SET_ACCOUNT_TEST_LDAP_USER_MAPPINGS_ERROR,
  errorMsg,
});

export const testLdapUserMappings =
  (props = { username: '' }) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy =
      currState.app &&
      currState.app.healthCheck &&
      currState.app.healthCheck.isEngineHealthy;

    let resp = false;

    if (isHealthy) {
      dispatch(setTestLdapUserMappingsPending());
      resp = request
        .post(`/service/accounts/mappings/ldap/test`)
        .send({ username: props.username })
        .then(setTestLdapUserMappingsResp =>
          dispatch(
            setTestLdapUserMappingsSuccess(setTestLdapUserMappingsResp.body),
          ),
        )
        .catch(err => {
          let errResp;
          if (err.response && err.response.body && err.response.body.data) {
            errResp = dispatch(
              setTestLdapUserMappingsError(err.response.body.data),
            );
          } else {
            errResp = dispatch(setTestLdapUserMappingsError());
          }
          return errResp;
        });
    }
    return resp || dispatch(setTestLdapUserMappingsError());
  };

// *** REMOVE USER ********************************************************** //
export const setRemoveLdapUserMappingPending = () => ({
  type: SET_ACCOUNT_REMOVE_LDAP_USER_MAPPING_PENDING,
});

export const setRemoveLdapUserMappingSuccess = resp => ({
  type: SET_ACCOUNT_REMOVE_LDAP_USER_MAPPING_SUCCESS,
  resp,
});

export const setRemoveLdapUserMappingError = errorMsg => ({
  type: SET_ACCOUNT_REMOVE_LDAP_USER_MAPPING_ERROR,
  errorMsg,
});

export const removeLdapUserMapping =
  (props = {}) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy =
      currState.app &&
      currState.app.healthCheck &&
      currState.app.healthCheck.isEngineHealthy;
    const isAdmin =
      currState.auth &&
      currState.auth.account &&
      currState.auth.account.isAdmin;

    let resp = false;

    if (
      isHealthy &&
      // Bit more checking here than usual in order to ensure the route gets
      // composed correctly
      typeof props === 'object' &&
      typeof props.account === 'string' &&
      typeof props.username === 'string'
    ) {
      dispatch(setRemoveLdapUserMappingPending());

      resp = request
        .delete(
          `/service/accounts/${encodeURIComponent(
            props.account,
          )}/users/mappings/ldap/${encodeURIComponent(props.username)}`,
        )
        .then(removeLdapUserMappingResp => {
          // Rather than try to patch the updated response into the
          // existing nested accounts tree on the client, we'll just
          // silently re-fetch the current accounts structure from the
          // service...
          if (isAdmin) {
            return request
              .get('/service/accounts')
              .query({ nested: true })
              .then(accountsResp =>
                Promise.all([
                  dispatch(
                    setRemoveLdapUserMappingSuccess(
                      removeLdapUserMappingResp.body,
                    ),
                  ),
                  dispatch(setAccountsFetchSuccess(accountsResp.body)),
                ]),
              );
          }
          return request
            .get(
              `/service/accounts/${encodeURIComponent(
                currState.auth.account.context.accountname,
              )}`,
            )
            .then(accountResp =>
              Promise.all([
                dispatch(
                  setRemoveLdapUserMappingSuccess(
                    removeLdapUserMappingResp.body,
                  ),
                ),
                dispatch(setAccountFetchSuccess(accountResp.body)),
              ]),
            );
        })
        .catch(err => {
          let errResp;
          if (err.response && err.response.body && err.response.body.data) {
            errResp = dispatch(
              setRemoveLdapUserMappingError(err.response.body.data),
            );
          } else {
            errResp = dispatch(setRemoveLdapUserMappingError());
          }
          return errResp;
        });
    }
    return resp || dispatch(setRemoveLdapUserMappingError());
  };

// *** UPDATE USER CREDENTIALS ********************************************** //
export const setUpdateUserCredsPending = () => ({
  type: SET_ACCOUNT_UPDATE_USER_CREDENTIALS_PENDING,
});

export const setUpdateUserCredsSuccess = resp => ({
  type: SET_ACCOUNT_UPDATE_USER_CREDENTIALS_SUCCESS,
  resp,
});

export const setUpdateUserCredsError = errorMsg => ({
  type: SET_ACCOUNT_UPDATE_USER_CREDENTIALS_ERROR,
  errorMsg,
});

export const updateUserCreds =
  (props = {}) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy =
      currState.app &&
      currState.app.healthCheck &&
      currState.app.healthCheck.isEngineHealthy;

    let resp = false;

    if (
      isHealthy &&
      // Bit more checking here than usual in order to ensure the route gets
      // composed correctly
      typeof props === 'object' &&
      typeof props.account === 'string' &&
      typeof props.user === 'string' &&
      typeof props.type === 'string' &&
      typeof props.newpass === 'string'
    ) {
      dispatch(setUpdateUserCredsPending());

      resp = request
        .post(
          `/service/accounts/${encodeURIComponent(
            props.account,
          )}/users/${encodeURIComponent(props.user)}/credentials`,
        )
        .send({
          type: props.type,
          newpass: props.newpass,
        })
        .then(
          accountResp => dispatch(setUpdateUserCredsSuccess(accountResp.body)),
          // Rather than try to patch the updated response into the
          // existing nested accounts tree on the client, we'll just
          // silently re-fetch the current accounts structure from the
          // service...
          // request
          //   .get('/service/accounts')
          //   .query({ nested: true })
          //   .then(accountsResp =>
          //     Promise.all([
          //       dispatch(setUpdateUserCredsSuccess(accountResp.body)),
          //       dispatch(setAccountsFetchSuccess(accountsResp.body)),
          //     ]),
          //   ),
        )
        .catch(err => {
          let errResp;
          if (err.response && err.response.body && err.response.body.data) {
            errResp = dispatch(setUpdateUserCredsError(err.response.body.data));
          } else {
            errResp = dispatch(setUpdateUserCredsError());
          }
          return errResp;
        });
    }
    return resp || dispatch(setUpdateUserCredsError());
  };

// *** FETCH ACCOUNT ROLES, PER USER **************************************** //
export const setAccountsUserRolesFetchPending = () => ({
  type: SET_ACCOUNTS_USER_ROLES_FETCH_PENDING,
});

export const setAccountsUserRolesFetchSuccess = resp => ({
  type: SET_ACCOUNTS_USER_ROLES_FETCH_SUCCESS,
  resp,
});

export const setAccountsUserRolesFetchError = errorMsg => ({
  type: SET_ACCOUNTS_USER_ROLES_FETCH_ERROR,
  errorMsg,
});

export const fetchAccountsUserRoles =
  (props = {}) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy =
      currState.app &&
      currState.app.healthCheck &&
      currState.app.healthCheck.isEngineHealthy;

    let resp = false;

    if (
      isHealthy &&
      typeof props === 'object' &&
      typeof props.user === 'string'
    ) {
      dispatch(setAccountsUserRolesFetchPending());
      resp = request
        .get(`/service/rbac/roles/users/${encodeURIComponent(props.user)}`)
        .then(fetchAccountsUserRolesResp =>
          dispatch(
            setAccountsUserRolesFetchSuccess(fetchAccountsUserRolesResp.body),
          ),
        )
        .catch(err => {
          let errResp;
          if (err.response && err.response.body && err.response.body.data) {
            errResp = dispatch(
              setAccountsUserRolesFetchError(err.response.body.data),
            );
          } else {
            errResp = dispatch(setAccountsUserRolesFetchError());
          }
          return errResp;
        });
    }
    return resp || dispatch(setAccountsUserRolesFetchError());
  };

// *** FETCH ROLES ********************************************************** //
export const setRolesFetchPending = () => ({
  type: SET_ROLES_FETCH_PENDING,
});

export const setRolesFetchSuccess = resp => ({
  type: SET_ROLES_FETCH_SUCCESS,
  resp,
});

export const setRolesFetchError = errorMsg => ({
  type: SET_ROLES_FETCH_ERROR,
  errorMsg,
});

export const fetchRoles =
  (props = {}) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy =
      currState.app &&
      currState.app.healthCheck &&
      currState.app.healthCheck.isEngineHealthy;

    let resp = false;

    if (
      isHealthy &&
      typeof props === 'object' &&
      typeof props.account === 'string'
    ) {
      dispatch(setRolesFetchPending());
      resp = request
        .get(`/service/rbac/roles`)
        .query(props)
        .then(fetchRolesResp =>
          dispatch(setRolesFetchSuccess(fetchRolesResp.body)),
        )
        .catch(err => {
          let errResp;
          if (err.response && err.response.body && err.response.body.data) {
            errResp = dispatch(setRolesFetchError(err.response.body.data));
          } else {
            errResp = dispatch(setRolesFetchError());
          }
          return errResp;
        });
    }
    return resp || dispatch(setRolesFetchError());
  };

// *** UPDATE ROLE MEMBERS ************************************************** //
export const setUpdateRolesMembersPending = () => ({
  type: SET_ROLES_UPDATE_MEMBERS_PENDING,
});

export const setUpdateRolesMembersSuccess = resp => ({
  type: SET_ROLES_UPDATE_MEMBERS_SUCCESS,
  resp,
});

export const setUpdateRolesMembersError = errorMsg => ({
  type: SET_ROLES_UPDATE_MEMBERS_ERROR,
  errorMsg,
});

export const updateRolesMembers =
  (props = {}, params = {}) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy =
      currState.app &&
      currState.app.healthCheck &&
      currState.app.healthCheck.isEngineHealthy;

    let resp = false;

    if (
      isHealthy &&
      // Ensure route gets composed correctly
      typeof props === 'object' &&
      typeof props.account === 'string' &&
      typeof props.user === 'string'
    ) {
      dispatch(setUpdateRolesMembersPending());
      resp = request
        .put(
          `/service/rbac/accounts/${encodeURIComponent(
            props.account,
          )}/users/${encodeURIComponent(props.user)}/roles`,
        )
        .set('Content-Type', 'application/json')
        .send(params)
        .then(updateRolesMembersResp =>
          dispatch(setUpdateRolesMembersSuccess(updateRolesMembersResp.body)),
        )
        .catch(err => {
          let errResp;
          if (err.response && err.response.body && err.response.body.data) {
            errResp = dispatch(
              setUpdateRolesMembersError(err.response.body.data),
            );
          } else {
            errResp = dispatch(setUpdateRolesMembersError());
          }
          return errResp;
        });
    }
    return resp || dispatch(setUpdateRolesMembersError());
  };

// *** FETCH SAML IDPS ****************************************************** //
export const setSamlIdpsFetchPending = () => ({
  type: SET_SAML_IDPS_FETCH_PENDING,
});

export const setSamlIdpsFetchSuccess = resp => ({
  type: SET_SAML_IDPS_FETCH_SUCCESS,
  resp,
});

export const setSamlIdpsFetchError = errorMsg => ({
  type: SET_SAML_IDPS_FETCH_ERROR,
  errorMsg,
});

export const fetchSamlIdps = () => (dispatch, getState) => {
  const currState = getState();
  const isHealthy =
    currState.app &&
    currState.app.healthCheck &&
    currState.app.healthCheck.isEngineHealthy;

  let resp = false;

  if (isHealthy) {
    dispatch(setSamlIdpsFetchPending());
    resp = request
      .get(`/service/saml/idps`)
      .then(fetchSamlIdpsResp =>
        dispatch(setSamlIdpsFetchSuccess(fetchSamlIdpsResp.body)),
      )
      .catch(err => {
        let errResp;
        if (err.response && err.response.body && err.response.body.data) {
          errResp = dispatch(setSamlIdpsFetchError(err.response.body.data));
        } else {
          errResp = dispatch(setSamlIdpsFetchError());
        }
        return errResp;
      });
  }
  return resp || dispatch(setSamlIdpsFetchError());
};

// *** ADD SAML IDP ********************************************************* //
export const setSamlIdpAddPending = () => ({
  type: SET_SAML_IDP_ADD_PENDING,
});

export const setSamlIdpAddSuccess = resp => ({
  type: SET_SAML_IDP_ADD_SUCCESS,
  resp,
});

export const setSamlIdpAddError = errorMsg => ({
  type: SET_SAML_IDP_ADD_ERROR,
  errorMsg,
});

export const clearSamlIdpAddError = () => ({
  type: CLEAR_SAML_IDP_ADD_ERROR,
});

export const addSamlIdp = props => (dispatch, getState) => {
  const currState = getState();
  const isHealthy =
    currState.app &&
    currState.app.healthCheck &&
    currState.app.healthCheck.isEngineHealthy;

  let resp = false;

  if (isHealthy) {
    dispatch(setSamlIdpAddPending());
    resp = request
      .post(`/service/saml/idps`)
      .set('Content-Type', 'application/json')
      .send(props)
      .then(addNewSamlIdpResp =>
        dispatch(setSamlIdpAddSuccess(addNewSamlIdpResp.body)),
      )
      .catch(err => {
        let errResp;
        if (err.response && err.response.body && err.response.body.data) {
          errResp = dispatch(setSamlIdpAddError(err.response.body.data));
        } else {
          errResp = dispatch(setSamlIdpAddError());
        }
        return errResp;
      });
  }

  return resp || dispatch(setSamlIdpAddError());
};

// *** UPDATE SAML IDP ****************************************************** //
export const setSamlIdpUpdatePending = () => ({
  type: SET_SAML_IDP_UPDATE_PENDING,
});

export const setSamlIdpUpdateSuccess = resp => ({
  type: SET_SAML_IDP_UPDATE_SUCCESS,
  resp,
});

export const setSamlIdpUpdateError = errorMsg => ({
  type: SET_SAML_IDP_UPDATE_ERROR,
  errorMsg,
});

export const clearSamlIdpUpdateError = () => ({
  type: CLEAR_SAML_IDP_UPDATE_ERROR,
});

export const updateSamlIdp = props => (dispatch, getState) => {
  const currState = getState();
  const isHealthy =
    currState.app &&
    currState.app.healthCheck &&
    currState.app.healthCheck.isEngineHealthy;

  let resp = false;

  if (isHealthy) {
    dispatch(setSamlIdpUpdatePending());
    resp = request
      .put(`/service/saml/idps/${props.name}`)
      .set('Content-Type', 'application/json')
      .send(props)
      .then(updateSamlIdpResp =>
        dispatch(setSamlIdpUpdateSuccess(updateSamlIdpResp.body)),
      )
      .catch(err => {
        let errResp;
        if (err.response && err.response.body && err.response.body.data) {
          errResp = dispatch(setSamlIdpUpdateError(err.response.body.data));
        } else {
          errResp = dispatch(setSamlIdpUpdateError());
        }
        return errResp;
      });
  }

  return resp || dispatch(setSamlIdpUpdateError());
};

// *** REMOVE SAML IDP ****************************************************** //
export const setSamlIdpRemovePending = () => ({
  type: SET_SAML_IDP_REMOVE_PENDING,
});

export const setSamlIdpRemoveSuccess = resp => ({
  type: SET_SAML_IDP_REMOVE_SUCCESS,
  resp,
});

export const setSamlIdpRemoveError = errorMsg => ({
  type: SET_SAML_IDP_REMOVE_ERROR,
  errorMsg,
});

export const clearSamlIdpRemoveError = () => ({
  type: CLEAR_SAML_IDP_REMOVE_ERROR,
});

export const removeSamlIdp = props => (dispatch, getState) => {
  const currState = getState();
  const isHealthy =
    currState.app &&
    currState.app.healthCheck &&
    currState.app.healthCheck.isEngineHealthy;

  let resp = false;

  if (isHealthy) {
    dispatch(setSamlIdpRemovePending());
    resp = request
      .delete(`/service/saml/idps/${props.name}`)
      .then(removeSamlIdpResp =>
        dispatch(setSamlIdpRemoveSuccess(removeSamlIdpResp.body)),
      )
      .catch(err => {
        let errResp;
        if (err.response && err.response.body && err.response.body.data) {
          errResp = dispatch(setSamlIdpRemoveError(err.response.body.data));
        } else {
          errResp = dispatch(setSamlIdpRemoveError());
        }
        return errResp;
      });
  }
  return resp || dispatch(setSamlIdpRemoveError());
};

// *** SAML IDPS VIEW / DIALOGS ********************************************* //
export const setAddSamlIdpDialog = open => ({
  type: SET_ADD_SAML_IDP_DIALOG,
  open,
});

export const setUpdateSamlIdpDialog = open => ({
  type: SET_UPDATE_SAML_IDP_DIALOG,
  open,
});

export const setRemoveSamlIdpDialog = open => ({
  type: SET_REMOVE_SAML_IDP_DIALOG,
  open,
});
