import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import type { CreateEntityActionOptions } from './createEntityAction';
import getEntityObject from './getEntityObject';

const createEntityReducers = <State, Actions>(
  entities: CreateEntityActionOptions<Actions>,
  builder: ActionReducerMapBuilder<State>,
) => {
  Object.keys(entities).forEach(entity => {
    const { thunk, stateKey } = entities[entity as keyof Actions];

    builder
      .addCase(thunk.pending, state => {
        const [base, key] = getEntityObject(state, stateKey);
        base[key].isLoading = true;
      })
      .addCase(thunk.fulfilled, (state, action) => {
        const [base, key] = getEntityObject(state, stateKey);
        base[key] = {
          isLoading: false,
          isUpdating: false,
          ...action.payload.body,
        };
      })
      .addCase(thunk.rejected, (state, action) => {
        const [base, key] = getEntityObject(state, stateKey);
        base[key] = {
          ...base[key],
          isLoading: false,
          isUpdating: false,
          error: action.payload || action.error,
        };
      });
  });
};

export default createEntityReducers;
