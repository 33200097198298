import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { cloneElement, isValidElement } from 'react';
import { toast } from 'react-toastify';
import { Message, Icon, Button } from 'semantic-ui-react';

import s from './Toast.scss'; // eslint-disable-line postcss-modules/no-unused-class

// Default FA icon names for each toast severity level
const levelIcons = {
  SUCCESS: 'thumbs up',
  INFO: 'info circle',
  WARNING: 'warning circle',
  ERROR: 'warning sign',
};

// Create a default close button
const CloseButton = ({ closeToast }) => (
  <Button icon onClick={closeToast} className={s.anchoreToastCloseBtn}>
    <Icon name="times" />
  </Button>
);

CloseButton.propTypes = {
  closeToast: PropTypes.func,
};

CloseButton.defaultProps = {
  closeToast: undefined,
};

const AnchoreToastWrapper = ({ children }) => <>{cloneElement(children)}</>;

// Apply isomorphic styles to the HOC
const AnchoreToastWrapperStyled = withStyles(s)(AnchoreToastWrapper);

/**
 * Example:
 *
 *   fn({
 *     message   :  'Message ...', // Required - must be a string of greater than 0-length, a React element, or
 *                                 //            an array of strings / React elements, otherwise the operation
 *                                 //            will not raise a toast and will return `false`
 *     dismissAll : true           // Optional - clear any currently visible toasts before displaying this one
 *                                 //            and defaults to `false`
 *     title      : 'Goodbye!',    // Optional - must be a string of greater than 0-length or a React element
 *     level      : 'error',       // Optional - can be `default`, `success`, `info`, `warning`, or `error`
 *                                 //            and defaults to `default`
 *     autoClose  : 8000,          // Optional - number of milliseconds or `false` (defaults to `false`)
 *     icon       : 'sign out'     // Optional - FontAwesome class name. If not provided the toast will use
 *                                 //            an associated severity icon if a valid `level` other than
 *                                 //            `default` is provided. Passing `false` will force no icon
 *                                 //            in all cases.
 *   });
 *
 * The above function will return the ID of the toast, or `false` if the operation was unsuccessful.
 *
 * If, instead of a props object, `false` is passed as a parameter, all currently visible toasts will be
 * cleared.
 *
 * Note that all the other <ToastContainer> API props can be set in the config object. However, apart from
 * `closeButton` and the properties shown in the above example, none of them are overridden with different
 * defaults.
 *
 */
export default (d = {}) => {
  const props = d;
  let toastId = false;

  // Check to see that a non 0-length string or a React element was provided
  // for the message payload
  if (
    typeof props === 'object' &&
    (!props.toastId ||
      (props.toastId &&
        (!toast.isActive(props.toastId) || props.dismissAll))) &&
    ((typeof props.message === 'string' && props.message.trim().length) ||
      (props.message instanceof Array &&
        props.message.length &&
        props.message.every(
          item =>
            (typeof item === 'string' && item.trim().length) ||
            isValidElement(item),
        )) ||
      isValidElement(props.message))
  ) {
    // Assign a severity level to this toast (or assign `default` if no valid
    // level was provided)
    const level =
      toast.TYPE[
        typeof props.level === 'string' ? props.level.toUpperCase() : 'DEFAULT'
      ] || 'default';

    // Message titles are optional, but if present must be a string or React element
    let title = false;
    if (
      (typeof props.title === 'string' && props.title.trim().length) ||
      isValidElement(props.title)
    ) {
      title = props.title;
    }

    // Dismisses all currently displayed toasts before displaying this one
    if (props.dismissAll === true) {
      toast.dismiss();
    }

    // Set the string that holds the icon class (or don't show an icon at all
    // if `props.icon` is `false`)
    let iconStr = false;
    if (typeof props.icon === 'string' && props.icon.trim().length) {
      iconStr = props.icon;
    } else if (level !== 'default' && props.icon !== false) {
      iconStr = levelIcons[level.toUpperCase()];
    }

    // In the absence of specific valid properties within the `props` object,
    // assign some custom default values...
    // Toast `type` should be `default` if no valid level was provided
    props.type = level;

    // By default, don't automatically close the toast after a countdown
    props.autoClose =
      typeof props.autoClose === 'number' ? props.autoClose : false;

    // Assign both a general and severity-based custom CSS classes to a list
    // that can be applied to the container element
    const anchoreToastClasses = [s.anchoreToast, s[`anchoreToast_${level}`]];

    if (props.autoClose) {
      // Push an additional class that indicates the presence of a progress bar
      // into the container list
      anchoreToastClasses.push(s.anchoreToastProgress);
      // Add a custom class to the progress bar control itself
      props.progressClassName = s.anchoreToastProgressBar;
    }
    props.className = classNames.apply(this, anchoreToastClasses);

    // Use a nicer default close button (if one isn't provided by the props)
    if (!isValidElement(props.closeButton)) {
      props.closeButton = <CloseButton />;
    }

    // If the message is an array, ensure that any list items that are React elements
    // get placed inside a <Message.Item /> element
    if (props.message instanceof Array) {
      const date = new Date();
      props.message = props.message.map(item =>
        isValidElement(item) ? (
          <Message.Item key={`toast_item_${date.getTime()}`}>
            {item}
          </Message.Item>
        ) : (
          item
        ),
      );
    }

    // Invoke Toastify's `toast` function - this will also return the unique ID for
    // this toast
    toastId = toast(
      <AnchoreToastWrapperStyled>
        <Message icon className={classNames(s.anchoreToastMessage, 'toast')}>
          <Message.Content>
            {title ? (
              <Message.Header className={s.anchoreToastTitle}>
                {title}
              </Message.Header>
            ) : null}
            {props.message instanceof Array ? (
              <Message.List
                items={props.message}
                className={s.anchoreToastList}
              />
            ) : (
              props.message
            )}
          </Message.Content>
        </Message>
      </AnchoreToastWrapperStyled>,
      {
        ...props,
        ...{
          icon: iconStr ? (
            <Icon
              name={iconStr}
              size="big"
              className={s.anchoreToastMessageIcon}
            />
          ) : null,
        },
        ...{ theme: window?.App.darkReader?.isEnabled() ? 'dark' : 'light' },
      },
    );
  } else if (d === false) {
    toast.dismiss();
  }
  return toastId;
};

AnchoreToastWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
