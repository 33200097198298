import type { Reducer, AnyAction } from 'redux';

import { SET_DOWNLOAD_IN_PROGRESS } from '../actionTypes';

export interface DownloadState {
  inProgress: Record<string, true>;
}

export const defaultState: DownloadState = {
  inProgress: {},
};

const reducer: Reducer<DownloadState> = (
  state = defaultState,
  action: AnyAction,
) => {
  const key = `${action.downloadTypeId}_${action.id}`;
  switch (action.type) {
    case SET_DOWNLOAD_IN_PROGRESS:
      // eslint-disable-next-line no-case-declarations
      let downloads = state.inProgress;
      if (action.value) {
        downloads[key] = true;
      } else {
        const { [key]: remove, ...rest } = downloads;
        downloads = rest;
      }
      return {
        ...state,
        inProgress: downloads,
      };

    default:
      return state;
  }
};

export default reducer;
