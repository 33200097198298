import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import {
  Button,
  Divider,
  Form,
  Icon,
  Input,
  Popup,
  Segment,
  TextArea,
} from 'semantic-ui-react';

import type { PostAccountUserApiKeysBody } from '@models';
import { createApiKey } from '@redux/accounts.slice';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { hideModal, selectActiveModalProps } from '@redux/modals.slice';
import CancelButton from '@shared/components/CancelButton';
import CopyButton from '@shared/components/CopyButton';
import ErrMessage from '@shared/components/ErrMessage';
import Modal from '@shared/components/Modal';

// eslint-disable-next-line postcss-modules/no-unused-class
import s from './NewApiKeyModal.scss';

const NewApiKeyModal = () => {
  const dispatch = useAppDispatch();
  const modalProps = useAppSelector(selectActiveModalProps('newApiKey'));
  const {
    data: apiKeysData,
    isUpdating,
    error,
  } = useAppSelector(state => state.accounts.apiKeys);
  const modalActive = !!modalProps;
  const [newKeyData, setNewKeyData] = useState<PostAccountUserApiKeysBody>({
    name: '',
    description: '',
    expires_at: moment().add(6, 'month').utc().format(),
  });

  // This flag indicates whether the key has been successfully saved
  // This is used to disable all editing so the user can copy the key value
  // before closing the modal
  const [keySaved, setKeySaved] = useState(false);
  const [keyValue, setKeyValue] = useState('');

  const today = moment().toDate();

  // Check whether name is unique
  const isKeyNameUnique = useMemo(() => {
    // Don't display error after saving
    if (apiKeysData && newKeyData.name && !keySaved) {
      return !Object.keys(apiKeysData)
        .map(key => apiKeysData[key])
        .find(
          t =>
            t.name.trim().toLowerCase() ===
            newKeyData.name.trim().toLowerCase(),
        );
    }
    return true;
  }, [apiKeysData, newKeyData.name, keySaved]);

  // Are all the required fields provided and valid?
  const isFormValid =
    newKeyData.expires_at && isKeyNameUnique && newKeyData.name;

  // Clear local state when the modal closes
  useEffect(() => {
    if (!modalProps) {
      setNewKeyData({
        name: '',
        description: '',
        expires_at: moment().add(6, 'month').utc().format(),
      });
      setKeySaved(false);
    }
  }, [modalProps]);

  // Save the API key to the backend
  const saveApiKey = useCallback(() => {
    if (modalProps && isFormValid && !isUpdating) {
      dispatch(
        createApiKey({
          account: modalProps.account,
          user: modalProps.userName,
          body: newKeyData,
        }),
      )
        .unwrap()
        .then(response => {
          setKeySaved(true);
          setKeyValue((response.body.data as any).value);
        })
        .catch(() => {
          // Do nothing, because the error should already have been displayed inline
        });
    }
  }, [isFormValid, isUpdating, modalProps, newKeyData]);

  if (!modalActive) return null;

  return (
    <Modal id="newApiKey" size="small" aria-label="New API Key Modal">
      <Modal.Header role="heading" aria-level={1}>
        <Icon.Group>
          <Icon color="teal" name="key" />
        </Icon.Group>
        &nbsp;Create New API Key
      </Modal.Header>
      <Modal.Content className={s.modalContent}>
        {error && <ErrMessage error message={error.message} />}
        <p>
          To add a new API key, enter a unique name and provide an expiry date,
          then click <strong>Save</strong>.
        </p>
        <Form onSubmit={saveApiKey} style={{ marginBottom: '1em' }}>
          <Popup
            open={!isKeyNameUnique}
            content="An API key with this name already exists—please enter a different name"
            position="bottom center"
            className={classNames(
              s.errorPopup,
              'animate__animated animate__fadeIn',
            )}
            trigger={
              <Form.Field
                control={Input}
                disabled={keySaved}
                autoFocus
                required
                value={newKeyData.name || ''}
                label="Name"
                name="Name"
                spellCheck={false}
                error={!isKeyNameUnique}
                id="new-key-name"
                autoComplete="off"
                placeholder="API Key name (max 50 characters)"
                maxLength={50}
                onChange={(ev, d) =>
                  !keySaved &&
                  setNewKeyData({
                    ...newKeyData,
                    name: d.value,
                  })
                }
              />
            }
          />
          <Form.Field
            id="new-key-description"
            disabled={keySaved}
            control={TextArea}
            label="Description"
            name="Description"
            placeholder="Add some information about your new API key (max 250 characters)"
            value={newKeyData.description || ''}
            type="text"
            maxLength={250}
            autoComplete="off"
            onChange={(ev, { value }) =>
              typeof value === 'string' &&
              !keySaved &&
              setNewKeyData({
                ...newKeyData,
                description: value,
              })
            }
          />
        </Form>
        <Form>
          <Form.Field disabled={keySaved} required>
            <label htmlFor="new-key-expiry">Expiry Date</label>
            <ReactDatePicker
              id="new-key-expiry"
              minDate={today}
              required
              placeholderText="Expiry date"
              disabled={keySaved}
              selected={
                newKeyData.expires_at
                  ? moment(newKeyData.expires_at).toDate()
                  : null
              }
              dateFormat="EEE MMM dd yyyy"
              onChange={value =>
                !keySaved &&
                setNewKeyData({
                  ...newKeyData,
                  expires_at: value ? moment(value).utc().format() : '',
                })
              }
            />
          </Form.Field>
          {keySaved ? (
            <>
              <Divider className={s.divider} />
              <Form.Field>
                <label htmlFor="new-key-value">Key value</label>
                <p className={s.keyValueMessage}>
                  Please be sure to save this key value now, as you won&apos;t
                  be able to access it later. You can click{' '}
                  <strong>Copy</strong> to copy it to your clipboard.
                </p>
                <Segment
                  id="new-key-value"
                  className={s.noBoxShadow}
                  style={{ paddingTop: '0' }}
                >
                  <CopyButton
                    corner
                    content={keyValue || ''}
                    message="API key value was successfully copied to the clipboard"
                  />
                  <pre className={s.keyValue}>
                    <code>{keyValue || ''}</code>
                  </pre>
                </Segment>
              </Form.Field>
            </>
          ) : null}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {keySaved ? (
          <Button
            color="blue"
            onClick={() => {
              dispatch(hideModal('newApiKey'));
            }}
          >
            <Icon name="times" /> Close
          </Button>
        ) : (
          <>
            <CancelButton
              disabled={isUpdating}
              onClick={() => dispatch(hideModal('newApiKey'))}
            />
            <Button
              type="submit"
              color="green"
              disabled={!isFormValid || isUpdating}
              loading={isUpdating}
              onClick={saveApiKey}
            >
              <Icon name="checkmark" /> Save
            </Button>
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
};
export default withStyles(s)(NewApiKeyModal);
