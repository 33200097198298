// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uwnWz{background-color:unset!important}.uwnWz .xQMDg,.uwnWz .l6iP_,.uwnWz .iyQfI{background-color:#1c1e1f!important}.uwnWz .xQMDg{border-top-color:#93897926!important}.uwnWz .YBxfW,.uwnWz .AzkSN{color:#ffffffde!important}.uwnWz .iyQfI{color:#ffffffd9!important;font-size:26.4px!important;font-size:1.65rem!important}.uwnWz .AzkSN{font-size:19.2px!important;font-size:1.2rem!important;margin-bottom:1.5rem!important}.uwnWz .YBxfW{font-size:17.6px!important;font-size:1.1rem!important}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `uwnWz`,
	"actions": `xQMDg`,
	"content": `l6iP_`,
	"title": `iyQfI`,
	"text": `YBxfW`,
	"textHeader": `AzkSN`
};
module.exports = ___CSS_LOADER_EXPORT___;
