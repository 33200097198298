import {
  SET_SWAGGER_SPEC_PENDING,
  SET_SWAGGER_SPEC_SUCCESS,
  SET_SWAGGER_SPEC_ERROR,
} from '../actionTypes';

export default (
  state = {
    isFetchingSwaggerSpec: false,
    fetchSwaggerSpecError: false,
    errorMsg: false,
    data: false,
  },
  action = {},
) => {
  switch (action.type) {
    /**
     * *************************************************************************
     * SWAGGER SPEC DATA REDUCERS
     */
    case SET_SWAGGER_SPEC_PENDING:
      return {
        ...state,
        isFetchingSwaggerSpec: true,
        fetchSwaggerSpecError: false,
        errorMsg: false,
      };
    case SET_SWAGGER_SPEC_SUCCESS:
      return {
        ...state,
        isFetchingSwaggerSpec: false,
        fetchSwaggerSpecError: false,
        errorMsg: false,
        data: action.resp.data,
      };
    case SET_SWAGGER_SPEC_ERROR:
      return {
        ...state,
        isFetchingSwaggerSpec: false,
        fetchSwaggerSpecError: true,
        errorMsg: action.errorMsg,
        data: false,
      };

    default:
      return state;
  }
};
