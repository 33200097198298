import unionBy from 'lodash/unionBy';

import history from '@/history';
import { SET_ACCOUNT_UPDATE_CONTEXT_SUCCESS } from '@redux/actionTypes';
import {
  appStateReset,
  appStateUpdate,
  logout,
} from '@redux/actions/action-auth';
import { setDownloadInProgress } from '@redux/actions/action-download';
import { startVersionCheck } from '@redux/actions/action-health';
import { updateAccountContext } from '@redux/actions/action-system';
import raiseToast from '@shared/components/Toast';

const socketListenerInit = ({
  props,
  setIsLicenseGood,
  startAppConnectionCheck,
  startHealthCheck,
  store,
  isWebSocketOnly,
}) => {
  const { account } = props;

  let {
    isAppDBHealthy,
    isAuthenticated,
    isEngineHealthy,
    isEngineVersionGood,
    isReportsEnabledAndActive,
  } = props;

  // When a page unloads due to a URL-driven context switch, the socket sends a
  // context message to all shared sessions. This requires special handling
  // because the `context` socket listener should not reset the app state or
  // change the URL history during page unload, as these actions will happen
  // automatically. To handle this, the `isPageUnloading` flag is set to `true`
  // just before the page unloads. This flag tells the context listener to
  // ignore messages that originate from a URL-driven context switch. Once the
  // page is fully loaded again, the flag is reset to `false`. The event
  // listener is set to `once` to ensure the flag is reset only once per page
  // load.
  let isPageUnloading = false;
  window.addEventListener(
    'beforeunload',
    () => {
      isPageUnloading = true;
    },
    { once: true },
  );

  const sock = window.io('/', {
    transports: isWebSocketOnly ? ['websocket'] : ['polling', 'websocket'],
  });

  // Listen for socket connection and then add client to `healthcheck` room to
  // listen to the health heartbeat
  sock
    .on('connect', () => {
      sock.emit('addToRoom', 'healthcheck');

      // If the connection with the app service was severed for any reason
      // re-establishing a connection with the socket service should restore the
      // health status back to `true`.
      store.dispatch(startAppConnectionCheck(true));

      // Run initial health check after page load
      store.dispatch(
        startHealthCheck(
          isEngineHealthy,
          isReportsEnabledAndActive,
          isAppDBHealthy,
        ),
      );
    })

    // Losing contact with the app server raises the app lockout shield
    .on('connect_error', () => {
      raiseToast(false);
      store.dispatch(startAppConnectionCheck(false));
    })

    // Raise the app lockout shield if the engine / app server goes down,
    // and remove it once the connection is restored
    .on('health', (sockMsg = {}) => {
      const currState = store.getState();
      isAuthenticated = currState?.auth?.isAuthenticated;

      ({
        isAppDBHealthy,
        isEngineHealthy,
        isEngineVersionGood,
        isReportsEnabledAndActive,
      } = currState.app.healthCheck);

      // Don't dispatch a health update to Redux if the health state
      // hasn't changed
      if (isEngineHealthy !== sockMsg.engine) {
        raiseToast(false);
        store.dispatch(
          startHealthCheck(
            sockMsg.engine,
            sockMsg.reportsCheck,
            sockMsg.appDBCheck,
          ),
        );
      }

      // Version check and state synchronization
      if (isEngineVersionGood.valid !== sockMsg.versionCheck.valid) {
        // Don't dispatch a version update to Redux if the version state
        // hasn't changed
        store.dispatch(startVersionCheck(sockMsg.versionCheck));
      } else if (
        // If the service version changes in the background, sync against
        // the current version returned by the health check
        isAuthenticated &&
        isEngineVersionGood &&
        sockMsg?.versionCheck &&
        currState.auth?.engineVersion &&
        (isEngineVersionGood.engineVersion !==
          sockMsg.versionCheck.engineVersion ||
          currState.auth.engineVersion !== sockMsg.versionCheck.engineVersion)
      ) {
        if (process.env.BROWSER && window.App) {
          window.App.engineVersion = sockMsg.versionCheck.engineVersion;
          window.App.isEngineVersionGood = isEngineVersionGood;
        }

        const appCopy = { ...currState.app };
        const authCopy = { ...currState.auth };
        appCopy.healthCheck.isEngineVersionGood.engineVersion =
          sockMsg.versionCheck.engineVersion;
        authCopy.engineVersion = sockMsg.versionCheck.engineVersion;

        // Update the state store with the current service version
        store.dispatch(
          appStateUpdate({
            auth: authCopy,
            app: appCopy,
          }),
        );
      }

      // Don't dispatch an activity update to Redux if the reports state
      // hasn't changed
      if (isReportsEnabledAndActive.active !== sockMsg.reportsCheck.active) {
        store.dispatch(
          startHealthCheck(
            sockMsg.engine,
            sockMsg.reportsCheck,
            sockMsg.appDBCheck,
          ),
        );
      }

      // But update component state on each message, because this keep
      // things like the time-elapsed until license expiry message fresh
      setIsLicenseGood(sockMsg.license);
    })

    // General-purpose messaging channel that raises a toast notification
    .on('message', (sockMsg = {}) => {
      const sMsg = sockMsg;
      if (sMsg.message) {
        sMsg.message = (
          <div dangerouslySetInnerHTML={{ __html: sMsg.message }} /> // eslint-disable-line react/no-danger
        );
        raiseToast(sMsg);
      }
    })

    // Messages in this channel inform administrators of updates to the
    // account list
    .on('account', (sockMsg = {}) => {
      const sMsg = sockMsg;
      const { data } = sMsg;

      // Get the current state object
      const currState = store.getState();

      if (data && currState.auth?.account?.isAdmin) {
        // Extract the payload
        const { accounts, type, username: messageUsername, accountname } = data;

        // Create a copy the `auth` object
        const authCopy = { ...currState.auth };
        const systemCopy = { ...currState.system };

        // Merge the received account data with the `auth` copy and the `system`
        // copy
        authCopy.account.accounts = unionBy(
          accounts,
          authCopy.account.accounts,
          'name',
        );
        systemCopy.accounts = unionBy(accounts, systemCopy.accounts, 'name');

        // Update the state store
        store.dispatch(
          appStateUpdate({
            auth: authCopy,
            system: systemCopy,
          }),
        );

        // Raise a toast to inform administrators (other than the one who
        // invoked the action) that the account has changed. The only
        // exception is if an admin is currently switched to use the data
        // context of an account that is deleted as this condition is
        // handled by the `reset` message handler.
        if (
          sMsg.message &&
          messageUsername !== authCopy.account.username &&
          (type !== 'delete' ||
            (type === 'delete' &&
              authCopy.account.context.accountname !== accountname))
        ) {
          sMsg.message = (
            <div dangerouslySetInnerHTML={{ __html: sMsg.message }} /> // eslint-disable-line react/no-danger
          );
          raiseToast(sMsg);
        }
      }
    })

    // Messages in this channel inform users within the same account of
    // updates to the event list
    .on('event', (sockMsg = {}) => {
      const { data } = sockMsg;

      // Get the current state object
      const currState = store.getState();

      if (data) {
        // Extract the payload (If needed, username and accountname are also
        // passed through)
        const { events, type } = data;

        // Create a copy of the 'events' object
        const eventsCopy = { ...currState.events };
        const {
          data: newData,
          filteredEvents: newFiltered,
          selected: newSelected = {},
          removeEventsErrors = {},
        } = eventsCopy;

        // For an event list clear, update the 'events' data
        if (type === 'clear') {
          // And set it as empty
          const emptyData = {
            item_count: 0,
            next_page: false,
            page: 1,
            results: [],
          };

          eventsCopy.data = emptyData;
          if (newFiltered) eventsCopy.filteredEvents = emptyData;
          eventsCopy.selected = {};
          eventsCopy.isRemovingEvents = false;
          eventsCopy.removeEventsError = false;
          eventsCopy.errorMsg = false;
          eventsCopy.isRemoveEventsModalOpen = false;
        }

        // For event deletion, update the 'events' data
        if (type === 'delete') {
          const { results, errors } = events;
          const checkForRemoval = (arr, currEvent) => {
            const { event_id: id } = currEvent;
            const isSelected = !!newSelected[id];
            let keepEvent = true;
            // Don't keep if event was deleted / not found
            if (
              results.find(result => result === id) ||
              errors.find(err => err.code === 'NotFound' && err.id === id)
            ) {
              keepEvent = false;
            }
            // Remove selection of event if we're not keeping it
            if (!keepEvent && isSelected) delete newSelected[id];
            if (keepEvent) arr.push(currEvent);
            return arr;
          };
          newData.results = newData.results.reduce(checkForRemoval, []);
          eventsCopy.data = newData;
          if (newFiltered) {
            newFiltered.results = newFiltered.results.reduce(
              checkForRemoval,
              [],
            );
            eventsCopy.filteredEvents = newFiltered;
          }
          const newErrors = errors.reduce((errs, currErr) => {
            const { id, code } = currErr;
            const newErrs = { ...errs };
            if (code !== 'NotFound') newErrs[id] = currErr;
            return newErrs;
          }, removeEventsErrors);
          const hasErrors =
            !!(newErrors && Object.keys(newErrors).length) || false;
          // Update references in 'events'
          eventsCopy.selected = newSelected;
          eventsCopy.removeEventsError = hasErrors;
          eventsCopy.removeEventsErrors = hasErrors ? newErrors : false;
        }

        // Update the state store
        store.dispatch(
          appStateUpdate({
            events: eventsCopy,
          }),
        );
      }
    })

    // Messages in this channel inform administrators of updates to RBAC
    // roles, permissions, and memberships lists. This handler should *not*
    // be used to apply RBAC permission changes to the session itself as in-
    // flight updates of this nature this will be handled by a different
    // socket listener that is scoped to the user ID.
    .on('rbac', (sockMsg = {}) => {
      const { data } = sockMsg;

      // Get the current state object
      const currState = store.getState();

      if (data && currState.auth?.account && currState.auth.account?.isAdmin) {
        if (data.roles) {
          // Create a copy the `system` object and assign the data
          const systemCopy = { ...currState.system };
          systemCopy.roles = data.roles;

          // Update the state store
          store.dispatch(appStateUpdate({ system: systemCopy }));
        }
      }
    })

    // Reset the app context, reset app state, and then refresh auth data
    .on('reset', (sockMsg = {}) => {
      const sMsg = sockMsg;

      if (account.context) {
        store
          .dispatch(
            updateAccountContext({
              name: account.accountname,
            }),
          )
          .then(resp => {
            if (resp && resp.type === SET_ACCOUNT_UPDATE_CONTEXT_SUCCESS) {
              const currState = store.getState();
              isAuthenticated = currState?.auth?.isAuthenticated;
              sMsg.message = (
                <div
                  dangerouslySetInnerHTML={{ __html: sMsg.message }} // eslint-disable-line react/no-danger
                />
              );
              setTimeout(() => {
                raiseToast(sMsg);
              }, 0);
              store.dispatch(
                appStateReset({
                  ...((isAuthenticated && currState.auth.account.baseState) ||
                    {}),
                  auth: {
                    ...currState.auth,
                    ...resp.resp.data,
                  },
                }),
              );
              history.replace('/');
            }
          });
      }
    })

    // Terminate the user's session if they receive a logout instruction
    // scoped to their session ID. This will invoke the `logout` Redux
    // action, which will only POST a logout request back to the server if
    // the `server` prop is set to `true` (the default). If the `action` key
    // of the inbound socket message is set to `setLogoutSuccess`, this
    // indicates that a server-side log out is already complete and only
    // client-side logout operations are required, so `false` is passed to
    // the Redux action.
    .on('logout', (sockMsg = {}) => {
      const sMsg = sockMsg;
      store
        .dispatch(
          logout({
            server: sockMsg.action !== 'setLogoutSuccess',
            user: sockMsg.user,
            account: sockMsg.account,
          }),
        )
        .then(() => {
          document.getElementById('bgColor')?.removeAttribute('style');
          sMsg.message = (
            <div dangerouslySetInnerHTML={{ __html: sMsg.message }} /> // eslint-disable-line react/no-danger
          );
          history.replace('/', {});
          raiseToast(sMsg);
        });
    })

    // When a user switches to a different account context using the **Switch
    // Account Data Context** control, update the context across all shared
    // sessions. This update only applies to context-switches invoked by the
    // control and not by URL-driven context switches.
    .on('context', (sockMsg = {}) => {
      const currState = store.getState();

      if (
        !isPageUnloading &&
        currState.auth.account.sessionID === sockMsg.account.sessionID &&
        currState.auth.account.context.accountname !==
          sockMsg.account.context.accountname
      ) {
        // Set the new account context in the page object and in state
        window.App.account = sockMsg.account;
        store.dispatch(
          appStateReset({
            ...(sockMsg.account.baseState || {}),
            auth: { ...currState.auth, ...{ account: sockMsg.account } },
          }),
        );
        const path = document.location.pathname.split('/');

        if (path[1] !== sockMsg.account.context.accountname) {
          history.push(`/${sockMsg.account.context.accountname}`);
        }
      }
    })

    .on('download', (sockMsg = {}) => {
      const currState = store.getState();
      const { notificationType, inProgress, downloadTypeId, id, message } =
        sockMsg;
      const { inProgress: ip } = currState.app.download;
      const alreadyInProgress = ip[`${downloadTypeId}_${id}`];

      if (downloadTypeId && id) {
        if (alreadyInProgress !== inProgress) {
          store.dispatch(setDownloadInProgress(downloadTypeId, id, inProgress));
        }
        if (message) {
          raiseToast({
            toastId: `download_${downloadTypeId}`,
            level: notificationType ?? 'info',
            icon: 'cloud download',
            autoClose: 7000,
            message,
            dismissAll: true,
          });
        }
      }
    });

  return sock;
};

export default socketListenerInit;
