import moment from 'moment-timezone';

import { getUserPermsSync } from '@/rbac';
import { fetchServicesHealth } from '@redux/actions/action-services';
import actions from '@routes/actions.json';

const USER_TIMEZONE = moment.tz.guess();

// Details about the services we want to add to our background cycler. If an
// entry doesn't have a `permissions` key it is only used in admin accounts.
const SERVICE_LIST = [
  {
    action: actions.system,
    fetchFn: fetchServicesHealth,
    isFetching: 'app.servicesHealth.isFetchingServicesHealth',
  },
];

/**
 *
 * @param serviceList
 * @returns {number}
 */
const serviceFetchCycler = ({ setServiceFetchCyclerLastRun, cycle, store }) => {
  if (process.env.BROWSER && window.App?.serviceFetchCyclerId) {
    clearInterval(window.App.serviceFetchCyclerId);
  }
  return setInterval(() => {
    const currState = store.getState();
    const { app, auth } = currState;

    if (
      auth.isAuthenticated &&
      app.healthCheck?.isEngineHealthy &&
      app.healthCheck.isLicenseGood?.isBeforeExpiration
    ) {
      Promise.all(
        SERVICE_LIST.filter(serviceItem => !currState[serviceItem.isFetching])
          .filter(serviceItem => {
            // If this item has no permissions, check to see if this is an admin
            // account
            const isAdmin = !serviceItem.permissions && auth.account?.isAdmin;
            let isPermitted = false;
            // If the user is not an admin and there are permissions then check
            // them against the action list
            if (
              !isAdmin &&
              serviceItem.permissions instanceof Array &&
              serviceItem.permissions.length
            ) {
              const userPermsList = getUserPermsSync({
                account: auth.account,
                actions: serviceItem.action,
              });
              // Every permission must be present amongst the user permissions
              // for this service item
              isPermitted = serviceItem.permissions?.every(
                permission => userPermsList[permission],
              );
            }
            return isAdmin || isPermitted;
          })
          .map(serviceItem =>
            store.dispatch(serviceItem.fetchFn(serviceItem.fetchProps)),
          ),
      ).catch(console.error);
      setServiceFetchCyclerLastRun(moment().tz(USER_TIMEZONE).toISOString());
    }
  }, cycle);
};

export default serviceFetchCycler;
