import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

import DatePicker from '@shared/components/DatePicker';

// eslint-disable-next-line postcss-modules/no-unused-class
import s from './ExpirationDate.scss';

const ExpirationDate = props => {
  const { enabled, value, onSetExpirationDate } = props;

  let strEl = <span>No expiration set</span>;
  const today = moment();

  if (value) {
    const expiry = moment(value);
    const isToday = expiry.isSame(today, 'day');
    const hasPassed = expiry.isBefore(today, 'second');
    strEl = (
      <span className={s.clockIconWrapper}>
        {hasPassed ? (
          <i className={classNames(s.clockIcon, s.expired)} />
        ) : (
          <i
            className={classNames(s.clockIcon, isToday ? s.expiring : s.active)}
          />
        )}
        <span>
          Expire{hasPassed ? 'd' : 's'} {expiry.fromNow()}
        </span>
      </span>
    );
  }

  // Update the data object with the new (or removed) expiration
  const updateDate = dateData =>
    onSetExpirationDate(
      dateData instanceof Date ? moment(dateData).toISOString() : false,
    );

  return (
    <div className={classNames(s.expirationWrapper)}>
      <DatePicker
        value={value}
        customInput={
          <span
            className={classNames(s.triggerGroup, !enabled ? s.disabled : '')}
          >
            <Icon name="calendar alternate outline" />
            {strEl}
          </span>
        }
        onChange={updateDate}
        disabled={!enabled}
      />
      {value && enabled ? (
        <Button basic compact icon size="mini" onClick={updateDate}>
          <Icon color="red" name="times" />
        </Button>
      ) : null}
    </div>
  );
};

ExpirationDate.defaultProps = { value: null, enabled: true };

ExpirationDate.propTypes = {
  enabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onSetExpirationDate: PropTypes.func.isRequired,
};

export default withStyles(s)(ExpirationDate);
