import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment';
import { Container, Grid, List } from 'semantic-ui-react';

import s from './Footer.scss';

const Footer = () => (
  <>
    <div className={s.root}>
      <Container fluid>
        <Grid padded="horizontally" inverted columns="equal">
          <Grid.Row>
            <Grid.Column verticalAlign="middle">
              <List inverted>
                <List.Item as="span" style={{ fontSize: '0.85rem' }}>
                  © {moment().year()} Anchore Inc. All Rights Reserved
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column
              floated="right"
              verticalAlign="middle"
              textAlign="right"
            >
              <List
                horizontal
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <List.Item
                  as="a"
                  href="https://anchore.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={s.anchoreIconSmallInverted}
                />
                <List.Item
                  as="a"
                  href="https://x.com/anchore"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={s.xIconSmallInverted}
                />
                <List.Item
                  as="a"
                  href="https://facebook.com/anchore"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <List.Icon
                    inverted
                    link
                    name="facebook square"
                    size="large"
                    className={s.socialIcon}
                  />
                </List.Item>
                <List.Item
                  as="a"
                  href="https://github.com/anchore"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <List.Icon
                    inverted
                    link
                    name="github"
                    size="large"
                    className={s.socialIcon}
                  />
                </List.Item>
                <List.Item
                  as="a"
                  href="https://linkedin.com/company/anchore"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <List.Icon
                    inverted
                    link
                    name="linkedin square"
                    size="large"
                    className={s.socialIcon}
                  />
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
    <div className={s.shade} />
  </>
);

export default withStyles(s)(Footer);
