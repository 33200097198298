// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.izQDf{align-items:center;color:#000000a6;display:flex;font-size:13.6px;font-size:.85rem;justify-content:space-between;padding:1rem 0 0}.izQDf div *{outline:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `izQDf`
};
module.exports = ___CSS_LOADER_EXPORT___;
