/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Grid, Input, Popup, TextArea, Icon } from 'semantic-ui-react';

import { setCaretToEndOfInput } from '@/DOMUtils';

import s from './Text.scss';

const defaultEditorProps = {
  inputType: 'input',
  inputComponent: null,
  trim: true,
  wide: false,
  saveOnEnter: true,
  open: false,
};

const TextFormatter = props => {
  const { disabled, editable, children, editor, cellClassName } = props;
  const {
    rowId,
    cell,
    open,
    closeEditor,
    updateValue,
    inputType,
    inputComponent,
    trim,
    wide,
    saveOnEnter,
    onClick,
  } = { ...defaultEditorProps, ...editor };
  const { value: cellValue, column } = cell;
  const displayValue = (
    <div className={cellClassName ?? s.cellValue}>{children}</div>
  );
  const [editorValue, setEditorValue] = useState(cellValue);
  const trimmedEditorValue = trim ? editorValue?.trim() : editorValue;
  const inputId = `celleditor-${column.id}-${rowId}`;
  const inputElementProps = {
    id: inputId,
    name: `cellEditor_input_${cell.column.id}`,
    required: true,
    maxLength: '250',
    placeholder: 'Max 250 characters',
    style: { width: '100%' },
    spellCheck: false,
    value: editorValue,
    onChange: (ev, d) => setEditorValue(trim ? d.value.trim() : d.value),
    autoComplete: 'off',
    type: 'text',
  };

  return editable ? (
    <Popup
      className="animate__animated animate__fadeIn"
      style={{ width: '34rem' }}
      flowing
      pinned
      disabled={disabled}
      trigger={
        <div onClick={onClick} className={s.cell}>
          <Button
            className={s.editorButton}
            disabled={disabled}
            icon
            size="tiny"
            labelPosition="right"
            basic
            fluid
          >
            <Icon name="edit" />
            {displayValue}
          </Button>
        </div>
      }
      content={
        <Grid verticalAlign="middle" centered divided columns={2}>
          <Grid.Row>
            <Grid.Column width="13">
              {inputType === 'input' ? <Input {...inputElementProps} /> : null}
              {inputType === 'textarea' ? (
                <TextArea {...inputElementProps} />
              ) : null}
              {inputType === 'custom' && inputComponent ? inputComponent : null}
            </Grid.Column>
            <Grid.Column width="3">
              <Button
                size="tiny"
                compact
                floated="left"
                circular
                disabled={
                  editorValue === cellValue || !trimmedEditorValue.length
                }
                icon="checkmark"
                color="green"
                onClick={() => {
                  updateValue(editorValue);
                  closeEditor();
                }}
              />
              <Button
                size="tiny"
                compact
                floated="right"
                circular
                icon="remove"
                color="red"
                onClick={closeEditor}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }
      onOpen={() => {
        setEditorValue(cellValue);
        setTimeout(() => {
          setCaretToEndOfInput(document.getElementById(inputId));
        }, 0);
      }}
      onClose={closeEditor}
      onKeyDown={ev => {
        if (saveOnEnter && ev.key === 'Enter' && editorValue.trim().length) {
          updateValue(document.getElementById(inputId)?.value);
          closeEditor();
        }
      }}
      on="click"
      open={open}
      position="bottom left"
      wide={wide}
    />
  ) : (
    <div className={s.cell} disabled={disabled}>
      {displayValue}
    </div>
  );
};

TextFormatter.defaultProps = {
  editable: false,
  disabled: false,
  cellClassName: undefined,
};

TextFormatter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
  cellClassName: PropTypes.string,
  editor: PropTypes.shape({
    cell: PropTypes.shape({
      value: PropTypes.string,
      column: PropTypes.shape({
        id: PropTypes.string,
      }),
      row: PropTypes.shape({
        original: PropTypes.shape(),
      }),
    }).isRequired,
    open: PropTypes.bool,
    closeEditor: PropTypes.func.isRequired,
    updateValue: PropTypes.func.isRequired,
    rowId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    inputType: PropTypes.string,
    inputComponent: PropTypes.element,
    trim: PropTypes.bool,
    wide: PropTypes.bool,
    saveOnEnter: PropTypes.bool,
  }).isRequired,
};

export default TextFormatter;
