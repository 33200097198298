import {
  SET_IMAGE_ADD_DIALOG,
  SET_IMAGE_ADD_ERROR,
  SET_IMAGE_ADD_PENDING,
  SET_IMAGE_ADD_SUCCESS,
  SET_IMAGE_REMOVE_ERROR,
  SET_IMAGE_REMOVE_PENDING,
  SET_IMAGE_REMOVE_SUCCESS,
  SET_IMAGE_SELECTION_VIEW_PROPERTIES,
  SET_IMAGES_FETCH_ERROR,
  SET_IMAGES_FETCH_PENDING,
  SET_IMAGES_FETCH_SUCCESS,
  SET_IMAGES_REMOVE_DIALOG,
  SET_IMAGES_REMOVE_ERROR,
  SET_IMAGES_REMOVE_PENDING,
  SET_IMAGES_REMOVE_SUCCESS,
  SET_IMAGES_ROUTE_DATA,
  SET_REPOSITORY_ADD_ERROR,
  SET_REPOSITORY_ADD_PENDING,
  SET_REPOSITORY_ADD_SUCCESS,
  SET_REPOSITORY_WATCH_MODAL,
  SET_SUBSCRIPTION_UPDATE_SUCCESS,
  SET_TAGS_REMOVE_DIALOG,
  SET_TAGS_REMOVE_PENDING,
  SET_TAGS_REMOVE_SUCCESS,
  SET_TAGS_REMOVE_ERROR,
  CLEAR_TAGS_REMOVE_ERROR,
} from '../actionTypes';

export const defaultState = {
  isAddImageModalOpen: false,
  addImageModalRegistry: '',
  addImageModalRepository: '',
  addImageModalTag: '',
  addImageModalDigest: '',
  addImageModalForce: undefined,
  isRemoveImagesModalOpen: false,
  isWatchRepositoryModalOpen: false,
  isRemoveTagsModalOpen: false,
  isFetchingImages: false,
  fetchImagesError: false,
  isAddingImage: false,
  addImageError: false,
  isRemovingImage: false,
  removeImageError: false,
  isRemovingImages: false,
  removeImagesError: false,
  isAddingRepository: false,
  addRepositoryError: false,
  errorMsg: false,
  data: false,
  pending: false,
  routeData: [],
  routeInfo: {},
  viewProps: {
    images: {
      pageSize: 10,
      filterStr: '',
      page: 0,
    },
    registry: {
      pageSize: 10,
      filterStr: '',
      page: 0,
    },
    repository: {
      pageSize: 10,
      filterStr: '',
      page: 0,
      selected: {},
      selectOption: 'custom',
    },
    tag: {
      pageSize: 10,
      filterStr: '',
      page: 0,
    },
    status: {
      pageSize: 10,
      filterStr: '',
      page: 0,
      pending: true,
      analyzing: true,
      failed: true,
      scoped: false,
    },
  },
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    /**
     * *************************************************************************
     * IMAGE SELECTION, TOP-LEVEL - IMAGE DIALOG DATA REDUCERS
     */
    case SET_IMAGE_ADD_DIALOG:
      return {
        ...state,
        isAddImageModalOpen: action.open,
        addImageModalRegistry: action.registry,
        addImageModalRepository: action.repository,
        addImageModalTag: action.tag,
        addImageModalDigest: action.digest,
        addImageModalForce: action.force,
        addImageError: false,
        addRepositoryError: false,
        errorMsg: false,
      };

    case SET_IMAGES_REMOVE_DIALOG:
      return {
        ...state,
        isRemoveImagesModalOpen: action.open,
        errorMsg: false,
      };

    case SET_REPOSITORY_WATCH_MODAL:
      return {
        ...state,
        isWatchRepositoryModalOpen: action.open,
        addRepositoryError: false,
        errorMsg: false,
      };

    case SET_TAGS_REMOVE_DIALOG:
      return {
        ...state,
        isRemoveTagsModalOpen: action.open,
        removeTagsError: false,
        errorMsg: false,
      };

    /**
     * *************************************************************************
     * IMAGE SELECTION, TOP-LEVEL - FETCH IMAGE DATA REDUCERS
     */
    case SET_IMAGES_FETCH_PENDING:
      // For image selection, we only want to deal with nested results
      if (!action.nested) return state;

      return {
        ...state,
        isFetchingImages: true,
        data: false,
        pending: false,
      };

    case SET_IMAGES_FETCH_SUCCESS:
      // For image selection, we only want to deal with nested results
      if (!action.nested) return state;

      return {
        ...state,
        isFetchingImages: false,
        fetchImagesError: false,
        errorMsg: false,
        data: action.resp.data.nested,
        pending: action.resp.data.pending,
      };

    case SET_IMAGES_FETCH_ERROR:
      // For image selection, we only want to deal with nested results
      if (!action.nested) return state;

      return {
        ...state,
        isFetchingImages: false,
        fetchImagesError: true,
        errorMsg: action.errorMsg,
        data: false,
        pending: false,
      };

    case SET_IMAGES_ROUTE_DATA:
      return {
        ...state,
        routeData: action.routeData,
        routeInfo: action.routeInfo,
      };

    /**
     * *************************************************************************
     * REPOSITORY DATA REDUCERS
     */
    case SET_REPOSITORY_ADD_PENDING:
      return {
        ...state,
        isAddingRepository: true,
        addRepositoryError: false,
        errorMsg: false,
      };
    case SET_REPOSITORY_ADD_SUCCESS:
      return {
        ...state,
        isAddingRepository: false,
        addRepositoryError: false,
        errorMsg: false,
        ...(action.resp.data.dryrun
          ? {
              isAddImageModalOpen: state.isAddImageModalOpen,
              addImageModalRegistry: state.registry,
              addImageModalRepository: state.repository,
              addImageModalTag: state.tag,
              addImageModalDigest: state.addImageModalDigest,
              addImageModalForce: state.addImageModalForce,
            }
          : {
              isAddImageModalOpen: false,
              addImageModalRegistry: '',
              addImageModalRepository: '',
              addImageModalTag: '',
              addImageModalDigest: '',
              addImageModalForce: undefined,
            }),
        isWatchRepositoryModalOpen: false,
      };
    case SET_REPOSITORY_ADD_ERROR:
      return {
        ...state,
        isAddingRepository: false,
        addRepositoryError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * IMAGE SELECTION, TOP-LEVEL - ADD IMAGE DATA REDUCERS
     */
    case SET_IMAGE_ADD_PENDING:
      return {
        ...state,
        isAddingImage: action.id || true,
      };
    case SET_IMAGE_ADD_SUCCESS:
      return {
        ...state,
        isAddingImage: false,
        addImageError: false,
        errorMsg: false,
        isAddImageModalOpen: false,
        addImageModalRegistry: '',
        addImageModalRepository: '',
        addImageModalTag: '',
        addImageModalDigest: '',
        addImageModalForce: undefined,
      };
    case SET_IMAGE_ADD_ERROR:
      return {
        ...state,
        isAddingImage: false,
        addImageError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * IMAGE SELECTION, TOP-LEVEL - REMOVE DATA REDUCERS
     */
    case SET_IMAGE_REMOVE_PENDING:
      return {
        ...state,
        isRemovingImage: action.id || true,
      };
    case SET_IMAGE_REMOVE_SUCCESS:
      return {
        ...state,
        isRemovingImage: false,
        removeImageError: false,
        errorMsg: false,
      };
    case SET_IMAGE_REMOVE_ERROR:
      return {
        ...state,
        isRemovingImage: false,
        removeImageError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * IMAGES SELECTION, TOP-LEVEL - REMOVE DATA REDUCERS
     */
    case SET_IMAGES_REMOVE_PENDING:
      return {
        ...state,
        isRemovingImages: true,
      };
    case SET_IMAGES_REMOVE_SUCCESS:
      return {
        ...state,
        isRemovingImages: false,
        removeImagesError: false,
        errorMsg: false,
        isRemoveImagesModalOpen: false,
      };
    case SET_IMAGES_REMOVE_ERROR:
      return {
        ...state,
        isRemovingImages: false,
        removeImagesError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * IMAGE SELECTION VIEW REDUCER
     */
    case SET_IMAGE_SELECTION_VIEW_PROPERTIES:
      return {
        ...state,
        viewProps: action.viewProps,
      };

    case SET_SUBSCRIPTION_UPDATE_SUCCESS:
      return {
        ...state,
        isWatchRepositoryModalOpen: false,
      };

    /**
     * *************************************************************************
     * IMAGE SELECTION, TOP-LEVEL - REMOVE TAG REDUCERS
     */
    case SET_TAGS_REMOVE_PENDING:
      return {
        ...state,
        isRemovingTags: true,
      };
    case SET_TAGS_REMOVE_SUCCESS:
      return {
        ...state,
        isRemovingTags: false,
        removeTagsError: false,
        errorMsg: false,
        isRemoveTagsModalOpen: false,
        viewProps: {
          ...state.viewProps,
          repository: {
            ...state.viewProps.repository,
            selected: {},
          },
        },
      };
    case SET_TAGS_REMOVE_ERROR:
      return {
        ...state,
        isRemovingTags: false,
        removeTagsError: true,
        errorMsg: action.errorMsg,
      };

    case CLEAR_TAGS_REMOVE_ERROR:
      return {
        ...state,
        removeTagsError: false,
        isRemoveTagsModalOpen: false,
      };

    default:
      return state;
  }
};
