import request from 'superagent';

import {
  CLEAR_EVENTS_ERROR,
  CLEAR_EVENTS_PENDING,
  CLEAR_EVENTS_REMOVE_ERRORS,
  CLEAR_EVENTS_SEARCH,
  CLEAR_EVENTS_SUCCESS,
  SET_EVENT_REMOVE_DIALOG,
  SET_EVENT_REMOVE_ERROR,
  SET_EVENT_REMOVE_PENDING,
  SET_EVENT_REMOVE_SUCCESS,
  SET_EVENT_TYPES_FETCH_ERROR,
  SET_EVENT_TYPES_FETCH_PENDING,
  SET_EVENT_TYPES_FETCH_SUCCESS,
  SET_EVENTS_ACTIVE_TAB,
  SET_EVENTS_ERROR,
  SET_EVENTS_PENDING,
  SET_EVENTS_REMOVE_DIALOG,
  SET_EVENTS_REMOVE_ERROR,
  SET_EVENTS_REMOVE_PENDING,
  SET_EVENTS_REMOVE_SUCCESS,
  SET_EVENTS_SEARCH_ERROR,
  SET_EVENTS_SEARCH_PENDING,
  SET_EVENTS_SEARCH_SUCCESS,
  SET_EVENTS_SELECTED,
  SET_EVENTS_SUCCESS,
  SET_EVENTS_VIEW_PROPERTIES,
  SET_NEW_NOTIFICATION_CONFIG_TEST_PENDING,
  SET_NEW_NOTIFICATION_CONFIG_TEST_SUCCESS,
  SET_NEW_NOTIFICATION_CONFIG_TEST_ERROR,
  SET_NOTIFICATION_ADD_DIALOG,
  SET_NOTIFICATION_CONFIG_ADD_ERROR,
  SET_NOTIFICATION_CONFIG_ADD_PENDING,
  SET_NOTIFICATION_CONFIG_ADD_SUCCESS,
  SET_NOTIFICATION_CONFIG_REMOVE_ERROR,
  SET_NOTIFICATION_CONFIG_REMOVE_PENDING,
  SET_NOTIFICATION_CONFIG_REMOVE_SUCCESS,
  SET_NOTIFICATION_CONFIG_TEST_ERROR,
  SET_NOTIFICATION_CONFIG_TEST_PENDING,
  SET_NOTIFICATION_CONFIG_TEST_SUCCESS,
  SET_NOTIFICATION_CONFIG_UPDATE_ERROR,
  SET_NOTIFICATION_CONFIG_UPDATE_PENDING,
  SET_NOTIFICATION_CONFIG_UPDATE_SUCCESS,
  SET_NOTIFICATION_CONFIGS_FETCH_ERROR,
  SET_NOTIFICATION_CONFIGS_FETCH_PENDING,
  SET_NOTIFICATION_CONFIGS_FETCH_SUCCESS,
  SET_NOTIFICATION_REMOVE_DIALOG,
  SET_NOTIFICATION_UPDATE_DIALOG,
  SET_NOTIFICATIONS_ACTIVE_TAB,
  SET_NOTIFICATIONS_SPEC_FETCH_ERROR,
  SET_NOTIFICATIONS_SPEC_FETCH_PENDING,
  SET_NOTIFICATIONS_SPEC_FETCH_SUCCESS,
} from '../actionTypes';

// *** EVENTS VIEW ********************************************************** //

export const setEventsActiveTabIndex = idx => ({
  type: SET_EVENTS_ACTIVE_TAB,
  idx: typeof idx === 'number' ? idx : 0,
});

export const setNotificationsActiveTabIndex = idx => ({
  type: SET_NOTIFICATIONS_ACTIVE_TAB,
  idx: typeof idx === 'number' ? idx : 0,
});

export const setEventsViewProps = (view, props) => (dispatch, getState) => {
  const currState = getState();
  if (currState.events.viewProps[view]) {
    // Copy view properties object
    const viewProps = { ...currState.events.viewProps };
    viewProps[view] = { ...viewProps[view], ...props };
    dispatch({
      type: SET_EVENTS_VIEW_PROPERTIES,
      viewProps,
    });
  }
};

export const setEventsSelected = selected => ({
  type: SET_EVENTS_SELECTED,
  selected,
});

// Remove events - dialog
export const setEventsRemoveDialog = open => ({
  type: SET_EVENTS_REMOVE_DIALOG,
  open,
});

// Remove event - dialog
export const setEventRemoveDialog = open => ({
  type: SET_EVENT_REMOVE_DIALOG,
  open,
});

// *** FETCH EVENTS ********************************************************* //

export const setEventsFetchPending = silent => ({
  type: SET_EVENTS_PENDING,
  silent,
});

export const setEventsFetchSuccess = (resp, params = {}) => ({
  type: SET_EVENTS_SUCCESS,
  before: params.before || false,
  since: params.since || false,
  resp,
});

export const setEventsFetchError = errorMsg => ({
  type: SET_EVENTS_ERROR,
  errorMsg,
});

export const fetchEvents =
  (params = {}) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy = currState.app?.healthCheck?.isEngineHealthy;
    let resp = false;

    if (isHealthy) {
      dispatch(setEventsFetchPending(!!params.silent));
      resp = request
        .get(`/service/events`)
        .query(params)
        .then(eventsResp =>
          dispatch(setEventsFetchSuccess(eventsResp.body, params)),
        )
        .catch(err => {
          let errResp;
          if (err.response?.body?.data) {
            errResp = dispatch(setEventsFetchError(err.response.body.data));
          } else {
            errResp = dispatch(setEventsFetchError());
          }
          return errResp;
        });
    }
    return resp || dispatch(setEventsFetchError());
  };

// *** CLEAR EVENTS ********************************************************* //

export const clearEventsPending = () => ({
  type: CLEAR_EVENTS_PENDING,
});

export const clearEventsSuccess = resp => ({
  type: CLEAR_EVENTS_SUCCESS,
  resp,
});

export const clearEventsError = errorMsg => ({
  type: CLEAR_EVENTS_ERROR,
  errorMsg,
});

export const clearEvents = () => (dispatch, getState) => {
  const currState = getState();
  const isHealthy = currState.app?.healthCheck?.isEngineHealthy;
  let resp = false;

  if (isHealthy) {
    dispatch(clearEventsPending());
    resp = request
      .delete(`/service/events`)
      .then(() =>
        Promise.all([dispatch(clearEventsSuccess()), dispatch(fetchEvents())]),
      )
      .catch(err => {
        let errResp;
        if (err.response?.body?.data) {
          errResp = dispatch(clearEventsError(err.response.body.data));
        } else {
          errResp = dispatch(clearEventsError());
        }
        return errResp;
      });
  }
  return resp || dispatch(clearEventsError());
};

// *** REMOVE EVENT ******************************************************* //

export const setEventRemovePending = () => ({
  type: SET_EVENT_REMOVE_PENDING,
});

export const setEventRemoveSuccess = resp => ({
  type: SET_EVENT_REMOVE_SUCCESS,
  resp,
});

export const setEventRemoveError = errorMsg => ({
  type: SET_EVENT_REMOVE_ERROR,
  errorMsg,
});

export const removeEvent = props => (dispatch, getState) => {
  const currState = getState();
  const isHealthy =
    currState.app &&
    currState.app.healthCheck &&
    currState.app.healthCheck.isEngineHealthy;

  let resp = false;

  if (isHealthy) {
    dispatch(setEventRemovePending());
    resp = request
      .delete(`/service/events/${props.id}`)
      .then(() =>
        dispatch(setEventRemoveSuccess({ data: props.id, status: 'success' })),
      )
      .catch(err => {
        let errResp;
        if (err.response && err.response.body && err.response.body.data) {
          errResp = dispatch(setEventRemoveError(err.response.body.data));
        } else {
          errResp = dispatch(setEventRemoveError());
        }
        return errResp;
      });
  }
  return resp || dispatch(setEventRemoveError());
};

// *** REMOVE EVENTS ****************************************************** //

export const setEventsRemovePending = () => ({
  type: SET_EVENTS_REMOVE_PENDING,
});

export const setEventsRemoveSuccess = resp => ({
  type: SET_EVENTS_REMOVE_SUCCESS,
  resp,
});

export const setEventsRemoveError = errorMsg => ({
  type: SET_EVENTS_REMOVE_ERROR,
  errorMsg,
});

export const clearEventsRemoveErrors = () => ({
  type: CLEAR_EVENTS_REMOVE_ERRORS,
});

export const removeEvents = props => async (dispatch, getState) => {
  const currState = getState();
  const isHealthy =
    currState.app &&
    currState.app.healthCheck &&
    currState.app.healthCheck.isEngineHealthy;

  if (!isHealthy) {
    return dispatch(setEventsRemoveError());
  }

  try {
    dispatch(setEventsRemovePending());

    const {
      body: { data },
    } = await request.delete('/service/events/list').send(props);

    return dispatch(
      setEventsRemoveSuccess({
        data,
        status: 'success',
      }),
    );
  } catch (err) {
    let errResp;
    if (err.response && err.response.body && err.response.body.data) {
      errResp = dispatch(setEventsRemoveError(err.response.body.data));
    } else {
      errResp = dispatch(setEventsRemoveError());
    }
    return errResp;
  }
};

// *** FETCH EVENT TYPES **************************************************** //

export const setEventTypesFetchPending = () => ({
  type: SET_EVENT_TYPES_FETCH_PENDING,
});

export const setEventTypesFetchSuccess = resp => ({
  type: SET_EVENT_TYPES_FETCH_SUCCESS,
  resp,
});

export const setEventTypesFetchError = errorMsg => ({
  type: SET_EVENT_TYPES_FETCH_ERROR,
  errorMsg,
});

export const fetchEventTypes = () => (dispatch, getState) => {
  const currState = getState();
  const isHealthy = currState.app?.healthCheck?.isEngineHealthy;

  let resp = false;

  if (isHealthy) {
    dispatch(setEventTypesFetchPending());
    resp = request
      .get(`/service/event-types`)
      .then(eventsResp => dispatch(setEventTypesFetchSuccess(eventsResp.body)))
      .catch(err => {
        let errResp;
        if (err.response && err.response.body && err.response.body.data) {
          errResp = dispatch(setEventTypesFetchError(err.response.body.data));
        } else {
          errResp = dispatch(setEventTypesFetchError());
        }
        return errResp;
      });
  }
  return resp || dispatch(setEventTypesFetchError());
};

// *** SEARCH EVENTS ******************************************************** //

export const setEventsSearchPending = () => ({
  type: SET_EVENTS_SEARCH_PENDING,
});

export const setEventsSearchSuccess = (resp, params = {}) => ({
  type: SET_EVENTS_SEARCH_SUCCESS,
  params,
  resp,
});

export const setEventsSearchError = errorMsg => ({
  type: SET_EVENTS_SEARCH_ERROR,
  errorMsg,
});

export const searchEvents =
  (params = {}) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy =
      currState.app &&
      currState.app.healthCheck &&
      currState.app.healthCheck.isEngineHealthy;

    let resp = false;

    if (isHealthy) {
      dispatch(setEventsSearchPending());
      resp = request
        .get(`/service/events`)
        .query(params)
        .then(eventsResp =>
          dispatch(setEventsSearchSuccess(eventsResp.body, params)),
        )
        .catch(err => {
          let errResp;
          if (err.response && err.response.body && err.response.body.data) {
            errResp = dispatch(setEventsSearchError(err.response.body.data));
          } else {
            errResp = dispatch(setEventsSearchError());
          }
          return errResp;
        });
    }
    return resp || dispatch(setEventsSearchError());
  };

export const clearEventsSearch = () => ({
  type: CLEAR_EVENTS_SEARCH,
});

// *** NOTIFICATIONS VIEW *************************************************** //

export const setNotificationAddDialog = open => ({
  type: SET_NOTIFICATION_ADD_DIALOG,
  open,
});

export const setNotificationUpdateDialog = id => ({
  type: SET_NOTIFICATION_UPDATE_DIALOG,
  id,
});

export const setNotificationRemoveDialog = id => ({
  type: SET_NOTIFICATION_REMOVE_DIALOG,
  id,
});

// *** FETCH NOTIFICATIONS SPEC ********************************************* //

export const setNotificationsSpecFetchPending = () => ({
  type: SET_NOTIFICATIONS_SPEC_FETCH_PENDING,
});

export const setNotificationsSpecFetchSuccess = resp => ({
  type: SET_NOTIFICATIONS_SPEC_FETCH_SUCCESS,
  resp,
});

export const setNotificationsSpecFetchError = errorMsg => ({
  type: SET_NOTIFICATIONS_SPEC_FETCH_ERROR,
  errorMsg,
});

export const fetchNotificationsSpec = key => (dispatch, getState) => {
  const currState = getState();
  const isHealthy = currState?.app?.healthCheck?.isEngineHealthy;

  let resp = false;

  if (isHealthy) {
    dispatch(setNotificationsSpecFetchPending());
    resp = request
      .get('/service/spec/swagger')
      .query({ key })
      .then(fetchNotificationsSpecResp =>
        dispatch(
          setNotificationsSpecFetchSuccess(fetchNotificationsSpecResp.body),
        ),
      )
      .catch(fetchNotificationsSpecErr => {
        let errResp;
        if (
          fetchNotificationsSpecErr.response &&
          fetchNotificationsSpecErr.response.body &&
          fetchNotificationsSpecErr.response.body.data
        ) {
          errResp = dispatch(
            setNotificationsSpecFetchError(
              fetchNotificationsSpecErr.response.body.data,
            ),
          );
        } else {
          errResp = dispatch(setNotificationsSpecFetchError());
        }
        return errResp;
      });
  }
  return resp || dispatch(setNotificationsSpecFetchError());
};

// *** FETCH NOTIFICATION CONFIGS ******************************************* //

export const setNotificationConfigsFetchPending = () => ({
  type: SET_NOTIFICATION_CONFIGS_FETCH_PENDING,
});

export const setNotificationConfigsFetchSuccess = resp => ({
  type: SET_NOTIFICATION_CONFIGS_FETCH_SUCCESS,
  resp,
});

export const setNotificationConfigsFetchError = errorMsg => ({
  type: SET_NOTIFICATION_CONFIGS_FETCH_ERROR,
  errorMsg,
});

export const fetchNotificationConfigs = () => (dispatch, getState) => {
  const currState = getState();
  const isHealthy = currState?.app?.healthCheck?.isEngineHealthy;

  let resp = false;

  if (isHealthy) {
    dispatch(setNotificationConfigsFetchPending());
    resp = request
      .get(`/service/notifications/configs`)
      .then(fetchNotificationConfigsResp =>
        dispatch(
          setNotificationConfigsFetchSuccess(fetchNotificationConfigsResp.body),
        ),
      )
      .catch(err => {
        let errResp;
        if (err.response && err.response.body && err.response.body.data) {
          errResp = dispatch(
            setNotificationConfigsFetchError(err.response.body.data),
          );
        } else {
          errResp = dispatch(setNotificationConfigsFetchError());
        }
        return errResp;
      });
  }
  return resp || dispatch(setNotificationConfigsFetchError());
};

// *** TEST NEW NOTIFICATION CONFIG ***************************************** //

export const setNewNotificationConfigTestPending = id => ({
  type: SET_NEW_NOTIFICATION_CONFIG_TEST_PENDING,
  id,
});

export const setNewNotificationConfigTestSuccess = resp => ({
  type: SET_NEW_NOTIFICATION_CONFIG_TEST_SUCCESS,
  resp,
});

export const setNewNotificationConfigTestError = errorMsg => ({
  type: SET_NEW_NOTIFICATION_CONFIG_TEST_ERROR,
  errorMsg,
});

export const testNewNotificationConfig =
  (name, id, params) => (dispatch, getState) => {
    const currState = getState();
    const isHealthy = currState?.app?.healthCheck?.isEngineHealthy;

    let resp = false;

    if (isHealthy) {
      dispatch(setNewNotificationConfigTestPending(id));
      resp = request
        .post(`/service/notifications/test/${name}`)
        .send(params)
        .then(testNewNotificationConfigResp =>
          dispatch(
            setNewNotificationConfigTestSuccess(
              testNewNotificationConfigResp.body,
            ),
          ),
        )
        .catch(err => {
          let errResp;
          if (err.response && err.response.body && err.response.body.data) {
            errResp = dispatch(
              setNewNotificationConfigTestError(err.response.body.data),
            );
          } else {
            errResp = dispatch(setNewNotificationConfigTestError(err));
          }
          return errResp;
        });
    }
    return resp || dispatch(setNewNotificationConfigTestError());
  };

// *** TEST NOTIFICATION CONFIG ********************************************* //

export const setNotificationConfigTestPending = id => ({
  type: SET_NOTIFICATION_CONFIG_TEST_PENDING,
  id,
});

export const setNotificationConfigTestSuccess = resp => ({
  type: SET_NOTIFICATION_CONFIG_TEST_SUCCESS,
  resp,
});

export const setNotificationConfigTestError = errorMsg => ({
  type: SET_NOTIFICATION_CONFIG_TEST_ERROR,
  errorMsg,
});

export const testNotificationConfig = (name, id) => (dispatch, getState) => {
  const currState = getState();
  const isHealthy = currState?.app?.healthCheck?.isEngineHealthy;

  let resp = false;

  if (isHealthy) {
    dispatch(setNotificationConfigTestPending(id));
    resp = request
      .get(`/service/notifications/configs/test/${name}/${id}`)
      .then(testNotificationConfigResp =>
        dispatch(
          setNotificationConfigTestSuccess(testNotificationConfigResp.body),
        ),
      )
      .catch(err => {
        let errResp;
        if (err.response && err.response.body && err.response.body.data) {
          errResp = dispatch(
            setNotificationConfigTestError(err.response.body.data),
          );
        } else {
          errResp = dispatch(setNotificationConfigTestError(err));
        }
        return errResp;
      });
  }
  return resp || dispatch(setNotificationConfigTestError());
};

// *** ADD NOTIFICATION CONFIG ********************************************** //

export const setNotificationConfigAddPending = id => ({
  type: SET_NOTIFICATION_CONFIG_ADD_PENDING,
  id,
});

export const setNotificationConfigAddSuccess = resp => ({
  type: SET_NOTIFICATION_CONFIG_ADD_SUCCESS,
  resp,
});

export const setNotificationConfigAddError = errorMsg => ({
  type: SET_NOTIFICATION_CONFIG_ADD_ERROR,
  errorMsg,
});

export const addNotificationConfig =
  (name, conf = {}) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy = currState?.app?.healthCheck?.isEngineHealthy;

    let resp = false;

    if (isHealthy && typeof name === 'string' && name.length) {
      dispatch(setNotificationConfigAddPending(true));
      resp = request
        .post(`/service/notifications/configs/${name}`)
        .send(conf)
        .then(addNotificationConfigResp =>
          // Rather than try to patch the updated response into the existing list
          // on the client, we'll just silently re-fetch the current configs data
          // from the service...
          request
            .get('/service/notifications/configs')
            .then(fetchNotificationConfigsResp =>
              Promise.all([
                dispatch(
                  setNotificationConfigAddSuccess(
                    addNotificationConfigResp.body,
                  ),
                ),
                dispatch(
                  setNotificationConfigsFetchSuccess(
                    fetchNotificationConfigsResp.body,
                  ),
                ),
              ]),
            ),
        )
        .catch(err => {
          let errResp;
          if (
            err.response &&
            err.response.body &&
            err.response.body.data &&
            err.response.body.data.message
          ) {
            errResp = dispatch(
              setNotificationConfigAddError(err.response.body.data),
            );
          } else {
            errResp = dispatch(setNotificationConfigAddError());
          }
          return errResp;
        });
    }
    return resp || dispatch(setNotificationConfigAddError());
  };

// *** UPDATE NOTIFICATION CONFIG ******************************************* //

export const setNotificationConfigUpdatePending = id => ({
  type: SET_NOTIFICATION_CONFIG_UPDATE_PENDING,
  id,
});

export const setNotificationConfigUpdateSuccess = resp => ({
  type: SET_NOTIFICATION_CONFIG_UPDATE_SUCCESS,
  resp,
});

export const setNotificationConfigUpdateError = errorMsg => ({
  type: SET_NOTIFICATION_CONFIG_UPDATE_ERROR,
  errorMsg,
});

export const updateNotificationConfig =
  (name, id, conf = {}) =>
  (dispatch, getState) => {
    const currState = getState();
    const isHealthy = currState?.app?.healthCheck?.isEngineHealthy;

    let resp = false;

    if (
      isHealthy &&
      typeof name === 'string' &&
      name.length &&
      typeof id === 'string' &&
      id.length
    ) {
      dispatch(setNotificationConfigUpdatePending(true));
      resp = request
        .put(`/service/notifications/configs/${name}/${id}`)
        .send(conf)
        .then(updateNotificationConfigResp =>
          // Rather than try to patch the updated response into the existing list
          // on the client, we'll just silently re-fetch the current configs data
          // from the service...
          request
            .get('/service/notifications/configs')
            .then(fetchNotificationConfigsResp =>
              Promise.all([
                dispatch(
                  setNotificationConfigUpdateSuccess(
                    updateNotificationConfigResp.body,
                  ),
                ),
                dispatch(
                  setNotificationConfigsFetchSuccess(
                    fetchNotificationConfigsResp.body,
                  ),
                ),
              ]),
            ),
        )
        .catch(err => {
          let errResp;
          if (
            err.response &&
            err.response.body &&
            err.response.body.data &&
            err.response.body.data.message
          ) {
            errResp = dispatch(
              setNotificationConfigUpdateError(err.response.body.data),
            );
          } else {
            errResp = dispatch(setNotificationConfigUpdateError());
          }
          return errResp;
        });
    }
    return resp || dispatch(setNotificationConfigUpdateError());
  };

// *** DELETE NOTIFICATION CONFIG ******************************************* //

export const setNotificationConfigRemovePending = id => ({
  type: SET_NOTIFICATION_CONFIG_REMOVE_PENDING,
  id,
});

export const setNotificationConfigRemoveSuccess = resp => ({
  type: SET_NOTIFICATION_CONFIG_REMOVE_SUCCESS,
  resp,
});

export const setNotificationConfigRemoveError = errorMsg => ({
  type: SET_NOTIFICATION_CONFIG_REMOVE_ERROR,
  errorMsg,
});

export const removeNotificationConfig = (name, id) => (dispatch, getState) => {
  const currState = getState();
  const isHealthy = currState?.app?.healthCheck?.isEngineHealthy;

  let resp = false;

  if (
    isHealthy &&
    typeof name === 'string' &&
    name.length &&
    typeof id === 'string' &&
    id.length
  ) {
    dispatch(setNotificationConfigRemovePending(id));
    resp = request
      .delete(`/service/notifications/configs/${name}/${id}`)
      .then(() =>
        // Rather than try to patch the updated response into the existing list
        // on the client, we'll just silently re-fetch the current configs data
        // from the service...
        request
          .get(`/service/notifications/configs`)
          .then(fetchNotificationConfigsResp =>
            Promise.all([
              dispatch(setNotificationConfigRemoveSuccess()),
              dispatch(
                setNotificationConfigsFetchSuccess(
                  fetchNotificationConfigsResp.body,
                ),
              ),
            ]),
          ),
      )
      .catch(err => {
        let errResp;
        if (
          err.response &&
          err.response.body &&
          err.response.body.data &&
          err.response.body.data.message
        ) {
          errResp = dispatch(
            setNotificationConfigRemoveError(err.response.body.data),
          );
        } else {
          errResp = dispatch(setNotificationConfigRemoveError());
        }
        return errResp;
      });
  }
  return resp || dispatch(setNotificationConfigRemoveError());
};
